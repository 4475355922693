/**
 * @author TomaszCzura ({}
 */
import axios from 'axios';
import store from '../store';
import * as endpoints from './endpoints';
import * as logModel from './../model/transaction-log';
import * as actions from './../actions/transaction-logs';

export function getTransactionLogs(page, size, sortingName, sortingType, searchPhrase, siteId) {
  const data = {
    page: page,
    size: size,
    query: searchPhrase,
    sort: `${sortingName},${sortingType.toLowerCase()}`,
  };

  if (siteId !== -1) {
    data.siteId = siteId;
  }

  return axios.get(endpoints.TRANSACTION_LOGS, endpoints.headers(data))
      .then((response) => {
        const logs = response.data.content.map((log) => logModel.toLocal(log));
        const totalPages = response.data.totalPages;
        const totalElements = response.data.totalElements;
        store.dispatch(actions.getTransactionLogsSuccess(logs, totalPages, totalElements));
        return response;
      })
      .catch((error) => {
        endpoints.handleUnauthorizedError(error, () => {
          store.dispatch(actions.getTransactionLogsError(error));
        });
      });
}
