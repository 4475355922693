/**
 * @author TomaszCzura ({}
 */
import * as types from '../actions/action-types';

const initialState = {
  transactionLogs: [],
  presentedLog: {},
  getLogsError: null,
  totalPages: 0,
  totalElements: 0,
};

const transactionLogReducer = function(state = initialState, action) {
  switch (action.type) {
    case types.GET_TRANSACTION_LOGS_SUCCESS:
      return Object.assign({}, state, {transactionLogs: action.logs,
        totalPages: action.totalPages,
        totalElements: action.totalElements, getLogsError: null});
    case types.GET_TRANSACTION_LOGS_ERROR:
      return Object.assign({}, state, {
        transactionLogs: [],
        getLogsError: action.error,
        totalPages: 0,
        totalElements: 0,
      });
  }
  return state;
};

export default transactionLogReducer;
