/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

const DATE_FORMAT = 'YYYY-MM-DD H:mm';

class DateRange extends Component {
  componentDidMount() {
    $('#date-picker').daterangepicker({
      timePicker: true,
      timePickerIncrement: 1,
      timePicker24Hour: true,
      autoApply: true,
      opens: 'center',
      drops: 'down',
      orientation: 'left',
      locale: {
        format: DATE_FORMAT,
      },
    }).on('hide.daterangepicker', (ev, picker) => {
      this.props.onChange(picker.startDate.format(DATE_FORMAT), picker.endDate.format(DATE_FORMAT));
    });
  }

  componentDidUpdate() {
    if (this.props) {
      $('#date-picker').data('daterangepicker').setStartDate(this.props.startDateValue);
      $('#date-picker').data('daterangepicker').setEndDate(this.props.endDateValue);
    }
  }

  render() {
    let required = '';
    if (this.props.isRequired) {
      required = <span className="required">*</span>;
    }
    const isReadonly = this.props.isReadonly;

    return (
      <div className={`form-group form-md-line-input ${this.props.error ? 'has-error' : ''}`}>
        <label className="control-label col-md-3">
          {this.props.labelText}
          {required}
        </label>
        <div className="col-md-4">
          <div className="input-group input-large">
            <input type="text" className="form-control" id="date-picker"
              value={`${this.props.startDateValue} - ${this.props.endDateValue}`}
              name={this.props.checkDateId}
              readOnly={isReadonly}
              disabled={isReadonly}
            />
            <div className="form-control-focus"> </div>
          </div>
        </div>
        <div className="col-md-9 col-md-offset-3">
          {this.props.error ? (<span className="error-block">{this.props.error}</span>) : null}
        </div>
      </div>
    );
  }
}

DateRange.propTypes = {
  labelText: PropTypes.string,
  checkDateId: PropTypes.string,
  endDateId: PropTypes.string,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  startDateValue: PropTypes.string,
  endDateValue: PropTypes.string,
  error: PropTypes.string,
  isReadonly: PropTypes.bool,
};
DateRange.defaultProps = {};

export default DateRange;
