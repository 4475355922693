/**
 * @author TomaszCzura ({}
 */

import axios from 'axios';
import store from '../store';
import * as actions from '../actions/users-actions';
import * as endpoints from './endpoints';
import {toLocal, userLocalToNetwork} from '../model/user';
import {getCurrentUserId, logout} from '../utils/auth';

export function registerNewUser(user) {
  const data = userLocalToNetwork(user);
  return axios.post(endpoints.USERS, data, endpoints.headers())
      .then((response) => {
        store.dispatch(actions.createUserSuccess(user));
        return response;
      })
      .catch(function(error) {
        const userError = actions.createUserError(error);
        store.dispatch(userError);
        return userError;
      });
}

export function getUsers() {
  return axios.get(endpoints.USERS, endpoints.headers())
      .then((response) => {
        const users = response.data.map((user) => toLocal(user));
        store.dispatch(actions.getUsersSuccess(users));
        return response;
      })
      .catch((error) => {
        endpoints.handleUnauthorizedError(error, () => {
          store.dispatch(actions.getUsersError(error));
        });
      },
      );
}

export function getUser(userId) {
  const user = store.getState().usersState.users.filter((user) => user.id === userId)[0];
  if (user) {
    return store.dispatch(actions.getUserSuccess(user));
  } else {
    return axios.get(`${endpoints.USERS}/${userId}`, endpoints.headers())
        .then((response) => {
          const user = toLocal(response.data);
          store.dispatch(actions.getUserSuccess(user));
          return response;
        })
        .catch(function(error) {
          store.dispatch(actions.getUserError(error));
        });
  }
}

export function getUserProfile() {
  const profile = store.getState().usersState.userProfile;
  const currentUserId = parseInt(getCurrentUserId());
  if (profile && profile.id === currentUserId) {
    const profileCopy = Object.assign({}, profile);
    return store.dispatch(actions.getProfileSuccess(profileCopy));
  } else {
    return axios.get(`${endpoints.USERS}/${currentUserId}`, endpoints.headers())
        .then((response) => {
          const profile = toLocal(response.data);
          store.dispatch(actions.getProfileSuccess(profile));
          store.dispatch(actions.changeLanguage(profile.language));
          return response;
        })
        .catch(function(error) {
          if (error && error.response && error.response.status === 404) {
            logout(() => {
              const loginUrl = window.location.protocol + '//' + window.location.host + '/login';
              window.location.replace(loginUrl);
            });
          } else {
            store.dispatch(actions.getProfileError(error));
          }
        });
  }
}

export function updateUser(user) {
  const data = userLocalToNetwork(user);

  return axios.put(`${endpoints.USERS}/${user.id}`, data, endpoints.headers())
      .then((response) => {
        user.simpleSites = [];
        if (!user.availableSites) {
          user.availableSites = user.sites;
        }
        user.sites = [];

        user.siteIds.forEach(function(part, index) {
          const siteId = parseInt(user.siteIds[index]);
          const site = user.availableSites.find((site) => site.id === siteId);
          const siteCopy = site;
          siteCopy.selectedByUser = true;
          user.sites.push(siteCopy);
          const simpleSite = {'id': site.id, 'name': site.name};

          if (simpleSite) {
            user.simpleSites.push(simpleSite);
          }
        });

        store.dispatch(actions.updateUserSuccess(user));
        return response;
      })
      .catch(function(error) {
        store.dispatch(actions.updateUserError(user, error));
      });
}

export function updateOwnUser(user) {
  const data = userLocalToNetwork(user);

  return axios.put(`${endpoints.USERS}/${user.id}`, data, endpoints.headers())
      .then((response) => {
        user.simpleSites = [];
        user.sites.forEach(function(part, index) {
          user.sites[index].selectedByUser = false;
        });

        user.siteIds.forEach(function(part, index) {
          const siteId = parseInt(user.siteIds[index]);
          const siteIndex = user.sites.findIndex((site) => site.id === siteId);
          user.sites[siteIndex].selectedByUser = true;
          const site = user.sites[siteIndex];
          const simpleSite = {'id': site.id, 'name': site.name};

          if (simpleSite) {
            user.simpleSites.push(simpleSite);
          }
        });

        store.dispatch(actions.changeLanguage(user.language));
        store.dispatch(actions.updateUserSuccess(user));
        return response;
      })
      .catch(function(error) {
        store.dispatch(actions.updateUserError(user, error));
      });
}

export function disableOrEnableUser(user) {
  return axios.delete(`${endpoints.USERS}/${user.id}`, endpoints.headers())
      .then((response) => {
        store.dispatch(actions.updateUserSuccess(user));
        return response;
      })
      .catch(function(error) {
        store.dispatch(actions.updateUserSuccess(user, error));
      });
}
