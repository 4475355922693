/**
 * @author TomaszCzura ({}
 */
import React from 'react';
import PropTypes from 'prop-types';
import * as user from './../../model/user';
import * as usersApi from './../../api/users-api';
import * as auth from './../../utils/auth';
import i18next from 'i18next';
import ModalDialog from './../utils/ModalDialog';
import TextInput from './../utils/TextInput';
import Form from './../utils/Form';
import SelectInput from './../utils/SelectInput';
import * as blockUtils from './../../utils/BlockUtils';
import FormComponent from './../utils/FormComponent';
import ErrorBox from './../utils/ErrorBox';
import {getErrorDescription} from '../../utils/view-utils';
import Multiselect from './../utils/Multiselect';


class EditAnotherUserForm extends FormComponent {
  constructor(props) {
    super(props);

    this.sendForm = this.sendForm.bind(this);
    this.validateUsername = this.validateUsername.bind(this);
    this.onSitesChange = this.onSitesChange.bind(this);
    this.mapAvailableRolesToObjects = this.mapAvailableRolesToObjects.bind(this);
  }

  initialState() {
    return user.toForm(this.props.presentedUser, this.props.availableRoles, this.props.sites);
  }

  sendForm() {
    const validEmail = this.validateEmail();

    if (validEmail) {
      if (this.state.id) {
        usersApi.updateUser(this.state).catch((err) => this.handleError(err));
        blockUtils.blockUI();
      }
    }
  }

  validateUsername(username = this.state.username) {
    if (username) {
      this.setState({usernameError: ''});
      return true;
    } else {
      this.setState({usernameError: i18next.t('errors.is_required', {field: i18nexaddt.t('users.attrs.login')})});
      return false;
    }
  }

  validateEmail(email = this.state.email) {
    if (email) {
      this.setState({emailError: ''});
      return true;
    } else {
      this.setState({emailError: i18next.t('errors.is_required', {field: i18next.t('users.attrs.email')})});
      return false;
    }
  }

  handleError(error) {
    this.setState({
      error: error,
    });
  }

  onSitesChange(selectedSites) {
    const state = {};
    if (!selectedSites) {
      selectedSites = [];
    } else {
      selectedSites = selectedSites.filter((e) => {
        return e !== undefined;
      });
    }
    state['siteIds'] = selectedSites;
    this.setState(state);
  }

  mapAvailableRolesToObjects() {
    return this.state.availableRoles.map((role) => {
      return {'id': role, 'name': i18next.t([`users.roles.${role}`])};
    });
  }

  render() {
    return (
      <ModalDialog modalTitle={i18next.t('users.edit', {username: this.state.username})}
        dialogId={this.props.dialogId} onDialogHide={blockUtils.unblockUI}
        onDialogShown={this.onFormShow} onSubmit={this.sendForm}>

        <Form>
          <ErrorBox errorMessage={getErrorDescription(this.state.error)} />

          <TextInput fieldId="username" labelText={i18next.t('users.attrs.login')} isRequired={true}
            error={this.state.usernameError}
            validate={this.validateUsername} onChange={this.onChange} value={this.state.username} />

          <TextInput fieldId="email" labelText={i18next.t('users.attrs.email')}
            onChange={this.onChange} value={this.state.email} error={this.state.emailError} />

          <SelectInput fieldId="language" labelText={i18next.t('users.attrs.language')} selected={this.state.language}
            onChange={this.onChange} values={user.UserLanguages} />

          {this.state.id !== auth.getCurrentUserId() ?
          <SelectInput fieldId="role" labelText={i18next.t('users.attrs.role')}
            onChange={this.onChange}
            values={this.mapAvailableRolesToObjects()} selected={this.state.role} /> :
          null}

          <Multiselect fieldId="siteIds" labelText={i18next.t('sites.name', {count: 2})}
            selected={this.state.siteIds}
            selectableHeader={i18next.t('developers.attrs.available_sites')}
            selectionHeader={i18next.t('developers.attrs.access_to')}
            values={this.state.availableSites} onChange={this.onSitesChange} />

        </Form>
      </ModalDialog>
    );
  }
}

EditAnotherUserForm.propTypes = {
  dialogId: PropTypes.string,
  presentedUser: PropTypes.object,
};

EditAnotherUserForm.defaultProps = {};

export default EditAnotherUserForm;
