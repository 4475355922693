import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

class DatePickerInput extends Component {
  componentDidMount() {
    $('#date-picker').datepicker({
      autoApply: true,
      autoclose: true,
      opens: 'center',
      drops: 'down',
      orientation: 'left',
      format: 'dd.mm.yyyy',
    }).on('hide', (ev) => {
      this.props.onChange(moment(ev.date).format('DD.MM.YYYY'));
      ev.stopPropagation();
    });
  }

  componentDidUpdate() {
    if (this.props) {
      $('#date-picker').data('datepicker').setStartDate(this.props.initialDate);
    }
  }

  render() {
    let required = '';
    if (this.props.isRequired) {
      required = <span className="required">*</span>;
    }
    const isReadonly = this.props.isReadonly;

    return (
      <div className={`form-group form-md-line-input ${this.props.error ? 'has-error' : ''}`}>
        <label className="control-label col-md-3">
          {this.props.labelText}
          {required}
        </label>
        <div className="col-md-4">
          <div className="input-group input-large">
            <input
              type="text"
              className="form-control"
              id="date-picker"
              value={this.props.initialDate}
              name={this.props.checkDateId}
              readOnly={isReadonly}
              disabled={isReadonly}
            />
            <div className="form-control-focus"> </div>
          </div>
        </div>
        <div className="col-md-9 col-md-offset-3">
          {this.props.error ? (<span className="error-block">{this.props.error}</span>) : null}
        </div>
      </div>
    );
  }
}

DatePickerInput.propTypes = {
  initialDate: PropTypes.string,
  checkDateId: PropTypes.string,
  labelText: PropTypes.string,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.string,
  isReadonly: PropTypes.bool,
};

export default DatePickerInput;
