/**
 * @author Tomasz Czura (9/7/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Form from '../utils/Form';
import TextInfo from '../utils/TextInfo';
import BooleanInfo from '../utils/BooleanInfo';
import TokenPortlet from './TokenPortlet';
import i18next from 'i18next';
import * as devApi from './../../api/developers-api';
import * as viewUtils from './../../utils/view-utils';
import * as blockUtils from './../../utils/BlockUtils';
import * as developerModel from './../../model/developer';

class DeveloperDetails extends Component {
  constructor(props) {
    super(props);

    this.onPasswordReset = this.onPasswordReset.bind(this);
    this.onRegeneratePMSToken = this.onRegeneratePMSToken.bind(this);
    this.onRevokePMSToken = this.onRevokePMSToken.bind(this);
    this.onRegenerateGuestToken = this.onRegenerateGuestToken.bind(this);
    this.onRevokeGuestToken = this.onRevokeGuestToken.bind(this);
  }

  onRegeneratePMSToken(developer) {
    blockUtils.blockUI();
    devApi.regeneratePMSToken(developer, () => this.onTokenRegenerated(), (error) => this.onTokenRegenerated(error));
  }

  onRevokePMSToken(developer) {
    blockUtils.blockUI();
    devApi.revokePMSToken(developer, () => this.onTokenRevoked(), (error) => this.onTokenRevoked(error));
  }

  onRegenerateGuestToken(developer) {
    blockUtils.blockUI();
    devApi.regenerateGuestToken(developer, () => this.onTokenRegenerated(), (error) => this.onTokenRegenerated(error));
  }

  onRevokeGuestToken(developer) {
    blockUtils.blockUI();
    devApi.revokeGuestToken(developer, () => this.onTokenRevoked(), (error) => this.onTokenRevoked(error));
  }

  onTokenRevoked(error) {
    blockUtils.unblockUI();
    if (error) {
      window.showAlert(i18next.t('error'), viewUtils.getErrorDescription(error));
    } else {
      window.showAlert(i18next.t('success'), i18next.t('developers.token_revoked'));
    }
  }

  onTokenRegenerated(error) {
    blockUtils.unblockUI();
    if (error) {
      window.showAlert(i18next.t('error'), viewUtils.getErrorDescription(error));
    } else {
      window.showAlert(i18next.t('success'), i18next.t('developers.token_regenerated'));
    }
  }

  onPasswordReset() {
    confirm(() => this.resetPassword(), i18next.t('developers.sure_to_reset'));
  }

  resetPassword() {
    blockUtils.blockUI();
    devApi.resetPassword(this.props.presentedDeveloper, (developer) => {
      blockUtils.unblockUI();
      window.showAlert(i18next.t('developers.password_changed'),
          i18next.t('developers.new_password', {
            password: developer.password,
          },
          ),
      );
    }, (error) => {
      blockUtils.unblockUI();
      window.showAlert(i18next.t('error'), viewUtils.getErrorDescription(error));
    });
  }

  render() {
    return (
      <Form>
        <TextInfo labelText={i18next.t('developers.attrs.email')} value={this.props.presentedDeveloper.email} />
        <BooleanInfo labelText={i18next.t('developers.attrs.active')} value={this.props.presentedDeveloper.active} />

        {this.props.presentedDeveloper.sites ? (
          <div className="form-group form-md-line-input">
            <label className="col-md-2 control-label" >{i18next.t('developers.attrs.sites')} </label>
            <div className="col-md-5">
              <ul className="form-control form-control-static">
                {this.props.presentedDeveloper.sites.map((site) => {
                  return <li key={site.id}>{site.name} (ID: {site.id})</li>;
                })}
              </ul>
            </div>

          </div>) : null}


        <TextInfo labelText={i18next.t('developers.attrs.push_url')} value={this.props.presentedDeveloper.pushUrl} />
        <TextInfo labelText={i18next.t('developers.attrs.push_method')}
          value={this.props.presentedDeveloper.pushMethod}
        />
        <TextInfo labelText={i18next.t('developers.attrs.push_content_type')}
          value={this.props.presentedDeveloper.pushContentType}
        />
        <TextInfo labelText={i18next.t('developers.attrs.push_authorization')}
          value={this.props.presentedDeveloper.pushAuthorization}
        />
        <TextInfo labelText={i18next.t('developers.attrs.push_body')}
          value={this.props.presentedDeveloper.pushBody}
        />
        <BooleanInfo
          labelText={i18next.t('developers.attrs.own_email_verification')}
          value={this.props.presentedDeveloper.hasOwnEmailVerificationService}
        />


        <button type="button" className="btn btn-warning" onClick={this.onPasswordReset}>
          {i18next.t('developers.actions.reset_password')}
        </button>

        {this.props.presentedDeveloper ?
          <div className="row">
            <TokenPortlet title={i18next.t('developers.pms_api_token')}
              onRevoke={this.onRevokePMSToken} onRegenerate={this.onRegeneratePMSToken}
              token={this.props.presentedDeveloper.pmsToken}
              encoded={developerModel.encodedPMSToken(this.props.presentedDeveloper)}
              developer={this.props.presentedDeveloper} />
            <TokenPortlet title={i18next.t('developers.guest_api_token')}
              onRevoke={this.onRevokeGuestToken} onRegenerate={this.onRegenerateGuestToken}
              token={this.props.presentedDeveloper.guestToken}
              encoded={developerModel.encodedGuestToken(this.props.presentedDeveloper)}
              developer={this.props.presentedDeveloper} />
          </div> :
          null
        }
      </Form>
    );
  }
}

DeveloperDetails.propTypes = {
  presentedDeveloper: PropTypes.object,
};
DeveloperDetails.defaultProps = {};

export default DeveloperDetails;
