/**
 * @author Tomasz Czura (9/17/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

class Multiselect extends Component {
  componentDidMount() {
    const $multiSelect = $(`#${this.props.fieldId}`);
    const onChange = () => {
      const disabledOptions = $(`.disabledOption`).get();
      const disabledValues = disabledOptions.map((option) => {
        if (option.value && option.selected) {
          return option.value.toString();
        }
      });
      this.props.onChange($multiSelect.val()?.concat(disabledValues));
    };
    $multiSelect.multiSelect({
      selectableHeader: this.props.selectableHeader,
      selectionHeader: this.props.selectionHeader,
      afterSelect: onChange,
      afterDeselect: onChange,
    });
    $multiSelect.multiSelect('select', this.props.selected.map((id) => id.toString()));
  }

  render() {
    return (
      <div className="form-group form-md-line-input">
        <label className="col-md-3 control-label" htmlFor={this.props.fieldId}>
          {this.props.labelText}
          {this.props.isRequired ? (<span className="required">*</span>) : null}
        </label>
        <div className="col-md-9">
          <select multiple="multiple" className="multi-select" id={this.props.fieldId}>
            {this.props.values.map((value) => {
              if (this.props.available) {
                if (this.props.available.find((a) => a.id === value.id)) {
                  return (
                    <option key={value.id + '-' + value.name} value={value.id}>{value.name}</option>
                  );
                } else {
                  return (
                    <option className="disabledOption" selected={value.selectedByUser} disabled
                      key={value.id + '-' + value.name} value={value.id}>
                      {value.name}
                    </option>
                  );
                }
              } else {
                return (
                  <option key={value.id + '-' + value.name} value={value.id}>{value.name}</option>
                );
              }
            })
            }
          </select>
        </div>
      </div>
    );
  }
}

Multiselect.propTypes = {
  onChange: PropTypes.func,
  fieldId: PropTypes.string,
  values: PropTypes.array,
  labelText: PropTypes.string,
  isRequired: PropTypes.bool,
  selected: PropTypes.array,
  selectableHeader: PropTypes.string,
  selectionHeader: PropTypes.string,
  available: PropTypes.array,
};
Multiselect.defaultProps = {
  selectableHeader: i18next.t('developers.attrs.available_sites'),
  selectionHeader: i18next.t('developers.attrs.access_to'),
};

export default Multiselect;
