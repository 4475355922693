/**
 * @author Tomasz Czura (11/23/16)
 */

export function toLocal(dashboard) {
  return {
    batteryAlerts: dashboard.batteryAlerts || [],
    feeds: dashboard.feeds,
    changeLogs: dashboard.changeLogs || [],
  };
}
