/**
 * @author Tomasz Czura (11/23/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import {selectedSiteId} from '../../store';
import {getLockCodeText} from '../../model/transaction-log';
import {DashboardMessageTypes} from '../../socket/DashboardSocket';
import TimeAgo from 'react-timeago';
import moment from 'moment';

class FeedRow extends Component {
  constructor(props) {
    super(props);

    this.getFeedIconBackground = this.getFeedIconBackground.bind(this);
    this.getFeedIcon = this.getFeedIcon.bind(this);
    this.getFeedDescription = this.getFeedDescription.bind(this);
  }

  getFeedIcon() {
    switch (this.props.feed.type) {
      case DashboardMessageTypes.NEW_MOBILE_USER: return 'fa-user-plus';
      case DashboardMessageTypes.NEW_ACTIVE_MOBILE_USER: return 'fa-user';
      case DashboardMessageTypes.KEY_ISSUED: return 'fa-key';
      case DashboardMessageTypes.SITE_CONNECTION_CHANGE:
        return this.props.feed.hmsConnection || this.props.feed.siteAgentConnection ? 'fa-check' : 'fa-warning';
      case DashboardMessageTypes.DOOR_OPENING:
        return this.props.feed.success ? 'fa-unlock' : 'fa-warning';
    }
  }

  getFeedIconBackground() {
    if (this.props.feed.type === DashboardMessageTypes.DOOR_OPENING) {
      return this.props.feed.success ? 'label-success' : 'label-danger';
    } else if (this.props.feed.type === DashboardMessageTypes.SITE_CONNECTION_CHANGE) {
      return (this.props.feed.hmsConnection === true) ||
      (this.props.feed.siteAgentConnection === true) ? 'label-success' : 'label-danger';
    } else {
      return 'label-success';
    }
  }

  getFeedDescription() {
    switch (this.props.feed.type) {
      case DashboardMessageTypes.NEW_MOBILE_USER: return i18next.t('toastr.new_user');
      case DashboardMessageTypes.NEW_ACTIVE_MOBILE_USER: return i18next.t('toastr.new_active_user');
      case DashboardMessageTypes.KEY_ISSUED: return i18next.t('toastr.new_key', {room: this.props.feed.room});
      case DashboardMessageTypes.SITE_CONNECTION_CHANGE:
        if (this.props.feed.hmsConnection === true) {
          return i18next.t('dashboard.hms_connected');
        } else if (this.props.feed.hmsConnection === false) {
          return i18next.t('dashboard.hms_disconnected');
        } else if (this.props.feed.siteAgentConnection === true) {
          return i18next.t('dashboard.site_agent_connected');
        } else if (this.props.feed.siteAgentConnection === false) {
          return i18next.t('dashboard.site_agent_disconnected');
        } else {
          return '';
        }
      case DashboardMessageTypes.DOOR_OPENING:
        return this.props.feed.success ?
                    i18next.t('dashboard.feed_opened', {room: this.props.feed.room}) :
                    i18next.t('dashboard.feed_not_opened',
                        {
                          room: this.props.feed.room,
                          error: getLockCodeText(parseInt(this.props.feed.errorCode)),
                        });
    }
  }

  render() {
    let feedDescription = this.getFeedDescription();
    if (selectedSiteId() !== this.props.feed.siteId && this.props.feed.type !== DashboardMessageTypes.NEW_MOBILE_USER) {
      feedDescription = `${this.props.feed.siteName} - ${feedDescription}`;
    }

    return (
      <li>
        <div className="col1">
          <div className="cont">
            <div className="cont-col1">
              <div className={`label label-sm ${this.getFeedIconBackground()}`}>
                <i className={`fa ${this.getFeedIcon()}`}/>
              </div>
            </div>
            <div className="cont-col2">
              <div className="desc">{feedDescription}</div>
            </div>
          </div>
        </div>
        <div className="col2">
          <div className="date"><TimeAgo date={moment(this.props.feed.date)}/></div>
        </div>
      </li>
    );
  }
}

FeedRow.propTypes = {
  feed: PropTypes.object,
};
FeedRow.defaultProps = {};

export default FeedRow;
