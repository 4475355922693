/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import HotelSelector from './HotelSelector';

class Actions extends Component {
  render() {
    return (
      <div className="page-actions">
        <HotelSelector/>
      </div>
    );
  }
}

Actions.propTypes = {};
Actions.defaultProps = {};

export default Actions;

