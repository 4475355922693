/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReduxDataTable, {onRowClick} from './../redux_data_table/ReduxDataTable';
import i18next from 'i18next';
import * as viewUtils from './../../utils/view-utils';
import * as blockUtils from './../../utils/BlockUtils';
import * as mobileUsersApi from './../../api/mobile-users-api';
import TableActions from './../utils/TableActions';
import ShowAction from './../utils/actions/ShowAction';
import DestroyAction from './../utils/actions/DestroyAction';
import {hasReservations} from '../../model/mobile-users';
import * as rights from '../../model/rights';

class MobileUsersList extends Component {
  constructor(props) {
    super(props);
    this.tableId = 'mobile_users_table';
    this.destroyMobileUser = this.destroyMobileUser.bind(this);
  }

  componentDidMount() {
    $('.popovers').popover();
  }

  destroyMobileUser(user) {
    blockUtils.blockUI(`#${this.tableId}`);
    mobileUsersApi.destroyMobileUser(user.id);
  }

  renderReservations(reservations) {
    if (reservations == null) {
      return (
        <tr>
          <td colSpan="4">---</td>
        </tr>
      );
    }
    return reservations.map((reservation) =>
      <tr key={reservation.id}>
        <td>{reservation.siteName}</td>
        <td>{reservation.reservationId}</td>
        <td>{viewUtils.toYYYYMMDDHM(reservation.checkinDate)}</td>
        <td>{viewUtils.toYYYYMMDDHM(reservation.checkoutDate)}</td>
      </tr>,
    );
  }

  render() {
    return (
      <div className="table-container">
        <ReduxDataTable {...this.props} notSortable={[0, 2, 4]} collapsable={true}>
          <thead>
            <tr role="row" className="heading">
              <th/>
              <th data-sort="email">{i18next.t('mobile_users.attrs.email')}</th>
              <th>{i18next.t('mobile_users.attrs.nr_of_devices')}</th>
              <th>{i18next.t('mobile_users.attrs.register_date')}</th>
              <th>{i18next.t('actions')}</th>
              <th className="hidden"/>
            </tr>
          </thead>
          <tbody>
            {
              this.props.users.map((user) => {
                return (
                  <tr key={user.id} onClick={(event) => onRowClick(user.id, event)} className="odd gradeX collapsible">
                    <td className={`${hasReservations(user) ? 'control' : ''}`}/>
                    <td>
                      { user.email ?
                      (
                        <span className={
                          `label verified_label ${user.emailVerified ? 'label-success' : 'label-warning'}`
                        }>
                          {user.email}
                        </span>
                      ) : null
                      }
                    </td>
                    <td>{user.mobileDevices}</td>
                    <td>
                      {/* this is a filed for sorting with datatable - not visible*/}
                      <span className="date_sort_helper">{viewUtils.toYYYYMMDDHHSS(user.registrationDate)}</span>
                      {viewUtils.toYYYYMMDDHM(user.registrationDate)}
                    </td>
                    <td>
                      <TableActions identifier={user.id}>
                        <ShowAction path={`/mobile_users/${user.id}/logs`}
                          title={i18next.t('mobile_users.actions.show_logs')}
                          icon="icon-list"
                        />
                        <DestroyAction
                          destroyElement={() => this.destroyMobileUser(user)}
                          condition={rights.currentUserHasRight(rights.MOBILE_USER_DELETE_PRIVILEGE)}
                        />
                      </TableActions>
                    </td>
                    <td className="hidden">
                      <table className="table table-responsive table-striped">
                        <thead>
                          <tr>
                            <th>{i18next.t('mobile_users.attrs.developer_name')}</th>
                            <th>{i18next.t('mobile_users.attrs.full_name')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{user.developerName}</td>
                            <td>{user.firstName} {user.lastName}</td>
                          </tr>
                        </tbody>
                      </table><br/>
                      <table className="table table-responsive table-striped">
                        <thead>
                          <tr>
                            <th>{i18next.t('checkins.attrs.site_name')}</th>
                            <th>{i18next.t('checkins.attrs.reservation_id')}</th>
                            <th>{i18next.t('checkins.attrs.start_date')}</th>
                            <th>{i18next.t('checkins.attrs.end_date')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.renderReservations(user.reservations)}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </ReduxDataTable>
      </div>
    );
  }
}

MobileUsersList.propTypes = {
  users: PropTypes.array,
  error: PropTypes.any,
};
MobileUsersList.defaultProps = {};

export default MobileUsersList;
