/**
 * @author TomaszCzura ({}
 */

export const UserLanguages = [
  {id: 'en', name: 'English'},
  {id: 'de', name: 'Deutsch'},
];

export function defaultRegisterNewUser(availableRoles, sites) {
  return {
    username: '',
    password: '',
    passwordConfirmation: '',
    role: 'ROLE_VISITOR',
    passwordError: '',
    usernameError: '',
    passwordConfError: '',
    email: '',
    siteIds: [],
    sites: sites || [],
    language: 'en',
    availableRoles: availableRoles || [],
    enabled: true,
  };
}

export function toForm(user, availableRoles, sites) {
  return {
    id: user.id,
    username: user.username,
    email: user.email,
    role: user.role,
    siteName: user.siteName,
    siteId: user.siteId,
    password: '',
    passwordConfirmation: '',
    siteIds: filterSitesBasedOnSelected(user.sites),
    sites: user.sites || [],
    availableSites: sites,
    simpleSites: createSimpleSitesFromSelectedSites(user.sites),
    language: user.language || 'en',
    availableRoles: availableRoles || user.availableRoles || [],
    authorities: user.authorities || [],
    enabled: user.enabled,
  };
}

export function toLocal(user) {
  return {
    id: user.id,
    username: user.username,
    role: user.role,
    siteIds: filterSitesBasedOnSelected(user.sites),
    sites: user.sites,
    simpleSites: createSimpleSitesFromSelectedSites(user.sites),
    language: user.language,
    email: user.email || '',
    authorities: user.authorities || [],
    availableRoles: user.availableRoles || [],
    enabled: user.enabled,
  };
}

export function userLocalToNetwork(user) {
  return {
    username: user.username,
    password: user.password,
    passwordConfirmation: user.passwordConfirmation,
    siteIds: user.siteIds,
    language: user.language,
    email: user.email,
    accountType: user.role,
    enabled: user.enabled,
  };
}

export function filterSitesBasedOnSelected(sites) {
  if (!sites || !sites.length) {
    return [];
  }

  return sites.filter((site) => site.selectedByUser).map((site) => site.id);
}

export function createSimpleSitesFromSelectedSites(sites) {
  if (!sites || !sites.length) {
    return [];
  }

  return sites.filter((site) => site.selectedByUser).map((site) => {
    const container = {};

    container['id'] = site.id;
    container['name'] = site.name;

    return container;
  });
}
