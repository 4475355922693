/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import WhitePortlet from '../utils/WhitePortlet';
import SitesList from './SitesList';
import i18next from 'i18next';
import {connect} from 'react-redux';
import * as sitesApi from './../../api/sites-api';
import ErrorBox from './../utils/ErrorBox';
import {getErrorDescription} from '../../utils/view-utils';
import SiteSocket from './../../socket/SiteSocket';

class SitesListContainer extends Component {
  componentDidMount() {
    sitesApi.getSites();
    SiteSocket.getInstance().connect();
  }

  componentWillUnmount() {
    SiteSocket.getInstance().disconnect();
  }

  render() {
    return (
      <WhitePortlet caption={i18next.t('sites.name', {count: 2})} icon="icon-home">
        <ErrorBox errorMessage={getErrorDescription(this.props.getSitesError)}/>
        <ErrorBox errorMessage={getErrorDescription(this.props.destroySiteError)}/>

        <SitesList sites={this.props.sites} createSiteError={this.props.createSiteError}/>
      </WhitePortlet>
    );
  }
}

SitesListContainer.propTypes = {
  sites: PropTypes.array,
  createSiteError: PropTypes.object,
  getSitesError: PropTypes.object,
  destroySiteError: PropTypes.object,
};
SitesListContainer.defaultProps = {};

const mapStateToProps = function(store) {
  return {
    sites: store.sitesState.sites,
    createSiteError: store.sitesState.createSiteError,
    getSitesError: store.sitesState.getSitesError,
    destroySiteError: store.sitesState.destroySiteError,
  };
};

export default connect(mapStateToProps)(SitesListContainer);
