/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

class SelectInput extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  onChange(e) {
    if (this.props.validate && this.props.validate(e.target.value)) {
      this.props.onChange(e);
    }
  }

  handleBlur(e) {
    if (this.props.validate) {
      this.props.validate(e.target.value);
    }
  }

  render() {
    return (
      <div className="form-group form-md-line-input">
        <label className="col-md-3 control-label" htmlFor={this.props.fieldId}>
          {this.props.labelText}
          {this.props.isRequired ? (<span className="required">*</span>) : null}
        </label>
        <div className="col-md-9">
          <select
            className="form-control"
            name={this.props.fieldId}
            value={this.props.selected}
            disabled={this.props.isReadonly}
            onChange={this.props.onChange}
            onBlur={this.handleBlur}
          >
            {this.props.values.map((value) => {
              return (
                <option
                  key={value.id}
                  value={value.id}
                >
                  {value.name}
                </option>);
            })}
          </select>
          <div className="form-control-focus"> </div>
        </div>
        <div className="col-md-9 col-md-offset-3">
          {this.props.error ? (<span className="error-block">{this.props.error}</span>) : null}
        </div>
      </div>
    );
  }
}

SelectInput.propTypes = {
  fieldId: PropTypes.string,
  labelText: PropTypes.string,
  isRequired: PropTypes.bool,
  selected: PropTypes.any,
  values: PropTypes.array,
  onChange: PropTypes.func,
  error: PropTypes.string,
  isReadonly: PropTypes.bool,
  validate: PropTypes.func,
};

SelectInput.defaultProps = {};

export default SelectInput;
