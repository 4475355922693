import React, {Component} from 'react';
import PropTypes from 'prop-types';

class LiveSiteStatus extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <span>Here we will present some nice graphs</span>
    );
  }
}

LiveSiteStatus.propTypes = {
  action: PropTypes.func,
};

export default LiveSiteStatus;
