import React, {Component} from 'react';
import * as api from '../../../api/analytics-api';
import Cookies from 'universal-cookie';
import TextInfo from '../../utils/TextInfo';
import CustomChart from './CustomChart';

const SITE_COOKIE_KEY = 'analytics-siteId';
const TERMINAL_COOKIE_KEY = 'analytics-terminalId';
const QUERY_TIME_COOKIE_KEY = 'analytics-chartsQueryTime';
const cookies = new Cookies();

class TerminalKpi extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: 'Nothing to show here...',
      chartsViews: [],
    };
  }

  componentDidMount() {
    const siteId = cookies.get(SITE_COOKIE_KEY);
    const terminalId = cookies.get(TERMINAL_COOKIE_KEY);
    const queryTime = cookies.get(QUERY_TIME_COOKIE_KEY);

    if (!siteId || !terminalId) {
      return;
    }

    this.setState({
      message: 'Getting charts...',
      chartsView: [],
    });

    api.getTerminalKpis(siteId, terminalId, queryTime).then((chartsResponse) => {
      if (chartsResponse.errorMessage !== undefined) {
        this.setState({message: this.makeErrorMessage(chartsResponse)});
      } else {
        this.setState({
          chartsView: chartsResponse.data.charts,
          message: '',
        });
      }
    });
  }

  render() {
    return (
      <div>
        <TextInfo labelText={this.state.message}/>
        <CustomChart chartViews={this.state.chartsView || []}/>
      </div>
    );
  }
}

export default TerminalKpi;
