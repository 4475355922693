/**
 * @author Tomasz Czura (9/1/16)
 */
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import i18next from 'i18next';
import TextInput from './../utils/TextInput';
import TextArea from './../utils/TextArea';
import Checkbox from '../utils/Checkbox';
import Form from './../utils/Form';
import * as devModel from './../../model/developer';
import * as devApi from './../../api/developers-api';
import FormComponent from './../utils/FormComponent';
import ErrorBox from './../utils/ErrorBox';
import ModalDialog from './../utils/ModalDialog';
import * as blockUtils from './../../utils/BlockUtils';
import {getErrorDescription} from './../../utils/view-utils';
import {isValidEmail} from './../../utils/validate-utils';
import store from './../../store';
import SelectInput from './../utils/SelectInput';
import Multiselect from './../utils/Multiselect';
import PopoverSpan from './../utils/PopoverSpan';

class DeveloperForm extends FormComponent {
  constructor(props) {
    super(props);

    this.sendForm = this.sendForm.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.onSiteChange = this.onSiteChange.bind(this);
    this.onSitesChange = this.onSitesChange.bind(this);
  }

  initialState() {
    return this.props.presentedDeveloper ? devModel.toForm(this.props.presentedDeveloper) : devModel.defaultLocal();
  }


  validateEmail(email = this.state.email) {
    if (email) {
      if (isValidEmail(email)) {
        this.setState({emailError: ''});
        return true;
      } else {
        this.setState({emailError: i18next.t('errors.invalid_format', {field: i18next.t('developers.attrs.email')})});
        return false;
      }
    } else {
      this.setState({emailError: i18next.t('errors.is_required', {field: i18next.t('developers.attrs.email')})});
      return false;
    }
  }

  sendForm() {
    const validEmail = this.validateEmail();

    if (validEmail) {
      if (this.state.id) {
        devApi.updateDeveloper(this.state);
      } else {
        devApi.createDeveloper(this.state, this.onDeveloperCreated);
      }

      blockUtils.blockUI();
    }
  }

  onDeveloperCreated(developer) {
    window.showAlert(
        i18next.t('developers.created'),
        i18next.t('developers.new_password',
            {
              password: developer.password,
            }),
    );
  }

  onSiteChange(e) {
    const state = {};
    const selected = e.target.options[e.target.selectedIndex];
    state['siteIds'] = selected.value;
    state['siteNames'] = selected.text;
    this.setState(state);
  }

  onSitesChange(selectedSites) {
    const state = {};
    state['siteIds'] = selectedSites;
    this.setState(state);
  }

  render() {
    const modalTitle = i18next.t('developers.create_new');
    const examplePush = `\r
{
    "data": {
        "push_message_type": "%type%"
    },
    "notification": {
        "title": "Your title",
        "body": "Your notification message"
    },
    "to" : "%user_token%"
}`;

    return (
      <ModalDialog modalTitle={modalTitle} dialogId={this.props.dialogId} onDialogHide={blockUtils.unblockUI}
        onDialogShown={this.onFormShow} onSubmit={this.sendForm}>

        <Form>
          <ErrorBox errorMessage={getErrorDescription(this.state.error)} />

          <TextInput fieldId="email" labelText={i18next.t('developers.attrs.email')}
            isRequired={true} isReadonly={this.state.id ? true : false}
            validate={this.validateEmail} error={this.state.emailError}
            onChange={this.onChange} value={this.state.email} />

          <Multiselect fieldId="siteIds" labelText={i18next.t('sites.name', {count: 2})}
            selected={this.state.siteIds}
            selectableHeader={i18next.t('developers.attrs.available_sites')}
            selectionHeader={i18next.t('developers.attrs.access_to')}
            values={store.getState().sitesState.sites} onChange={this.onSitesChange} />

          <TextInput fieldId="pushUrl" labelText={i18next.t('developers.attrs.push_url')}
            onChange={this.onChange} value={this.state.pushUrl} />

          <SelectInput fieldId="pushMethod" labelText={i18next.t('developers.attrs.push_method')}
            onChange={this.onChange} selected={this.state.pushMethod}
            values={devModel.PushMethods} />

          <TextInput fieldId="pushContentType" labelText={i18next.t('developers.attrs.push_content_type')}
            onChange={this.onChange} value={this.state.pushContentType} />

          <TextInput fieldId="pushAuthorization" labelText={i18next.t('developers.attrs.push_authorization')}
            onChange={this.onChange} value={this.state.pushAuthorization} />

          <TextArea fieldId="pushBody" labelText={i18next.t('developers.attrs.push_body')}
            onChange={this.onChange} value={this.state.pushBody}>
            <PopoverSpan title={<i className="icon-info info_click" />}
              popoverTitle={i18next.t('developers.push_example')}
              popoverContent={ReactDOMServer.renderToStaticMarkup(<div>
                <pre>{examplePush}</pre>
                <div dangerouslySetInnerHTML={{__html: i18next.t('developers.token_desc')}} />
                <div dangerouslySetInnerHTML={{__html: i18next.t('developers.type_desc')}} />
              </div>)}
              trigger="click" />
          </TextArea>

          <Checkbox
            fieldId="hasOwnEmailVerificationService"
            labelText={i18next.t('developers.attrs.own_email_verification')}
            onChange={this.onCheckboxChanged}
            value={this.state.hasOwnEmailVerificationService}
          />

        </Form>

      </ModalDialog>
    );
  }
}

DeveloperForm.propTypes = {
  dialogId: PropTypes.string,
  error: PropTypes.object,
  presentedDeveloper: PropTypes.object,
};
DeveloperForm.defaultProps = {};

export default DeveloperForm;
