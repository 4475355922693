/**
 * @author Tomasz Czura (9/7/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

class InnerPortlet extends Component {
  render() {
    return (
      <div className="portlet light inner_portlet">
        <div className="portlet-title">
          <div className="caption font-green-sharp">
            <span className="caption-subject font-green sbold uppercase">{this.props.title}</span>
          </div>
          {/* <div className="actions">*/}
          {this.props.actions}
          {/* </div>*/}
        </div>
        <div className="portlet-body">
          {this.props.children}
        </div>
      </div>
    );
  }
}

InnerPortlet.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.any,
  children: PropTypes.node,
};
InnerPortlet.defaultProps = {};

export default InnerPortlet;
