/**
 * @author TomaszCzura ({}
 */
import React, {
  Component,
} from 'react';
import PropTypes from 'prop-types';
import Menu from './Menu';

class Main extends Component {
  render() {
    return (
      <main className="page-container">
        <Menu/>
        <div className="page-content-wrapper">
          <div className="page-content">
            {this.props.children}
          </div>
        </div>
      </main>
    );
  }
}

Main.propTypes = {
  children: PropTypes.node,
};
Main.defaultProps = {};

export default Main;
