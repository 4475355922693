/**
 * @author Tomasz Czura (11/10/16)
 */

export function toLocal(billing) {
  return {
    id: billing.id,
    roomNumber: billing.roomNumber,
    reservationId: billing.reservationId,
    externalReservationId: billing.externalReservationId,
    uuid: billing.uuid,
    createdAt: billing.createdAt,
    checkIn: billing.checkIn,
    checkOut: billing.checkOut,
  };
}
