/**
 * @author Tomasz Czura (11/18/16)
 */
import axios from 'axios';
import * as endpoints from './endpoints';
import store from '../store';
import * as actions from './../actions/site-agent-logs-actions';
import * as logsModel from './../model/site-agent-log';

export function getLogs(siteId) {
  // setTimeout(() => {
  //     store.dispatch(actions.getLogsSuccess([
  //         {id: 1, createdAt: new Date().getTime()},
  //         {id: 2, createdAt: new Date().getTime()},
  //         {id: 3, createdAt: new Date().getTime()}
  //     ]))
  // }, 1000);

  return axios.get(endpoints.GET_SITE_AGENT_LOGS(siteId), endpoints.headers())
      .then((response) => {
        const logs = response.data.map((log) => logsModel.toLocal(log));
        store.dispatch(actions.getLogsSuccess(logs));
      })
      .catch((error) => {
        store.dispatch(actions.getLogsError(error));
      });
}

export function requestLogs(siteId, onSuccess, onError) {
  return axios.get(endpoints.GET_SITE_AGENT_LOGS(siteId), endpoints.headers())
      .then((response) => {
        // let logs = response.data.map(log => logsModel.toLocal(log));
        // store.dispatch(actions.getLogsSuccess(logs));
        if (onSuccess) onSuccess();
      })
      .catch((error) => {
        if (onError) onError();
        // store.dispatch(actions.getLogsError(error));
      });
}

export function getLog(siteId, logId) {
  return axios.get(endpoints.GET_SITE_AGENT_LOG(siteId, logId), endpoints.headers())
      .then((response) => {
        const log = logsModel.toLocal(response.data);
        store.dispatch(actions.getLogSuccess(log));
      })
      .catch((error) => {
        store.dispatch(actions.getLogError(error));
      });
}
