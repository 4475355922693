/**
 * @author Marcus Wolschon (2019-12-02 mnarcus.wolschon@sphinx-electronics.de)
 */
import * as types from '../actions/action-types';

const initialState = {
  specialareas: [],
  getSpecialareasError: null,
};

const specialareasReducer = function(state = initialState, action) {
  switch (action.type) {
    case types.GET_SITE_SPECIALAREAS_SUCCESS:
      return Object.assign({}, state, {
        specialareas: action.specialareas,
        getSpecialareasError: null,
      });
    case types.GET_SITE_SPECIALAREAS_ERROR:
      return Object.assign({}, state, {
        specialareas: [],
        getSpecialareasError: action.error,
      });
    case types.CURRENT_SITE_CHANGE:
      return Object.assign({}, state, {
        specialareas: [],
        getSpecialareasError: null,
        currentSiteId: action.currentSiteId,
      });
    case types.DELETE_SITE_SPECIALAREA_ERROR:
      return Object.assign({}, state, {
        destroySpecialareaError: action.error,
      });
    case types.DELETE_SITE_SPECIALAREA_SUCCESS:
    {
      const newSpecialAreas = state.specialareas.filter((specialarea) => specialarea.id != action.specialAreaId);
      return Object.assign({}, state, {
        specialareas: newSpecialAreas,
        destroySpecialareaError: null,
      });
    }
    case types.UPDATE_SITE_SPECIALAREA_SUCCESS:
    {
      const newSpecialAreas = state.specialareas.map(
          (specialarea) => specialarea.id == action.specialArea.id ? action.specialArea : specialarea,
      );
      return Object.assign({}, state, {
        specialareas: newSpecialAreas,
        siteId: action.siteId,
        updateSpecialAreaError: null,
      });
    }
    case types.UPDATE_SITE_SPECIALAREA_ERROR:
      return Object.assign({}, state, {updateSpecialAreaError: action.error});
    case types.NEW_SITE_SPECIALAREA_SUCCESS:
    {
      const newSpecialAreas = state.specialareas.concat([action.specialArea]);
      return Object.assign({}, state, {
        specialareas: newSpecialAreas,
        siteId: action.siteId,
        createSpecialareaError: null,
      });
    }
    case types.NEW_SITE_SPECIALAREA_ERROR:
      return Object.assign({}, state, {
        specialareas: state.specialareas,
        createSpecialareaError: action.error,
      });
  }
  return state;
};

export default specialareasReducer;
