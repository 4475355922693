import React, {Component} from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import ReduxDataTable from '../../redux_data_table/ReduxDataTable';
import BatteryStatus from '../../utils/BatteryStatus';
import BooleanLabel from '../../utils/BooleanLabel';
import TableActions from '../../utils/TableActions';
import TableAction from '../../utils/actions/TableAction';

class SiteStatus extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="table-container">
        <ReduxDataTable
          {...this.props}
          notSortable={[8]}
        >
          <thead>
            <tr role="row" className="heading">
              <th data-sort="terminalId">{i18next.t('terminals.attributes.terminal_id')}</th>
              <th data-sort="name">{i18next.t('terminals.attributes.name')}</th>
              <th data-sort="type">{i18next.t('terminals.attributes.type')}</th>
              <th data-sort="provisioned">{i18next.t('terminals.attributes.provisioned')}</th>
              <th data-sort="online">{i18next.t('terminals.attributes.online')}</th>
              <th data-sort="firmwareVersionString">{i18next.t('terminals.attributes.firmware_version_string')}</th>
              <th data-sort="firmwareVersion">{i18next.t('terminals.attributes.firmware_version')}</th>
              <th data-sort="batteryPercentage">{i18next.t('terminals.attributes.battery_status')}</th>
              <th>{i18next.t('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.terminals.map((terminal, index) => {
              return (
                <tr key={index} className="odd">
                  <td>{terminal.terminalId}</td>
                  <td>{terminal.name}</td>
                  <td>{terminal.type}</td>
                  <td>
                    <BooleanLabel value={terminal.provisioned} />
                  </td>
                  <td>
                    <BooleanLabel value={terminal.online} />
                  </td>
                  <td>{terminal.firmwareVersionString}</td>
                  <td>{terminal.firmwareVersion}</td>
                  <td className="status-column">
                    <BatteryStatus status={terminal.batteryStatus} batteryPercentage={terminal.batteryPercentage}/>
                  </td>
                  <td>
                    {
                      this.props.terminalAction ?
                        <TableActions identifier={terminal.terminalId}>
                          <TableAction
                            action={() => this.props.terminalAction(terminal.terminalId, 'UNLOCK_TERMINAL')}
                            icon="icon-lock-open" text="Unlock Terminal"
                          />
                          <TableAction
                            action={() => this.props.terminalAction(terminal.terminalId, 'LOCK_TERMINAL')}
                            icon="icon-lock" text="Lock Terminal"
                          />
                        </TableActions> :
                        null
                    }
                  </td>
                </tr>
              );
            })}
          </tbody>
        </ReduxDataTable>
      </div>
    );
  }
}

SiteStatus.propTypes = {
  terminals: PropTypes.array,
  totalElements: PropTypes.number,
  totalPages: PropTypes.number,
  onPageChange: PropTypes.func,
  onElementsCountChange: PropTypes.func,
  elementsCount: PropTypes.number,
  tableId: PropTypes.string,
  terminalAction: PropTypes.func,
};

export default SiteStatus;
