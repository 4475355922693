/**
 * @author Tomasz Czura (11/18/16)
 */

export function toLocal(log) {
  return {
    id: log.id,
    createdAt: log.createdAt,
    content: log.content,
  };
}
