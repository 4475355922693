/**
 * @author Tomasz Czura (8/18/16)
 */
import AppSocket, {BASE_SOCKET_URL} from './socket';
import * as actions from '../actions/sites-actions';
import store from '../store';

let siteSocketInstance = null;
const SITE_SOCKET_URL = `${BASE_SOCKET_URL}/api/ws/sites`;

export const SiteMessageTypes = {
  CONNECTED: 'connected',
  DISCONNECTED: 'disconnected',
  HMS_DISCONNECTED: 'HMSDisconnected',
  HMS_CONNECTED: 'HMSConnected',
};

class SiteSocket extends AppSocket {
  constructor() {
    super();
    if (!siteSocketInstance) {
      siteSocketInstance = this;
    }
    return siteSocketInstance;
  }

  static getInstance() {
    if (!siteSocketInstance) {
      siteSocketInstance = new SiteSocket();
    }
    return siteSocketInstance;
  }

  getSocketUrl() {
    return SITE_SOCKET_URL;
  }

  handleMessage(message) {
    switch (message.type) {
      case SiteMessageTypes.CONNECTED:
        store.dispatch(actions.siteConnected(message.siteId));
        break;
      case SiteMessageTypes.DISCONNECTED:
        store.dispatch(actions.siteDisconnected(message.siteId));
        break;
      case SiteMessageTypes.HMS_CONNECTED:
        store.dispatch(actions.hmsConnected(message.siteId));
        break;
      case SiteMessageTypes.HMS_DISCONNECTED:
        store.dispatch(actions.hmsDisconnected(message.siteId));
        break;
      default:
                // console.log(`Message type: ${message.type} is not recognized`);
    }
  }
}

export default SiteSocket;
