import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Bar, Line} from 'react-chartjs-2';
import Cookies from 'universal-cookie';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
);

const cookies = new Cookies();

class CustomChart extends Component {
  constructor(props) {
    super(props);
  }

  createOptions(chartView) {
    return {
      responsive: true,
      onClick: (event, elements) => {
        if (elements[0]) {
          this.onChartValueClick(chartView, elements[0].datasetIndex, elements[0].index);
        }
      },
      plugins: {
        legend: {
          position: 'top',
          labels: {
            font: {
              size: 15,
            },
          },
        },
        title: {
          display: true,
          text: chartView.title.text[0].text,
          font: {
            size: 20,
          },
        },
        tooltip: {
          titleFont: {
            size: 20,
          },
          bodyFont: {
            size: 25,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            font: {
              size: 20,
            },
          },
        },
        y: {
          max: chartView.maxValue,
          min: chartView.minValue,
          ticks: {
            count: 11,
            font: {
              size: 20,
            },
          },
          title: {
            display: true,
            text: chartView.valueUnit.text[0].text,
            font: {
              size: 15,
            },
          },
        },
      },
    };
  }

  onChartValueClick(chartView, datasetIndex, index) {
    switch (chartView.type) {
      case 'SiteTerminalsWithMostUnlocks':
      case 'SiteTerminalsWithWorstBestUptime':
      case 'SiteTerminalsWithWorstBestConnectivity':
      case 'SiteTerminalsWithWorstBestWebUnlockUnlockSuccessRate':
      case 'SiteTerminalsWithWorstBestWebUnlockLatency':
      case 'SiteTerminalsWithLeastRemainingBattery':
      case 'SiteTerminalsWithWorstBestBatteryFluctuation':
      case 'SiteTerminalsWithFatalWebUnlocks':
        setTimeout(() => {
          this.onTerminalClick(chartView.labels[index].textValue);
        }, 50);
        break;
      case 'SiteTerminalUptimeHistory':
      case 'SiteTerminalWebUnlockSuccessRateHistory':
        if (datasetIndex > 0) {
          setTimeout(() => {
            this.onTerminalClick(chartView.datasets[datasetIndex].name.textValue);
          }, 50);
        }
        break;
    }
  }

  onTerminalClick(terminalId) {
    cookies.set('analytics-terminalId', terminalId, {expires: new Date(Date.now() + 31536000000)});
    this.props.triggerTabChangeFunc();
  }

  getBorderColor(datasetIndex, isRed, isGreen) {
    return isRed ?
      'rgb(' + (datasetIndex * 40 + 210) + ', ' + (datasetIndex * 100 + 10) + ', ' + (datasetIndex * 100 + 40) + ')' :
      isGreen ?
        'rgb(' + (datasetIndex * 60) + ', ' + (datasetIndex * 20 + 200) + ', ' + (datasetIndex * 60) + ')' :
        'rgb(' + (datasetIndex * 20 + 140) + ',' + (datasetIndex * 20 + 140) + ',' + (datasetIndex * 20 + 140) + ')';
  }

  getBackgroundColor(datasetIndex, isRed, isGreen) {
    return isRed ?
      'rgba(' + (datasetIndex * 40 + 210) + ', ' + (datasetIndex * 100 + 10) + ', ' + (datasetIndex * 100 + 40) + ', 0.5)' :
      isGreen ?
        'rgba(' + (datasetIndex * 60) + ', ' + (datasetIndex * 20 + 200) + ', ' + (datasetIndex * 60) + ', 0.5)' :
        'rgba(' + (datasetIndex * 20 + 140) + ',' + (datasetIndex * 20 + 140) + ',' + (datasetIndex * 20 + 140) + ', 0.5)';
  }

  isGreen(chartView, datasetIndex) {
    switch (chartView.type) {
      case 'SiteTerminalConnectivityHistory':
      case 'SiteTerminalsWithWorstBestConnectivity':
      case 'TerminalConnectivityHistory':
        return false;
      case 'SiteTerminalUnlockHistory':
      case 'TerminalUnlockHistory':
        return datasetIndex === 2 || datasetIndex === 3;
      case 'SiteTerminalsWithMostUnlocks':
        return true;
      default:
        return datasetIndex === 0;
    }
  }

  isRed(chartView, datasetIndex, index) {
    switch (chartView.type) {
      case 'SiteHMSUptimeHistory':
      case 'SiteTerminalUptimeHistory':
      case 'TerminalUptimeHistory':
      case 'SiteTerminalsWithWorstBestUptime':
        return datasetIndex === 0 && chartView.datasets[datasetIndex].values[index].numericValue < this.props.minimumUptime;
      case 'SiteTerminalWebUnlockSuccessRateHistory':
      case 'SiteTerminalsWithWorstBestWebUnlockUnlockSuccessRate':
      case 'TerminalWebUnlockSuccessRateHistory':
        return datasetIndex === 0 && chartView.datasets[datasetIndex].values[index].numericValue < this.props.minimumWebUnlockSuccessRate;
      case 'SiteTerminalsWithWorstBestWebUnlockLatency':
        return datasetIndex === 0 && chartView.datasets[datasetIndex].values[index].numericValue > this.props.maximumWebUnlockLatency;
      case 'SiteTerminalWebUnlockLatencyHistogram':
        return chartView.labels[index].numericValue > this.props.maximumWebUnlockLatency;
      case 'SiteTerminalBatteryHistory':
      case 'TerminalBatteryHistory':
        return datasetIndex === 0 && chartView.datasets[datasetIndex].values[index].numericValue < this.props.minimumBatteryPercentage;
      case 'SiteTerminalsWithLeastRemainingBattery':
        return datasetIndex === 0 && chartView.datasets[datasetIndex].values[index].numericValue < this.props.minimumBatteryDaysRemaining;
      case 'SiteTerminalsWithWorstBestBatteryFluctuation':
        return datasetIndex === 0 && chartView.datasets[datasetIndex].values[index].numericValue > this.props.maximumBatteryFluctuation;
      case 'SiteTerminalUnlockHistory':
      case 'TerminalUnlockHistory':
        return (datasetIndex === 0 || datasetIndex === 1) && chartView.datasets[datasetIndex].values[index].numericValue > 0;
      case 'SiteTerminalsWithFatalWebUnlocks':
        return true;
      default:
        return false;
    }
  }

  getData(chartView) {
    return {
      labels: chartView.labels.map((l) => l.text[0].text),
      datasets: chartView.datasets.map((ds, datasetIndex) => ({
        label: ds.name.text[0].text,
        data: ds.values.map((v) => v.numericValue),
        borderColor: ds.values.map((v, i) => this.getBorderColor(datasetIndex, this.isRed(chartView, datasetIndex, i), this.isGreen(chartView, datasetIndex, i))),
        backgroundColor: ds.values.map((v, i) => this.getBackgroundColor(datasetIndex, this.isRed(chartView, datasetIndex, i), this.isGreen(chartView, datasetIndex, i))),
        pointRadius: 0,
        pointHitRadius: 20,
        spanGaps: true,
        segment: {
          borderColor: (ctx) => this.getBorderColor(datasetIndex,
              this.isRed(chartView, datasetIndex, ctx.p0DataIndex) ||
            this.isRed(chartView, datasetIndex, ctx.p0DataIndex + 1),
              this.isGreen(chartView, datasetIndex, ctx.p0DataIndex) ||
            this.isGreen(chartView, datasetIndex, ctx.p0DataIndex + 1)),
          backgroundColor: (ctx) => this.getBackgroundColor(datasetIndex,
              this.isRed(chartView, datasetIndex, ctx.p0DataIndex) ||
            this.isRed(chartView, datasetIndex, ctx.p0DataIndex + 1),
              this.isGreen(chartView, datasetIndex, ctx.p0DataIndex) ||
            this.isGreen(chartView, datasetIndex, ctx.p0DataIndex + 1)),
        },
        tension: 0.4,
        borderWidth: 3,
      })),
    };
  }

  makeChart(chartView) {
    return {
      barChart: chartView.barChart,
      options: this.createOptions(chartView),
      data: this.getData(chartView),
    };
  }

  render() {
    return (
      <div className="col-lg-12" style={{overflow: 'auto'}}>
        {this.props.chartViews.map((chartView) => this.makeChart(chartView)).map((chart, idx) => {
          return (chart.barChart ?
              <div className="col-lg-6" key={idx}>
                <Bar options={chart.options} data={chart.data}/>
              </div> :
              <div className="col-lg-6" key={idx}>
                <Line options={chart.options} data={chart.data}/>
              </div>
          );
        })}
      </div>
    );
  }
}

CustomChart.propTypes = {
  chartViews: PropTypes.array,
  chartsQueryTime: PropTypes.string,
  minimumUptime: PropTypes.string,
  minimumWebUnlockSuccessRate: PropTypes.string,
  maximumWebUnlockLatency: PropTypes.string,
  minimumBatteryPercentage: PropTypes.string,
  minimumBatteryDaysRemaining: PropTypes.string,
  maximumBatteryFluctuation: PropTypes.string,
  minimumFirmwareVersionString: PropTypes.string,
  maximumTimeDeviation: PropTypes.string,
  triggerTabChangeFunc: PropTypes.func,
};

export default CustomChart;
