/**
 * @author Tomasz Czura (8/11/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter, Link} from 'react-router';
import * as auth from './../../utils/auth';
import i18next from 'i18next';

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.onLogoutClick = this.onLogoutClick.bind(this);
  }

  onLogoutClick() {
    auth.logout(() => {
      this.props.router.replace('/login');
    });
  }

  render() {
    const userName = this.props.userProfile ? this.props.userProfile.username : i18next.t('profile.my_profile');

    return (
      <li className="dropdown dropdown-user dropdown-dark">
        <a href="#"
          className="dropdown-toggle"
          data-toggle="dropdown"
          data-hover="dropdown"
          data-close-others="true"
          aria-expanded="false">
          <span className="username username-hide-on-mobile">{userName ? userName : this.props.userProfile.email}</span>
          <i className="fa fa-angle-down"/>
        </a>
        <ul className="dropdown-menu dropdown-menu-default">
          <li>
            {this.props.userProfile ?
            <Link to={`/profile`}>
              <i className="icon-user"/>{i18next.t('profile.edit')}
            </Link> :
            null}
          </li>
          <li className="divider"/>
          <li>
            <a href="#" onClick={this.onLogoutClick}>
              <i className="icon-logout"/>{i18next.t('profile.logout')}
            </a>
          </li>
        </ul>
      </li>
    );
  }
}

ProfileMenu.propTypes = {
  router: PropTypes.any,
  userProfile: PropTypes.object,
};

ProfileMenu.defaultProps = {};

export default withRouter(ProfileMenu);
