/**
 * @author TomaszCzura ({}
 */
import * as types from '../actions/action-types';

export function getCheckinsSuccess(checkins, totalPages = 0, totalElements = 0) {
  return {
    type: types.GET_CHECKINS_SUCCESS,
    checkins, totalPages, totalElements,
  };
}

export function getCheckinsError(error) {
  return {
    type: types.GET_CHECKINS_ERROR,
    error: error,
  };
}

export function getCheckinSuccess(presentedCheckin) {
  return {
    type: types.GET_CHECKIN_SUCCESS,
    presentedCheckin,
  };
}


export function getCheckinError(error) {
  return {
    type: types.GET_CHECKIN_ERROR,
    error,
  };
}

export function getCheckinKeysSuccess(checkinId, checkinKeys) {
  return {
    type: types.GET_CHECKIN_KEYS_SUCCESS,
    checkinKeys, checkinId,
  };
}

export function deleteCheckinSuccess(checkinId) {
  return {
    type: types.DELETE_CHECKIN_SUCCESS,
    checkinId,
  };
}

export function deleteCheckinError(error) {
  return {
    type: types.DELETE_CHECKIN_ERROR,
    error,
  };
}

export function createCheckinSuccess(checkin) {
  return {
    type: types.NEW_CHECKIN_SUCCESS,
    checkin,
  };
}

export function createCheckinError(error) {
  return {
    type: types.NEW_CHECKIN_ERROR,
    error,
  };
}

export function updateCheckinSuccess(presentedCheckin) {
  return {
    type: types.UPDATE_CHECKIN_SUCCESS,
    presentedCheckin,
  };
}

export function updateCheckinError(presentedCheckin, error) {
  return {
    type: types.UPDATE_CHECKIN_ERROR,
    error,
    presentedCheckin,
  };
}

export function clearCheckinErrors() {
  return {
    type: types.CLEAR_CHECKIN_ERRORS,
  };
}

export function userMatchedSuccess(bookingId, users) {
  return {
    type: types.USER_MATCHED_SUCCESS,
    bookingId, users,
  };
}

export function userMatchedError(error) {
  return {
    type: types.USER_MATCHED_ERROR,
    error,
  };
}

export function getLogsSuccess(checkinId, logs, totalPages, totalElements) {
  return {
    type: types.GET_BOOKING_LOGS_SUCCESS,
    logs, totalPages, totalElements,
  };
}

export function getLogsError(error) {
  return {
    type: types.GET_BOOKING_LOGS_ERROR,
    error,
  };
}
