/**
 * @author TomaszCzura ({}
 */
import * as auth from './../utils/auth';

// Sites
export const SITE_READ_PRIVILEGE = 'SITE_READ_PRIVILEGE';
export const SITE_ADD_PRIVILEGE = 'SITE_ADD_PRIVILEGE';
export const SITE_EDIT_PRIVILEGE = 'SITE_EDIT_PRIVILEGE';
export const SITE_DELETE_PRIVILEGE = 'SITE_DELETE_PRIVILEGE';
// Reservation
export const RESERVATION_READ_PRIVILEGE = 'RESERVATION_READ_PRIVILEGE';
export const RESERVATION_ADD_PRIVILEGE = 'RESERVATION_ADD_PRIVILEGE';
export const RESERVATION_EDIT_PRIVILEGE = 'RESERVATION_EDIT_PRIVILEGE';
export const RESERVATION_DELETE_PRIVILEGE = 'RESERVATION_DELETE_PRIVILEGE';
// User
export const USER_READ_PRIVILEGE= 'USER_READ_PRIVILEGE';
export const USER_ADD_PRIVILEGE = 'USER_ADD_PRIVILEGE';
export const USER_EDIT_PRIVILEGE = 'USER_EDIT_PRIVILEGE';
// Mobile User
export const MOBILE_USER_READ_PRIVILEGE = 'MOBILE_USER_READ_PRIVILEGE';
export const MOBILE_USER_DELETE_PRIVILEGE = 'MOBILE_USER_DELETE_PRIVILEGE';
// Developer
export const DEVELOPER_READ_PRIVILEGE = 'DEVELOPER_READ_PRIVILEGE';
export const DEVELOPER_ADD_PRIVILEGE = 'DEVELOPER_ADD_PRIVILEGE';
export const DEVELOPER_EDIT_PRIVILEGE = 'DEVELOPER_EDIT_PRIVILEGE';
export const DEVELOPER_DELETE_PRIVILEGE = 'DEVELOPER_DELETE_PRIVILEGE';
// Log
export const LOG_READ_PRIVILEGE = 'LOG_READ_PRIVILEGE';
// Files
export const FILE_READ_PRIVILEGE = 'FILE_READ_PRIVILEGE';
export const FILE_ADD_PRIVILEGE = 'FILE_ADD_PRIVILEGE';
export const FILE_EDIT_PRIVILEGE = 'FILE_EDIT_PRIVILEGE';
export const FILE_DELETE_PRIVILEGE = 'FILE_DELETE_PRIVILEGE';
// Special Area
export const SPECIAL_AREA_READ_PRIVILEGE = 'SPECIAL_AREA_READ_PRIVILEGE';
export const SPECIAL_AREA_ADD_PRIVILEGE = 'SPECIAL_AREA_ADD_PRIVILEGE';
export const SPECIAL_AREA_EDIT_PRIVILEGE = 'SPECIAL_AREA_EDIT_PRIVILEGE';
export const SPECIAL_AREA_DELETE_PRIVILEGE = 'SPECIAL_AREA_DELETE_PRIVILEGE';
// Change Log
export const CHANGE_LOG_ADD_PRIVILEGE = 'CHANGE_LOG_ADD_PRIVILEGE';

export function currentUserHasRight(privilege) {
  const userPermissions = auth.getCurrentUserPermissions();
  if (!userPermissions) {
    return false;
  }
  return typeof userPermissions.find((permission) => permission === privilege) !== 'undefined';
}


