/**
 * @author Tomasz Czura (9/7/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

class DownloadAction extends Component {
  render() {
    return (
      <li>
        {this.props.url ?
                    <a href={this.props.url} download={this.props.name}>
                      <i className="icon-cloud-download"/>{i18next.t('files.actions.download')}
                    </a> :
                    null
        }
      </li>
    );
  }
}

DownloadAction.propTypes = {
  url: PropTypes.string,
  name: PropTypes.any,
};
DownloadAction.defaultProps = {};

export default DownloadAction;
