/**
 * @author TomaszCzura ({}
 */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import MobileUsersList from './MobileUsersList';
import WhitePortlet from '../utils/WhitePortlet';
import RoleBasedComponent from '../utils/RoleBasedComponent';
import * as rights from '../../model/rights';
import * as blockUtils from './../../utils/BlockUtils';
import i18next from 'i18next';
import * as mobileUsersApi from './../../api/mobile-users-api';
import ErrorBox from './../utils/ErrorBox';
import {getErrorDescription} from '../../utils/view-utils';

class MobileUsersListContainer extends RoleBasedComponent {
  constructor(props) {
    super(props);

    this.tableId = 'mobile_users_logs_table';

    this.downloadMobileUsers = this.downloadMobileUsers.bind(this);

    this.pageNumber = 0;
    this.elementsCount = 10;
    this.sortingName = '';
    this.sortingType = '';
    this.searchPhrase = '';
  }

  componentDidMount() {
    this.checkRights(
        () => mobileUsersApi.getMobileUsers(
            this.pageNumber,
            this.elementsCount,
            'createdAt', 'desc', ''),
    );
  }

  componentDidUpdate() {
    blockUtils.unblockUI(`#${this.tableId}`);
  }

  downloadMobileUsers(
      pageNumber = this.pageNumber,
      elementsCount = this.elementsCount,
      sortingName = this.sortingName,
      sortingType = this.sortingType,
      searchPhrase = this.searchPhrase) {
    mobileUsersApi.getMobileUsers(pageNumber, elementsCount, sortingName, sortingType, searchPhrase);
    blockUtils.blockUI(`#${this.tableId}`);

    this.pageNumber = pageNumber;
    this.elementsCount = elementsCount;
    this.sortingName = sortingName;
    this.sortingType = sortingType;
    this.searchPhrase = searchPhrase;
  }

  getRights() {
    return rights.MOBILE_USER_READ_PRIVILEGE;
  }

  render() {
    return (
      <WhitePortlet caption={i18next.t('mobile_users.name', {count: 2})} icon="icon-screen-smartphone">
        <ErrorBox errorMessage={getErrorDescription(this.props.getMobileUsersError)}/>
        <ErrorBox errorMessage={getErrorDescription(this.props.destroyMobileUsersError)}/>

        <MobileUsersList users={this.props.users} error={this.props.error}
          getData={this.downloadMobileUsers}
          tableId={this.tableId} defaultSort={[5, 'desc']}
          totalPages={this.props.totalPages} totalElements={this.props.totalElements}/>
      </WhitePortlet>
    );
  }
}

MobileUsersListContainer.propTypes = {
  users: PropTypes.array,
  error: PropTypes.any,
  getMobileUsersError: PropTypes.object,
  destroyMobileUsersError: PropTypes.object,
  totalElements: PropTypes.number,
  totalPages: PropTypes.number,
};

MobileUsersListContainer.defaultProps = {};

const mapStateToProps = function(store) {
  return {
    users: store.mobileUsersState.mobileUsers,
    getMobileUsersError: store.mobileUsersState.getMobileUsersError,
    destroyMobileUsersError: store.mobileUsersState.destroyMobileUserError,
    totalPages: store.mobileUsersState.totalPages,
    totalElements: store.mobileUsersState.totalElements,
  };
};

export default connect(mapStateToProps)(MobileUsersListContainer);
