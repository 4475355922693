/**
 * @author TomaszCzura ({}
 */
import axios from 'axios';
import store from '../store';
import * as endpoints from './endpoints';
import * as mobileUserModel from './../model/mobile-users';
import * as logModel from './../model/transaction-log';
import * as actions from './../actions/mobile-users-actions';

export function getMobileUsers(page, size, sortingName, sortingType, searchPhrase) {
  const params = {
    page: page,
    size: size,
    query: searchPhrase,
    sort: `${sortingName || 'createdAt'},${sortingType.toLowerCase()}`,
  };

  return axios.get(endpoints.MOBILE_USERS, endpoints.headers(params))
      .then((response) => {
        const users = response.data.content.map((user) => mobileUserModel.toLocal(user));
        const totalPages = response.data.totalPages;
        const totalElements = response.data.totalElements;
        store.dispatch(actions.getMobileUsersSuccess(users, totalPages, totalElements));
        return response;
      })
      .catch((error) => {
        endpoints.handleUnauthorizedError(error, () => {
          store.dispatch(actions.getMobileUsersError(error));
        });
      });
}

export function getMobileUserLogs(mobileUserId, page, size, sortingName, sortingType, searchPhrase) {
  const data = {
    page: page,
    size: size,
    query: searchPhrase,
    sort: `${sortingName},${sortingType.toLowerCase()}`,
  };

  return axios.get(endpoints.MOBILE_USER_LOGS(mobileUserId), endpoints.headers(data))
      .then((response) => {
        const logs = response.data.content.map((log) => logModel.toLocal(log));
        const totalPages = response.data.totalPages;
        const totalElements = response.data.totalElements;
        store.dispatch(actions.getMobileUserLogsSuccess(logs, totalPages, totalElements));
      })
      .catch((error) => {
        store.dispatch(actions.getMobileUserLogsError(error));
      });
}

export function destroyMobileUser(mobileUserId) {
  return axios.delete(`${endpoints.MOBILE_USERS}/${mobileUserId}`, endpoints.headers())
      .then((response) => {
        store.dispatch(actions.destroyMobileUserSuccess(mobileUserId));
      })
      .catch((error) => {
        store.dispatch(actions.destroyMobileUserError(error));
      });
}
