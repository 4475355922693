/**
 * @author Tomasz Czura (11/18/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import WhitePortlet from '../utils/WhitePortlet';
import i18next from 'i18next';
import {connect} from 'react-redux';
import ErrorBox from './../utils/ErrorBox';
import {getErrorDescription} from './../../utils/view-utils';
import * as siteAgentLogsApi from './../../api/site-agent-api';
import * as blockUtils from './../../utils/BlockUtils';
import SiteAgentLogs from './SiteAgentLogs';

class SiteAgentLogsContainer extends Component {
  constructor(props) {
    super(props);

    this.requestLogs = this.requestLogs.bind(this);
  }

  componentDidMount() {
    siteAgentLogsApi.getLogs(this.props.params.siteId);
  }

  requestLogs() {
    blockUtils.blockUI();
    siteAgentLogsApi.requestLogs(this.props.params.siteId, () => {
      blockUtils.unblockUI();
      window.showAlert(i18next.t('success'), i18next.t('site_agent_logs.request_success'));
    }, () => {
      blockUtils.unblockUI();
      window.showAlert(i18next.t('error'), i18next.t('site_agent_logs.request_failed'));
    });
  }

  render() {
    const actions = <button
      className="btn blue"
      onClick={() => this.requestLogs()}>{i18next.t('site_agent_logs.actions.request')}
    </button>;

    return (
      <WhitePortlet caption={i18next.t('site_agent_logs.name')} icon="icon-list" actions={actions}>
        <ErrorBox errorMessage={getErrorDescription(this.props.getLogsError)}/>
        <SiteAgentLogs logs={this.props.logs} siteId={this.props.params.siteId}/>
      </WhitePortlet>
    );
  }
}

SiteAgentLogsContainer.propTypes = {
  logs: PropTypes.array,
  getLogsError: PropTypes.object,
  params: PropTypes.any,
};
SiteAgentLogsContainer.defaultProps = {};

const mapStateToProps = function(store) {
  return {
    logs: store.siteAgentLogsState.logs,
    getLogsError: store.siteAgentLogsState.getLogsError,
  };
};

export default connect(mapStateToProps)(SiteAgentLogsContainer);
