/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import i18next from 'i18next';
import MenuItem from './MenuItem';
import * as rights from './../model/rights';

class Menu extends Component {
  render() {
    return (
      <aside className="page-sidebar-wrapper">
        <div className="page-sidebar navbar-collapse collapse">
          <ul
            className="page-sidebar-menu"
            data-keep-expanded="false"
            data-auto-scroll="true"
            data-slide-speed="200"
          >
            <MenuItem
              route="/"
              isIndex={true}
              icon="icon-bar-chart"
              title={i18next.t('dashboard.title')}
            />

            {rights.currentUserHasRight(rights.SITE_READ_PRIVILEGE) ? (
              <MenuItem
                className="selected_selected"
                route="/sites"
                icon="icon-home"
                title={i18next.t('sites.name', {count: 2})}
              />
            ) : null}

            {rights.currentUserHasRight(rights.RESERVATION_READ_PRIVILEGE) ? (
              <MenuItem
                className="selected_selected"
                route="/bookings"
                icon="icon-calendar"
                title={i18next.t('checkins.name', {count: 2})}
              />
            ) : null}

            {rights.currentUserHasRight(rights.USER_READ_PRIVILEGE) ? (
              <MenuItem
                route="/users"
                icon="icon-users"
                title={i18next.t('users.name', {count: 2})}
              />
            ) : null}

            {rights.currentUserHasRight(rights.MOBILE_USER_READ_PRIVILEGE) ? (
              <MenuItem
                route="/mobile_users"
                icon="icon-screen-smartphone"
                title={i18next.t('mobile_users.name', {count: 2})}
              />
            ) : null}

            {rights.currentUserHasRight(rights.LOG_READ_PRIVILEGE) ? (
              <MenuItem
                route="/transaction_logs"
                icon="icon-list"
                title={i18next.t('transaction_logs.name', {count: 2})}
              />
            ) : null}

            {rights.currentUserHasRight(rights.DEVELOPER_READ_PRIVILEGE) ? (
              <MenuItem
                route="/developers"
                icon="icon-screen-desktop"
                title={i18next.t('developers.name', {count: 2})}
              />
            ) : null}

            {rights.currentUserHasRight(rights.FILE_READ_PRIVILEGE) ? (
              <MenuItem
                route="/files"
                icon="icon-docs"
                title={i18next.t('files.name', {count: 2})}
              />
            ) : null}

            {rights.currentUserHasRight(rights.SITE_READ_PRIVILEGE) ? (
              <MenuItem
                route="/billing"
                icon="icon-key"
                title={i18next.t('billing.name')}
              />
            ) : null}

            {rights.currentUserHasRight(rights.SITE_READ_PRIVILEGE) ? (
              <MenuItem
                route="/rooms"
                icon="icon-grid"
                title={i18next.t('rooms.name', {count: 2})}
              />
            ) : null}

            {rights.currentUserHasRight(rights.SPECIAL_AREA_READ_PRIVILEGE) ? (
              <MenuItem
                route="/specialareas"
                icon="icon-lock"
                title={i18next.t('specialareas.name', {count: 2})}
              />
            ) : null}

            {rights.currentUserHasRight(rights.SITE_READ_PRIVILEGE) ? (
              <MenuItem
                route="/terminals"
                icon="icon-screen-tablet"
                title={i18next.t('terminals.menu')}
              />
            ) : null}

            <MenuItem
              route="/info"
              icon="icon-info"
              title={i18next.t('info.name')}
            />
          </ul>
        </div>
      </aside>
    );
  }
}

Menu.propTypes = {};
Menu.defaultProps = {};

export default Menu;
