/**
 * @author TomaszCzura ({}
 */
import * as endpoints from './endpoints';
import * as actions from '../actions/dahsboard-actions';
import axios from 'axios';
import * as dashboardModel from './../model/dashboard';
import store from '../store';

export function getDashboardStats(siteId) {
  let params = {};
  if (siteId && siteId !== -1) {
    params = {siteId: siteId};
  }

  return axios.get(endpoints.DASHBOARD, endpoints.headers(params))
      .then((response) => {
        const dashboard = dashboardModel.toLocal(response.data);
        store.dispatch(actions.getDashboardStats(dashboard));
        return dashboard;
      }).catch((error) => {
        endpoints.handleUnauthorizedError(error, () => {});
      });
}
