/**
 * @author TomaszCzura ({}
 */
import React from 'react';
import App from './components/App';
import {browserHistory, IndexRoute, Route, Router} from 'react-router';
import Dashboard from './components/dashboard/Dashboard';
import UsersListContainer from './components/users/UsersListContainer';
import UserDetailsContainer from './components/users/UserDetailsContainer';
import SiteDetailsContainer from './components/sites/SiteDetailsContainer';
import SiteRoomsListContainer from './components/sites/SiteRoomsListContainer';
import SiteSpecialAreasListContainer from './components/sites/SiteSpecialAreasListContainer';
import SiteAgentLogsContainer from './components/site_agent_logs/SiteAgentLogsContainer';
import LogDetailsContainer from './components/site_agent_logs/LogDetailsContainer';
import RoomsListContainer from './components/rooms/RoomsListContainer';
import SpecialareasListContainer from './components/specialareas/SpecialareasListContainer';
import TerminalsContainer from './components/terminals/TerminalsContainer';
import SitesListContainer from './components/sites/SitesListContainer';
import BookingsListContainer from './components/checkins/BookingsListContainer';
import MobileUsersListContainer from './components/mobile_users/MobileUsersListContainer';
import MobileUserLogContainer from './components/mobile_users/MobileUserLogContainer';
import TransactionLogsListContainer from './components/transaction_logs/TransactionLogsListContainer';
import DevelopersListContainer from './components/developers/DevelopersListContainer';
import DevelopersDetailsContainer from './components/developers/DevelopersDetailsContainer';
import ProfileDetailsContainer from './components/profile/ProfileDetailsContainer';
import FilesListContainer from './components/files/FilesListContainer';
import BookingLogsContainer from './components/checkins/BookingLogsContainer';
import BillingContainer from './components/billing/BillingContainer';
import InfoContainer from './components/info/InfoContainer';
import Login from './components/login/LoginForm';
import * as auth from './utils/auth';
import * as rights from './model/rights';

function requireAuth(nextState, replace) {
  if (!auth.loggedIn()) {
    replace({
      pathname: '/login',
      state: {nextPathname: nextState.location.pathname},
    });
  }
}

function checkIsAuth(nextState, replace) {
  if (auth.loggedIn()) {
    replace({
      pathname: '/',
      state: {nextPathname: nextState.location.pathname},
    });
  }
}

function requireReadRights(privilege, nextState, replace) {
  if (!rights.currentUserHasRight(privilege)) {
    replace({
      pathname: '/',
      state: {nextPathname: nextState.location.pathname},
    });
  }
}

export default (
  <Router history={browserHistory}>
    <Route exact path="/" component={App} onEnter={requireAuth}>
      <IndexRoute component={Dashboard} />
      <Route
        exact
        path="bookings"
        onEnter={(next, replace) =>
          requireReadRights(rights.RESERVATION_READ_PRIVILEGE, next, replace)
        }
      >
        <IndexRoute component={BookingsListContainer} />
        <Route exact path=":bookingId/logs" component={BookingLogsContainer} />
      </Route>

      <Route
        exact
        path="users"
        onEnter={(next, replace) =>
          requireReadRights(rights.USER_READ_PRIVILEGE, next, replace)
        }
      >
        <IndexRoute component={UsersListContainer} />
        <Route path=":userId" component={UserDetailsContainer} />
      </Route>

      <Route
        path="sites"
        onEnter={(next, replace) =>
          requireReadRights(rights.SITE_READ_PRIVILEGE, next, replace)
        }
      >
        <IndexRoute component={SitesListContainer} />
        <Route path=":siteId" component={SiteDetailsContainer} />
        <Route path=":siteId/rooms" component={SiteRoomsListContainer} />
        <Route
          path=":siteId/specialareas"
          component={SiteSpecialAreasListContainer}
        />
        <Route path=":siteId/logs" component={SiteAgentLogsContainer} />
        <Route path=":siteId/logs/:logId" component={LogDetailsContainer} />
      </Route>

      <Route
        path="mobile_users"
        onEnter={(next, replace) =>
          requireReadRights(rights.MOBILE_USER_READ_PRIVILEGE, next, replace)
        }
      >
        <IndexRoute component={MobileUsersListContainer} />
        <Route path=":mobileUserId/logs" component={MobileUserLogContainer} />
      </Route>

      <Route
        path="transaction_logs"
        onEnter={(next, replace) =>
          requireReadRights(rights.LOG_READ_PRIVILEGE, next, replace)
        }
      >
        <IndexRoute component={TransactionLogsListContainer} />
      </Route>

      <Route
        path="developers"
        onEnter={(next, replace) =>
          requireReadRights(rights.DEVELOPER_READ_PRIVILEGE, next, replace)
        }
      >
        <IndexRoute components={DevelopersListContainer} />
        <Route path=":developerId" component={DevelopersDetailsContainer} />
      </Route>

      <Route
        path="files"
        onEnter={(next, replace) =>
          requireReadRights(rights.FILE_READ_PRIVILEGE, next, replace)
        }
      >
        <IndexRoute components={FilesListContainer} />
      </Route>

      <Route path="profile" component={ProfileDetailsContainer} />

      <Route
        path="billing"
        onEnter={(next, replace) =>
          requireReadRights(rights.SITE_READ_PRIVILEGE, next, replace)
        }
      >
        <IndexRoute components={BillingContainer} />
      </Route>

      <Route
        path="rooms"
        onEnter={(next, replace) =>
          requireReadRights(rights.SITE_READ_PRIVILEGE, next, replace)
        }
      >
        <IndexRoute components={RoomsListContainer} />
      </Route>

      <Route
        path="specialareas"
        onEnter={(next, replace) =>
          requireReadRights(rights.SPECIAL_AREA_READ_PRIVILEGE, next, replace)
        }
      >
        <IndexRoute components={SpecialareasListContainer} />
      </Route>

      <Route
        path="terminals"
        onEnter={(next, replace) =>
          requireReadRights(rights.SITE_READ_PRIVILEGE, next, replace)
        }
      >
        <IndexRoute components={TerminalsContainer} />
      </Route>

      <Route path="info">
        <IndexRoute components={InfoContainer} />
      </Route>
    </Route>
    <Route
      path="login"
      component={Login}
      onEnter={(next, replace) => checkIsAuth(next, replace)}
    />
  </Router>
);
