/**
 * @author Tomasz Czura (11/4/16)
 */
import AppSocket, {BASE_SOCKET_URL} from './socket';
import * as bookingsApi from './../api/bookings-api';
import {doesUserHaveAccessToSite} from '../store';

let bookingSocketInstance = null;
const BOOKING_SOCKET_URL = `${BASE_SOCKET_URL}/api/ws/dashboard`;

export const BookingMessageTypes = {
  BOOKING_CHANGED: 'reservationChange',
};

class BookingSocket extends AppSocket {
  constructor() {
    super();
    if (!bookingSocketInstance) {
      bookingSocketInstance = this;
    }
    return bookingSocketInstance;
  }

  static getInstance() {
    if (!bookingSocketInstance) {
      bookingSocketInstance = new BookingSocket();
    }
    return bookingSocketInstance;
  }

  connect(onBookingRefresh, onKeyRefresh) {
    super.connect();
    this.onBookingRefresh = onBookingRefresh;
    this.onKeyRefresh = onKeyRefresh;
  }

  getSocketUrl() {
    return BOOKING_SOCKET_URL;
  }

  handleMessage(message) {
    switch (message.type) {
      case BookingMessageTypes.BOOKING_CHANGED:
        if (doesUserHaveAccessToSite(message.siteId)) {
          if (message.keyId) {
            bookingsApi.getBooking(message.reservationId, () => this.onKeyRefresh(message.keyId), true);
          } else {
            bookingsApi.getBooking(message.reservationId, this.onBookingRefresh, true);
          }
        }
        break;
      default:
                // console.log(`Message type: ${message.type} is not recognized`);
    }
  }
}

export default BookingSocket;
