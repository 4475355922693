/**
 * @author Tomasz Czura (11/10/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

class MonthPicker extends Component {
  constructor(props) {
    super(props);

    this.onMonthChange = this.onMonthChange.bind(this);
  }

  onMonthChange(e) {
    this.props.onChange(parseInt(e.target.value));
  }

  componentDidMount() {
    $(`#month_selector`).find(`option[value="${this.props.selected}"]`).attr('selected', 'selected');
  }

  render() {
    const months = moment.months();

    return (
      <div className="form-group form-md-line-input col-md-2 billing_data">
        <select className="form-control" id="month_selector" name="month" onChange={this.onMonthChange}>
          {months.map((value, index) => {
            return (<option key={index} value={index}>{value}</option>);
          })}
        </select>
        <div className="form-control-focus"> </div>
      </div>
    );
  }
}

MonthPicker.propTypes = {
  selected: PropTypes.number,
  onChange: PropTypes.func,
};
MonthPicker.defaultProps = {};

export default MonthPicker;
