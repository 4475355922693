/**
 * @author Tomasz Czura (9/1/16)
 */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import WhitePortlet from '../utils/WhitePortlet';
import RoleBasedComponent from '../utils/RoleBasedComponent';
import i18next from 'i18next';
import * as rights from '../../model/rights';
import * as devApi from './../../api/developers-api';
import ErrorBox from './../utils/ErrorBox';
import {getErrorDescription} from '../../utils/view-utils';
import DevelopersList from './DevelopersList';

class DevelopersListContainer extends RoleBasedComponent {
  componentDidMount() {
    this.checkRights(() => devApi.getDevelopers());
  }

  getRights() {
    return rights.DEVELOPER_READ_PRIVILEGE;
  }

  render() {
    return (
      <WhitePortlet caption={i18next.t('developers.name', {count: 2})} icon="icon-screen-desktop">
        <ErrorBox errorMessage={getErrorDescription(this.props.getDevelopersError)} />
        <ErrorBox errorMessage={getErrorDescription(this.props.destroyDeveloperError)} />

        <DevelopersList developers={this.props.developers}
          updateDeveloperError={this.props.updateDeveloperError}
          createDeveloperError={this.props.createDeveloperError} />
      </WhitePortlet>
    );
  }
}

DevelopersListContainer.propTypes = {
  developers: PropTypes.array,
  getDevelopersError: PropTypes.object,
  createDeveloperError: PropTypes.object,
  destroyDeveloperError: PropTypes.object,
  updateDeveloperError: PropTypes.object,
};
DevelopersListContainer.defaultProps = {};

const mapStateToProps = function(store) {
  return {
    developers: store.developersState.developers,
    getDevelopersError: store.developersState.getDevelopersError,
    createDeveloperError: store.developersState.createDeveloperError,
    destroyDeveloperError: store.developersState.destroyDeveloperError,
    updateDeveloperError: store.developersState.updateDeveloperError,
  };
};

export default connect(mapStateToProps)(DevelopersListContainer);
