/**
 * @author Tomasz Czura (9/7/16)
 */
import * as types from '../actions/action-types';

const initialState = {
  files: [],
  presentedFile: {},
  getFilesError: null,
  getFileError: null,
  createFileError: null,
  updateFileError: null,
  destroyFileError: null,
};

const fileReducer = function(state = initialState, action) {
  switch (action.type) {
    case types.NEW_FILE_SUCCESS:
    {
      const newFiles = state.files.concat([action.file]);
      return Object.assign({}, state, {files: newFiles, createFileError: null});
    }
    case types.NEW_FILE_ERROR:
      return Object.assign({}, state, {createFileError: action.error});
    case types.GET_FILES_SUCCESS:
      return Object.assign({}, state, {files: action.files, getFilesError: null});
    case types.GET_FILES_ERROR:
      return Object.assign({}, state, {files: [], getFilesError: action.error});
    case types.UPDATE_FILE_SUCCESS:
    {
      const newFiles = state.files.map((dev) => dev.id == action.presentedFile.id ? action.presentedFile : dev);
      return Object.assign({}, state, {files: newFiles, presentedFile: action.presentedFile, updateFileError: null});
    }
    case types.UPDATE_FILE_ERROR:
      return Object.assign({}, state, {updateFileError: action.error});
    case types.DELETE_FILE_SUCCESS:
    {
      const newFiles = state.files.filter((dev) => dev.id != action.fileId);
      return Object.assign({}, state, {files: newFiles, destroyFileError: null, presentedSite: {}});
    }
    case types.DELETE_FILE_ERROR:
      return Object.assign({}, state, {destroyFileError: action.error});
  }
  return state;
};

export default fileReducer;
