/**
 * @author TomaszCzura ({}
 */
import * as types from '../actions/action-types';

export function userLogoutAction() {
  return {
    type: types.USER_LOGOUT,
  };
}
