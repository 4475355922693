import React, {Component} from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';

class CreateNewButton extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const style = {
      marginBottom: 10,
    };
    return (<button
      className="btn btn-primary"
      onClick={this.props.callBack}
      style={style}
    >
      {i18next.t('links.new')}
    </button>);
  }
}

CreateNewButton.propTypes = {
  callBack: PropTypes.any,
};

export default CreateNewButton;
