/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

class TextInfo extends Component {
  render() {
    return (
      <div className="form-group form-md-line-input">
        <label className="col-md-2 control-label" >{this.props.labelText} </label>
        <div className={`col-md-${this.props.textCols}`}>
          <div className='form-control form-control-static'>{this.props.value}</div>
        </div>

        {this.props.children}
      </div>
    );
  }
}

TextInfo.propTypes = {
  children: PropTypes.node,
  value: PropTypes.any,
  labelText: PropTypes.string,
  textCols: PropTypes.string,
};
TextInfo.defaultProps = {
  textCols: '5',
};

export default TextInfo;
