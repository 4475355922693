/**
 * @author TomaszCzura ({}
 */
import * as types from '../actions/action-types';

const initialState = {
  checkins: [],
  presentedCheckin: {
    keys: [],
  },
  totalPages: 0,
  totalElements: 0,
  getCheckinError: null,
  getCheckinsError: null,
  createCheckinError: null,
  updateCheckinError: null,
  destroyCheckinError: null,
  logs: [],
  getLogsError: null,
  forceUpdate: false,
  logsTotalPages: 0,
  logsTotalElements: 0,
};

const checkinReducer = function(state = initialState, action) {
  switch (action.type) {
    case types.NEW_CHECKIN_SUCCESS:
    {
      const newCheckins = [action.checkin, ...state.checkins];
      return Object.assign({}, state, {
        forceUpdate: true,
        checkins: newCheckins,
        totalElements: state.totalElements + 1,
        presentedCheckin: {},
        createCheckinError: null,
      });
    }
    case types.NEW_CHECKIN_ERROR:
      return Object.assign({}, state, {
        forceUpdate: true,
        checkins: state.checkins,
        createCheckinError: action.error,
        presentedCheckin: {},
      });
    case types.GET_CHECKINS_SUCCESS:
      return Object.assign({}, state, {
        forceUpdate: false,
        checkins: action.checkins, totalPages: action.totalPages, totalElements: action.totalElements,
        getCheckinsError: null, presentedCheckin: {}});
    case types.GET_CHECKINS_ERROR:
      return Object.assign({}, state, {checkins: [],
        forceUpdate: true,
        totalPages: 0, totalElements: 0,
        getCheckinsError: action.error, presentedCheckin: {}});
    case types.GET_CHECKIN_SUCCESS:
    {
      let newCheckins;
      if (state.checkins.filter((checkin) => checkin.id === action.presentedCheckin.id).length > 0) {
        newCheckins = state.checkins.map(
            (checkin) => checkin.id === action.presentedCheckin.id ? action.presentedCheckin : checkin,
        );
      } else {
        newCheckins = [action.presentedCheckin, ...state.checkins];
      }
      return Object.assign({}, state, {
        forceUpdate: false,
        checkins: newCheckins,
        presentedCheckin: action.presentedCheckin,
        getCheckinError: null,
      });
    }
    case types.GET_CHECKIN_ERROR:
      return Object.assign({}, state, {
        forceUpdate: true,
        presentedCheckin: {},
        getCheckinError: action.error,
      });
    case types.GET_CHECKIN_KEYS_SUCCESS:
    {
      const presentedCheckin = Object.assign({}, state.presentedCheckin);
      presentedCheckin.keys = action.checkinKeys;
      return Object.assign({}, state, {
        forceUpdate: false,
        presentedCheckin: presentedCheckin,
      });
    }
    case types.UPDATE_CHECKIN_SUCCESS:
    {
      const newCheckins = state.checkins.map(
          (checkin) => checkin.id === action.presentedCheckin.id ? action.presentedCheckin : checkin,
      );
      return Object.assign({}, state, {
        forceUpdate: true,
        checkins: newCheckins,
        presentedCheckin: action.presentedCheckin,
        updateCheckinError: null,
      });
    }
    case types.UPDATE_CHECKIN_ERROR:
      return Object.assign({}, state, {
        forceUpdate: true,
        updateCheckinError: action.error,
      });
    case types.DELETE_CHECKIN_SUCCESS:
    {
      const newCheckins = state.checkins.filter(
          (checkin) => checkin.id !== action.checkinId,
      );
      return Object.assign({}, state, {
        forceUpdate: false,
        checkins: newCheckins,
        totalElements: state.totalElements - 1,
        destroyCheckinError: null,
        presentedCheckin: {},
      });
    }
    case types.DELETE_CHECKIN_ERROR:
      return Object.assign({}, state, {
        forceUpdate: true,
        destroyCheckinError: action.error,
      });
    case types.NEW_KEY_SUCCESS:
    {
      const presentedCheckin = presentedCheckinForKey(action.key, state);
      if (presentedCheckin) {
        presentedCheckin.keys.push(action.key);
        return Object.assign({}, state, {
          forceUpdate: true,
          presentedCheckin: presentedCheckin,
          error: null,
        });
      }
      break;
    }
    case types.NEW_KEY_ERROR:
    {
      const presentedCheckin = presentedCheckinForKey(action.key, state.presentedCheckin);
      if (presentedCheckin) {
        return Object.assign({}, state, {
          forceUpdate: true,
          presentedCheckin: presentedCheckin,
          error: action.error,
        });
      }
      break;
    }
    case types.DELETE_KEY_SUCCESS:
    {
      if (state.presentedCheckin) {
        const presentedCheckin = Object.assign({}, state.presentedCheckin);
        const keyIndex = presentedCheckin.keys.findIndex((key) => key.id === action.keyId);
        presentedCheckin.keys.splice(keyIndex, 1);
        return Object.assign({}, state, {
          forceUpdate: true,
          presentedCheckin: presentedCheckin,
          error: null,
        });
      }
      break;
    }
    case types.USER_MATCHED_SUCCESS:
    {
      const changedCheckin = Object.assign({}, state.checkins.find((c) => c.id === action.bookingId));
      changedCheckin.keys = action.users;
      const newCheckins = state.checkins.map(
          (checkin) => checkin.id === action.bookingId ? changedCheckin : checkin,
      );
      return Object.assign({}, state, {
        forceUpdate: false,
        checkins: newCheckins,
        presentedCheckin: action.presentedCheckin,
        updateCheckinError: null,
      });
    }
    case types.USER_MATCHED_ERROR:
      return Object.assign({}, state, {
        forceUpdate: true,
        getCheckinsError: action.error,
      });
    case types.GET_BOOKING_LOGS_SUCCESS:
      return Object.assign({}, state, {
        logs: action.logs,
        getLogsError: null,
        logsTotalPages: action.totalPages,
        logsTotalElements: action.totalElements,
      });
    case types.GET_BOOKING_LOGS_ERROR:
      return Object.assign({}, state, {
        logs: [],
        getLogsError: action.error,
        logsTotalPages: 0,
        logsTotalElements: 0,
      });
  }

  return state;
};

export function presentedCheckinForKey(key, state) {
  if (state.presentedCheckin &&
    key.siteId === state.presentedCheckin.siteId &&
    key.phoneNumber === state.presentedCheckin.phoneNumber) {
    return Object.assign({}, state.presentedCheckin);
  } else {
    return null;
  }
}

export default checkinReducer;
