/**
 * @author Tomasz Czura (4/20/17)
 */
export function initializeErrorReporting() {
  if (shouldReportErrors()) {
    Raven.config('https://dc1c2c0a755f4179b11681659f193731@sentry.io/160092').install();
  }
}

function shouldReportErrors() {
  return typeof Raven !== 'undefined';
}
