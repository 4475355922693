import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../utils/TextInput';
import SelectInput from '../../utils/SelectInput';
import {connect} from 'react-redux';
import {selectedSiteId} from '../../../store';
import FormComponent from '../../utils/FormComponent';
import i18next from 'i18next';
import Cookies from 'universal-cookie';
import Checkbox from '../../utils/Checkbox';
import * as api from '../../../api/analytics-api';
import * as blockUtils from '../../../utils/BlockUtils';
import * as viewUtils from '../../../utils/view-utils';

const TEXT_KEY = 'terminals.options.';
const SITE_COOKIE_KEY = 'analytics-siteId';
const QUERY_TIME_COOKIE_KEY = 'analytics-chartsQueryTime';
const cookies = new Cookies();

class AnalyticsOptions extends FormComponent {
  constructor(props) {
    super(props);

    this.passOptionsToSuper = this.passOptionsToSuper.bind(this);
    this.updateChosenSite = this.updateChosenSite.bind(this);
    this.updateQueryTime = this.updateQueryTime.bind(this);
  }

  initialState() {
    const chosenSite = selectedSiteId() !== -1 ? selectedSiteId() : cookies.get(SITE_COOKIE_KEY);

    const chosenTimeFromCookie = cookies.get(QUERY_TIME_COOKIE_KEY);
    if (!chosenTimeFromCookie) {
      cookies.set(QUERY_TIME_COOKIE_KEY, 'two_days_hourly', {expires: new Date(Date.now() + 31536000000)});
    }
    const chosenTime = cookies.get(QUERY_TIME_COOKIE_KEY);

    this.getAnalyticsOptionsFromServer(chosenSite);

    return {
      chartsQueryTime: chosenTime,
      chosenSite: chosenSite,
      showAdvancedOptions: false,
    };
  }

  getAnalyticsOptionsFromServer(chosenSite) {
    blockUtils.blockUI();
    api.getOptionsForKpiGraphs(chosenSite).then((response) => {
      const options = response.data;
      this.setState({
        minimumUptime: options.minimumUptime,
        minimumWebUnlockSuccessRate: options.minimumWebUnlockSuccessRate,
        maximumWebUnlockLatency: options.maximumWebUnlockLatency,
        minimumBatteryPercentage: options.minimumBatteryPercentage,
        minimumBatteryDaysRemaining: options.minimumBatteryDaysRemaining,
        maximumBatteryFluctuation: options.maximumBatteryFluctuation,
        minimumFirmwareVersionString: options.minimumFirmwareVersionString,
        maximumTimeDeviation: options.maximumTimeDeviation,
      });
      blockUtils.unblockUI();
    }).catch((error) => {
      blockUtils.unblockUI();
      window.showAlert(i18next.t('error'), viewUtils.getErrorDescription(error));
    });
  }

  getValuesForTimeInterval() {
    return [
      {'id': 'one_day_hourly', 'name': i18next.t('terminals.times.one_day_hourly')},
      {'id': 'two_days_hourly', 'name': i18next.t('terminals.times.two_days_hourly')},
      {'id': 'one_week_hourly', 'name': i18next.t('terminals.times.one_week_hourly')},
      {'id': 'two_weeks_hourly', 'name': i18next.t('terminals.times.two_weeks_hourly')},
      {'id': 'one_month_daily', 'name': i18next.t('terminals.times.one_month_daily')},
      {'id': 'three_month_daily', 'name': i18next.t('terminals.times.three_month_daily')},
      {'id': 'one_year_weekly', 'name': i18next.t('terminals.times.one_year_weekly')},
    ];
  }

  passOptionsToSuper() {
    if (this.validateAllFields('options-form', 'terminals.options.') === false) {
      return;
    }

    this.props.onOptionsSave(this.state);
  };

  updateQueryTime(e) {
    cookies.set(QUERY_TIME_COOKIE_KEY, e.target.value, {expires: new Date(Date.now() + 31536000000)});
    this.onChange(e);
  }

  updateChosenSite(e) {
    if (e.target.value !== '-1') {
      cookies.set(SITE_COOKIE_KEY, e.target.value, {expires: new Date(Date.now() + 31536000000)});
      this.getAnalyticsOptionsFromServer(e.target.value);
    }

    this.onChange(e);
  }

  render() {
    return (
      <div className="row col-md-12 options-form">
        <div className="row col-md-12">
          <div className="col-md-6">
            <SelectInput
              fieldId="chosenSite"
              labelText={i18next.t('terminals.options.chosenSite')}
              onChange={this.updateChosenSite}
              selected={this.state.chosenSite}
              values={this.props.sites}
              isRequired={true}
              validate={() => this.validateField(TEXT_KEY, 'chosenSite')}
              error={this.state.chosenSiteError}
            />
          </div>
          <div className="col-md-6">
            <SelectInput
              fieldId="chartsQueryTime"
              labelText={i18next.t('terminals.options.chartsQueryTime')}
              selected={this.state.chartsQueryTime}
              onChange={this.updateQueryTime}
              values={this.getValuesForTimeInterval()}
              isRequired={true}
            />
          </div>
        </div>
        <div className="row col-md-5">
          <Checkbox
            fieldId="advancedOptions"
            labelText="Show advanced options"
            onChange={() => this.setState({showAdvancedOptions: !this.state.showAdvancedOptions})}
            value={this.state.showAdvancedOptions} />
        </div>
        {this.state.showAdvancedOptions ?
          <div className="row col-md-12">
            <div className="col-md-6">
              <TextInput
                fieldId="minimumUptime"
                labelText={i18next.t('terminals.options.minimumUptime')}
                onChange={this.onChange}
                value={this.state.minimumUptime}
                isRequired={true}
                validate={() => this.validateField(TEXT_KEY, 'minimumUptime')}
                error={this.state.minimumUptimeError}
              />
              <TextInput
                fieldId="minimumWebUnlockSuccessRate"
                labelText={i18next.t('terminals.options.minimumWebUnlockSuccessRate')}
                onChange={this.onChange}
                value={this.state.minimumWebUnlockSuccessRate}
                isRequired={true}
                validate={() => this.validateField(TEXT_KEY, 'minimumWebUnlockSuccessRate')}
                error={this.state.minimumWebUnlockSuccessRateError}
              />
              <TextInput
                fieldId="maximumWebUnlockLatency"
                labelText={i18next.t('terminals.options.maximumWebUnlockLatency')}
                onChange={this.onChange}
                value={this.state.maximumWebUnlockLatency}
                isRequired={true}
                validate={() => this.validateField(TEXT_KEY, 'maximumWebUnlockLatency')}
                error={this.state.maximumWebUnlockLatencyError}
              />
              <TextInput
                fieldId="minimumBatteryPercentage"
                labelText={i18next.t('terminals.options.minimumBatteryPercentage')}
                onChange={this.onChange}
                value={this.state.minimumBatteryPercentage}
                isRequired={true}
                validate={() => this.validateField(TEXT_KEY, 'minimumBatteryPercentage')}
                error={this.state.minimumBatteryPercentageError}
              />
            </div>
            <div className="col-md-6">
              <TextInput
                fieldId="minimumBatteryDaysRemaining"
                labelText={i18next.t('terminals.options.minimumBatteryDaysRemaining')}
                onChange={this.onChange}
                value={this.state.minimumBatteryDaysRemaining}
                isRequired={true}
                validate={() => this.validateField(TEXT_KEY, 'minimumBatteryDaysRemaining')}
                error={this.state.minimumBatteryDaysRemainingError}
              />
              <TextInput
                fieldId="maximumBatteryFluctuation"
                labelText={i18next.t('terminals.options.maximumBatteryFluctuation')}
                onChange={this.onChange}
                value={this.state.maximumBatteryFluctuation}
                isRequired={true}
                validate={() => this.validateField(TEXT_KEY, 'maximumBatteryFluctuation')}
                error={this.state.maximumBatteryFluctuationError}
              />
              <TextInput
                fieldId="minimumFirmwareVersionString"
                labelText={i18next.t('terminals.options.minimumFirmwareVersionString')}
                onChange={this.onChange}
                value={this.state.minimumFirmwareVersionString}
                isRequired={true}
                validate={() => this.validateField(TEXT_KEY, 'minimumFirmwareVersionString')}
                error={this.state.minimumFirmwareVersionStringError}
              />
              <TextInput
                fieldId="maximumTimeDeviation"
                labelText={i18next.t('terminals.options.maximumTimeDeviation')}
                onChange={this.onChange}
                value={this.state.maximumTimeDeviation}
                isRequired={true}
                validate={() => this.validateField(TEXT_KEY, 'maximumTimeDeviation')}
                error={this.state.maximumTimeDeviationError}
              />
            </div>
          </div> :
          null
        }
        <div className="row col-md-12">
          <button
            className="btn btn-primary"
            onClick={this.passOptionsToSuper}
            style={{margin: 10}}
          >
            {i18next.t('terminals.save_button')}
          </button>
        </div>
      </div>
    );
  }
}

AnalyticsOptions.propTypes = {
  onOptionsSave: PropTypes.func,
  sites: PropTypes.array,
};
AnalyticsOptions.defaultProps = {
  onOptionsSave: PropTypes.func,
  sites: [],
};

const mapStateToProps = function(store) {
  const availableSites = store.sitesState.sites;
  const index = availableSites.findIndex((entry) => entry.id === -1);
  if (index === -1) {
    availableSites.splice(0, 0, {'id': -1, 'name': ''});
  }
  return {
    sites: availableSites,
  };
};

export default connect(mapStateToProps)(AnalyticsOptions);
