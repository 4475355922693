/**
 * @author TomaszCzura ({}
 */

import i18next from 'i18next';
import {nameOfSite} from '../store';

export function toLocal(log) {
  return {
    // Transaction data
    id: log.id,
    source: log.source,
    type: log.type || 'UNKNOWN',
    reportedAt: log.reportedAt,
    createdAt: log.createdAt,
    // Reservation data
    siteName: nameOfSite(log.siteId),
    siteId: log.siteId,
    reservationId: log.reservationId,
    room: log.room,
    // Mobile user data
    mobileUserEmail: log.mobileUserEmail || '',
    osVersion: log.osVersion,
    appVersion: log.appVersion,
    verified: log.verified,
    // Terminal data
    terminalErrorCode: log.terminalErrorCode,
    terminalErrorCodeAsMessage: parseInt(log.terminalErrorCode) || '',
    terminalBatteryLevel: log.terminalBatteryLevel,
    terminalBatteryStatus: log.terminalBatteryStatus,
  };
}

export function getLockCodeText(lockError) {
  switch (lockError) {
    case 0x00: return i18next.t('transaction_logs.lock_errors.other_error');
    case 0xFE: return i18next.t('transaction_logs.lock_errors.other_error');
    case 0xFF: return i18next.t('transaction_logs.lock_errors.other_error');
    case 0x02: return i18next.t('transaction_logs.lock_errors.invalid_key');
    case 0x03: return i18next.t('transaction_logs.lock_errors.invalid_key_response_length');
    case 0x04: return i18next.t('transaction_logs.lock_errors.scan_timeout');
    case 0x05: return i18next.t('transaction_logs.lock_errors.enable_notification_timeout');
    case 0x06: return i18next.t('transaction_logs.lock_errors.ble_connection_timeout');
    case 0x07: return i18next.t('transaction_logs.lock_errors.key_result_error');
    case 0x08: return i18next.t('transaction_logs.lock_errors.device_response_error');
    case 0x09: return i18next.t('transaction_logs.lock_errors.other_error');
    case 0x61: return i18next.t('transaction_logs.lock_errors.discover_services_error');
    case 0x62: return i18next.t('transaction_logs.lock_errors.authorization_failed');
    case 0x63: return i18next.t('transaction_logs.lock_errors.bluetooth_disabled');
    case 0x64: return i18next.t('transaction_logs.lock_errors.no_permissions');
    case 0x65: return i18next.t('transaction_logs.lock_errors.bluetooth_not_available');
    case 0x66: return i18next.t('transaction_logs.lock_errors.open_in_progress');
    case 0x11: return i18next.t('transaction_logs.lock_errors.invalid_generation');
    case 0x12: return i18next.t('transaction_logs.lock_errors.no_valid_access_zone');
    case 0x13: return i18next.t('transaction_logs.lock_errors.no_valid_access_point');
    case 0x14: return i18next.t('transaction_logs.lock_errors.id_does_not_match');
    case 0x15: return i18next.t('transaction_logs.lock_errors.sa_invalid');
    case 0x16: return i18next.t('transaction_logs.lock_errors.key_already_used');
    case 0x17: return i18next.t('transaction_logs.lock_errors.blacklist_key');
    case 0x21: return i18next.t('transaction_logs.lock_errors.start_time_not_reached');
    case 0x22: return i18next.t('transaction_logs.lock_errors.end_time_exceeded');
    case 0x23: return i18next.t('transaction_logs.lock_errors.time_mask_not_match');
    case 0x24: return i18next.t('transaction_logs.lock_errors.invalid_time_model');
    case 0x25: return i18next.t('transaction_logs.lock_errors.refresh_time_too_long_ago');
    case 0x26: return i18next.t('transaction_logs.lock_errors.key_locked');
    case 0x31: return i18next.t('transaction_logs.lock_errors.dnd_access_denied');
    case 0x32: return i18next.t('transaction_logs.lock_errors.sabotage_blocked');
    case 0x33: return i18next.t('transaction_logs.lock_errors.battery_blocked');
    case 0x34: return i18next.t('transaction_logs.lock_errors.permanently_blocked');
    case 0x35: return i18next.t('transaction_logs.lock_errors.no_toggle_privilege');
    case 0x41: return i18next.t('transaction_logs.lock_errors.no_access_control');
    case 0x42: return i18next.t('transaction_logs.lock_errors.error_rfid_tag');
    case 0x43: return i18next.t('transaction_logs.lock_errors.key_format_invalid');
    case 0x44: return i18next.t('transaction_logs.lock_errors.sa_entry_expired');
    case 0x45: return i18next.t('transaction_logs.lock_errors.sa_master_op');
    case 0x46: return i18next.t('transaction_logs.lock_errors.no_authorization');
    case 0x47: return i18next.t('transaction_logs.lock_errors.invalid_timezone');
    case 0x01: return i18next.t('transaction_logs.lock_errors.invalid_crc');
    default: return i18next.t('transaction_logs.lock_errors.other_error');
  }
}
