/**
 * @author TomaszCzura ({}
 */
import * as types from '../actions/action-types';

const initialState = {
  sites: [],
  presentedSite: {},
  currentSiteId: -1,
  getSitesError: null,
  getSiteError: null,
  createSiteError: null,
  updateSiteError: null,
  updateSpecialAreaError: null,
  destroySiteError: null,
  rooms: [],
  specialareas: [],
  getRoomsError: null,
  getSpecialareasError: null,
  createSpecialareaError: null,
};

const sitesReducer = function(state = initialState, action) {
  switch (action.type) {
    case types.NEW_SITE_SUCCESS:
    {
      const newSites = state.sites.concat([action.site]);
      return Object.assign({}, state, {sites: newSites, presentedSite: {}, createSiteError: null});
    }
    case types.NEW_SITE_ERROR:
      return Object.assign({}, state, {sites: state.sites, createSiteError: action.error, presentedSite: {}});
    case types.GET_SITES_SUCCESS:
      return Object.assign({}, state, {sites: action.sites, getSitesError: null});
    case types.GET_SITES_ERROR:
      return Object.assign({}, state, {sites: [], getSitesError: action.error, presentedSite: {}});
    case types.GET_SITE_SUCCESS:
      return Object.assign({}, state, {presentedSite: action.presentedSite, getSiteError: null});
    case types.GET_SITE_ERROR:
      return Object.assign({}, state, {presentedSite: {}, getSiteError: action.error});
    case types.UPDATE_SITE_SUCCESS:
    {
      const newSites = state.sites.map((site) => site.id === action.presentedSite.id ? action.presentedSite : site);
      return Object.assign({}, state, {sites: newSites, presentedSite: action.presentedSite, updateSiteError: null});
    }
    case types.UPDATE_SITE_ERROR:
      return Object.assign({}, state, {updateSiteError: action.error});
    case types.DELETE_SITE_SUCCESS:
    {
      const newSites = state.sites.filter((site) => site.id !== action.siteId);
      return Object.assign({}, state, {sites: newSites, destroySiteError: null, presentedSite: {}});
    }
    case types.DELETE_SITE_ERROR:
      return Object.assign({}, state, {destroySiteError: action.error});
    case types.CURRENT_SITE_CHANGE:
      return Object.assign({}, state, {currentSiteId: action.currentSiteId});
    case types.SITE_CONNECTED:
      return setSiteState(state, action.siteId, true);
    case types.SITE_DISCONNECTED:
    {
      setHmsState(state, action.siteId, false);
      return setSiteState(state, action.siteId, false);
    }
    case types.HMS_CONNECTED:
      return setHmsState(state, action.siteId, true);
    case types.HMS_DISCONNECTED:
      return setHmsState(state, action.siteId, false);
    case types.GET_SITE_ROOMS_SUCCESS:
      return Object.assign({}, state, {rooms: action.rooms, getRoomsError: null});
    case types.GET_SITE_ROOMS_ERROR:
      return Object.assign({}, state, {rooms: [], getRoomsError: action.error});
  }

  return state;
};

function setSiteState(storeState, siteId, siteState) {
  const newSites = storeState.sites.slice();
  const site = newSites.filter((site) => site.id === siteId)[0];
  if (site) {
    site.connected = siteState;
    return Object.assign({}, storeState, {sites: newSites});
  } else {
    return storeState;
  }
}

function setHmsState(storeState, siteId, hmsState) {
  const newSites = storeState.sites.slice();
  const site = newSites.filter((site) => site.id === siteId)[0];
  if (site) {
    site.hmsConnected = hmsState;
    return Object.assign({}, storeState, {sites: newSites});
  } else {
    return storeState;
  }
}

export default sitesReducer;
