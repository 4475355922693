/**
 * @author Tomasz Czura (9/1/16)
 */
export const PushMethods = [
  {id: 'GET', name: 'GET'},
  {id: 'OPTIONS', name: 'OPTIONS'},
  {id: 'PATCH', name: 'PATCH'},
  {id: 'POST', name: 'POST'},
  {id: 'PUT', name: 'PUT'},
  {id: 'TRACE', name: 'TRACE'},
];


export function toLocal(developer) {
  return {
    id: developer.id,
    email: developer.email,
    active: developer.active || false,
    pmsToken: developer.pmsSecret ? {
      id: developer.id,
      secret: developer.pmsSecret,
    } : {},
    guestToken: developer.mobileSecret ? {
      id: developer.id,
      secret: developer.mobileSecret,
    } : {},
    sites: developer.sites,
    pushUrl: developer.pushUrl,
    pushMethod: developer.pushMethod,
    pushContentType: developer.pushContentType,
    pushAuthorization: developer.pushAuthorization,
    pushBody: developer.pushBody,
    password: developer.secret,
    hasOwnEmailVerificationService: developer.hasOwnEmailVerificationService,
  };
}

export function toNetwork(developer) {
  return {
    email: developer.email,
    active: developer.active || false,
    siteIds: developer.siteIds || [],
    pushUrl: developer.pushUrl,
    pushMethod: developer.pushMethod,
    pushContentType: developer.pushContentType,
    pushAuthorization: developer.pushAuthorization,
    pushBody: developer.pushBody,
    hasOwnEmailVerificationService: developer.hasOwnEmailVerificationService,
  };
}

export function toForm(developer) {
  return {
    id: developer.id,
    email: developer.email,
    active: developer.active || false,
    siteIds: developer.sites.map((site) => site.id) || [],
    pmsToken: developer.pmsToken || {},
    guestToken: developer.guestToken || {},
    pushUrl: developer.pushUrl || '',
    pushMethod: developer.pushMethod || 'GET',
    pushContentType: developer.pushContentType || '',
    pushAuthorization: developer.pushAuthorization || '',
    pushBody: developer.pushBody || '',
    hasOwnEmailVerificationService: developer.hasOwnEmailVerificationService || false,
  };
}

export function defaultLocal() {
  return {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    passwordConfirmation: '',
    siteIds: [],
    active: true,
    pushUrl: '',
    pushMethod: 'GET',
    pushContentType: '',
    pushAuthorization: '',
    pushBody: '',
    hasOwnEmailVerificationService: false,
  };
}

export function encodedPMSToken(developer) {
  return encodedToken(developer.pmsToken, 'pms');
}

export function encodedGuestToken(developer) {
  return encodedToken(developer.guestToken, 'mobile');
}

function encodedToken(token, type) {
  if (!token || !token.id || !token.secret) return '';
  const encodedId = btoa(`v1-${token.id}-${type}`);
  const encodedSecret = btoa(token.secret);
  return btoa(`${encodedId}:${encodedSecret}`);
}
