/**
 * @author TomaszCzura ({}
 */
import * as types from '../actions/action-types';

const initialState = {
  mobileUsers: [],
  presentedMobileUser: {},
  getMobileUsersError: null,
  logs: [],
  getLogsError: null,
  destroyMobileUserError: null,
  totalPages: 0,
  totalElements: 0,
  logsTotalPages: 0,
  logsTotalElements: 0,
};

const mobileUsersReducer = function(state = initialState, action) {
  switch (action.type) {
    case types.GET_MOBILE_USERS_SUCCESS:
      return Object.assign({}, state, {mobileUsers: action.users,
        totalPages: action.totalPages,
        totalElements: action.totalElements,
        getMobileUsersError: null});
    case types.GET_MOBILE_USERS_ERROR:
      return Object.assign({}, state, {
        mobileUsers: [],
        getMobileUsersError: action.error,
      });
    case types.GET_MOBILE_USER_LOGS_SUCESS:
      return Object.assign({}, state, {
        logs: action.logs,
        getLogsError: null,
        logsTotalPages: action.totalPages,
        logsTotalElements: action.totalElements,
      });
    case types.GET_MOBILE_USER_LOGS_ERROR:
      return Object.assign({}, state, {
        logs: [],
        getLogsError: action.error,
        logsTotalPages: 0,
        logsTotalElements: 0,
      });
    case types.DELETE_MOBILE_USER_SUCCESS:
    {
      const newUsers = state.mobileUsers.filter((users) => users.id != action.mobileUserId);
      return Object.assign({}, state, {
        mobileUsers: newUsers,
        destroyMobileUserError: null,
      });
    }
    case types.DELETE_MOBILE_USER_ERROR:
      return Object.assign({}, state, {
        destroyMobileUserError: action.error,
      });
  }
  return state;
};

export default mobileUsersReducer;
