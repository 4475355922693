import React, {Component} from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import Select from 'react-select';

class ColumnSearch extends Component {
  constructor(props) {
    super(props);

    this.onSearchClick = this.onSearchClick.bind(this);
    this.onClearClick = this.onClearClick.bind(this);

    this.state = {
      operator: ':',
      options: [],
    };
  }

  onSearchClick() {
    const $table = $(`#${this.props.tableId}_parent`);

    const searchQuery = [];
    searchQuery.push(this.state.column);
    searchQuery.push(this.state.operator);
    searchQuery.push($table.find('#search_field').val());

    this.props.onSearchClick(searchQuery.join(','));
  }

  onClearClick() {
    $(`#${this.props.tableId}_parent`).find('#search_field').val('');
    this.props.onSearchClick('');
  }

  componentDidMount() {
    const options = [];
    $(`#${this.props.tableId} th`).each(function(i, obj) {
      const $obj = $(obj);
      if ($obj.attr('data-sort')) {
        options.push({value: $obj.attr('data-sort'), label: $obj.html()});
      }
    });

    this.setState({
      options: options,
    });
  }

  render() {
    const operators = [
      {value: ':', label: i18next.t('redux_table.operators.equal')},
      {value: '<', label: i18next.t('redux_table.operators.less_than')},
      {value: '>', label: i18next.t('redux_table.operators.greater_than')},
    ];

    return (
      <div id={`${this.props.tableId}_filter`} className="search_dt col-md-10 col-sm-10">
        <form className="row g-3">
          <div className={'col-sm-2'}></div>
          <div className={'col-sm-3'}>
            <Select
              className="form-select"
              id="column-selector"
              placeholder={i18next.t('redux_table.search_column')}
              options={this.state.options}
              onChange={(e) => this.setState({column: e.value})}
            />
          </div>
          <div className={'col-sm-2'}>
            <Select
              className="form-select"
              id="column-operator"
              options={operators}
              onChange={(e) => this.setState({operator: e.value})}
            />
          </div>
          <div className={'col-sm-3'}>
            <input className="form-control" id="search_field" aria-controls={this.props.tableId}/>
          </div>
          <div className={'col-sm-1'}>
            <button type="button" id="submit-button" className="btn btn-primary" onClick={this.onSearchClick}>
              <i className="fa fa-search"/>
              {i18next.t('redux_table.search')}
            </button>
          </div>
          <div className={'col-sm-1'}>
            <button type="button" className="btn btn-danger" onClick={this.onClearClick}>
              <i className="fa fa-close"/>
              {i18next.t('redux_table.clear')}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

ColumnSearch.propTypes = {
  tableId: PropTypes.string,
  onSearchClick: PropTypes.func,
  options: PropTypes.array,
};
ColumnSearch.defaultProps = {};

export default ColumnSearch;
