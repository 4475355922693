/**
 * @author Tomasz Czura (9/7/16)
 */
import axios from 'axios';
import store from '../store';
import * as actions from '../actions/files-actions';
import * as endpoints from './endpoints';
import * as fileModel from './../model/file';
import * as auth from './../utils/auth';

export function getFiles() {
  return axios.get(endpoints.FILES, endpoints.headers())
      .then((response) => {
        const files = response.data.map((d) => fileModel.toLocal(d));
        store.dispatch(actions.getFilesSuccess(files));
      })
      .catch((error) => {
        endpoints.handleUnauthorizedError(error, () => {
          store.dispatch(actions.getFilesError(error));
        });
      });
}

export function getFile(fileId, onFileDownload, onError) {
  const xhr = new XMLHttpRequest();
  xhr.onreadystatechange = function() {
    if (this.readyState == 4) {
      if (this.status == 200) {
        onFileDownload(this.response);
      } else {
        onError(this.status);
      }
    }
  };
  xhr.open('GET', endpoints.FILES + '/' + fileId);
  xhr.setRequestHeader('Authorization', `Bearer ${auth.getToken()}`);
  xhr.responseType = 'blob';
  xhr.send();
}

export function createFile(file) {
  const data = fileModel.toNetwork(file);

  return axios.post(endpoints.FILES, data, endpoints.headers())
      .then((response) => {
        file.id = response.data.id;
        file.createdDate = new Date().getTime();
        store.dispatch(actions.createFileSuccess(file));
      })
      .catch((error) => {
        store.dispatch(actions.createFileError(error));
      });
}

export function updateFile(file) {
  const data = fileModel.toNetwork(file);

  return axios.put(`${endpoints.FILES}/${file.id}`, data, endpoints.headers())
      .then((response) => {
        store.dispatch(actions.updateFileSuccess(file));
      })
      .catch((error) => {
        store.dispatch(actions.updateFileError(error));
      });
}

export function destroyFile(fileId) {
  return axios.delete(`${endpoints.FILES}/${fileId}`, endpoints.headers())
      .then((response) => {
        store.dispatch(actions.destroyFileSuccess(fileId));
      })
      .catch((error) => {
        store.dispatch(actions.destroyFileError(error));
      });
}
