import React from 'react';
import PropTypes from 'prop-types';
import Header from './header/Header';
import Footer from './Footer';
import Main from './Main';
import DashboardSocket from './../socket/DashboardSocket';
import moment from 'moment';
import * as auth from './../utils/auth';
import CookieConsent from './utils/CookieConsent';
import SessionEndDialog from './utils/SessionEndDialog';
import {connect} from 'react-redux';
import {getUserProfile} from '../api/users-api';

export const AppVersion = `${process.env.VERSION}`;

class Application extends React.Component {
  constructor(props) {
    super(props);
    this.scheduleSessionEndDialog = this.scheduleSessionEndDialog.bind(this);
  }

  componentDidMount() {
    getUserProfile();
    $.ready();
    DashboardSocket.getInstance().connect();
    this.scheduleSessionEndDialog();
  }

  scheduleSessionEndDialog() {
    window.clearTimeout(this.sessionEndConfirmId);
    const dialogId = 'sessionEndDialog';
    const millisToSessionEnd = moment(auth.getSessionEnd()).diff();
    const millisToShowDialog = millisToSessionEnd - 30000 > 0 ? millisToSessionEnd - 30000 : 0;
    if (millisToSessionEnd > 0) {
      this.sessionEndConfirmId = setTimeout(() => {
        window.showDialog(SessionEndDialog, {dialogId: dialogId, onRenew: this.scheduleSessionEndDialog}, dialogId);
      }, millisToShowDialog);
    } else {
      auth.logout();
    }
  }

  componentWillUnmount() {
    DashboardSocket.getInstance().disconnect();
    window.clearTimeout(this.sessionEndConfirmId);
  }

  render() {
    return (
      <div>
        <CookieConsent />
        <Header />
        <div className="clearfix"></div>
        <Main>{this.props.children}</Main>
        <Footer />
      </div>
    );
  }
}

Application.propTypes = {
  children: PropTypes.node,
};

const mapStateToProps = function(store) {
  return {
    userProfile: store.usersState.theLanguage,
    userLanguage: store.usersState.userProfile ? store.usersState.userProfile.language : 'en',
  };
};

export default connect(mapStateToProps)(Application);
