/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as blockUtils from './../../utils/BlockUtils';
import i18next from 'i18next';

class DataTable extends Component {
  componentDidMount() {
    this.initDataTable();
    if (this.props.showLoading) {
      blockUtils.blockUI(`#${this.props.tableId}`);
    }
  }

  initDataTable() {
    const saveState = this.props.saveState === undefined ? true : this.props.saveState;
    const responsiveOptions = this.props.responsive ? {
      className: 'control',
      orderable: false,
      targets: 0,
    } : {};

    const buttons = this.props.csvExportBtn > 0 ? [
      {
        extend: 'csv',
        className: 'btn blue',
        text: i18next.t('billing.actions.csv_export'),
        title: this.props.csvFilename,
      },
    ] : [];

    $(`#${this.props.tableId}`).DataTable({
      'dom': '<\'row\' <\'col-md-12\'B>><\'row\'<\'col-md-6 col-sm-12\'l>'+
        '<\'col-md-6 col-sm-12\'f>r><\'table-scrollable\'t>'+
        '<\'row\'<\'col-md-5 col-sm-12\'i><\'col-md-7 col-sm-12\'p>>',
      'bStateSave': saveState,
      'pagingType': 'bootstrap_extended',
      'language': {
        search: i18next.t('redux_table.search') + ':',
        info: i18next.t('redux_table.showing_of', {min: '_START_', max: '_END_', total: '_TOTAL_'}),
        lengthMenu: '_MENU_ ' + i18next.t('redux_table.records'),
        paginate: {
          page: i18next.t('redux_table.page'),
          of: 'von',
        },
      },
      'columnDefs': [
        {
          targets: this.props.notSortable,
          orderable: false,
        },
        responsiveOptions,
      ],
      'aaSorting': this.props.defaultSort || [],
      'responsive': {
        details: {
          type: 'column',
        },
      },
      'buttons': buttons,
    });
  }

  UNSAFE_componentWillUpdate() {
    blockUtils.unblockUI(`#${this.props.tableId}`);
    if ($.fn.DataTable.isDataTable(`#${this.props.tableId}`)) {
      $(`#${this.props.tableId}`).DataTable().destroy();
    }
  }

  componentDidUpdate() {
    this.initDataTable();
  }

  render() {
    return (
      <table
        className={`table table-striped table-bordered table-hover table-responsive 
        ${this.props.responsive ? 'dt-responsive' : ''}`}
        id={this.props.tableId}
      >
        {this.props.children}
      </table>
    );
  }
}

DataTable.propTypes = {
  children: PropTypes.node,
  tableId: PropTypes.string,
  saveState: PropTypes.bool,
  notSortable: PropTypes.arrayOf(Number),
  defaultSort: PropTypes.array,
  responsive: PropTypes.bool,
  showLoading: PropTypes.bool,
  csvExportBtn: PropTypes.bool,
  csvFilename: PropTypes.string,
};
DataTable.defaultProps = {
  showLoading: true,
  csvExportBtn: false,
  csvFileName: 'data',
};

export default DataTable;
