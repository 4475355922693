/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ErrorAlert extends Component {
  render() {
    return (
            this.props.error ? (
            <div className="alert alert-danger">
              <span>{this.props.error}</span>
            </div>
            ) : null
    );
  }
}

ErrorAlert.propTypes = {
  error: PropTypes.string,
};
ErrorAlert.defaultProps = {};

export default ErrorAlert;
