/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DataTable from './../utils/DataTable';
import i18next from 'i18next';
import * as sitesApi from './../../api/sites-api';
import SiteForm from './SiteForm';
import SiteDefaultsForm from './SiteDefaultsForm';
import Status from '../utils/Status';
import * as blockUtils from './../../utils/BlockUtils';
import * as rights from './../../model/rights';
import * as viewUtils from './../../utils/view-utils';
import * as siteModel from './../../model/site';
import BooleanLabel from './../utils/BooleanLabel';
import TableAction from './../utils/actions/TableAction';
import ShowAction from './../utils/actions/ShowAction';
import EditAction from './../utils/actions/EditAction';
import EditDefaultsAction from './../utils/actions/EditDefaultsAction';
import DestroyAction from './../utils/actions/DestroyAction';
import TableActions from './../utils/TableActions';
import CreateNewButton from '../CreateNewButton';
import {onRowClick} from '../redux_data_table/ReduxDataTable';
import AnalyticsOptionsForm from './AnalyticsOptionsForm';

class SitesList extends Component {
  constructor(props) {
    super(props);
    this.siteFormDialogId = 'siteFormDialog';
    this.destroySite = this.destroySite.bind(this);
    this.disableSite = this.disableSite.bind(this);
    this.enableSite = this.enableSite.bind(this);
    this.showSiteForm = this.showSiteForm.bind(this);
    this.showSiteDefaultsForm = this.showSiteDefaultsForm.bind(this);
  }

  destroySite(siteId) {
    blockUtils.blockUI('#sites_table');
    sitesApi.destroySite(siteId);
  }

  disableSite(site) {
    this.changeSiteState(site, true);
  }

  enableSite(site) {
    this.changeSiteState(site, false);
  }

  changeSiteState(site, disabled) {
    confirm(
        () => {
          blockUtils.blockUI('#sites_table');
          const siteCopy = siteModel.toForm(site);
          siteCopy.disabled = disabled;
          sitesApi.updateSite(siteCopy);
        },
      disabled ?
        i18next.t('sites.actions.sure_disable') :
        i18next.t('sites.actions.sure_enable'),
    );
  }

  componentDidUpdate() {
    this.handleError();
  }

  handleError() {
    if (this.props.createSiteError) {
      if (this.modalDialog) {
        this.modalDialog.handleError(this.props.createSiteError);
      }
      blockUtils.unblockUI();
    } else {
      $(`#${this.siteFormDialogId}`).modal('hide');
    }
  }

  showSiteForm(site) {
    this.modalProps = {
      dialogId: this.siteFormDialogId,
      error: this.props.createSiteError,
      presentedSite: site,
    };
    this.modalDialog = viewUtils.showModal(
        SiteForm,
        this.siteFormDialogId,
        this.modalProps,
    );
  }

  showAnalyticsOptionsForm(siteId) {
    const properties = {
      dialogId: 'analytics-form',
      siteId: siteId,
    };

    viewUtils.showModal(AnalyticsOptionsForm, properties.dialogId, properties);
  }

  showSiteDefaultsForm(site) {
    this.modalProps = {
      dialogId: this.siteFormDialogId,
      error: this.props.createSiteError,
      presentedSite: site,
    };
    this.modalDialog = viewUtils.showModal(
        SiteDefaultsForm,
        this.siteFormDialogId,
        this.modalProps,
    );
  }

  render() {
    return (
      <div className="table-container">
        {rights.currentUserHasRight(rights.SITE_ADD_PRIVILEGE) ?
          (<CreateNewButton callBack={() => this.showSiteForm()} />) :
          null
        }
        <DataTable tableId="sites_table" notSortable={[3, 4, 9]}>
          <thead>
            <tr role="row" className="heading">
              <th>ID</th>
              <th>{i18next.t('sites.attrs.name')}</th>
              <th width="1%">{i18next.t('sites.attrs.status')}</th>
              <th width="1%">{i18next.t('sites.attrs.hms_connected')}</th>
              <th>{i18next.t('sites.attrs.alerts')}</th>
              <th>{i18next.t('sites.attrs.contact_email')}</th>
              <th>{i18next.t('sites.attrs.token')}</th>
              <th>{i18next.t('sites.attrs.enabled')}</th>
              <th>{i18next.t('sites.attrs.hms_interface_version')}</th>
              <th>{i18next.t('sites.attrs.last_seen')}</th>
              <th>{i18next.t('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.sites.map((site) => {
              return (
                <tr onClick={() => onRowClick(site.id)} key={site.id}>
                  <td>{site.id}</td>
                  <td>{site.name}</td>
                  <td className="status-column">
                    <Status online={site.connected} />
                  </td>
                  <td className="status-column">
                    <Status online={site.hmsConnected} />
                  </td>
                  <td>
                    <BooleanLabel value={site.disconnectionNotifications} />
                  </td>
                  <td>{site.contactEmail}</td>
                  <td>{site.token}</td>
                  <td>
                    <BooleanLabel value={!site.disabled} />
                  </td>
                  <td>{site.siteAgentVersion}</td>
                  <td>
                    {site.connected ? i18next.t('sites.online') : site.lastSeen}
                  </td>
                  <td>
                    <TableActions identifier={site.id}>
                      <ShowAction
                        title={i18next.t('sites.actions.show_specialareas')}
                        icon="icon-lock"
                        path={`/sites/${site.id}/specialareas`}
                      />
                      <EditAction
                        onEdit={() => this.showSiteForm(site)}
                        condition={rights.currentUserHasRight(rights.SITE_EDIT_PRIVILEGE)}
                      />
                      <EditDefaultsAction
                        onEdit={() => this.showSiteDefaultsForm(site)}
                        condition={rights.currentUserHasRight(rights.SITE_EDIT_PRIVILEGE)}
                      />
                      <TableAction
                        text={i18next.t('terminals.edit_analytics_options_title')}
                        action={() => this.showAnalyticsOptionsForm(site.id)}
                        condition={rights.currentUserHasRight(rights.SITE_EDIT_PRIVILEGE)}
                        icon="icon-pie-chart"
                      />
                      <TableAction
                        text={i18next.t('sites.actions.enable')}
                        action={() => this.enableSite(site)}
                        condition={site.disabled === true}
                        icon="icon-check"
                      />
                      <TableAction
                        text={i18next.t('sites.actions.disable')}
                        action={() => this.disableSite(site)}
                        condition={site.disabled !== true}
                        icon="icon-ban"
                      />
                      <DestroyAction
                        destroyElement={() => this.destroySite(site.id)}
                        condition={rights.currentUserHasRight(rights.SITE_DELETE_PRIVILEGE)}
                      />
                    </TableActions>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </DataTable>
      </div>
    );
  }
}

SitesList.propTypes = {
  sites: PropTypes.array,
  createSiteError: PropTypes.object,
  getSitesError: PropTypes.string,
  destroySiteError: PropTypes.string,
};
SitesList.defaultProps = {};

export default SitesList;
