/**
 * @author Tomasz Czura (9/6/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import RowsCountSelect from './RowsCountSelect';
import Paging from './Paging';
import RowsSummary from './RowsSummary';
import {getExpandedCheckinRowId, saveExpandedCheckinRowId} from '../../utils/auth';
import ColumnSearch from './ColumnSearch';

class ReduxDataTable extends Component {
  constructor(props) {
    super(props);

    this.onPageChange = this.onPageChange.bind(this);
    this.onElementsCountChange = this.onElementsCountChange.bind(this);
    this.initCollapsable = this.initCollapsable.bind(this);
    this.initEmptyRow = this.initEmptyRow.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.sortData = this.sortData.bind(this);
    this.collapse = this.collapse.bind(this);

    this.sortType = 'ASC';
    this.sortColumn = -1;
    this.sortName = '';
    this.searchPhrase = '';

    this.state = {
      currentPage: 1,
      elementsCount: 10,
    };
  }

  componentDidMount() {
    this.initSortableRows();
    this.initCollapsable();
    this.initEmptyRow();
  }

  initEmptyRow() {
    const rowsCount = $('tr.odd').length;
    $('tr.emptyRow').remove();
    if (rowsCount === 0) {
      const colsCount = this.getColumnsCount();
      const emptyRow = $(`<tr class="emptyRow">`+
        `<td valign="top" colspan=${colsCount} class="dataTables_empty">`+
          `${i18next.t('redux_table.no_data')}`+
        `</td></tr>`);
      $('tr').after(emptyRow);
    }
  }

  initSortableRows() {
    const rowHeads = $(`#${this.props.tableId}`).find('tr.heading th');
    rowHeads.addClass('sorting');
    $.each(this.props.notSortable, (index, value) => {
      rowHeads.eq(parseInt(value)).removeClass('sorting');
    });
    if (this.state.sortColumn > -1) {
      rowHeads.eq(this.sortColumn).addClass(this.sortType === 'ASC' ? 'sorting_asc' : 'sorting_desc');
    }

    if (this.props.defaultSort) {
      rowHeads.eq(this.props.defaultSort[0])
          .addClass(this.props.defaultSort[1] === 'ASC' ? 'sorting_asc' : 'sorting_desc');
      this.sortName = rowHeads.eq(this.props.defaultSort[0]).data().sort_name;
      this.sortType = this.props.defaultSort[1].toLowerCase();
    }

    rowHeads.on('click', (e) => {
      const $target = $(e.target);
      if ($target.hasClass('sorting')) {
        this.clearSortingColumns();
        this.sortType = 'ASC';
        $target.addClass('sorting_asc').removeClass('sorting');
        this.sortData($target);
      } else if ($target.hasClass('sorting_asc')) {
        this.clearSortingColumns();
        this.sortType = 'DESC';
        $target.addClass('sorting_desc').removeClass('sorting_asc');
        this.sortData($target);
      } else if ($target.hasClass('sorting_desc')) {
        this.clearSortingColumns();
        this.sortType = 'ASC';
        $target.addClass('sorting_asc').removeClass('sorting_desc');
        this.sortData($target);
      }
    });
  }

  clearSortingColumns() {
    $('th.sorting_asc').removeClass('sorting_asc').addClass('sorting');
    $('th.sorting_desc').removeClass('sorting_desc').addClass('sorting');
  }

  sortData(targetElement) {
    this.sortColumn = targetElement.index();
    this.sortName = targetElement.data('sort');
    this.props.getData(
        this.state.currentPage - 1,
        this.state.elementsCount,
        this.sortName,
        this.sortType,
        this.searchPhrase,
    );
  }

  collapse() {
    $('tr.child').remove();
    $('tr').removeClass('parent');
  }

  componentDidUpdate() {
    this.collapse();
    this.initEmptyRow();

    const $expandedElement = $(`#${getExpandedCheckinRowId()}`);
    if ($expandedElement) {
      $expandedElement.find('td.control').click();
    }
  }

  getColumnsCount() {
    return $('table > thead > tr:first > th').length;
  }

  onSearchClick(phrase) {
    this.searchPhrase = phrase;
    this.props.getData(
        this.state.currentPage - 1,
        this.state.elementsCount,
        this.sortName,
        this.sortType,
        this.searchPhrase,
    );
  }

  componentWillUnmount() {
    $('body').off('click', `#${this.props.tableId} td.control`);
    saveExpandedCheckinRowId('');
  }

  initCollapsable() {
    if (this.props.collapsable) {
      const $body = $('body');
      $body.off('click', 'td.control');
      $body.on('click', 'td.control', (e) => {
        const colsCount = this.getColumnsCount();
        const $closestTr = $(e.target).closest('tr');
        if ($closestTr.hasClass('parent')) {
          $closestTr.next().remove();
          $closestTr.removeClass('parent');
          saveExpandedCheckinRowId($closestTr.attr(''));
        } else {
          const $closestHidden = $(e.target).parent().find('.hidden');
          const elementHtml = `<tr class='child'>`+
          `<td colspan='${colsCount}'><span>${$closestHidden.html()}</td>`+
          `</span></tr>`;
          $closestTr.after(elementHtml);
          $closestTr.addClass('parent');
          saveExpandedCheckinRowId($closestTr.attr('id'));
          $('.popovers').popover();
        }
      });
    }
  }

  onPageChange(newPageNumber) {
    this.setState({
      currentPage: newPageNumber,
    });
    this.props.getData(newPageNumber - 1, this.state.elementsCount, this.sortName, this.sortType, this.searchPhrase);
  }

  onElementsCountChange(elementsCount) {
    this.setState({
      elementsCount: parseInt(elementsCount),
    });
    this.props.getData(this.state.currentPage - 1, elementsCount, this.sortName, this.sortType, this.searchPhrase);
  }

  render() {
    let startIndex = 0;
    if (this.props.totalElements > 0) {
      startIndex = (this.state.currentPage - 1) * this.state.elementsCount + 1;
    }
    let endIndex = startIndex + this.state.elementsCount - 1;
    if (endIndex > this.props.totalElements) {
      endIndex = this.props.totalElements;
    }

    return (
      <div className="redux_table" id={`${this.props.tableId}_parent`}>
        <div className={`row`}>
          <RowsCountSelect
            tableId={this.props.tableId}
            onElementsCount={this.onElementsCountChange}
            elementsCount={this.state.elementsCount}
          />
          <ColumnSearch
            tableId={this.props.tableId}
            onSearchClick={this.onSearchClick}
          />
        </div>
        <div className="table-scrollable">
          <table
            className={`table table-striped table-bordered `+
                    `table-hover table-responsive dataTable `+
                    `${this.props.collapsable ? 'dt-responsive dtr-column' : ''}`}
            id={this.props.tableId}>
            {this.props.children}
          </table>
        </div>
        <div className="row">
          <RowsSummary tableId={this.props.tableId} min={startIndex} max={endIndex}
            total={this.props.totalElements}/>
          <Paging maxPage={this.props.totalPages} currentPage={this.state.currentPage} minPage={1}
            onPageChange={this.onPageChange} tableId={this.props.tableId}/>
        </div>
      </div>
    );
  }
}

export function onRowClick(btnIdentifier, event) {
  setTimeout(function() {
    if (!event || event.target.className !== 'control') {
      $('#' + btnIdentifier).addClass('open');
    }
  }, 10);
}

ReduxDataTable.propTypes = {
  children: PropTypes.node,
  tableId: PropTypes.string,
  totalElements: PropTypes.number,
  totalPages: PropTypes.number,
  numberOfElements: PropTypes.number,
  collapsable: PropTypes.bool,
  notSortable: PropTypes.array,
  getData: PropTypes.func,
  defaultSort: PropTypes.any,
};
ReduxDataTable.defaultProps = {
  collapsable: false,
  notSortable: [],
};

export default ReduxDataTable;
