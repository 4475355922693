/**
 * @author Tomasz Czura (11/8/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DataTable from './../utils/DataTable';
import i18next from 'i18next';
import * as roomModel from './../../model/room';
import * as viewUtils from './../../utils/view-utils';

class RoomsList extends Component {
  render() {
    return (
      <div className="table-container">
        <DataTable tableId="rooms_table" defaultSort={[0, 'asc']}>
          <thead>
            <tr role="row" className="heading">
              <th>{i18next.t('rooms.attrs.room_number')}</th>
              <th>{i18next.t('rooms.attrs.battery_level')}</th>
              <th>{i18next.t('rooms.attrs.battery_status')}</th>
              <th>{i18next.t('rooms.attrs.updated_at')}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.rooms.map((room) => {
              return (
                <tr key={room.id}>
                  <td>{room.number}</td>
                  <td>{room.batteryLevel}%</td>
                  <td><span className={`${room.batteryStatus === '0' ? 'text-connected' : 'text-disconnected'}`}>
                    {roomModel.getBatteryStatusInfo(room.batteryStatus)}</span></td>
                  <td>{viewUtils.toYYYYMMDDHMS(room.updatedAt)}</td>
                </tr>
              );
            })}
          </tbody>
        </DataTable>
      </div>
    );
  }
}

RoomsList.propTypes = {
  rooms: PropTypes.array,
};
RoomsList.defaultProps = {};

export default RoomsList;
