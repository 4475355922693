/**
 * @author TomaszCzura ({}
 */
import * as types from '../actions/action-types';

export function getSitesSuccess(sites) {
  return {
    type: types.GET_SITES_SUCCESS,
    sites,
  };
}

export function getSitesError(error) {
  return {
    type: types.GET_SITES_ERROR,
    error: error,
  };
}

export function getSiteError(error) {
  return {
    type: types.GET_SITE_ERROR,
    error: error,
  };
}

export function getSiteSuccess(presentedSite) {
  return {
    type: types.GET_SITE_SUCCESS,
    presentedSite,
  };
}

export function deleteSiteSuccess(siteId) {
  return {
    type: types.DELETE_SITE_SUCCESS,
    siteId,
  };
}

export function deleteSiteError(error) {
  return {
    type: types.DELETE_SITE_ERROR,
    error,
  };
}

export function deleteSpecialareaSuccess(siteId, specialAreaId) {
  return {
    type: types.DELETE_SITE_SPECIALAREA_SUCCESS,
    siteId,
    specialAreaId,
  };
}

export function deleteSpecialareaError(error) {
  return {
    type: types.DELETE_SITE_SPECIALAREA_ERROR,
    error,
  };
}

export function clearSiteErrors() {
  return {
    type: types.CLEAR_SITE_ERRORS,
  };
}

export function createSiteSuccess(site) {
  return {
    type: types.NEW_SITE_SUCCESS,
    site,
  };
}

export function createSpecialAreaSuccess(siteId, specialArea) {
  return {
    type: types.NEW_SITE_SPECIALAREA_SUCCESS,
    siteId,
    specialArea,
  };
}

export function createSiteError(error) {
  return {
    type: types.NEW_SITE_ERROR,
    error,
  };
}

export function createSpecialAreaError(siteId, specialArea, error) {
  return {
    type: types.NEW_SITE_SPECIALAREA_ERROR,
    error,
    siteId,
    specialArea,
  };
}

export function updateSiteSuccess(presentedSite) {
  return {
    type: types.UPDATE_SITE_SUCCESS,
    presentedSite,
  };
}

export function updateSpecialAreaSuccess(siteId, specialArea) {
  return {
    type: types.UPDATE_SITE_SPECIALAREA_SUCCESS,
    siteId,
    specialArea,
  };
}

export function updateSiteError(presentedSite, error) {
  return {
    type: types.UPDATE_SITE_ERROR,
    error,
    presentedSite,
  };
}

export function updateSpecialAreaError(siteId, specialArea, error) {
  return {
    type: types.UPDATE_SITE_SPECIALAREA_ERROR,
    error,
    siteId,
    specialArea,
  };
}

export function changeCurrentSite(currentSiteId) {
  return {
    type: types.CURRENT_SITE_CHANGE,
    currentSiteId,
  };
}

export function siteConnected(siteId) {
  return {
    type: types.SITE_CONNECTED,
    siteId,
  };
}

export function siteDisconnected(siteId) {
  return {
    type: types.SITE_DISCONNECTED,
    siteId,
  };
}

export function hmsConnected(siteId) {
  return {
    type: types.HMS_CONNECTED,
    siteId,
  };
}

export function hmsDisconnected(siteId) {
  return {
    type: types.HMS_DISCONNECTED,
    siteId,
  };
}

export function getSiteRoomsSuccess(rooms) {
  return {
    type: types.GET_SITE_ROOMS_SUCCESS,
    rooms,
  };
}

export function getSiteRoomsError(error) {
  return {
    type: types.GET_SITE_ROOMS_ERROR,
    error,
  };
}
export function getSiteSpecialareasSuccess(specialareas) {
  return {
    type: types.GET_SITE_SPECIALAREAS_SUCCESS,
    specialareas,
  };
}

export function getSiteSpecialareasError(error) {
  return {
    type: types.GET_SITE_SPECIALAREAS_ERROR,
    error,
  };
}
