// import "@babel/polyfill";

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import store from './store';
import router from './router';
import Languages from './i18n/Languages';
import ConfirmDialog from './components/utils/ConfirmDialog';
import AlertDialog from './components/utils/AlertDialog';
import i18next from 'i18next';
import ReduxToastr from 'react-redux-toastr';
import * as ErrorReport from './utils/ErrorReport';

Languages.initialize(navigator.languages[1] || 'en');
ErrorReport.initializeErrorReporting();

function confirm(onConfirm, message = i18next.t('confirmation.are_you_sure')) {
  const props = {onConfirm: onConfirm, message: message};
  showDialog(ConfirmDialog, props, 'confirmation_dialog');
}

function showAlert(title, text, onOkClick = () => { }) {
  const props = {onOkClick: onOkClick, title: title, text: text};
  showDialog(AlertDialog, props, 'alert_dialog');
}

function showDialog(component, props, dialogId) {
  const wrapper = document.body.appendChild(document.createElement('div'));
  ReactDOM.render(React.createElement(component, props), wrapper);
  const $dialog = $(`#${dialogId}`);
  $dialog.modal('show');
  $dialog.on(('hide.bs.modal'), function(e) {
    ReactDOM.unmountComponentAtNode(wrapper);
    setTimeout(wrapper.remove(), 100);
  });
}

window.confirm = confirm;
window.alert = alert;
window.showAlert = showAlert;
window.showDialog = showDialog;

ReactDOM.render(
    <Provider store={store}>
      <div>
        {router}
        <ReduxToastr
          timeOut={10000}
          newestOnTop={true}
          preventDuplicates={false}
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut" />
      </div>
    </Provider>,
    document.getElementById('root'),
);
