import {Box, Tab, Tabs} from '@mui/material';
import React, {Component} from 'react';
import TabPanel from './TabPanel';
import PropTypes from 'prop-types';
import WhitePortlet from '../utils/WhitePortlet';
import LiveSiteStatus from './LiveSiteStatus';
import SiteKpi from './kpi/SiteKpi';
import TerminalKpi from './kpi/TerminalKpi';
import i18next from 'i18next';
import SiteStatusContainer from './site_status/SiteStatusContainer';

class TerminalsContainer extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.triggerTabChangeOnClickOfTerminal = this.triggerTabChangeOnClickOfTerminal.bind(this);

    this.state = {
      value: 0,
    };
  }

  handleChange(event, newValue) {
    this.setState({
      value: newValue,
    });
  }

  triggerTabChangeOnClickOfTerminal() {
    this.handleChange(null, 3);
  }

  render() {
    return (
      <WhitePortlet
        caption="Terminal Data"
        icon="icon-screen-tablet"
      >
        <div className="scrolled_div">
          <Box sx={{width: '100%', height: '100%'}}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
              <Tabs value={this.state.value} onChange={this.handleChange} aria-label="basic tabs example">
                <Tab
                  label={i18next.t('terminals.tabs.site_status')}
                  id={0}
                  aria-controls='simple-tabpanel-0'
                />
                <Tab
                  disabled={true}
                  label={i18next.t('terminals.tabs.live_site_status')}
                  id={1}
                  aria-controls='simple-tabpanel-1'
                />
                <Tab
                  label={i18next.t('terminals.tabs.site_kpi')}
                  id={2}
                  aria-controls='simple-tabpanel-2'
                />
                <Tab
                  label={i18next.t('terminals.tabs.terminal_kpi')}
                  id={3}
                  aria-controls='simple-tabpanel-3'
                />
              </Tabs>
            </Box>
            <TabPanel value={this.state.value} index={0}>
              <SiteStatusContainer />
            </TabPanel>
            <TabPanel value={this.state.value} index={1}>
              <LiveSiteStatus/>
            </TabPanel>
            <TabPanel value={this.state.value} index={2}>
              <SiteKpi triggerTabChangeFunc={this.triggerTabChangeOnClickOfTerminal}/>
            </TabPanel>
            <TabPanel value={this.state.value} index={3}>
              <TerminalKpi/>
            </TabPanel>
          </Box>
        </div>
      </WhitePortlet>
    );
  }
}

TerminalsContainer.propTypes = {
  value: PropTypes.number,
};

export default TerminalsContainer;
