/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Checkbox extends Component {
  render() {
    return (
      <div className={`form-group form-md-line-input ${this.props.error ? 'has-error' : ''}`}>
        <label className="col-md-3 control-label" htmlFor={this.props.fieldId}>
          {this.props.labelText}
        </label>
        <div className="col-md-9">
          <div className="md-checkbox">
            <input type="checkbox" id={this.props.fieldId} className="md-check" onChange={this.props.onChange}
              name={this.props.fieldId} checked={this.props.value}/>
            <label htmlFor={this.props.fieldId}>
              <span/>
              <span className="check"/>
              <span className="box"/>
            </label>
          </div>
        </div>
        <div className="col-md-9 col-md-offset-3">
          {this.props.error ? (<span className="error-block">{this.props.error}</span>) : null}
        </div>
      </div>
    );
  }
}

Checkbox.propTypes = {
  error: PropTypes.any,
  fieldId: PropTypes.string,
  labelText: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
};
Checkbox.defaultProps = {};

export default Checkbox;
