/**
 * @author Tomasz Czura (11/10/16)
 */
import * as types from '../actions/action-types';

const initialState = {
  rooms: [],
  getRoomsError: null,
};

const roomsReducer = function(state = initialState, action) {
  switch (action.type) {
    case types.GET_SITE_ROOMS_SUCCESS:
      return Object.assign({}, state, {rooms: action.rooms, getRoomsError: null});
    case types.GET_SITE_ROOMS_ERROR:
      return Object.assign({}, state, {rooms: [], getRoomsError: action.error});
    case types.CURRENT_SITE_CHANGE:
      return Object.assign({}, state, {rooms: [], getRoomsError: null, currentSiteId: action.currentSiteId});
  }
  return state;
};

export default roomsReducer;
