import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, List, ListItem, ListItemButton, ListItemText} from '@mui/material';
import EditAction from '../utils/actions/EditAction';
import DestroyAction from '../utils/actions/DestroyAction';

class ChangeLog extends Component {
  constructor(props) {
    super(props);
  }

  createStylingAsNeeded() {
    const background = this.props.selectedVersion === this.props.changeLog.versionNumber ? '#f2f6f9' : '';
    return {
      backgroundColor: background,
    };
  }

  render() {
    return (
      <ListItemButton
        style={this.createStylingAsNeeded()}
        onClick={this.props.action}
      >
        <List dense={true} className="col-md-8">
          <ListItem>
            <ListItemText
              primaryTypographyProps={{fontSize: '14px', fontWeight: 'bold', color: '#485a6a'}}
              secondaryTypographyProps={{fontSize: '12px', color: '#485a6a'}}
              primary={this.props.changeLog.versionNumber + ' - ' + this.props.changeLog.releaseDate + ' ' + (this.props.changeLog.hasBeenRead === true ? '' : '*')}
              secondary={this.props.changeLog.summary}
            />
          </ListItem>
        </List>
        <Button
          variant="contained"
          size="medium"
          href={this.props.changeLog.cannyLink}
          target="_blank"
          rel="noopener noreferrer"
        >See changes on Canny</Button>

        {this.props.editButtonCallback ?
          <div className="btn-group" style={{marginLeft: '10px'}} id={this.props.changeLog.versionNumber}>
            <button type="button"
              className="btn gray btn-sm dropdown-toggle"
              data-toggle="dropdown"
              data-hover="dropdown"
              data-close-others="true"
              aria-expanded="false"
            >
              <i className="fa fa-cog"/>
              <i className="fa fa-angle-down"/>
            </button>
            <ul className="dropdown-menu in-table dropup" role="menu">
              <EditAction onEdit={() => this.props.editButtonCallback(this.props.changeLog)}/>
              <DestroyAction destroyElement={() => this.props.deleteButtonCallback(this.props.changeLog)}/>
            </ul>
          </div> :
          null
        }
      </ListItemButton>
    );
  }
}

ChangeLog.propTypes = {
  changeLog: PropTypes.object,
  action: PropTypes.func,
  selectedVersion: PropTypes.string,
  editButtonCallback: PropTypes.func,
  deleteButtonCallback: PropTypes.func,
};

ChangeLog.defaultProps = {};

export default ChangeLog;
