/**
 * @author Tomasz Czura (11/10/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

const MIN_YEAR = 2016;

class YearPicker extends Component {
  constructor(props) {
    super(props);

    this.onYearChange = this.onYearChange.bind(this);
  }

  onYearChange(e) {
    this.props.onChange(parseInt(e.target.value));
  }

  componentDidMount() {
    $(`#year_selector`).find(`option[value="${this.props.selected}"]`).attr('selected', 'selected');
  }

  render() {
    const availableYears = [];
    const currentYear = new Date().getFullYear();
    for (let year = MIN_YEAR; year <= currentYear; year++) {
      availableYears.push(year);
    }

    return (
      <div className="form-group form-md-line-input col-md-2 billing_data">
        <select className="form-control" id="year_selector" name="year" onChange={this.onYearChange}>
          {availableYears.map((value) => {
            return (<option key={value} value={value}>{value}</option>);
          })}
        </select>
        <div className="form-control-focus"> </div>
      </div>
    );
  }
}

YearPicker.propTypes = {
  selected: PropTypes.number,
  onChange: PropTypes.func,
};
YearPicker.defaultProps = {};

export default YearPicker;
