/**
 * @author TomaszCzura ({}
 */

export function toLocal(key) {
  let siteId = -1;
  let siteName = '';
  if (key.reservation && key.reservation.site) {
    siteId = key.reservation.site.id;
    siteName = key.reservation.site.name;
  }

  const booking = key.reservation;
  return {
    id: key.id,
    siteId: siteId,
    checkinDate: booking ? booking.checkinDate : '',
    checkoutDate: booking ? booking.checkoutDate : '',
    payload: key.payload ? btoa(key.payload) : '',
    siteName: siteName,
    room: booking ? booking.room : '',
    firstName: booking ? booking.firstName : '',
    lastName: booking ? booking.lastName : '',
    phoneNumber: booking ? booking.phoneNumber : '',
    reservationId: booking ? booking.id : '',
    mobileUserEmail: key.mobileUserEmail,
    mobileUserId: key.mobileUser ? key.mobileUser.id : '',
    generatedAt: key.generatedAt,
    errorMessage: key.errorMessage,
    errorCode: key.errorCode,
    userType: key.userType,
  };
}

export function isGenerated(key) {
  return !!key.payload;
}

export function createErrorMessage(key) {
  let messageToShow = '';
  if (key?.errorCode && key?.errorMessage) {
    messageToShow = key.errorCode + ': ' + key.errorMessage;
  }
  return messageToShow;
}
