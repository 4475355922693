/**
 * @author TomaszCzura ({}
 */
// Login
export const USER_LOGOUT = 'USER_LOGOUT';

// Keys
export const NEW_KEY_SUCCESS = 'NEW_KEY_SUCCESS';
export const NEW_KEY_ERROR = 'NEW_KEY_ERROR';

export const GET_KEYS_SUCCESS = 'GET_KEYS_SUCCESS';
export const GET_KEYS_ERROR = 'GET_KEYS_ERROR';
export const GET_KEY_SUCCESS = 'GET_KEY_SUCCESS';

export const UPDATE_KEY_SUCCESS = 'UPDATE_KEY_SUCCESS';
export const UPDATE_KEY_ERROR = 'UPDATE_KEY_ERROR';

export const DELETE_KEY_SUCCESS = 'DELETE_KEY_SUCCESS';
export const DELETE_KEY_ERROR = 'DELETE_KEY_ERROR';

// Checkins
export const NEW_CHECKIN_SUCCESS = 'NEW_CHECKIN_SUCCESS';
export const NEW_CHECKIN_ERROR = 'NEW_CHECKIN_ERROR';

export const GET_CHECKINS_SUCCESS = 'GET_CHECKINS_SUCCESS';
export const GET_CHECKINS_ERROR = 'GET_CHECKINS_ERROR';
export const GET_CHECKIN_SUCCESS = 'GET_CHECKIN_SUCCESS';
export const GET_CHECKIN_ERROR = 'GET_CHECKIN_ERROR';
export const GET_CHECKIN_KEYS_SUCCESS = 'GET_CHECKIN_KEYS_SUCCESS';

export const UPDATE_CHECKIN_SUCCESS = 'UPDATE_CHECKIN_SUCCESS';
export const UPDATE_CHECKIN_ERROR = 'UPDATE_CHECKIN_ERROR';

export const DELETE_CHECKIN_SUCCESS = 'DELETE_CHECKIN_SUCCESS';
export const DELETE_CHECKIN_ERROR = 'DELETE_CHECKIN_ERROR';

export const CLEAR_CHECKIN_ERRORS = 'CLEAR_CHECKIN_ERRORS';

export const USER_MATCHED_SUCCESS = 'USER_MATCHED_SUCCESS';
export const USER_MATCHED_ERROR = 'USER_MATCHED_ERROR';

export const GET_BOOKING_LOGS_SUCCESS = 'GET_BOOKING_LOGS_SUCCESS';
export const GET_BOOKING_LOGS_ERROR = 'GET_BOOKING_LOGS_ERROR';

// Users
export const NEW_USER_SUCCESS = 'NEW_USER_SUCCESS';
export const NEW_USER_ERROR = 'NEW_USER_ERROR';

export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_ERROR = 'GET_PROFILE_ERROR';
export const CLEAR_USER_ERRORS = 'CLEAR_USER_ERRORS';

// Sites
export const NEW_SITE_SUCCESS = 'NEW_SITE_SUCCESS';
export const NEW_SITE_ERROR = 'NEW_SITE_ERROR';

export const NEW_SITE_SPECIALAREA_SUCCESS = 'NEW_SITE_SPECIALAREA_SUCCESS';
export const NEW_SITE_SPECIALAREA_ERROR = 'NEW_SITE_SPECIALAREA_ERROR';

export const GET_SITES_SUCCESS = 'GET_SITES_SUCCESS';
export const GET_SITES_ERROR = 'GET_SITES_ERROR';
export const GET_SITE_SUCCESS = 'GET_SITE_SUCCESS';
export const GET_SITE_ERROR = 'GET_SITE_ERROR';

export const UPDATE_SITE_SUCCESS = 'UPDATE_SITE_SUCCESS';
export const UPDATE_SITE_ERROR = 'UPDATE_SITE_ERROR';

export const UPDATE_SITE_SPECIALAREA_SUCCESS =
  'UPDATE_SITE_SPECIALAREA_SUCCESS';
export const UPDATE_SITE_SPECIALAREA_ERROR = 'UPDATE_SITE_SPECIALAREA_ERROR';

export const DELETE_SITE_SUCCESS = 'DELETE_SITE_SUCCESS';
export const DELETE_SITE_ERROR = 'DELETE_SITE_ERROR';

export const DELETE_SITE_SPECIALAREA_SUCCESS =
  'DELETE_SITE_SPECIALAREA_SUCCESS';
export const DELETE_SITE_SPECIALAREA_ERROR = 'DELETE_SITE_SPECIALAREA_ERROR';

export const CURRENT_SITE_CHANGE = 'CURRENT_SITE_CHANGE';
export const SITE_CONNECTED = 'SITE_CONNECTED';
export const HMS_CONNECTED = 'HMS_CONNECTED';
export const SITE_DISCONNECTED = 'SITE_DISCONNECTED';
export const HMS_DISCONNECTED = 'HMS_DISCONNECTED';

export const CLEAR_SITE_ERRORS = 'CLEAR_SITE_ERRORS';

export const GET_SITE_ROOMS_SUCCESS = 'GET_SITE_ROOMS_SUCCESS';
export const GET_SITE_ROOMS_ERROR = 'GET_SITE_ROOMS_ERROR';

export const GET_SITE_SPECIALAREAS_SUCCESS = 'GET_SITE_SPECIALAREAS_SUCCESS';
export const GET_SITE_SPECIALAREAS_ERROR = 'GET_SITE_SPECIALAREAS_ERROR';

// Dashboard
export const GET_DASHBOARD_STATS = 'GET_DASHBOARD_STATS';
export const NEW_MOBILE_USER = 'NEW_MOBILE_USER';
export const NEW_ACTIVE_MOBILE_USER = 'NEW_ACTIVE_MOBILE_USER';
export const KEY_ISSUED = 'KEY_ISSUED';
export const DOOR_OPENING = 'DOOR_OPENING';
export const SITE_AGENT_CONNECTION_CHANGED = 'SITE_AGENT_CONNECTION_CHANGED';
export const ADD_CHANGE_LOG = 'ADD_CHANGE_LOG';
export const EDIT_CHANGE_LOG = 'EDIT_CHANGE_LOG';
export const DELETE_CHANGE_LOG = 'DELETE_CHANGE_LOG';

// Mobile users
export const GET_MOBILE_USERS_SUCCESS = 'GET_MOBILE_USERS_SUCCESS';
export const GET_RESERVATION_SUCCESS = 'GET_RESERVATION_SUCCESS';
export const GET_MOBILE_USERS_ERROR = 'GET_MOBILE_USERS_ERROR';

export const GET_MOBILE_USER_LOGS_SUCESS = 'GET_MOBILE_USER_LOGS_SUCESS';
export const GET_MOBILE_USER_LOGS_ERROR = 'GET_MOBILE_USER_LOGS_ERROR';

export const DELETE_MOBILE_USER_SUCCESS = 'DELETE_MOBILE_USER_SUCCESS';
export const DELETE_MOBILE_USER_ERROR = 'DELETE_MOBILE_USER_ERROR';

// Transaction logs
export const GET_TRANSACTION_LOGS_SUCCESS = 'GET_TRANSACTION_LOGS_SUCCESS';
export const GET_TRANSACTION_LOGS_ERROR = 'GET_TRANSACTION_LOGS_ERROR';

// Developers
export const GET_DEVELOPERS_SUCCESS = 'GET_DEVELOPERS_SUCCESS';
export const GET_DEVELOPERS_ERROR = 'GET_DEVELOPERS_ERROR';

export const GET_DEVELOPER_SUCCESS = 'GET_DEVELOPER_SUCCESS';
export const GET_DEVELOPER_ERROR = 'GET_DEVELOPER_ERROR';

export const NEW_DEVELOPER_SUCCESS = 'NEW_DEVELOPER_SUCCESS';
export const NEW_DEVELOPER_ERROR = 'NEW_DEVELOPER_ERROR';

export const UPDATE_DEVELOPER_SUCCESS = 'UPDATE_DEVELOPER_SUCCESS';
export const UPDATE_DEVELOPER_ERROR = 'UPDATE_DEVELOPER_ERROR';

export const DELETE_DEVELOPER_SUCCESS = 'DELETE_DEVELOPER_SUCCESS';
export const DELETE_DEVELOPER_ERROR = 'DELETE_DEVELOPER_ERROR';

// Tokens
export const PMS_TOKEN_REVOKED_SUCCESS = 'PMS_TOKEN_REVOKED_SUCCESS';
export const PMS_TOKEN_REVOKED_ERROR = 'PMS_TOKEN_REVOKED_ERROR';

export const PMS_TOKEN_REGENERATED_SUCCESS = 'PMS_TOKEN_REGENERATED_SUCCESS';
export const PMS_TOKEN_REGENERATED_ERROR = 'PMS_TOKEN_REGENERATED_ERROR';

export const GUEST_TOKEN_REVOKED_SUCCESS = 'GUEST_TOKEN_REVOKED_SUCCESS';
export const GUEST_TOKEN_REVOKED_ERROR = 'GUEST_TOKEN_REVOKED_ERROR';

export const GUEST_TOKEN_REGENERATED_SUCCESS =
  'GUEST_TOKEN_REGENERATED_SUCCESS';
export const GUEST_TOKEN_REGENERATED_ERROR = 'GUEST_TOKEN_REGENERATED_ERROR';

// Files
export const GET_FILES_SUCCESS = 'GET_FILES_SUCCESS';
export const GET_FILES_ERROR = 'GET_FILES_ERROR';

export const NEW_FILE_SUCCESS = 'NEW_FILE_SUCCESS';
export const NEW_FILE_ERROR = 'NEW_FILE_ERROR';

export const UPDATE_FILE_SUCCESS = 'UPDATE_FILE_SUCCESS';
export const UPDATE_FILE_ERROR = 'UPDATE_FILE_ERROR';

export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_ERROR = 'DELETE_FILE_ERROR';

// Billing
export const GET_BILLING_SUCCESS = 'GET_BILLING_SUCCESS';
export const GET_BILLING_ERROR = 'GET_BILLING_ERROR';

// SiteAgent logs
export const GET_SITE_AGENT_LOGS_SUCCESS = 'GET_AGENT_LOGS_SUCCESS';
export const GET_SITE_AGENT_LOGS_ERROR = 'GET_AGENT_LOGS_ERROR';

export const GET_SITE_AGENT_LOG_SUCCESS = 'GET_SITE_AGENT_LOG_SUCCESS';
export const GET_SITE_AGENT_LOG_ERROR = 'GET_SITE_AGENT_LOG_ERROR';
