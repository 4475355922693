/**
 * @author Tomasz Czura (10/13/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as viewUtils from './../../utils/view-utils';

class SortableDate extends Component {
  render() {
    return (
      <span>
        <span className="date_sort_helper">{viewUtils.toYYYYMMDDHHSS(this.props.dateMillis)}</span>
        {viewUtils.toYYYYMMDDHM(this.props.dateMillis)}
      </span>
    );
  }
}

SortableDate.propTypes = {
  dateMillis: PropTypes.number,
};
SortableDate.defaultProps = {};

export default SortableDate;
