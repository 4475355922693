import React, {Component} from 'react';
import {Avatar, Divider, List, ListItem, ListItemText} from '@mui/material';
import PropTypes from 'prop-types';
import * as model from '../../model/version-change';
import EditAction from '../utils/actions/EditAction';
import DestroyAction from '../utils/actions/DestroyAction';

class VersionChange extends Component {
  render() {
    return (
      <List dense={true}>
        <Divider />
        <ListItem>
          <Avatar>{model.getEntryTypeInitial(this.props.versionChange.changeLogEntryType)}</Avatar>
          <span>&nbsp;&nbsp;</span>
          <ListItemText
            primaryTypographyProps={{fontSize: '14px', fontWeight: 'bold', color: '#485a6a'}}
            secondaryTypographyProps={{fontSize: '12px', color: '#485a6a'}}
            primary={this.props.versionChange.header}
            secondary={this.props.versionChange.summary}
          />
          {this.props.editButtonCallback ?
            <div className="btn-group" style={{marginLeft: '10px', marginRight: '20px'}} id={this.props.versionChange.id}>
              <button type="button"
                className="btn gray btn-sm dropdown-toggle"
                data-toggle="dropdown"
                data-hover="dropdown"
                data-close-others="true"
                aria-expanded="false"
              >
                <i className="fa fa-cog"/>
                <i className="fa fa-angle-down"/>
              </button>
              <ul className="dropdown-menu in-table dropup" role="menu">
                <EditAction onEdit={() => this.props.editButtonCallback('versionChangeDialogId', this.props.versionChange)}/>
                <DestroyAction destroyElement={() => this.props.deleteButtonCallback(this.props.versionChange)}/>
              </ul>
            </div> :
            null
          }
        </ListItem>
        <Divider />
      </List>
    );
  }
}

VersionChange.propTypes = {
  versionChange: PropTypes.object,
  editButtonCallback: PropTypes.func,
  deleteButtonCallback: PropTypes.func,
};

VersionChange.defaultProps = {};

export default VersionChange;
