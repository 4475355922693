/**
 * @author Tomasz Czura (8/11/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import WhitePortlet from '../utils/WhitePortlet';
import i18next from 'i18next';
import UserDetails from './../users/UserDetails';
import * as blockUtils from './../../utils/BlockUtils';
import * as usersApi from './../../api/users-api';
import EditOwnUserForm from '../users/EditOwnUserForm';
import * as viewUtils from './../../utils/view-utils';


class ProfileDetailsContainer extends Component {
  constructor(props) {
    super(props);
    this.userFormDialogId = 'userFormDialog';
  }

  componentDidMount() {
    blockUtils.blockUI();
    usersApi.getUserProfile();
  }

  UNSAFE_componentWillUpdate() {
    blockUtils.unblockUI();
  }

  componentDidUpdate() {
    if (this.props.error && this.modalDialog) {
      this.modalDialog.handleError(this.props.error);
      blockUtils.unblockUI();
    } else {
      $(`#${this.userFormDialogId}`).modal('hide');
    }
  }

  showUserForm() {
    const props = {
      dialogId: this.userFormDialogId,
      error: this.props.error,
      presentedUser: this.props.userProfile,
    };
    this.modalDialog = viewUtils.showModal(EditOwnUserForm, this.userFormDialogId, props);
  }

  render() {
    return (
      <WhitePortlet caption={i18next.t('profile.my_profile')} icon="icon-user">
        <UserDetails presentedUser={this.props.userProfile}/>
        <a className="btn btn-info" data-toggle="modal"
          onClick={() => this.showUserForm()}>{i18next.t('links.edit')}</a>
      </WhitePortlet>
    );
  }
}

ProfileDetailsContainer.propTypes = {
  userProfile: PropTypes.object,
  error: PropTypes.any,
};
ProfileDetailsContainer.defaultProps = {};

const mapStateToProps = function(store) {
  return {
    userProfile: store.usersState.userProfile,
  };
};

export default connect(mapStateToProps)(ProfileDetailsContainer);
