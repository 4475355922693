/**
 * @author Tomasz Czura (9/6/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

class RowsSummary extends Component {
  render() {
    return (
      <div className="col-md-5 col-sm-5">
        <div id={`${this.props.tableId}_info`} role="status" aria-live="polite">
          {i18next.t('redux_table.showing_of', {min: this.props.min, max: this.props.max, total: this.props.total})}
        </div>
      </div>
    );
  }
}

RowsSummary.propTypes = {
  tableId: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  total: PropTypes.number,
};
RowsSummary.defaultProps = {};

export default RowsSummary;
