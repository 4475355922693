/**
 * @author TomaszCzura ({}
 */

import * as authApi from './../api/auth-api';
import cookie from 'react-cookie';
import store from './../store';
import * as actions from './../actions/auth-actions';
import SiteSocket from './../socket/SiteSocket';
import {browserHistory} from 'react-router';

const tokenCookieKey = 'token';
const emailCookieKey = 'userEmail';
const roleCookieKey = 'userRole';
const userIdCookieKey = 'userId';
const rememberCookieKey = 'remember';
const sessionEndCookieKey = 'sessionEnd';
const userPermissionsCookieKey = 'userPermissions';
const userCookieKey = 'user';

const expandedCheckinRowKey = 'expandedCheckinRowId';

export function loggedIn() {
  return !!getToken();
}

export function login(username, password, remember, onResult) {
  if (loggedIn()) {
    if (onResult) onResult({authenticated: true});
  } else {
    authApi.login(username, password, (response) => {
      if (response.authenticated) {
        saveUser(response.token, response.email, response.userRole, response.userId, remember, response.sessionEnd);
      } else {
        clearCookies();
        store.dispatch(actions.userLogoutAction());
      }
      if (onResult) onResult(response);
    });
  }
}

export function logout(onLogout) {
  clearCookies();
  store.dispatch(actions.userLogoutAction());
  const socket = SiteSocket.getInstance();
  socket.disconnect();
  if (onLogout) {
    onLogout();
  } else {
    browserHistory.push('/login');
  }
}

export function setCurrentUser(user) {
  const permissions = user.authorities.map((permission) => permission.authority);
  cookie.save(userPermissionsCookieKey, permissions, {path: '/', sameSite: 'lax'});
}

export function saveUser(token, userMail, userRole, userId, remember, sessionEnd) {
  const expireDate = new Date();
  expireDate.setDate(expireDate.getDate() + 365);

  const options = remember ? {path: '/', expires: expireDate} : {path: '/'};

  cookie.save(tokenCookieKey, token, options);
  cookie.save(emailCookieKey, userMail, options);
  cookie.save(roleCookieKey, userRole, options);
  cookie.save(rememberCookieKey, remember, options);
  cookie.save(userIdCookieKey, userId, options);
  if (sessionEnd) {
    cookie.save(sessionEndCookieKey, sessionEnd, options);
  }
}

export function getCurrentUserPermissions() {
  return cookie.load(userPermissionsCookieKey);
}

export function getCurrentUser() {
  return cookie.load(userCookieKey);
}

export function getToken() {
  return cookie.load(tokenCookieKey);
}

export function getUserRole() {
  return cookie.load(roleCookieKey);
}

export function getCurrentUserId() {
  return cookie.load(userIdCookieKey);
}

export function clearCookies() {
  cookie.remove(tokenCookieKey);
  cookie.remove(emailCookieKey);
  cookie.remove(roleCookieKey);
  cookie.remove(userIdCookieKey);
  cookie.remove(expandedCheckinRowKey);
  cookie.remove(sessionEndCookieKey);
  cookie.remove(userPermissionsCookieKey);
  cookie.remove(userCookieKey);
}

export function saveExpandedCheckinRowId(rowId) {
  cookie.save(expandedCheckinRowKey, rowId, {path: '/'});
}

export function getExpandedCheckinRowId() {
  return cookie.load(expandedCheckinRowKey);
}

export function getSessionEnd() {
  const today = new Date();
  return cookie.load(sessionEndCookieKey) || new Date().setDate(today.getDate() + 365);
}

export function isRemember() {
  return cookie.load(rememberCookieKey);
}

export function renewToken(response) {
  if (response.authenticated) {
    saveUser(response.token, response.email, response.userRole, response.userId, isRemember(), response.sessionEnd);
  } else {
    logout();
  }
}
