/**
 * @author Tomasz Czura (10/20/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {isValidEmail} from '../../utils/validate-utils';

class TagsInput extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const $tagsinput = $(`#select_${this.props.fieldId}`);
    $tagsinput.tagsinput({
      tagClass: function(item) {
        if (isValidEmail(item)) {
          return 'label label-primary';
        } else {
          return 'label label-danger label-important';
        }
        // switch (item) {
        //   case isValidEmail(item) : return 'label label-danger label-important';
        //   // case !isValidEmail(item): return 'label label-primary';
        // }
      },
    });
    $tagsinput.on('itemAdded', (event) => this.props.onChange($tagsinput.tagsinput('items')));
    $tagsinput.on('itemRemoved', (event) => this.props.onChange($tagsinput.tagsinput('items')));
  }


  render() {
    return (
      <div className={`form-group form-md-line-input ${this.props.error ? 'has-error' : ''}`}>
        <label className="col-md-3 control-label" htmlFor={this.props.fieldId}>
          {this.props.labelText}
          {this.props.isRequired ? (<span className="required">*</span>) : null}
        </label>
        <div className="col-md-9">
          <select multiple data-role="tagsinput" id={`select_${this.props.fieldId}`}
            onChange={this.props.onChange}
            placeholder={this.props.placeholder}
            disabled={this.props.isReadonly}>

            {this.props.values.map((value) => {
              return (
                <option key={value} value={value}><p>{value}</p></option>
              );
            })
            }
          </select>
        </div>
        <div className="col-md-9 col-md-offset-3">
          {this.props.error ? (<span className="error-block">{this.props.error}</span>) : null}
        </div>
      </div>
    );
  }
}

TagsInput.propTypes = {
  fieldId: PropTypes.string,
  placeholder: PropTypes.string,
  labelText: PropTypes.string,
  helpText: PropTypes.string,
  value: PropTypes.any,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  isReadonly: PropTypes.bool,
  isSecret: PropTypes.bool,
  error: PropTypes.string,
  validate: PropTypes.func,
  values: PropTypes.array,
};
TagsInput.defaultProps = {
  values: [],
};

export default TagsInput;
