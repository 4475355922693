/**
 * @author Tomasz Czura (11/10/16)
 */
import React from 'react';
import {connect} from 'react-redux';
import WhitePortlet from '../utils/WhitePortlet';
import RoleBasedComponent from '../utils/RoleBasedComponent';
import * as rights from '../../model/rights';
import i18next from 'i18next';
import ErrorBox from './../utils/ErrorBox';
import {getErrorDescription} from '../../utils/view-utils';
import BillingList from './BillingList';
import * as billingApi from './../../api/billing-api';
import {isSiteSelected, selectedSiteId} from '../../store';
import YearPicker from './YearPicker';
import MonthPicker from './MonthPicker';
import Form from './../utils/Form';
import * as blockUtils from './../../utils/BlockUtils';
import moment from 'moment';

class BillingContainer extends RoleBasedComponent {
  constructor(props) {
    super(props);

    this.downloadBilling = this.downloadBilling.bind(this);
    this.onYearChange = this.onYearChange.bind(this);
    this.onMonthChange = this.onMonthChange.bind(this);
    this.onBillingClick = this.onBillingClick.bind(this);

    this.year = new Date().getFullYear();
    this.month = new Date().getMonth();

    this.selectedYear = '';
    this.selectedMonth = '';
  }

  componentDidMount() {
    this.checkRights();
  }

  componentWillUpdate() {
    blockUtils.unblockUI();
  }

  downloadBilling() {
    billingApi.getBilling(this.props.currentSiteId, this.year, this.month + 1);
  }

  getRights() {
    return rights.SITE_READ_PRIVILEGE;
  }

  onYearChange(year) {
    this.year = year;
  }

  onMonthChange(month) {
    this.month = month;
  }

  onBillingClick() {
    blockUtils.blockUI();
    this.selectedMonth = this.month;
    this.selectedYear = this.year;
    this.downloadBilling();
  }

  render() {
    return (
      <WhitePortlet caption={i18next.t('billing.name')} icon="icon-wallet">
        <ErrorBox errorMessage={getErrorDescription(this.props.getBillingError)}/>
        {!isSiteSelected() ?
                    <ErrorBox errorMessage={i18next.t('billing.errors.select_site')}/> :
                    <div>

                      <Form>
                        <MonthPicker selected={this.month} onChange={this.onMonthChange}/>
                        <YearPicker selected={this.year} onChange={this.onYearChange}/>
                        <div className="form-group form-md-line-input col-md-1 billing_data">
                          <button type="button"
                            className="btn blue"
                            onClick={this.onBillingClick}>{i18next.t('billing.actions.show_billing')}</button>
                        </div>
                      </Form>

                      <BillingList billing={this.props.billing}
                        csvFilename={`${moment.months()[this.selectedMonth]}_${this.selectedYear}`}/>

                    </div>
        }
        <br/>


      </WhitePortlet>
    );
  }
}

BillingContainer.propTypes = {};
BillingContainer.defaultProps = {};

const mapStateToProps = function(store) {
  const currentSiteId = store.sitesState.currentSiteId === -1 ? selectedSiteId() : store.sitesState.currentSiteId;

  return {
    billing: store.billingState.billing,
    getBillingError: store.billingState.getBillingError,
    currentSiteId: currentSiteId,
  };
};

export default connect(mapStateToProps)(BillingContainer);
