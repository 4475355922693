/**
 * @author Marcus Wolschon (2019-12-02 mnarcus.wolschon@sphinx-electronics.de)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DataTable from './../utils/DataTable';
import i18next from 'i18next';
import * as sitesApi from './../../api/sites-api';
import SpecialareasForm from './SpecialareasForm';
import * as blockUtils from './../../utils/BlockUtils';
import * as rights from './../../model/rights';
import * as viewUtils from './../../utils/view-utils';
import EditAction from './../utils/actions/EditAction';
import DestroyAction from './../utils/actions/DestroyAction';
import TableActions from './../utils/TableActions';
import CreateNewButton from '../CreateNewButton';
import {onRowClick} from '../redux_data_table/ReduxDataTable';

class SpecialareasList extends Component {
  constructor(props) {
    super(props);
    this.specialareaFormDialogId = 'specialareaFormDialog';

    this.destroySpecialarea = this.destroySpecialarea.bind(this);
    this.showSpecialareaForm = this.showSpecialareaForm.bind(this);
  }

  destroySpecialarea(siteId, specialAreaId) {
    blockUtils.blockUI('#specialareas_table');
    sitesApi.destroySpecialarea(siteId, specialAreaId);
  }

  componentDidUpdate() {
    this.handleError();
  }

  handleError() {
    if (this.props.createSpecialAreaError) {
      if (this.modalDialog) {
        this.modalDialog.handleError(this.props.createSpecialAreaError);
      }
      blockUtils.unblockUI();
    } else {
      $(`#${this.specialareaFormDialogId}`).modal('hide');
    }
  }

  showSpecialareaForm(siteId, specialarea) {
    this.modalProps = {
      dialogId: this.specialareaFormDialogId,
      error: this.props.createSpecialAreaError,
      presentedSiteId: siteId,
      presentedSpecialArea: specialarea,
    };
    this.modalDialog = viewUtils.showModal(SpecialareasForm, this.specialareaFormDialogId, this.modalProps);
  }

  render() {
    return (
      <div className="table-container">
        {rights.currentUserHasRight(rights.SPECIAL_AREA_ADD_PRIVILEGE) ?
          (<CreateNewButton callBack={() => this.showSpecialareaForm(this.props.siteId)} />) :
          null
        }
        <DataTable tableId="specialareas_table" defaultSort={[0, 'asc']}>
          <thead>
            <tr role="row" className="heading">
              <th>{i18next.t('specialareas.attrs.areaNumber')}</th>
              <th>{i18next.t('specialareas.attrs.areaName')}</th>
              <th>{i18next.t('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.specialareas.map((specialarea) => {
              return (
                <tr onClick={() => onRowClick(specialarea.id)} key={specialarea.id}>
                  <td>{specialarea.areaNumber}</td>
                  <td>{specialarea.areaName}</td>
                  <td>
                    <TableActions identifier={specialarea.id}>
                      <EditAction
                        onEdit={() => this.showSpecialareaForm(this.props.siteId, specialarea)}
                        condition={rights.currentUserHasRight(rights.SPECIAL_AREA_EDIT_PRIVILEGE)}
                      />
                      <DestroyAction
                        destroyElement={() => this.destroySpecialarea(this.props.siteId, specialarea.id)}
                        condition={rights.currentUserHasRight(rights.SPECIAL_AREA_DELETE_PRIVILEGE)}
                      />
                    </TableActions>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </DataTable>
      </div>
    );
  }
}

SpecialareasList.propTypes = {
  specialareas: PropTypes.array,
  createSpecialAreaError: PropTypes.object,
  getSpecialareasError: PropTypes.object,
  destroySpecialareaError: PropTypes.object,
  updateSpecialAreaError: PropTypes.object,
  siteId: PropTypes.number,
};
SpecialareasList.defaultProps = {};

export default SpecialareasList;
