/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

class BooleanLabel extends Component {
  render() {
    const trueText = this.props.trueText || i18next.t('yes');
    const falseText = this.props.falseText || i18next.t('no');

    return (
      <span className={`label label-sm ${this.props.value ? 'label-success' : 'label-danger'}`}>
        {this.props.value ? trueText : falseText}
      </span>
    );
  }
}

BooleanLabel.propTypes = {
  value: PropTypes.bool,
  trueText: PropTypes.any,
  falseText: PropTypes.any,
};
BooleanLabel.defaultProps = {};

export default BooleanLabel;
