/**
 * @author Tomasz Czura (11/23/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import WhitePortlet from './../utils/WhitePortlet';
import i18next from 'i18next';
import BatteryAlert from './BatteryAlert';

class BatteryAlerts extends Component {
  render() {
    return (
      <div className="col-md-6 col-sm-12 col-xs-12">
        <WhitePortlet caption={i18next.t('dashboard.battery_alerts')} icon="icon-bell">
          <div className="scrolled_div tile">
            <ul className="feeds">
              {this.props.alerts.map((alert, index) => {
                return (
                  <BatteryAlert alert={alert} key={index}/>
                );
              })}
            </ul>
          </div>
        </WhitePortlet>
      </div>
    );
  }
}

BatteryAlerts.propTypes = {
  alerts: PropTypes.array,
};
BatteryAlerts.defaultProps = {};

export default BatteryAlerts;
