/**
 * @author TomaszCzura ({}
 */
export const BASE_SOCKET_URL = SocketAddress;

class AppSocket {
  constructor() {
    this.reconnectSocket = this.reconnectSocket.bind(this);
    this.onSocketOpen = this.onSocketOpen.bind(this);
    this.onSocketClosed = this.onSocketClosed.bind(this);
    this.onSocketError = this.onSocketError.bind(this);
    this.onSocketMessage = this.onSocketMessage.bind(this);
    this.connect = this.connect.bind(this);
    this.disconnect = this.disconnect.bind(this);
    this.canConnect = this.canConnect.bind(this);
    this.sendKeepAlive = this.sendKeepAlive.bind(this);
  }

  getSocketUrl() {
    throw new TypeError('You have to override getSocketUrl() method for AppSocket child');
  }

  handleMessage(message) {
    throw new TypeError('You have to override handleMessage(message) method for AppSocket child');
  }

  connect() {
    if (this.canConnect()) {
      this.socket = new WebSocket(this.getSocketUrl());

      this.socket.onopen = this.onSocketOpen;
      this.socket.onmessage = this.onSocketMessage;
      this.socket.onerror = this.onSocketError;
      this.socket.onclose = this.onSocketClosed;
    }
  }

  sendKeepAlive() {
    this.socket.send('{}');
  }

  disconnect() {
    if (this.socket) {
      this.socket.close();
    }
  }

  canConnect() {
    if (this.socket) {
      return this.socket.readyState !== WebSocket.OPEN && this.socket.readyState !== WebSocket.CONNECTING;
    } else {
      return true;
    }
  }

  onSocketOpen() {
    this.keepAlive = setInterval(this.sendKeepAlive, 30*1000);
  }

  onSocketMessage(msg) {
    const message = JSON.parse(msg.data);
    this.handleMessage(message);
  }

  onSocketError(error) {
  }

  onSocketClosed(closeEvent) {
    clearInterval(this.keepAlive);
    if (closeEvent.code !== 1000) {// normal disconnect
      this.reconnectSocket();
    }
  }

  reconnectSocket() {
    setTimeout(this.connect, 10*1000);
  }
}

export default AppSocket;
