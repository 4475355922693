import React, {Component} from 'react';
import PropTypes from 'prop-types';
import WhitePortlet from '../utils/WhitePortlet';
import ChangeLog from './ChangeLog';
import VersionChanges from './VersionChanges';
import * as api from '../../api/changelog-api';
import * as rights from '../../model/rights';
import * as viewUtils from '../../utils/view-utils';
import ChangeLogForm from './ChangeLogForm';
import VersionChangeForm from './VersionChangeForm';
import * as blockUtils from '../../utils/BlockUtils';
import store from '../../store';
import * as actions from '../../actions/dahsboard-actions';
import i18next from 'i18next';

class ChangeLogs extends Component {
  constructor(props) {
    super(props);

    this.changeLogDialogId = 'changeLogDialogId';
    this.versionChangeDialogId = 'versionChangeDialogId';

    this.showChangesForVersion = this.showChangesForVersion.bind(this);
    this.destroyChangeLog = this.destroyChangeLog.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.showForm = this.showForm.bind(this);
    this.destroyVersionChange = this.destroyVersionChange.bind(this);

    this.state = {
      versionChanges: this.props.versionChanges,
      selectedVersion: '',
    };
  }

  showChangesForVersion(changelog) {
    api.getChangesForVersion(changelog.versionNumber).then((versionChanges) => {
      this.setState({
        versionChanges: versionChanges,
        selectedVersion: changelog.versionNumber,
      });
    }).catch((err) => this.handleError(err));
  }

  submitForm(data) {
    if (data.id !== -1) {
      api.updateVersionEntry(this.state.selectedVersion, data)
          .then((response) => {
            const updateVersionChange = response.data;
            const newVersionChanges = this.state.versionChanges.map((versionChange) => versionChange.id === updateVersionChange.id ? updateVersionChange : versionChange);
            this.setState({
              versionChanges: newVersionChanges,
            });
            $(`#${this.versionChangeDialogId}`).modal('hide');
          }).catch((error) => {
            blockUtils.unblockUI();
            window.showAlert(i18next.t('error'), viewUtils.getErrorDescription(error));
          });
    } else {
      api.createVersionEntry(this.state.selectedVersion, data)
          .then((response) => {
            const newVersionChanges = [response.data, ...this.state.versionChanges];
            this.setState({
              versionChanges: newVersionChanges,
            });
            $(`#${this.versionChangeDialogId}`).modal('hide');
          }).catch((error) => {
            blockUtils.unblockUI();
            window.showAlert(i18next.t('error'), viewUtils.getErrorDescription(error));
          });
    }
    blockUtils.blockUI();
  }

  destroyVersionChange(versionChange) {
    blockUtils.blockUI();
    api.deleteVersionEntry(this.state.selectedVersion, versionChange)
        .then(() => {
          const updatedArray = this.state.versionChanges.filter(function(obj) {
            return obj.id !== versionChange.id;
          });
          this.setState({
            versionChanges: updatedArray,
          });
          blockUtils.unblockUI();
        })
        .catch((error) => {
          blockUtils.unblockUI();
          window.showAlert(i18next.t('error'), viewUtils.getErrorDescription(error));
        });
  }

  handleError(error) {
    this.setState({
      error: error,
    });
  }

  showForm(dialogId, objectToEdit) {
    const dialogIdAsString = dialogId + '';
    const modalProps = {
      dialogId: dialogIdAsString,
      error: this.state.creationError,
      objectToEdit: objectToEdit,
      submitForm: this.submitForm,
    };
    this.modalDialog = viewUtils.showModal(
      dialogIdAsString === this.changeLogDialogId ? ChangeLogForm : VersionChangeForm,
      dialogIdAsString,
      modalProps,
    );
  }

  destroyChangeLog(changelog) {
    blockUtils.blockUI();
    api.deleteVersion(changelog.versionNumber)
        .then(() => {
          store.dispatch(actions.deleteChangeLog(changelog.versionNumber));
          blockUtils.unblockUI();
        })
        .catch((error) => {
          blockUtils.unblockUI();
          window.showAlert(i18next.t('error'), viewUtils.getErrorDescription(error));
        });
  }

  render() {
    const showButtons = rights.currentUserHasRight(rights.CHANGE_LOG_ADD_PRIVILEGE);
    return (
      <div>
        <div className="col-md-6 col-lg-6">
          <WhitePortlet
            caption="Change Log"
            icon="icon-note"
            addButtonCallback={showButtons === true ? () => this.showForm(this.changeLogDialogId) : null}
          >
            <div className="scrolled_div tile">
              {this.props.changeLogs.map((changeLog) => {
                return (
                  <ChangeLog
                    action={() => this.showChangesForVersion(changeLog)}
                    selectedVersion={this.state.selectedVersion}
                    changeLog={changeLog}
                    key={changeLog.id}
                    editButtonCallback={showButtons === true ? () => this.showForm(this.changeLogDialogId, changeLog) : null}
                    deleteButtonCallback={showButtons === true ? () => this.destroyChangeLog(changeLog) : null}
                  />
                );
              })}
            </div>
          </WhitePortlet>
        </div>
        <div className="col-md-6">
          <WhitePortlet
            caption="Summary of changes"
            icon="icon-wrench"
            addButtonCallback={(showButtons === true && this.state.versionChanges) ? () => this.showForm(this.versionChangeDialogId) : null}
          >
            <div className="scrolled_div tile">
              <VersionChanges
                versionChanges={this.state.versionChanges || []}
                editButtonCallback={showButtons === true ? this.showForm : null}
                deleteButtonCallback={showButtons === true ? this.destroyVersionChange : null}
              />
            </div>
          </WhitePortlet>
        </div>
      </div>
    );
  }
}

ChangeLogs.propTypes = {
  changeLogs: PropTypes.array,
  versionChanges: PropTypes.array,
  creationError: PropTypes.string,
  selectedVersion: PropTypes.string,
};

ChangeLogs.defaultProps = {};

export default ChangeLogs;
