/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Form extends Component {
  render() {
    const enctype = this.props.fileForm ? 'multipart/form-data' : 'application/x-www-form-urlencoded';

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="form">
            <form className="form-horizontal" encType={enctype}>
              <div className="form-body">
                {this.props.children}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

Form.propTypes = {
  children: PropTypes.node,
  fileForm: PropTypes.bool,
};
Form.defaultProps = {};

export default Form;
