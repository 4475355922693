/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

class ModalDialog extends Component {
  constructor(props) {
    super(props);

    this.onCloseClick = this.onCloseClick.bind(this);
  }

  componentDidMount() {
    const _this = this;
    const $dialog = $(`#${this.props.dialogId}`);
    $dialog.on('show.bs.modal', function(e) {
      if (e.target.id === _this.props.dialogId) {
        if (_this.props.onDialogShown) _this.props.onDialogShown();
      }
    });

    $dialog.on('hide.bs.modal', function(e) {
      if (e.target.id === _this.props.dialogId) {
        if (_this.props.onDialogHide) _this.props.onDialogHide();
      }
    });
  }

  onCloseClick() {
    $(`#${this.props.dialogId}`).modal('hide');
    if (this.props.onCloseClick) this.props.onCloseClick();
  }

  render() {
    const disabled = this.props.saveDisabled === undefined ? false : this.props.saveDisabled;
    const showSave = this.props.showSave === undefined ? true : this.props.showSave;
    const saveText = this.props.saveText || i18next.t('links.save');
    const closeText = this.props.closeText || i18next.t('links.close');

    return (
      <div className="modal fade"
        id={this.props.dialogId}
        role="basic"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false">
        <div className={`modal-dialog ${this.props.customClass}`}>
          <div className="modal-content">
            <div className="modal-header">
              {this.props.showClose ?
              <button type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"/> : null }
              <h4 className="modal-title">{this.props.modalTitle}</h4>
            </div>
            <div className="modal-body">
              {this.props.children}
            </div>
            <div className="modal-footer">
              <button type="button"
                className={`btn  ${this.props.closeColor} btn-outline`}
                onClick={this.onCloseClick}>{closeText}
              </button>
              {showSave ?
                <button type="button"
                  className={`btn ${this.props.saveColor}`}
                  onClick={this.props.onSubmit}
                  disabled={disabled}>{saveText}
                </button> : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ModalDialog.propTypes = {
  dialogId: PropTypes.string,
  modalTitle: PropTypes.string,
  children: PropTypes.node,
  onDialogShown: PropTypes.func,
  onDialogHide: PropTypes.func,
  onSubmit: PropTypes.func,
  showSave: PropTypes.bool,
  saveDisabled: PropTypes.bool,
  customClass: PropTypes.string,
  saveColor: PropTypes.string,
  closeColor: PropTypes.string,
  saveText: PropTypes.string,
  closeText: PropTypes.string,
  onCloseClick: PropTypes.func,
  showClose: PropTypes.bool,
};
ModalDialog.defaultProps = {
  saveColor: 'green',
  closeColor: 'dark',
  showClose: true,
};

export default ModalDialog;
