/**
 * @author Tomasz Czura (9/7/16)
 */

export function toNetwork(file) {
  return {
    name: file.name,
    version: file.version,
    uri: file.url,
  };
}

export function toLocal(file) {
  return {
    id: file.id,
    name: file.name,
    createdDate: file.createdAt,
    version: file.version,
    url: file.uri,
    downloadsCount: file.downloadsCount,
  };
}

export function toForm(file) {
  return {
    id: file.id,
    name: file.name,
    createdDate: file.createdDate,
    version: file.version,
    url: file.url,
    downloadsCount: file.downloadsCount,
  };
}

export function defaultLocal() {
  return {
    id: '',
    name: '',
    version: '',
    url: '',
  };
}
