/**
 * @author TomaszCzura ({}
 */
import * as types from '../actions/action-types';

export function createKeySuccess(key) {
  return {
    type: types.NEW_KEY_SUCCESS,
    key,
  };
}

export function deleteKeySuccess(keyId) {
  return {
    type: types.DELETE_KEY_SUCCESS,
    keyId,
  };
}

export function deleteKeyError(error) {
  return {
    type: types.DELETE_KEY_ERROR,
    error,
  };
}
