import React, {Component} from 'react';
import AnalyticsOptions from './AnalyticsOptions';
import * as api from '../../../api/analytics-api';
import {Accordion, AccordionDetails, AccordionSummary} from '../../utils/Accordion';
import {Typography} from '@mui/material';
import CustomChart from './CustomChart';
import TextInfo from '../../utils/TextInfo';
import * as blockUtils from '../../../utils/BlockUtils';
import PropTypes from 'prop-types';
import ErrorBox from '../../utils/ErrorBox';

class SiteKpi extends Component {
  constructor(props) {
    super(props);

    this.onSaveChanges = this.onSaveChanges.bind(this);

    this.state = {
      chartsView: [],
      expanded: 'panel1',
      message: '',
      errorMessage: '',
    };
  }

  onSaveChanges(options) {
    this.setState({
      message: 'Getting charts...',
      chartsView: [],
      expanded: 'panel2',

      errorMessage: '',
    });

    blockUtils.blockUI();
    api.getSiteKpis(options.chosenSite, options.chartsQueryTime).then((chartsResponse) => {
      this.setState({
        chartsView: chartsResponse.data.charts,
        message: '',
        errorMessage: '',
      });
      blockUtils.unblockUI();
    }).catch((error) => {
      this.setState({
        errorMessage: this.getErrorMessage(error, options.chosenSite),
        message: '',
      });
      blockUtils.unblockUI();
    });
  }

  getErrorMessage(error, chosenSite) {
    if (error.response.status === 404) {
      return 'No data found for the given site with ID ' + chosenSite;
    } else if (error.response.status === 504) {
      return 'Request to Häfele API for site [' + chosenSite + '] timed out after 10 seconds. Please try again';
    }

    return error.message;
  }

  handleAccordionChange(newExpanded) {
    this.setState({
      expanded: newExpanded,
    });
  }

  render() {
    return (
      <div>
        <Accordion
          expanded={this.state.expanded === 'panel1'}
          onChange={() => this.handleAccordionChange('panel1')}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography><h4>Options</h4></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AnalyticsOptions
              onOptionsSave={this.onSaveChanges}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={this.state.expanded === 'panel2'}
          onChange={() => this.handleAccordionChange('panel2')}
        >
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography><h4>Content</h4></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ErrorBox errorMessage={this.state.errorMessage}/>
            <TextInfo labelText={this.state.message}/>
            <CustomChart chartViews={this.state.chartsView} triggerTabChangeFunc={this.props.triggerTabChangeFunc}/>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

SiteKpi.propTypes = {
  triggerTabChangeFunc: PropTypes.func,
};

export default SiteKpi;
