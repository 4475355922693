/**
 * @author TomaszCzura ({}
 */
import * as types from '../actions/action-types';

const initialState = {
  keys: [],
  currentKey: {},
  destroyKeyError: null,
  getKeysError: null,
};

const keysReducer = function(state = initialState, action) {
  switch (action.type) {
    case types.NEW_KEY_SUCCESS:
    {
      const newKeys = state.keys.concat([action.key]);
      return Object.assign({}, state, {keys: newKeys, error: null, currentKey: {}});
    }
    case types.NEW_KEY_ERROR:
      return Object.assign({}, state, {keys: state.keys, error: action.error, currentKey: {}});
    case types.GET_KEYS_SUCCESS:
      return Object.assign({}, state, {keys: action.keys, getKeysError: null, currentKey: {}});
    case types.GET_KEYS_ERROR:
      return Object.assign({}, state, {keys: [], getKeysError: action.error, currentKey: {}});
    case types.GET_KEY_SUCCESS:
      return Object.assign({}, state, {currentKey: action.currentKey});
    case types.UPDATE_KEY_SUCCESS:
    {
      const newKeys = state.keys.map((key) => key.id === action.currentKey.id ? action.currentKey : key);
      return Object.assign({}, state, {keys: newKeys, currentKey: action.currentKey, error: null});
    }
    case types.UPDATE_KEY_ERROR:
      return Object.assign({}, state, {error: action.error});
    case types.DELETE_KEY_SUCCESS:
    {
      const newKeys = state.keys.filter((key) => key.id !== action.keyId);
      return Object.assign({}, state, {keys: newKeys, destroyKeyError: null, currentKey: {}});
    }
    case types.DELETE_KEY_ERROR:
      return Object.assign({}, state, {destroyKeyError: action.error});
  }

  return state;
};

export default keysReducer;
