/**
 * @author Tomasz Czura (10/19/16)
 */
import React from 'react';
import AppSocket, {BASE_SOCKET_URL} from './socket';
import * as actions from '../actions/dahsboard-actions';
import store from '../store';
import {toastr} from 'react-redux-toastr';
import i18next from 'i18next';
import {isSiteSelected, selectedSiteId} from '../store';
import {getLockCodeText} from '../model/transaction-log';

let dashboardSocketInstance = null;
const DAHSBOARD_SOCKET_URL = `${BASE_SOCKET_URL}/api/ws/dashboard`;

export const DashboardMessageTypes = {
  NEW_MOBILE_USER: 'NewMobileUser',
  NEW_ACTIVE_MOBILE_USER: 'NewActiveMobileUser',
  KEY_ISSUED: 'KeyIssued',
  DOOR_OPENING: 'DoorOpening',
  SITE_CONNECTION_CHANGE: 'SiteConnectionStateChange',
};

class DashboardSocket extends AppSocket {
  constructor() {
    super();
    if (!dashboardSocketInstance) {
      dashboardSocketInstance = this;
    }
    this.addSiteToMessage = this.addSiteToMessage.bind(this);

    return dashboardSocketInstance;
  }

  static getInstance() {
    if (!dashboardSocketInstance) {
      dashboardSocketInstance = new DashboardSocket();
    }
    return dashboardSocketInstance;
  }

  getSocketUrl() {
    return DAHSBOARD_SOCKET_URL;
  }

  addSiteToMessage(message, siteId, siteName) {
    if (selectedSiteId() !== siteId && siteName) {
      return `${siteName} - ${message}`;
    } else {
      return message;
    }
  }

  handleMessage(message) {
    let toastMessage = '';
    switch (message.type) {
      case DashboardMessageTypes.NEW_MOBILE_USER:
        toastMessage = i18next.t('toastr.new_user');
        toastr.success(toastMessage, '', {icon: <i className="fa fa-user-plus toastr-icon"/>});
        store.dispatch(actions.newMobileUser());
        break;
      case DashboardMessageTypes.NEW_ACTIVE_MOBILE_USER:
        store.dispatch(actions.newActiveMobileUser(message));
        break;
      case DashboardMessageTypes.KEY_ISSUED:
        if (isSiteSelected() && selectedSiteId() === message.siteId || !isSiteSelected()) {
          toastMessage = i18next.t('toastr.new_key', {room: message.room});
          toastMessage = this.addSiteToMessage(toastMessage, message.siteId, message.siteName);
          toastr.success(toastMessage, '', {icon: <i className="fa fa-key toastr-icon"/>});
          store.dispatch(actions.keyIssued(message));
        }
        break;
      case DashboardMessageTypes.DOOR_OPENING:
        if (isSiteSelected() && selectedSiteId() === message.siteId || !isSiteSelected()) {
          if (message.success) {
            toastMessage = i18next.t('toastr.door_opened', {room: message.room});
            toastMessage = this.addSiteToMessage(toastMessage, message.siteId, message.siteName);
            toastr.success(toastMessage, '', {
              icon: <i className="fa fa-unlock toastr-icon"/>,
            });
          } else {
            toastMessage = i18next.t('toastr.door_not_opened',
                {room: message.room, error: getLockCodeText(parseInt(message.errorCode))});
            toastMessage = this.addSiteToMessage(toastMessage, message.siteId, message.siteName);
            toastr.error(toastMessage, '', {icon: <i className="fa fa-warning toastr-icon"/>});
          }
          store.dispatch(actions.doorOpening(message));
        }
        break;
      case DashboardMessageTypes.SITE_CONNECTION_CHANGE:
        if (isSiteSelected() && selectedSiteId() === message.siteId || !isSiteSelected()) {
          store.dispatch(actions.siteAgentConnectionChange(message));
        }
        break;
    }
  }
}

export default DashboardSocket;
