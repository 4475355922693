/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import WhitePortlet from '../utils/WhitePortlet';
import * as sitesApi from './../../api/sites-api';
import i18next from 'i18next';
import * as blockUtils from './../../utils/BlockUtils';
import SiteForm from './SiteForm';
import SiteDetails from './SiteDetails';
import ErrorBox from './../utils/ErrorBox';
import * as viewUtils from './../../utils/view-utils';

class SiteDetailsContainer extends Component {
  constructor(props) {
    super(props);
    this.siteFormDialogId = 'siteFormDialog';
  }

  componentDidMount() {
    blockUtils.blockUI();
    sitesApi.getSite(this.props.params.siteId);
  }

  UNSAFE_componentWillUpdate() {
    blockUtils.unblockUI();
  }

  componentDidUpdate() {
    if (this.props.updateSiteError) {
      if (this.modalDialog) {
        this.modalDialog.handleError(this.props.updateSiteError);
      }
      blockUtils.unblockUI();
    } else {
      $(`#${this.siteFormDialogId}`).modal('hide');
    }
  }

  showSiteForm() {
    const props = {
      dialogId: this.siteFormDialogId,
      error: this.props.updateSiteError,
      presentedSite: this.props.presentedSite,
    };
    this.modalDialog = viewUtils.showModal(SiteForm, this.siteFormDialogId, props);
  }

  render() {
    const caption = `${i18next.t('sites.name', {count: 2})} (#${this.props.params.siteId})`;

    return (
      <WhitePortlet caption={caption} icon="icon-home">
        <ErrorBox errorMessage={viewUtils.getErrorDescription(this.props.getSiteError)}/>
        <SiteDetails presentedSite={this.props.presentedSite}/>
        {!this.props.getSiteError ?
                    <button className="btn btn-primary"
                      onClick={() => this.showSiteForm()}>{i18next.t('links.edit')}</button> :
                null}
      </WhitePortlet>
    );
  }
}

SiteDetailsContainer.propTypes = {
  presentedSite: PropTypes.object,
  params: PropTypes.any,
  updateSiteError: PropTypes.object,
  getSiteError: PropTypes.object,
};

SiteDetailsContainer.defaultProps = {
  presentedSite: {},
};

const mapStateToProps = function(store) {
  return {
    presentedSite: store.sitesState.presentedSite,
    updateSiteError: store.sitesState.updateSiteError,
    getSiteError: store.sitesState.getSiteError,
  };
};

export default connect(mapStateToProps)(SiteDetailsContainer);
