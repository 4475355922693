/**
 * @author TomaszCzura ({}
 */
import axios from 'axios';
import * as endpoints from './endpoints';

function handleLoginResponse(response, callback) {
  if (response.data && response.data.token) {
    const responseData = response.data;
    callback({
      authenticated: true,
      token: responseData.token,
      userId: responseData.id,
      username: responseData.username,
      userRole: responseData.role,
      sessionEnd: responseData.tokenExpirationDate,
      lastLocale: responseData.lastLocale,
    });
  } else {
    callback({authenticated: false, error: response.error});
  }
}

export function login(username, password, callback) {
  const data = {
    username, password,
  };

  return axios.post(endpoints.LOGIN, data, {})
      .then((response) =>
        handleLoginResponse(response, callback))
      .catch((error) => {
        callback({authenticated: false, error: error});
      });
}

export function sendEmail(email, callback) {
  return axios.put(endpoints.PASSWORD_FORGET_INIT, email, {
    headers: {
      'content-type': 'text/plain',
    }})
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error.response);
      });
}

export function sendNewPassword(password, uuid, callback) {
  return axios.put(endpoints.PUT_NEW_PASSWORD(uuid), password, {
    headers: {
      'content-type': 'text/plain',
    }})
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error.response);
      });
}

export function renewToken(callback) {
  axios.post(endpoints.RENEW_TOKEN, {}, endpoints.headers())
      .then((response) => handleLoginResponse(response, callback))
      .catch((error) => {
        callback({authenticated: false, error: error});
      });
}
