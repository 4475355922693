/**
 * @author TomaszCzura ({})
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReduxDataTable, {onRowClick} from './../redux_data_table/ReduxDataTable';
import i18next from 'i18next';
import * as bookingsApi from '../../api/bookings-api';
import * as keysApi from '../../api/keys-api';
import * as blockUtils from './../../utils/BlockUtils';
import * as viewUtils from './../../utils/view-utils';
import * as bookingModel from './../../model/checkin';
import BookingForm from './BookingForm';
import TableAction from './../utils/actions/TableAction';
import DestroyAction from './../utils/actions/DestroyAction';
import EditAction from './../utils/actions/EditAction';
import ShowAction from './../utils/actions/ShowAction';
import TableActions from './../utils/TableActions';
import * as keyModel from './../../model/key';
import BooleanLabel from './../utils/BooleanLabel';
import CreateNewButton from '../CreateNewButton';
import * as rights from '../../model/rights';
import UserTypeLabel from '../utils/UserTypeLabel';

class BookingsList extends Component {
  constructor(props) {
    super(props);

    this.tableId = 'checkins_table';
    this.formDialogId = 'checkinFormDialog';
    this.destroyBooking = this.destroyBooking.bind(this);
    this.destroyKey = this.destroyKey.bind(this);
    this.showBookingForm = this.showBookingForm.bind(this);
    this.checkinGuest = this.checkinGuest.bind(this);
    this.setSublistButtonsActions = this.setSublistButtonsActions.bind(this);
    this.unMatchMobileUser = this.unMatchMobileUser.bind(this);

    this.setSublistButtonsActions();

    this.state = {
      openDropDown: 'dropdown',
    };
  }

  setSublistButtonsActions() {
    // This is a workaround for assign onClick handler for buttons on sublist
    // Because normal onClick not works in collapsible sublist
    $(document).on('DOMNodeInserted', (e) => {
      if (e.target.className === 'child') {
        $(e.target).find('button.unmatchBtn').on('click', (e) => {
          const targetData = $(e.target).data();
          const mobileUserEmails = targetData.mobile_user_email;
          const bookingId = targetData.booking_id;
          this.unMatchMobileUser(bookingId, mobileUserEmails);
        });

        $(e.target).find('button.destroyKeyBtn').on('click', (e) => {
          const keyId = $(e.target).data().key_id;
          this.destroyKey(keyId);
        });
      }
    });
  }

  destroyBooking(booking) {
    blockUtils.blockUI('#checkins_table');
    bookingsApi.destroyBooking(booking.id);
  }

  destroyKey(keyId) {
    confirm(() => {
      blockUtils.blockUI('#checkins_table');
      keysApi.destroyKey(keyId);
    });
  }

  componentDidUpdate() {
    this.handleError();
    $('.popovers').popover();
  }

  componentDidMount() {
    $('.popovers').popover();
  }

  handleError() {
    if (this.props.createCheckinError) {
      if (this.modalDialog) {
        this.modalDialog.handleError(this.props.createCheckinError);
      }
      blockUtils.unblockUI();
    } else {
      $(`#${this.formDialogId}`).modal('hide');
    }
  }

  checkinGuest(booking) {
    const bookingCopy = Object.assign({}, booking);
    bookingCopy.checkedIn = true;
    this.showBookingForm(bookingCopy);
  }

  showBookingForm(booking) {
    const props = {
      dialogId: this.formDialogId,
      error: this.props.createCheckinError,
      presentedCheckin: booking,
      bookingState: booking ? bookingModel.getState(booking) : false,
    };
    this.modalDialog = viewUtils.showModal(BookingForm, this.formDialogId, props);
  }

  unMatchMobileUser(bookingId, mobileUserEmails) {
    confirm(() => {
      bookingsApi.unMatchMobileUser(bookingId, mobileUserEmails);
      blockUtils.blockUI('#checkins_table');
    }, i18next.t('checkins.actions.sure_unmatch'));
  }

  generateKey(booking) {
    blockUtils.blockUI();
    bookingsApi.generateKey(booking.id, function() {
      blockUtils.unblockUI();
      window.showAlert(i18next.t('success'), i18next.t('checkins.generation.success'));
    }, function() {
      blockUtils.unblockUI();
      window.showAlert(i18next.t('error'), i18next.t('checkins.generation.error'));
    });
  }

  render() {
    return (
      <div className="table-container">
        {rights.currentUserHasRight(rights.RESERVATION_ADD_PRIVILEGE) ?
          <CreateNewButton callBack={() => this.showBookingForm()}/> :
          null
        }
        <ReduxDataTable {...this.props} notSortable={[0, 5, 6, 7, 8]} collapsable={true}>
          <thead>
            <tr role="row" className="heading">
              <th/>
              <th data-sort="id">ID</th>
              <th data-sort="reservationId">{i18next.t('checkins.attrs.res_id')}</th>
              <th data-sort="checkinDate">{i18next.t('checkins.attrs.start_date')}</th>
              <th data-sort="checkoutDate">{i18next.t('checkins.attrs.end_date')}</th>
              <th data-sort="room">{i18next.t('checkins.attrs.room')}</th>
              <th>{i18next.t('checkins.attrs.special_areas')}</th>
              <th>{i18next.t('checkins.attrs.status')}</th>
              <th>{i18next.t('actions')}</th>
              <th className="hidden"/>
            </tr>
          </thead>
          <tbody>
            {this.props.checkins.map((booking) => {
              return (
                <tr onClick={(event) => onRowClick(booking.id, event)} id={`booking_${booking.id}`} key={booking.id}
                  className="odd gradeX collapsible">
                  <td className={`${bookingModel.hasKeys(booking) ? 'control' : ''}`}/>
                  <td>{booking.id}</td>
                  <td>{booking.reservationId}</td>
                  <td>{viewUtils.toYYYYMMDDHM(booking.checkinDate)}</td>
                  <td>{viewUtils.toYYYYMMDDHM(booking.checkoutDate)}</td>
                  <td>{bookingModel.roomBedDescription(booking)}</td>
                  <td>{booking.specialAreas}</td>
                  <td>{bookingModel.getState(booking)}</td>

                  <td>
                    <TableActions identifier={booking.id}>
                      <EditAction
                        onEdit={() => this.showBookingForm(booking)}
                        condition={rights.currentUserHasRight(rights.RESERVATION_EDIT_PRIVILEGE)}
                      />
                      <TableAction text={i18next.t('checkins.actions.checkin')}
                        action={() => this.checkinGuest(booking)}
                        condition={!bookingModel.isCheckedIn(booking)} icon="icon-login"/>
                      <TableAction text={i18next.t('checkins.actions.generate_key')}
                        action={() => this.generateKey(booking)}
                        condition={bookingModel.canGenerateKey(booking)} icon="icon-key"/>
                      <ShowAction title={i18next.t('checkins.actions.show_logs')}
                        icon="icon-list" path={`/bookings/${booking.id}/logs`} />
                      <DestroyAction
                        destroyElement={() => this.destroyBooking(booking)}
                        condition={rights.currentUserHasRight(rights.RESERVATION_DELETE_PRIVILEGE)}/>
                    </TableActions>
                  </td>
                  <td className="hidden">
                    { booking.keys && booking.keys.length > 0 ?
                      <table className="table table-responsive table-striped">
                        <thead>
                          <tr>
                            <th>{i18next.t('checkins.attrs.matching_data')}</th>
                            <th>{i18next.t('checkins.attrs.user_type')}</th>
                            <th>{i18next.t('keys.attrs.generated_at')}</th>
                            <th>{i18next.t('checkins.attrs.special_areas')}</th>
                            <th>{i18next.t('keys.name')}</th>
                            <th>{i18next.t('actions')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {booking.keys.map((key) => {
                            return (
                              <tr key={key.id} id={`booking_key_${key.id}`}>
                                <td>{key.matchingData ?
                                  <span>
                                    {key.matchingData.toUpperCase()}:
                                    {key.mobileUserEmail}
                                  </span>:
                                  ''}
                                </td>
                                <td><UserTypeLabel value={key.userType || 'OTHER'}/></td>
                                <td>{
                                  keyModel.isGenerated(key) ? viewUtils.toYYYYMMDDHM(key.generatedAt) : null
                                }
                                </td>
                                <td>{key.specialAreas}</td>
                                <td><BooleanLabel value={keyModel.isGenerated(key)} trueText={key.id}
                                  falseText={keyModel.createErrorMessage(key)}
                                />
                                </td>
                                <td>
                                  <button className="btn btn-sm btn-danger unmatchBtn"
                                    data-mobile_user_email={key.mobileUserEmail}
                                    data-booking_id={booking.id}>
                                    {i18next.t('checkins.actions.unmatch')}
                                  </button>
                                </td>
                              </tr>);
                          },
                          )}

                        </tbody>

                      </table> : null
                    }
                  </td>
                </tr>
              );
            })}
          </tbody>
        </ReduxDataTable>
      </div>
    );
  }
}

BookingsList.propTypes = {
  checkins: PropTypes.array,
  createCheckinError: PropTypes.object,
  totalElements: PropTypes.number,
  totalPages: PropTypes.number,
  onPageChange: PropTypes.func,
  onElementsCountChange: PropTypes.func,
  elementsCount: PropTypes.number,
};

BookingsList.defaultProps = {};

export default BookingsList;
