/**
 * @author Tomasz Czura (11/8/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import WhitePortlet from '../utils/WhitePortlet';
import RoomsList from './../rooms/RoomsList';
import i18next from 'i18next';
import {connect} from 'react-redux';
import * as sitesApi from './../../api/sites-api';
import ErrorBox from './../utils/ErrorBox';
import {getErrorDescription} from './../../utils/view-utils';

class SiteRoomsListContainer extends Component {
  componentDidMount() {
    sitesApi.getSiteRooms(this.props.params.siteId);
  }

  render() {
    return (
      <WhitePortlet caption={i18next.t('rooms.name', {count: 2})} icon="icon-grid">
        <ErrorBox errorMessage={getErrorDescription(this.props.getRoomsError)}/>
        <RoomsList rooms={this.props.rooms}/>
      </WhitePortlet>
    );
  }
}

SiteRoomsListContainer.propTypes = {
  params: PropTypes.any,
  rooms: PropTypes.array,
  getRoomsError: PropTypes.object,
};
SiteRoomsListContainer.defaultProps = {};

const mapStateToProps = function(store) {
  return {
    rooms: store.sitesState.rooms,
    getRoomsError: store.sitesState.getRoomsError,
  };
};

export default connect(mapStateToProps)(SiteRoomsListContainer);
