/**
 * @author Tomasz Czura (11/23/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getBatteryStatusInfo} from '../../model/room';
import i18next from 'i18next';
import moment from 'moment';
import {nameOfSite, selectedSiteId} from '../../store';
import TimeAgo from 'react-timeago';

class BatteryAlert extends Component {
  render() {
    return (
      <li>
        <div className="col1">
          <div className="cont">
            <div className="cont-col1">
              <div className="label label-sm label-danger">
                <i className="fa fa-battery-1"/>
              </div>
            </div>
            <div className="cont-col2">
              <div className="desc">
                {selectedSiteId() === this.props.alert.siteId ?
                                    i18next.t('dashboard.battery_alert',
                                        {
                                          room: this.props.alert.room,
                                          level: this.props.alert.batteryLevel,
                                          status: getBatteryStatusInfo(this.props.alert.batteryHealth),
                                        }) :
                                    i18next.t('dashboard.battery_alert_site',
                                        {
                                          site: this.props.alert.siteName || nameOfSite(this.props.alert.siteId),
                                          room: this.props.alert.room,
                                          level: this.props.alert.batteryLevel,
                                          status: getBatteryStatusInfo(this.props.alert.batteryHealth),
                                        })
                }
              </div>
            </div>
          </div>
        </div>
        <div className="col2">
          <div className="date"><TimeAgo date={moment(this.props.alert.date)}/></div>
        </div>
      </li>
    );
  }
}

BatteryAlert.propTypes = {
  alert: PropTypes.object,
};
BatteryAlert.defaultProps = {};

export default BatteryAlert;
