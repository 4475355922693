/**
 * @author Tomasz Czura (9/5/16)
 */

export const ApiErrors = {
  WRONG_CREDENTIALS: 'WRONG_CREDENTIALS',
  USER_EXIST: 'USER_EXIST',
  RESERVATION_EXIST: 'RESERVATION_EXIST',
  BOOKING_IN_PAST: 'BOOKING_IN_PAST',
  DUPLICATE_USERNAME: 'DUPLICATE_USERNAME',
  DUPLICATE_RESERVATION_ID: 'DUPLICATE_RESERVATION_ID',
  INVALID_DATA: 'INVALID_DATA',
  INVALID_SITE_ID: 'INVALID_SITE_ID',
  UNKNOWN: 'UNKNOWN',
  ACCESS_DENIED: 'ACCESS_DENIED',
  RESERVATION_NOT_FOUND: 'RESERVATION_NOT_FOUND',
  RESOURCE_NOT_FOUND: 'RESOURCE_NOT_FOUND',
  USER_NOT_VALIDATED: 'USER_NOT_VALIDATED',
  ILLEGAL_USER_OPERATION: 'ILLEGAL_USER_OPERATION',
  RESERVATION_ALREADY_CHECKED_IN: 'RESERVATION_ALREADY_CHECKED_IN',
  MISSING_MATCHING_DATA: 'MISSING_MATCHING_DATA',
  OVERLAPPING_RESERVATION: 'OVERLAPPING_RESERVATION',
  AGENT_NOT_CONNECTED: 'AGENT_NOT_CONNECTED',
  EMAIL_SERVICE_UNAVAILABLE: 'EMAIL_SERVICE_UNAVAILABLE',
  INVALID_EMAIL: 'INVALID_EMAIL',
  DUPLICATE_EMAIL: 'DUPLICATE_EMAIL',
};
