/**
 * @author Tomasz Czura (9/6/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

class Paging extends Component {
  constructor(props) {
    super(props);

    this.onNextPageClick = this.onNextPageClick.bind(this);
    this.onPreviousPageClick = this.onPreviousPageClick.bind(this);
    this.handleEnterPress = this.handleEnterPress.bind(this);
  }

  componentDidMount() {
    $(`#${this.props.tableId}_parent`).find('#page_number_input').val(this.props.currentPage);
  }

  componentDidUpdate() {
    $(`#${this.props.tableId}_parent`).find('#page_number_input').val(this.props.currentPage);
  }

  onPreviousPageClick() {
    if (this.props.onPageChange && this.props.currentPage > 0) {
      this.props.onPageChange(this.props.currentPage - 1);
    }
  }

  onNextPageClick() {
    if (this.props.onPageChange && this.props.currentPage < this.props.maxPage) {
      this.props.onPageChange(this.props.currentPage + 1);
    }
  }

  handleEnterPress(e) {
    if (e.key === 'Enter') {
      const pageNumber = parseInt(e.target.value);
      if (pageNumber <= this.props.maxPage) {
        this.props.onPageChange(pageNumber);
      } else {
        e.target.value = this.props.maxPage;
        this.props.onPageChange(this.props.maxPage);
      }
    }
  }

  render() {
    const isFirstPage = this.props.currentPage == this.props.minPage;
    const isLastPage = this.props.currentPage == this.props.maxPage;

    return (
      <div className={`col-md-7 col-sm-7 ${this.props.maxPage == 0 ? 'hidden' : ''}`}>
        <div className="paging_bootstrap_extended paging_dt" id={`${this.props.tableId}_paginate`}>
          <div className="pagination-panel">
            {i18next.t('redux_table.page')}
            <button type="button" className="btn btn-sm default prev" disabled={isFirstPage}
              onClick={this.onPreviousPageClick}>
              <i className="fa fa-angle-left"/>
            </button>
            <input type="text" maxLength="5" id="page_number_input" onKeyPress={this.handleEnterPress}
              className="pagination-panel-input form-control input-sm input-inline input-mini paging_dt_input"/>
            <button type="button" className="btn btn-sm default next" disabled={isLastPage}
              onClick={this.onNextPageClick}>
              <i className="fa fa-angle-right"/>
            </button>
            {i18next.t('redux_table.of')}
            <span className="pagination-panel-total">{this.props.maxPage}</span>
          </div>
        </div>
      </div>
    );
  }
}

Paging.propTypes = {
  tableId: PropTypes.string,
  minPage: PropTypes.number,
  currentPage: PropTypes.number,
  maxPage: PropTypes.number,
  onPageChange: PropTypes.func,
};
Paging.defaultProps = {};

export default Paging;
