import React, {Component} from 'react';
import i18next from 'i18next';
import ErrorBox from '../../utils/ErrorBox';
import PropTypes from 'prop-types';
import SiteStatus from './SiteStatus';
import * as api from '../../../api/analytics-api';
import {selectedSiteId} from '../../../store';
import {connect} from 'react-redux';
import * as blockUtils from '../../../utils/BlockUtils';
import Cookies from 'universal-cookie';

const SITE_COOKIE_KEY = 'terminals-siteId';
const cookies = new Cookies();

class SiteStatusContainer extends Component {
  constructor(props) {
    super(props);

    this.tableId = 'site_status_table';

    this.state = {
      terminals: [],
      actions: [],
      getTerminalsError: i18next.t('terminals.errors.select_site'),
    };

    this.getErrorMessage = this.getErrorMessage.bind(this);
    this.getSiteStatus = this.getSiteStatus.bind(this);

    this.pageNumber = 0;
    this.elementsCount = 10;
    this.sortingName = 'terminalId';
    this.sortingType = 'ASC';
    this.searchPhrase = '';
  }

  componentDidMount() {
    if (this.props.siteId > 0) {
      cookies.set(SITE_COOKIE_KEY, this.props.siteId, {expires: new Date(Date.now() + 360000)});
      this.getSiteStatus();
    }
  }

  getSiteStatus(
      pageNumber = this.pageNumber,
      elementsCount = this.elementsCount,
      sortingName = this.sortingName,
      sortingType = this.sortingType,
      searchPhrase = this.searchPhrase,
  ) {
    const data = {
      page: pageNumber,
      size: elementsCount,
      query: searchPhrase,
      sort: `${sortingName},${sortingType.toLowerCase()}`,
    };

    const siteId = this.props ? this.props.siteId : cookies.get(SITE_COOKIE_KEY);

    blockUtils.blockUI();
    api.getSiteStatus(siteId, data).then((response) => {
      this.setState({
        terminals: response.data.terminalsPage.content,
        totalElements: response.data.terminalsPage.totalElements,
        totalPages: response.data.terminalsPage.totalPages,
        actions: response.data.actions,
        getTerminalsError: '',
      });
      blockUtils.unblockUI();
    }).catch((error) => {
      this.setState({
        terminals: [],
        actions: [],
        getTerminalsError: this.getErrorMessage(error),
      });
      blockUtils.unblockUI();
    });
  }

  switchTerminalState(terminalId, action) {
    const siteId = cookies.get(SITE_COOKIE_KEY);

    window.confirm(
        () => {
          blockUtils.blockUI();
          api.unlockTerminal(siteId, terminalId, action).then((response) => {
            blockUtils.unblockUI();
            window.showAlert(i18next.t('success'), response.data);
          }).catch((error) => {
            blockUtils.unblockUI();
            window.showAlert(i18next.t('error'), error);
          });
        },
        'Are you sure you wish to unlock Terminal [' + terminalId + ']?',
    );
  }

  getErrorMessage(error) {
    if (error.response.status === 404) {
      return 'No data found for the given site with ID ' + this.props.siteId;
    }

    return error.message;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.siteId !== prevProps.siteId) {
      cookies.set(SITE_COOKIE_KEY, this.props.siteId, {expires: new Date(Date.now() + 360000)});
      this.getSiteStatus();
    }
  }

  render() {
    const terminalAction = this.state.actions.length > 0 ? this.switchTerminalState : null;

    return (
      <div>
        {this.state.terminals.length > 0 ?
          <SiteStatus
            getData={this.getSiteStatus}
            error={this.state.error}
            terminals={this.state.terminals}
            totalElements={this.state.totalElements}
            totalPages={(this.state.totalPages)}
            tableId={this.tableId}
            terminalAction={terminalAction}
          /> :

          <ErrorBox
            errorMessage={this.state.getTerminalsError}
          />
        }
      </div>
    );
  }
}

SiteStatusContainer.propTypes = {
  siteId: PropTypes.number,
  error: PropTypes.any,
  terminals: PropTypes.array,
  getTerminalsError: PropTypes.string,
  totalElements: PropTypes.number,
  totalPages: PropTypes.number,
  actions: PropTypes.array,
};

SiteStatusContainer.defaultProps = {
  terminals: [],
  actions: [],
};

const mapStateToProps = function(store) {
  const currentSiteId = store.sitesState.currentSiteId === -1 ? selectedSiteId() : store.sitesState.currentSiteId;

  return {
    siteId: currentSiteId,
  };
};

export default connect(mapStateToProps)(SiteStatusContainer);
