/**
 * @author Tomasz Czura (8/26/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

class RadioAction extends Component {
  render() {
    return (
      <label className={`btn btn-transparent blue btn-outline btn-circle btn-sm ${this.props.active ? 'active' : ''}`}
        onClick={() => this.props.onChange(this.props.radioId)}>
        <input type="radio" name={this.props.groupName}
          id={this.props.radioId} checked={this.props.active}/>
        {this.props.title}
      </label>
    );
  }
}

RadioAction.propTypes = {
  title: PropTypes.string,
  groupName: PropTypes.string,
  radioId: PropTypes.string,
  active: PropTypes.bool,
  onChange: PropTypes.func,
};
RadioAction.defaultProps = {};

export default RadioAction;
