/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import WhitePortlet from '../utils/WhitePortlet';
import * as usersApi from './../../api/users-api';
import i18next from 'i18next';
import * as blockUtils from './../../utils/BlockUtils';
import UserDetails from './UserDetails';
import EditOwnUserForm from './EditOwnUserForm';
import ErrorBox from './../utils/ErrorBox';
import * as viewUtils from './../../utils/view-utils';


class UserDetailsContainer extends Component {
  constructor(props) {
    super(props);
    this.userFormDialogId = 'userFormDialog';
  }

  componentDidMount() {
    blockUtils.blockUI();
    usersApi.getUser(this.props.params.userId);
    usersApi.getUserProfile();
  }

  UNSAFE_componentWillUpdate() {
    blockUtils.unblockUI();
  }

  componentDidUpdate() {
    if (this.props.updateUserError && this.modalDialog) {
      this.modalDialog.handleError(this.props.updateUserError);
      blockUtils.unblockUI();
    } else {
      $(`#${this.userFormDialogId}`).modal('hide');
    }
  }

  showUserForm() {
    const props = {
      dialogId: this.userFormDialogId,
      error: this.props.updateUserError,
      presentedUser: this.props.presentedUser,
      availableRoles: this.props.userProfile.availableRoles,
      sites: this.props.userProfile.sites.filter((site) => site.selectedByUser),
    };
    this.modalDialog = viewUtils.showModal(EditOwnUserForm, this.userFormDialogId, props);
  }

  render() {
    const caption = `${i18next.t('users.name')} (#${this.props.params.userId})`;

    return (
      <WhitePortlet caption={caption} icon="icon-users">
        <ErrorBox errorMessage={viewUtils.getErrorDescription(this.props.getUserError)}/>
        <UserDetails presentedUser={this.props.presentedUser}/>
        <button className="btn btn-primary" onClick={() => this.showUserForm()}>{i18next.t('links.edit')}</button>
      </WhitePortlet>
    );
  }
}

UserDetailsContainer.propTypes = {
  presentedUser: PropTypes.object,
  updateUserError: PropTypes.object,
  getUserError: PropTypes.object,
  params: PropTypes.any,
  availableRoles: PropTypes.array,
  userProfile: PropTypes.object,
  sites: PropTypes.array,

};
UserDetailsContainer.defaultProps = {};

const mapStateToProps = function(store) {
  return {
    presentedUser: store.usersState.presentedUser,
    userProfile: store.usersState.userProfile,
    updateUserError: store.usersState.updateUserError,
    getUserError: store.usersState.getUserError,
  };
};

export default connect(mapStateToProps)(UserDetailsContainer);
