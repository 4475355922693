/**
 * @author TomaszCzura ({}
 */
import i18next from 'i18next';

class Languages {
}

Languages.initialize = function(language) {
  let lang = language;
  if (!lang) {
    lang = 'en'; // default language;
  } else {
    if (!lang.toString().includes('en') || !lang.toString().includes('de')) {
      lang = 'en'; // default language
    }
    if (lang.toString().includes('en')) {
      lang = 'en';
    }
    if (lang.toString().includes('de')) {
      lang = 'de';
    }
  }
  const translations = require(`./../../assets/locales/${lang}.json`);
  i18next.init({
    lng: lang,
    resources: translations,
  });
};

export default Languages;
