/**
 * @author TomaszCzura ({}
 */
import * as types from '../../src/actions/action-types';

export function getDashboardStats(stats) {
  return {
    type: types.GET_DASHBOARD_STATS,
    stats,
  };
}

export function createChangeLog(changelog) {
  return {
    type: types.ADD_CHANGE_LOG,
    changelog,
  };
}

export function updateChangeLog(changelog) {
  return {
    type: types.EDIT_CHANGE_LOG,
    changelog,
  };
}

export function deleteChangeLog(versionNumber) {
  return {
    type: types.DELETE_CHANGE_LOG,
    versionNumber,
  };
}

export function newMobileUser() {
  return {
    type: types.NEW_MOBILE_USER,
  };
}

export function newActiveMobileUser(message) {
  return {
    type: types.NEW_ACTIVE_MOBILE_USER,
    message,
  };
}

export function keyIssued(message) {
  return {
    type: types.KEY_ISSUED,
    message,
  };
}

export function doorOpening(data) {
  return {
    type: types.DOOR_OPENING,
    data,
  };
}

export function siteAgentConnectionChange(data) {
  return {
    type: types.SITE_AGENT_CONNECTION_CHANGED,
    data,
  };
}
