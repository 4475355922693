/**
 * @author Tomasz Czura (9/22/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

class SitesTableList extends Component {
  render() {
    return (
      <ul className="sites_list">
        {this.props.sites.map( (site) => {
          return <li key={site.id}>{site.name} (ID: {site.id})</li>;
        })}
      </ul>
    );
  }
}

SitesTableList.propTypes = {
  sites: PropTypes.array,
};
SitesTableList.defaultProps = {};

export default SitesTableList;
