export const ChangelogEntryTypes = [
  {id: 'BUG', name: 'B'},
  {id: 'FEATURE', name: 'F'},
  {id: 'OTHER', name: 'O'},
];

export function toLocal(data) {
  return data.map((entry) => {
    return {
      id: entry.id,
      header: entry.header || '',
      summary: entry.summary || '',
      changeLogEntryType: entry.changeLogEntryType,
    };
  });
}

export function getEntryTypeInitial(entryType) {
  if (!entryType) {
    return ChangelogEntryTypes['OTHER'];
  }

  return ChangelogEntryTypes.find((entry) => entry.id === entryType).name;
}
