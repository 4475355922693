/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

class LoginInput extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  onChange(e) {
    if (this.props.validate(e.target.value)) {
      this.props.onChange(e);
    }
  }

  handleBlur(e) {
    this.props.validate(e.target.value);
  }

  render() {
    return (
      <div className="form-group">
        <label className="control-label visible-ie8 visible-ie9">{this.props.title}</label>
        <div className={`input-icon ${this.props.error ? 'has-error' : ''}`}>
          <i className={`fa ${this.props.icon}`}/>
          <input className="form-control placeholder-no-fix" type={this.props.isSecret ? 'password' : 'text'}
            value={this.props.value} onChange={this.onChange} onBlur={this.handleBlur}
            placeholder={this.props.title} name={this.props.name} />
          {this.props.error ? (<span className="help-block">{this.props.error}</span>) : null}
          {this.props.success ? (<span className="help-block">{this.props.success}</span>) : null}
        </div>
      </div>
    );
  }
}

LoginInput.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  name: PropTypes.string,
  success: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  validate: PropTypes.func,
  isSecret: PropTypes.bool,
};
LoginInput.defaultProps = {};

export default LoginInput;
