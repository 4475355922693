/**
 * @author TomaszCzura ({}
 */
import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import * as specialAreasModel from './../../model/specialarea';
import * as blockUtils from './../../utils/BlockUtils';
import FormComponent from './../utils/FormComponent';
import Form from './../utils/Form';
import ModalDialog from './../utils/ModalDialog';
import TextInput from './../utils/TextInput';
import * as sitesApi from './../../api/sites-api';
import {isValidSpecialArea} from './../../utils/validate-utils';
import ErrorBox from './../utils/ErrorBox';
import {getErrorDescription} from './../../utils/view-utils';

class SpecialareasForm extends FormComponent {
  constructor(props) {
    super(props);

    this.sendForm = this.sendForm.bind(this);
    this.validateAreaName = this.validateAreaName.bind(this);
    this.validateAreaNumber = this.validateAreaNumber.bind(this);
    this.onDisconnectionNotificationChanged = this.onDisconnectionNotificationChanged.bind(this);
  }

  sendForm() {
    const validAreaName = this.validateAreaName();
    const validAreaNumber = this.validateAreaNumber();

    if (validAreaName && validAreaNumber) {
      if (this.state.id) {
        sitesApi.updateSiteSpecialarea(this.props.presentedSiteId, this.state);
      } else {
        sitesApi.createSiteSpecialarea(this.props.presentedSiteId, this.state);
      }

      blockUtils.blockUI();
    }
  }

  initialState() {
    const state = this.props.presentedSpecialArea ?
      specialAreasModel.toForm(this.props.presentedSpecialArea) :
      specialAreasModel.defaultSpecialArea();
    state['areanameError'] = ''; // error text to be displayed in TextInput of areaName
    state['areanumberError'] = ''; // error text to be displayed in TextInput of areaNumber
    state['error'] = '';
    return state;
  }

  onDisconnectionNotificationChanged(e) {
    const state = {};
    const isEnabled = e.target.checked;
    state[e.target.name] = isEnabled;
    if (!isEnabled && !this.state.contactEmail) {
      state['emailError'] = '';
    }
    this.setState(state);
  }

  validateAreaName(areaName = this.state.areaName) {
    return this.validateRequiredField(areaName, i18next.t('specialareas.attrs.areaName'), 'areanameError');
  }

  validateAreaNumber(areaNumber = this.state.areaNumber) {
    if (areaNumber) {
      if (isValidSpecialArea(areaNumber)) {
        this.setState({areanumberError: ''});
        return true;
      } else {
        this.setState({
          areanumberError: i18next.t('errors.invalid_format', {
            field: i18next.t('specialareas.attrs.areaNumber'),
          }),
        });
        return false;
      }
    } else {
      this.setState({
        areanumberError: i18next.t('errors.is_required', {
          field: i18next.t('specialareas.attrs.areaNumber'),
        }),
      });
      return false;
    }
  }

  handleError(errorMessage) {
    this.setState({
      error: errorMessage,
    });
  }

  render() {
    const modalTitle = this.state.id ?
    i18next.t('specialareas.edit', {
      id: this.state.areaName,
    }) : i18next.t('specialareas.create_new');

    return (
      <ModalDialog modalTitle={modalTitle}
        dialogId={this.props.dialogId}
        onDialogHide={blockUtils.unblockUI}
        onDialogShown={this.onFormShow}
        onSubmit={this.sendForm}>
        <Form>
          <ErrorBox errorMessage={getErrorDescription(this.state.error)}/>

          <TextInput fieldId="areaNumber"
            labelText={i18next.t('specialareas.attrs.areaNumber')}
            isRequired={true}
            error={this.state.areanumberError}
            validate={this.validateAreaNumber}
            onChange={this.onChange}
            value={this.state.areaNumber}/>

          <TextInput fieldId="areaName"
            labelText={i18next.t('specialareas.attrs.areaName')}
            isRequired={true}
            error={this.state.areanameError}
            validate={this.validateAreaName}
            onChange={this.onChange}
            value={this.state.areaName}/>
        </Form>
      </ModalDialog>
    );
  }
}

// parameters to this form
SpecialareasForm.propTypes = {
  dialogId: PropTypes.string,
  presentedSiteId: PropTypes.string,
  presentedSpecialArea: PropTypes.object,
  error: PropTypes.any,
};

SpecialareasForm.defaultProps = {};

export default SpecialareasForm;
