/**
 * @author Tomasz Czura (9/1/16)
 */
import * as types from '../actions/action-types';

const initialState = {
  developers: [],
  presentedDeveloper: {},
  getDevelopersError: null,
  getDeveloperError: null,
  createDeveloperError: null,
  updateDeveloperError: null,
  destroyDeveloperError: null,
};

const developerReducer = function(state = initialState, action) {
  let presentedDeveloper;
  switch (action.type) {
    case types.NEW_DEVELOPER_SUCCESS:
    {
      const newDevelopers = state.developers.concat([action.developer]);
      return Object.assign({}, state, {developers: newDevelopers, createDeveloperError: null});
    }
    case types.NEW_DEVELOPER_ERROR:
      return Object.assign({}, state, {
        createDeveloperError: action.error,
      });
    case types.GET_DEVELOPERS_SUCCESS:
      return Object.assign({}, state, {
        developers: action.developers,
        getDevelopersError: null,
      });
    case types.GET_DEVELOPERS_ERROR:
      return Object.assign({}, state, {
        developers: [],
        getDevelopersError: action.error,
      });
    case types.GET_DEVELOPER_SUCCESS:
      return Object.assign({}, state, {
        presentedDeveloper: action.presentedDeveloper,
        getDeveloperError: null,
      });
    case types.GET_DEVELOPER_ERROR:
      return Object.assign({}, state, {
        presentedDeveloper: {},
        getDeveloperError: action.error,
      });
    case types.UPDATE_DEVELOPER_SUCCESS:
    {
      const newDevs = state.developers.map(
          (dev) => dev.id == action.presentedDeveloper.id ?
        Object.assign({}, action.presentedDeveloper) : Object.assign({}, dev),
      );
      return Object.assign({}, state, {
        developers: newDevs,
        presentedDeveloper: action.presentedDeveloper,
        updateDeveloperError: null,
      });
    }
    case types.UPDATE_DEVELOPER_ERROR:
      return Object.assign({}, state, {
        updateDeveloperError: action.error,
      });
    case types.DELETE_DEVELOPER_SUCCESS:
    {
      const newDevs = state.developers.filter((dev) => dev.id != action.developerId);
      return Object.assign({}, state, {
        developers: newDevs,
        destroyDeveloperError: null,
        presentedSite: {},
      });
    }
    case types.DELETE_DEVELOPER_ERROR:
      return Object.assign({}, state, {
        destroyDeveloperError: action.error,
      });
    case types.PMS_TOKEN_REVOKED_SUCCESS: {
      presentedDeveloper = Object.assign({}, state.presentedDeveloper);
      presentedDeveloper.pmsToken = {};
      return Object.assign({}, state, {
        presentedDeveloper: presentedDeveloper,
      });
    }
    case types.GUEST_TOKEN_REVOKED_SUCCESS: {
      presentedDeveloper = Object.assign({}, state.presentedDeveloper);
      presentedDeveloper.guestToken = {};
      return Object.assign({}, state, {
        presentedDeveloper: presentedDeveloper,
      });
    }
    case types.PMS_TOKEN_REGENERATED_SUCCESS: {
      presentedDeveloper = Object.assign({}, state.presentedDeveloper);
      presentedDeveloper.pmsToken = action.token;
      return Object.assign({}, state, {
        presentedDeveloper: presentedDeveloper,
      });
    }
    case types.GUEST_TOKEN_REGENERATED_SUCCESS: {
      presentedDeveloper = Object.assign({}, state.presentedDeveloper);
      presentedDeveloper.guestToken = action.token;
      return Object.assign({}, state, {
        presentedDeveloper: presentedDeveloper,
      });
    }
  }
  return state;
};

export default developerReducer;
