import React, {Component} from 'react';
import PropTypes from 'prop-types';
import VersionChange from './VersionChange';

class VersionChanges extends Component {
  render() {
    return (
      <div>
        {this.props.versionChanges.map((versionChange) => {
          return (
            <VersionChange
              versionChange={versionChange}
              key={versionChange.id}
              editButtonCallback={this.props.editButtonCallback}
              deleteButtonCallback={this.props.deleteButtonCallback}
            />
          );
        })}
      </div>
    );
  }
}

VersionChanges.propTypes = {
  versionChanges: PropTypes.array,
  editButtonCallback: PropTypes.func,
  deleteButtonCallback: PropTypes.func,
};

VersionChanges.defaultProps = {};

export default VersionChanges;
