import FormComponent from '../utils/FormComponent';
import * as user from '../../model/user';
import * as usersApi from '../../api/users-api';
import * as blockUtils from '../../utils/BlockUtils';
import i18next from 'i18next';
import ModalDialog from '../utils/ModalDialog';
import Form from '../utils/Form';
import ErrorBox from '../utils/ErrorBox';
import {getErrorDescription} from '../../utils/view-utils';
import TextInput from '../utils/TextInput';
import SelectInput from '../utils/SelectInput';
import PropTypes from 'prop-types';
import React from 'react';
import Multiselect from '../utils/Multiselect';

class RegisterNewUserForm extends FormComponent {
  constructor(props) {
    super(props);

    this.sendForm = this.sendForm.bind(this);
    this.onSitesChange = this.onSitesChange.bind(this);
  }

  initialState() {
    return this.props.availableRoles && this.props.sites ?
      user.defaultRegisterNewUser(this.props.availableRoles, this.props.sites) : user.defaultRegisterNewUser();
  }

  sendForm() {
    const validEmail = this.validateEmail();

    if (validEmail) {
      usersApi.registerNewUser(this.state).then((response) => {
        blockUtils.unblockUI();
        if (response.type === 'NEW_USER_ERROR') {
          this.handleError(response.error);
        } else {
          window.showAlert(i18next.t('success'), i18next.t('users.successful_registration'));
          $(`#${this.props.dialogId}`).modal('hide');
        }
      });
      blockUtils.blockUI();
    }
  }

  validateEmail(email = this.state.email) {
    if (email) {
      this.setState({emailError: ''});
      return true;
    } else {
      this.setState({emailError: i18next.t('errors.is_required', {field: i18next.t('users.attrs.email')})});
      return false;
    }
  }

  handleError(error) {
    this.setState({
      error: error,
    });
  }

  mapAvailableRolesToObjects() {
    return this.state.availableRoles.map((role) => {
      return {'id': role, 'name': i18next.t([`users.roles.${role}`])};
    });
  }

  onSitesChange(selectedSites) {
    const state = {};
    if (!selectedSites) {
      selectedSites = [];
    } else {
      selectedSites = selectedSites.filter((e) => {
        return e !== undefined;
      });
    }
    state['siteIds'] = selectedSites;
    this.setState(state);
  }

  render() {
    const modalTitle = i18next.t('users.register_new_user');

    return (
      <ModalDialog modalTitle={modalTitle} dialogId={this.props.dialogId} onDialogHide={blockUtils.unblockUI}
        onDialogShown={this.onFormShow} onSubmit={this.sendForm}>

        <Form>
          <ErrorBox errorMessage={getErrorDescription(this.state.error)} />

          <TextInput fieldId="email" labelText={i18next.t('users.attrs.email')} isRequired={true}
            error={this.state.emailError}
            onChange={this.onChange} value={this.state.email} />

          <SelectInput fieldId="language" labelText={i18next.t('users.attrs.language')} selected={this.state.language}
            onChange={this.onChange} values={user.UserLanguages} />

          {this.state.availableRoles.length !== 0 ?
          <SelectInput fieldId="role" labelText={i18next.t('users.attrs.role')}
            onChange={this.onChange}
            values={this.mapAvailableRolesToObjects()} selected={this.state.role} /> :
          null}

          <Multiselect fieldId="siteIds" labelText={i18next.t('sites.name', {count: 2})}
            selected={this.state.siteIds}
            selectableHeader={i18next.t('developers.attrs.available_sites')}
            selectionHeader={i18next.t('developers.attrs.access_to')}
            values={this.state.sites} onChange={this.onSitesChange}
            available={this.state.availableSites} />
        </Form>
      </ModalDialog>
    );
  }
}

RegisterNewUserForm.propTypes = {
  dialogId: PropTypes.string,
  presentedUser: PropTypes.object,
};

RegisterNewUserForm.defaultProps = {};

export default RegisterNewUserForm;
