/**
 * @author Tomasz Czura (11/18/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

class LogDetails extends Component {
  componentDidMount() {

  }

  render() {
    return (
      <div>
        {this.props.log.content ? this.props.log.content.split('\n').map(function(item, index) {
          return (
            <span key={index}>{item}<br/></span>
          );
        }) : null}
      </div>
    );
  }
}

LogDetails.propTypes = {
  log: PropTypes.object,
};
LogDetails.defaultProps = {};

export default LogDetails;
