/**
 * @author Tomasz Czura (9/1/16)
 */

import axios from 'axios';
import store, {nameOfSite} from '../store';
import * as actions from '../actions/developers-actions';
import * as endpoints from './endpoints';
import * as devModel from './../model/developer';

export function getDevelopers() {
  return axios.get(endpoints.DEVELOPERS, endpoints.headers())
      .then((response) => {
        const developers = response.data.map( (d) => devModel.toLocal(d));
        store.dispatch(actions.getDevelopersSuccess(developers));
      })
      .catch((error) => {
        endpoints.handleUnauthorizedError(error, () => {
          store.dispatch(actions.getDevelopersError(error));
        });
      });
}

export function createDeveloper(developer, onSuccess) {
  const data = devModel.toNetwork(developer);

  return axios.post(endpoints.DEVELOPERS, data, endpoints.headers())
      .then((response) => {
        const localDev = devModel.toLocal(response.data);
        store.dispatch(actions.createDeveloperSuccess(localDev));
        if (onSuccess) onSuccess(localDev);
      })
      .catch((error) => {
        store.dispatch(actions.createDeveloperError(error));
      });
}

export function updateDeveloper(developer) {
  const data = devModel.toNetwork(developer);

  return axios.put(`${endpoints.DEVELOPERS}/${developer.id}`, data, endpoints.headers())
      .then((response) => {
        if (developer.siteIds) {
          developer.sites = developer.siteIds.map((siteId) => {
            return {
              id: siteId,
              name: nameOfSite(siteId),
            };
          });
        } else {
          developer.sites = [];
        }
        store.dispatch(actions.updateDeveloperSuccess(developer));
      })
      .catch((error) => {
        store.dispatch(actions.updateDeveloperError(error));
      });
}

export function destroyDeveloper(developerId) {
  return axios.delete(`${endpoints.DEVELOPERS}/${developerId}`, endpoints.headers())
      .then((response) => {
        store.dispatch(actions.destroyDeveloperSuccess(developerId));
      })
      .catch((error) => {
        store.dispatch(actions.destroyDeveloperError(error));
      });
}

export function getDeveloper(developerId) {
  let developer = store.getState().developersState.developers.filter((dev) => dev.id == developerId)[0];
  if (developer) {
    store.dispatch(actions.getDeveloperSuccess(developer));
  } else {
    return axios.get(`${endpoints.DEVELOPERS}/${developerId}`, endpoints.headers())
        .then((response) => {
          developer = devModel.toLocal(response.data);
          store.dispatch(actions.getDeveloperSuccess(developer));
        })
        .catch((error) => {
          store.dispatch(actions.getDeveloperError(error));
        });
  }
}

export function resetPassword(developer, onSuccess, onError) {
  return axios.post(`${endpoints.RESET_DEV_PASSWORD(developer.id)}`, {}, endpoints.headers())
      .then((response) => {
        developer.password = response.data.password;
        onSuccess(developer);
      })
      .catch((error) => {
        onError(error);
      });
}

export function regeneratePMSToken(developer, onSuccess, onError) {
  const data = {
    pmsSecret: developer.email,
  };

  return axios.put(`${endpoints.DEVELOPERS}/${developer.id}`, data, endpoints.headers())
      .then((response) => {
        const updatedDev = devModel.toLocal(response.data);
        store.dispatch(actions.regeneratePMSTokenSuccess(updatedDev.pmsToken));
        onSuccess();
      })
      .catch((error) => {
        store.dispatch(actions.regeneratePMSTokenError(error));
        onError();
      });
}

export function regenerateGuestToken(developer, onSuccess, onError) {
  const data = {
    mobileSecret: developer.email,
  };

  return axios.put(`${endpoints.DEVELOPERS}/${developer.id}`, data, endpoints.headers())
      .then((response) => {
        const updatedDev = devModel.toLocal(response.data);
        store.dispatch(actions.regenerateGuestTokenSuccess(updatedDev.guestToken));
        onSuccess();
      })
      .catch((error) => {
        store.dispatch(actions.regenerateGuestTokenError(error));
        onError();
      });
}

export function revokePMSToken(developer, onSuccess, onError) {
  const data = {
    pmsSecret: null,
  };

  return axios.put(`${endpoints.DEVELOPERS}/${developer.id}`, data, endpoints.headers())
      .then((response) => {
        store.dispatch(actions.revokePMSTokenSuccess());
        onSuccess();
      })
      .catch((error) => {
        store.dispatch(actions.revokePMSTokenError(error));
        onError();
      });
}

export function revokeGuestToken(developer, onSuccess, onError) {
  const data = {
    mobileSecret: null,
  };

  return axios.put(`${endpoints.DEVELOPERS}/${developer.id}`, data, endpoints.headers())
      .then((response) => {
        store.dispatch(actions.revokeGuestTokenSuccess());
        onSuccess();
      })
      .catch((error) => {
        store.dispatch(actions.revokeGuestTokenError(error));
        onError();
      });
}
