/**
 * @author Tomasz Czura (9/7/16)
 */
import * as types from './action-types';

export function getFilesSuccess(files) {
  return {
    type: types.GET_FILES_SUCCESS,
    files,
  };
}

export function getFilesError(error) {
  return {
    type: types.GET_FILES_ERROR,
    error,
  };
}

export function createFileSuccess(file) {
  return {
    type: types.NEW_FILE_SUCCESS,
    file,
  };
}

export function createFileError(error) {
  return {
    type: types.NEW_FILE_ERROR,
    error,
  };
}

export function updateFileSuccess(presentedFile) {
  return {
    type: types.UPDATE_FILE_SUCCESS,
    presentedFile,
  };
}

export function updateFileError(error) {
  return {
    type: types.UPDATE_FILE_ERROR,
    error,
  };
}

export function destroyFileSuccess(fileId) {
  return {
    type: types.DELETE_FILE_SUCCESS,
    fileId,
  };
}

export function destroyFileError(error) {
  return {
    type: types.DELETE_FILE_ERROR,
    error,
  };
}
