/**
 * @author Tomasz Czura (11/8/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import WhitePortlet from '../utils/WhitePortlet';
import SpecialareasList from './../specialareas/SpecialareasList';
import i18next from 'i18next';
import {connect} from 'react-redux';
import * as sitesApi from './../../api/sites-api';
import ErrorBox from './../utils/ErrorBox';
import {getErrorDescription} from './../../utils/view-utils';

class SiteSpecialAreasListContainer extends Component {
  componentDidMount() {
    // this.props.siteId = this.props.params.siteId
    sitesApi.getSiteSpecialareas(this.props.params.siteId);
  }

  render() {
    return (
      <WhitePortlet caption={i18next.t('specialareas.name', {count: 2})} icon="icon-grid">
        <ErrorBox errorMessage={getErrorDescription(this.props.getSpecialareasError)}/>
        <SpecialareasList specialareas={this.props.specialareas} siteId={this.props.params.siteId}/>
      </WhitePortlet>
    );
  }
}

SiteSpecialAreasListContainer.propTypes = {
  params: PropTypes.any,
  site: PropTypes.object,
  specialareas: PropTypes.array,
  getSpecialareasError: PropTypes.object,
  updateSpecialAreaError: PropTypes.object,
};
SiteSpecialAreasListContainer.defaultProps = {};

const mapStateToProps = function(store) {
  // var currentSiteId = store.sitesState.currentSiteId == -1 ? selectedSiteId() : store.sitesState.currentSiteId;
  return {
    specialareas: store.specialareasState.specialareas,
    getSpecialareasError: store.specialareasState.getSpecialareasError,
    updateSpecialAreaError: store.specialareasState.updateSpecialAreaError, // ,
    // siteId: currentSiteId
  };
};

export default connect(mapStateToProps)(SiteSpecialAreasListContainer);
