/**
 * @author Tomasz Czura (9/7/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DataTable from './../utils/DataTable';
import i18next from 'i18next';
import * as viewUtils from './../../utils/view-utils';
import TableActions from './../utils/TableActions';
import TableAction from './../utils/actions/TableAction';
import EditAction from './../utils/actions/EditAction';
import DestroyAction from './../utils/actions/DestroyAction';
import * as blockUtils from './../../utils/BlockUtils';
import * as filesApi from './../../api/files-api';
import FileForm from './FileForm';
import CreateNewButton from '../CreateNewButton';
import {onRowClick} from '../redux_data_table/ReduxDataTable';
import * as rights from '../../model/rights';

class FilesList extends Component {
  constructor(props) {
    super(props);

    this.filesTableId = 'files_table';
    this.formDialogId = 'fileFormDialog';

    this.destroyFile = this.destroyFile.bind(this);
    this.showForm = this.showForm.bind(this);
  }

  destroyFile(fileId) {
    blockUtils.blockUI(`#${this.filesTableId}`);
    filesApi.destroyFile(fileId);
  }

  componentDidUpdate() {
    this.handleError();
  }

  showForm(file) {
    const props = {
      dialogId: this.formDialogId,
      error: this.props.createFileError || this.props.updateFileError,
      presentedFile: file,
    };
    this.modalDialog = viewUtils.showModal(FileForm, this.formDialogId, props);
  }

  handleError() {
    const error = this.props.createFileError || this.props.updateFileError;
    if (error) {
      if (this.modalDialog) {
        this.modalDialog.handleError(error);
      }
      blockUtils.unblockUI();
    } else {
      $(`#${this.formDialogId}`).modal('hide');
    }
  }

  downloadFile(file) {
    blockUtils.blockUI();
    filesApi.getFile(file.id, (downloaded) => {
      blockUtils.unblockUI();
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(downloaded);
      link.download = file.name;
      document.body.appendChild(link);
      link.click();

      setTimeout(function() {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      }, 100);
    }, (errStatus) => {
      blockUtils.unblockUI();
      if (errStatus === 404) {
        window.showAlert(i18next.t('error'), i18next.t('api_errors.file_not_found'));
      } else {
        window.showAlert(i18next.t('error'), `${i18next.t('api_errors.unknown')}: ${errStatus}`);
      }
    });
  }

  render() {
    return (
      <div className="table-container">
        {rights.currentUserHasRight(rights.FILE_ADD_PRIVILEGE) ?
          (<CreateNewButton callBack={() => this.showForm()} />) :
          null
        }
        <DataTable tableId={this.filesTableId} notSortable={[4]}>
          <thead>
            <tr>
              <th>ID</th>
              <th>{i18next.t('files.attrs.name')}</th>
              <th>{i18next.t('files.attrs.version')}</th>
              <th>{i18next.t('files.attrs.uploaded_time')}</th>
              <th>{i18next.t('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.files.map((file) => {
              return (
                <tr onClick={() => onRowClick(file.id)} key={file.id}>
                  <td>{file.id}</td>
                  <td>{file.name}</td>
                  <td>{file.version}</td>
                  <td>{viewUtils.toYYYYMMDDHMS(file.createdDate)}</td>
                  <td>
                    <TableActions identifier={file.id}>
                      <EditAction
                        onEdit={() => this.showForm(file)}
                        condition={rights.currentUserHasRight(rights.FILE_EDIT_PRIVILEGE)}
                      />
                      <TableAction icon="icon-cloud-download"
                        text={i18next.t('files.actions.download')}
                        action={() => this.downloadFile(file)}
                      />
                      <DestroyAction
                        destroyElement={() => this.destroyFile(file.id)}
                        condition={rights.currentUserHasRight(rights.FILE_DELETE_PRIVILEGE)}
                      />
                    </TableActions>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </DataTable>
      </div>
    );
  }
}

FilesList.propTypes = {
  files: PropTypes.array,
  createFileError: PropTypes.object,
  updateFileError: PropTypes.object,
};
FilesList.defaultProps = {};

export default FilesList;
