/**
 * @author TomaszCzura ({}
 */

import axios from 'axios';
import store from '../store';
import * as actions from '../actions/keys-actions';
import * as endpoints from './endpoints';

export function destroyKey(keyId) {
  return axios.delete(`${endpoints.KEYS}/${keyId}`, endpoints.headers())
      .then((response) => {
        store.dispatch(actions.deleteKeySuccess(keyId));
        return response;
      })
      .catch(function(error) {
        store.dispatch(actions.deleteKeyError(error));
      });
}
