/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as blockUtils from './../../utils/BlockUtils';
import i18next from 'i18next';
import LoginInput from './LoginInput';
import {withRouter} from 'react-router';
import * as auth from './../../utils/auth';
import {sendEmail, sendNewPassword} from '../../api/auth-api';
import ErrorAlert from './../utils/ErrorAlert';
import CookieConsent from './../utils/CookieConsent';
import * as viewUtils from './../../utils/view-utils';
import {ApiErrors} from '../../utils/errors';
import RegisterNewUserForm from '../users/RegisterNewUserForm';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.userFormDialogId = 'userFormDialog';

    this.state = {
      usernameError: null,
      passwordError: null,
      rememberMe: false,

      passwordForgotten: false,
      passwordChange: false,
      emailError: null,
      emailSuccess: null,
      password2Error: null,
      newPasswordError: null,
      newPasswordSuccess: null,
      urlParam: null,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.loginUser = this.loginUser.bind(this);
    this.validateUsername = this.validateUsername.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.onRememberChange = this.onRememberChange.bind(this);
  }

  componentDidMount() {
    this.initBackground();
    this.setPasswordForgotten();
  }

  setPasswordForgotten() {
    const urlParam = new URLSearchParams(window.location.search).get('passwordForgotten');
    if (urlParam) {
      return this.setState({passwordChange: true, urlParam: urlParam});
    }
  }

  initBackground() {
    $('#loginContainer').backstretch([
      '/assets/metronic/pages/media/bg/1.jpg',
      '/assets/metronic/pages/media/bg/2.jpg',
      '/assets/metronic/pages/media/bg/3.jpg',
      '/assets/metronic/pages/media/bg/4.jpg',
    ], {
      fade: 1000,
      duration: 8000,
    },
    );
  }

  componentWillUnmount() {
    $('#loginContainer').backstretch('destroy');
  }

  onSubmit(e) {
    e.preventDefault();
    let isValid;
    if (this.state.username && this.state.password) {
      isValid = this.validateUsername(this.state.username);
      isValid = this.validatePassword(this.state.password) && isValid;
      if (isValid) {
        this.loginUser();
      }
    } else if (this.state.email) {
      isValid = this.validateEmail(this.state.email);
      if (isValid) {
        this.sendEmail();
      }
    } else if (this.state.password && this.state.password2) {
      isValid = this.validatePassword(this.state.password);
      isValid = this.validatePassword(this.state.password2) && isValid;
      if (isValid) {
        if (this.state.password !== this.state.password2) {
          return this.setState({passwordCompareError: i18next.t('login.password_compare_error')});
        }
        this.sendNewPassword();
      }
    }
  }

  loginUser() {
    blockUtils.blockUI('#loginContent');

    auth.login(this.state.username, this.state.password, false, (response) => {
      const loggedIn = response.authenticated;

      blockUtils.unblockUI('#loginContent');

      if (!loggedIn) {
        return this.setState({loginError: viewUtils.getErrorDescription(response.error)});
      }

      const {location} = this.props;

      if (location.state && location.state.nextPathname) {
        this.props.router.replace(location.state.nextPathname);
      } else {
        this.props.router.replace('/');
      }
    });
  }

  sendEmail() {
    blockUtils.blockUI('#loginContent');

    sendEmail(this.state.email, (response) => {
      if (response == null) {
        return this.setState({emailSuccess: null, emailError: i18next.t('api_errors.service_unavailable')});
      }
      if (response.status !== 200) {
        if (response.data.errorCode === ApiErrors.RESOURCE_NOT_FOUND) {
          return this.setState({emailSuccess: null,
            emailError: i18next.t('login.password_forgotten_email_user_not_found')});
        }
        if (response.data.errorCode === ApiErrors.EMAIL_SERVICE_UNAVAILABLE) {
          return this.setState({emailSuccess: null,
            emailError: i18next.t('login.password_forgotten_email_service_unavailable')});
        }
        return this.setState({emailSuccess: null, emailError: response.data.errorMessage});
      }
      return this.setState({emailSuccess: i18next.t('login.password_forgotten_email_success'), emailError: null});
    });

    blockUtils.unblockUI('#loginContent');
  }

  sendNewPassword() {
    blockUtils.blockUI('#loginContent');

    sendNewPassword(this.state.password, this.state.urlParam, (response) => {
      if (response == null) {
        return this.setState({newPasswordSuccess: null, newPasswordError: i18next.t('api_errors.service_unavailable')});
      }
      if (response.status !== 200) {
        if (response.data.errorCode === ApiErrors.RESOURCE_NOT_FOUND) {
          return this.setState({newPasswordSuccess: null,
            newPasswordError: i18next.t('login.password_forgotten_user_not_found')});
        }
        return this.setState({emailSuccess: null, emailError: response.data.errorMessage});
      }
      return this.setState({newPasswordSuccess: i18next.t('login.new_password_success'), newPasswordError: null});
    });

    blockUtils.unblockUI('#loginContent');
  }

  onChange(e) {
    const state = {};
    state[e.target.name] = e.target.value;
    this.setState(state);
  }

  onRememberChange(e) {
    const state = {};
    state[e.target.name] = e.target.checked;
    this.setState(state);
  }

  validateUsername(username) {
    if (username) {
      this.setState({
        usernameError: '',
      });
      return true;
    } else {
      this.setState({
        usernameError: i18next.t('login.username_required'),
      });
      return false;
    }
  }

  validatePassword(password) {
    if (password) {
      this.setState({
        passwordError: '',
      });
      return true;
    } else {
      this.setState({
        passwordError: i18next.t('login.password_required'),
      });
      return false;
    }
  }

  validateEmail(email) {
    if (email) {
      this.setState({
        emailError: '',
      });
      return true;
    } else {
      this.setState({
        emailError: i18next.t('login.password_forgotten_email_required'),
      });
      return false;
    }
  }

  componentDidUpdate() {
    if (this.props.updateUserError) {
      if (this.modalDialog) {
        this.modalDialog.handleError(this.props.updateUserError);
      }
      blockUtils.unblockUI();
    } else {
      $(`#${this.userFormDialogId}`).modal('hide');
    }
  }

  showUserForm() {
    const props = {
      dialogId: this.userFormDialogId,
    };
    this.modalDialog = viewUtils.showModal(RegisterNewUserForm, this.userFormDialogId, props);
  }

  render() {
    return (
      <div className="login" id="loginContainer">
        <CookieConsent/>
        <div className="logo">
          <a href='/'>
            <img src="/assets/images/hafele_icon.png" alt="" className="logo-default"/>
          </a>
        </div>
        <div className="content" id="loginContent">
          {!this.state.passwordChange ?
          !this.state.passwordForgotten ?
          <form className="login-form" onSubmit={this.onSubmit}>
            <h3 className="form-title">{i18next.t('login.title')}</h3>
            <ErrorAlert error={this.state.loginError}/>
            <LoginInput title={i18next.t('login.login')} icon="fa-user" error={this.state.usernameError}
              name="username" onChange={this.onChange} validate={this.validateUsername}/>
            <LoginInput title={i18next.t('login.password')}
              icon="fa-lock"
              name="password"
              error={this.state.passwordError}
              onChange={this.onChange}
              validate={this.validatePassword} isSecret={true}
            />
            <div className="form-actions">
              <label className="mt-checkbox-outline"/>
              <button type="submit" className="btn green pull-right"> {i18next.t('login.send_login')} </button>
            </div>
            <div>
              <a onClick={() => this.setState({passwordForgotten: true, password: null, username: null})}>
                {i18next.t('login.password_forgotten_link')}</a>
            </div>
            <div>
              <a onClick={() => this.showUserForm()}>
                {i18next.t('users.register_new_user')}</a>
            </div>
          </form> :
          <form onSubmit={this.onSubmit}>
            <h3 className="form-title"> {i18next.t('login.password_forgotten')} </h3>
            <LoginInput title={i18next.t('login.password_forgotten_email')}
              icon="fa-user"
              success={this.state.emailSuccess}
              error={this.state.emailError} name="email"
              onChange={this.onChange}
              validate={this.validateEmail}/>
            <button type="submit" className="btn green pull-right">
              {i18next.t('login.password_forgotten_send')} </button>
            <p> {i18next.t('login.password_forgotten_back')}&nbsp;
              <a onClick={() => this.setState({passwordForgotten: false, email: null})}> {i18next.t('login.login')} </a>
            </p>
          </form> :
          <form onSubmit={this.onSubmit}>
            <h3 className="form-title"> {i18next.t('login.password_forgotten')} </h3>
            <ErrorAlert error={this.state.newPasswordError}/>
            <LoginInput title={i18next.t('login.password')}
              icon="fa-lock"
              name="password"
              error={this.state.passwordError}
              onChange={this.onChange}
              validate={this.validatePassword} isSecret={true}
            />
            <LoginInput title={i18next.t('login.password2')}
              icon="fa-lock"
              name="password2"
              error={this.state.password2Error}
              success={this.state.newPasswordSuccess}
              onChange={this.onChange}
              validate={this.validatePassword} isSecret={true}
            />
            <button type="submit" className="btn green pull-right">
              {i18next.t('login.password_forgotten_send')} </button>
            <p> {i18next.t('login.password_forgotten_back')}&nbsp;
              <a href="/"> {i18next.t('login.login')} </a>
            </p>
          </form>
          }
        </div>
        <div className="copyright"> 2016 - {new Date().getFullYear()} &copy; Häfele</div>
      </div>
    );
  }
}

LoginForm.propTypes = {
  router: PropTypes.any,
  location: PropTypes.object,
  updateUserError: PropTypes.object,
};
LoginForm.defaultProps = {};

export default withRouter(LoginForm);
