/**
 * @author TomaszCzura ({}
 */

import axios from 'axios';
import store from '../store';
import * as endpoints from './endpoints';
import * as siteModel from './../model/site';
import * as specialAreaModel from './../model/specialarea';
import * as actions from '../actions/sites-actions';

export function getSites() {
  return axios.get(endpoints.SITES, endpoints.headers())
      .then((response) => {
        store.dispatch(actions.getSitesSuccess(response.data));
        return response;
      })
      .catch(function(error) {
        endpoints.handleUnauthorizedError(error, () => {
          store.dispatch(actions.getSitesError(error));
        });
      });
}

export function createSite(site) {
  const data = siteModel.toNetwork(site);

  return axios.post(endpoints.SITES, data, endpoints.headers())
      .then((response) => {
        data.id = response.data.id;
        store.dispatch(actions.createSiteSuccess(data));
        return response;
      })
      .catch(function(error) {
        store.dispatch(actions.createSiteError(error));
      });
}

export function updateSite(site) {
  const data = siteModel.toNetwork(site);

  return axios.put(`${endpoints.SITES}/${site.id}`, data, endpoints.headers())
      .then((response) => {
        store.dispatch(actions.updateSiteSuccess(site));
        return response;
      })
      .catch(function(error) {
        store.dispatch(actions.updateSiteError(site, error));
      });
}

export function getSite(siteId) {
  const site = store.getState().sitesState.sites.filter((site) => site.id === siteId)[0];
  if (site) {
    return store.dispatch(actions.getSiteSuccess(site));
  } else {
    return axios.get(`${endpoints.SITES}/${siteId}`, endpoints.headers())
        .then((response) => {
          store.dispatch(actions.getSiteSuccess(response.data));
          return response;
        })
        .catch(function(error) {
          store.dispatch(actions.getSiteError(error));
        });
  }
}

export function destroySite(siteId) {
  return axios.delete(`${endpoints.SITES}/${siteId}`, endpoints.headers())
      .then((response) => {
        store.dispatch(actions.deleteSiteSuccess(siteId));
        return response;
      })
      .catch(function(error) {
        store.dispatch(actions.deleteSiteError(error));
      });
}

export function destroySpecialarea(siteId, specialAreaId) {
  return axios.delete(`${endpoints.SITES}/${siteId}/specialareas/${specialAreaId}`, endpoints.headers())
      .then((response) => {
        store.dispatch(actions.deleteSpecialareaSuccess(siteId, specialAreaId));
        return response;
      })
      .catch(function(error) {
        store.dispatch(actions.deleteSpecialareaError(error));
      });
}

export function getSiteRooms(siteId) {
  return axios.get(endpoints.SITE_ROOMS(siteId), endpoints.headers())
      .then((response) => {
        store.dispatch(actions.getSiteRoomsSuccess(response.data));
      })
      .catch((error) => {
        store.dispatch(actions.getSiteRoomsError(error));
      });
}

export function getSiteSpecialareas(siteId, onSuccess = () => {}) {
  return axios.get(endpoints.SITE_SPECIALAREAS(siteId), endpoints.headers())
      .then((response) => {
        onSuccess(response.data);
        store.dispatch(actions.getSiteSpecialareasSuccess(response.data));
      })
      .catch((error) => {
        store.dispatch(actions.getSiteSpecialareasError(error));
      });
}


export function createSiteSpecialarea(siteId, specialArea) {
  const data = specialAreaModel.toNetwork(specialArea);

  return axios.post(endpoints.SITE_SPECIALAREAS(siteId), data, endpoints.headers())
      .then((response) => {
        data.id = response.data.id;
        store.dispatch(actions.createSpecialAreaSuccess(siteId, data));
        return response;
      })
      .catch(function(error) {
        store.dispatch(actions.createSpecialAreaError(siteId, specialArea, error));
      });
}

export function updateSiteSpecialarea(siteId, specialArea) {
  const data = specialAreaModel.toNetwork(specialArea);

  return axios.put(endpoints.SITE_SPECIALAREAS(siteId) + '/' + specialArea.id, data, endpoints.headers())
      .then((response) => {
        store.dispatch(actions.updateSpecialAreaSuccess(siteId, specialArea));
        return response;
      })
      .catch(function(error) {
        store.dispatch(actions.updateSpecialAreaError(siteId, specialArea, error));
      });
}
