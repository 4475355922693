/**
 * @author Tomasz Czura (11/10/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import WhitePortlet from '../utils/WhitePortlet';
import RoomsList from './RoomsList';
import i18next from 'i18next';
import {connect} from 'react-redux';
import * as sitesApi from './../../api/sites-api';
import ErrorBox from './../utils/ErrorBox';
import {getErrorDescription} from './../../utils/view-utils';
import {selectedSiteId} from './../../store';

class RoomsListContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      siteId: selectedSiteId(),
    };
  }

  componentDidMount() {
    const siteId = this.state.siteId;
    if (siteId > 0) {
      sitesApi.getSiteRooms(siteId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.siteId != this.state.siteId) {
      this.setState({
        siteId: nextProps.siteId,
      });
      if (nextProps.siteId > 0) {
        sitesApi.getSiteRooms(nextProps.siteId);
      }
    }
  }

  render() {
    return (
      <WhitePortlet caption={i18next.t('rooms.name', {count: 2})} icon="icon-grid">
        <ErrorBox errorMessage={getErrorDescription(this.props.getRoomsError)}/>
        {this.state.siteId > 0 ?
                    <RoomsList rooms={this.props.rooms}/> :
                    <ErrorBox errorMessage={i18next.t('rooms.errors.select_site')}/>
        }

      </WhitePortlet>
    );
  }
}

const mapStateToProps = function(store) {
  const currentSiteId = store.sitesState.currentSiteId == -1 ? selectedSiteId() : store.sitesState.currentSiteId;

  return {
    rooms: store.roomsState.rooms,
    getRoomsError: store.roomsState.getRoomsError,
    siteId: currentSiteId,
  };
};

RoomsListContainer.propTypes = {
  params: PropTypes.any,
  rooms: PropTypes.array,
  getRoomsError: PropTypes.object,
  siteId: PropTypes.number,
};
RoomsListContainer.defaultProps = {};

export default connect(mapStateToProps)(RoomsListContainer);
