/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';

class MenuItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <li>

        <Link to={this.props.route}
          className={location.pathname === this.props.route ? 'nav-item selected__selected' : 'nav-item'} >
          <i className={this.props.icon}/>
          <span className="title">{this.props.title}</span>
        </Link>
      </li>
    );
  }
}


MenuItem.propTypes = {
  route: PropTypes.string,
  isIndex: PropTypes.bool,
  icon: PropTypes.string,
  title: PropTypes.string,
  right: PropTypes.string,
};

MenuItem.defaultProps = {};

export default MenuItem;
