/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

class TextArea extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  onChange(e) {
    if (this.props.validate && this.props.validate(e.target.value)) {
      this.props.onChange(e);
    } else {
      this.props.onChange(e);
    }
  }

  handleBlur(e) {
    if (this.props.validate) {
      this.props.validate(e.target.value);
    }
  }

  render() {
    let required = '';
    if (this.props.isRequired) {
      required = <span className="required">*</span>;
    }
    return (
      <div className={`form-group form-md-line-input ${this.props.error ? 'has-error' : ''}`}>
        <label className="col-md-3 control-label" htmlFor={this.props.fieldId}>
          {this.props.labelText}
          {required}
          <br/>{this.props.children}
        </label>
        <div className="col-md-9">
          <textarea className="form-control" name={this.props.fieldId} rows="5"
            value={this.props.value} onChange={this.onChange} onBlur={this.handleBlur}/>
          <div className="form-control-focus"> </div>
        </div>
        <div className="col-md-9 col-md-offset-3">
          {this.props.error ? (<span className="error-block">{this.props.error}</span>) : null}
        </div>
      </div>
    );
  }
}

TextArea.propTypes = {
  labelText: PropTypes.string,
  fieldId: PropTypes.string,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  error: PropTypes.string,
  validate: PropTypes.func,
  children: PropTypes.object,
};
TextArea.defaultProps = {};

export default TextArea;
