/**
 * @author Tomasz Czura (11/10/16)
 */

import axios from 'axios';
import * as endpoints from './endpoints';
import store from '../store';
import * as actions from './../actions/billing-actions';
import * as billingModel from './../model/billing';

export function getBilling(siteId, year, month) {
  return axios.get(endpoints.GET_BILLING(siteId, year, month), endpoints.headers())
      .then((response) => {
        const data = response.data.map((b) => billingModel.toLocal(b));
        store.dispatch(actions.getBillingSuccess(year, month, data));
      })
      .catch((error) => {
        endpoints.handleUnauthorizedError(error, () => {
          store.dispatch(actions.getBillingError(error));
        });
      });
}
