/**
 * @author TomaszCzura ({}
 */
/* global App */
export function blockUI(element = null) {
  if (element) {
    App.blockUI({target: element, boxed: true, zIndex: 99999999});
  } else {
    App.blockUI({boxed: true, zIndex: 99999999});
  }
}

export function unblockUI(element = null) {
  if (element) {
    App.unblockUI(element);
  } else {
    App.unblockUI();
  }
}
