/**
 * @author Tomasz Czura (9/1/16)
 */
import * as types from './action-types';

export function getDevelopersSuccess(developers) {
  return {
    type: types.GET_DEVELOPERS_SUCCESS,
    developers,
  };
}

export function getDevelopersError(error) {
  return {
    type: types.GET_DEVELOPERS_ERROR,
    error,
  };
}

export function getDeveloperSuccess(presentedDeveloper) {
  return {
    type: types.GET_DEVELOPER_SUCCESS,
    presentedDeveloper,
  };
}

export function getDeveloperError(error) {
  return {
    type: types.GET_DEVELOPER_ERROR,
    error,
  };
}

export function createDeveloperSuccess(developer) {
  return {
    type: types.NEW_DEVELOPER_SUCCESS,
    developer,
  };
}

export function createDeveloperError(error) {
  return {
    type: types.NEW_DEVELOPER_ERROR,
    error,
  };
}

export function updateDeveloperSuccess(presentedDeveloper) {
  return {
    type: types.UPDATE_DEVELOPER_SUCCESS,
    presentedDeveloper,
  };
}

export function updateDeveloperError(error) {
  return {
    type: types.UPDATE_DEVELOPER_ERROR,
    error,
  };
}

export function destroyDeveloperSuccess(developerId) {
  return {
    type: types.DELETE_DEVELOPER_SUCCESS,
    developerId,
  };
}

export function destroyDeveloperError(error) {
  return {
    type: types.DELETE_DEVELOPER_ERROR,
    error,
  };
}

export function revokePMSTokenSuccess() {
  return {
    type: types.PMS_TOKEN_REVOKED_SUCCESS,
  };
}

export function revokePMSTokenError(error) {
  return {
    type: types.PMS_TOKEN_REVOKED_ERROR,
    error,
  };
}

export function revokeGuestTokenSuccess() {
  return {
    type: types.GUEST_TOKEN_REVOKED_SUCCESS,
  };
}

export function revokeGuestTokenError(error) {
  return {
    type: types.GUEST_TOKEN_REVOKED_ERROR,
    error,
  };
}

export function regeneratePMSTokenSuccess(token) {
  return {
    type: types.PMS_TOKEN_REGENERATED_SUCCESS,
    token,
  };
}

export function regeneratePMSTokenError(error) {
  return {
    type: types.PMS_TOKEN_REGENERATED_ERROR,
    error,
  };
}

export function regenerateGuestTokenSuccess(token) {
  return {
    type: types.GUEST_TOKEN_REGENERATED_SUCCESS,
    token,
  };
}

export function regenerateGuestTokenError(error) {
  return {
    type: types.GUEST_TOKEN_REGENERATED_ERROR,
    error,
  };
}
