/**
 * @author Tomasz Czura (9/7/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import TableAction from './TableAction';

class DestroyAction extends Component {
  constructor(props) {
    super(props);

    this.onDestroy = this.onDestroy.bind(this);
  }

  onDestroy() {
    confirm(() => this.props.destroyElement());
  }


  render() {
    return (
      <TableAction text={i18next.t('links.destroy')} action={this.onDestroy}
        condition={this.props.condition} icon="icon-trash"/>
    );
  }
}

DestroyAction.propTypes = {
  destroyElement: PropTypes.func,
  condition: PropTypes.bool,
};
DestroyAction.defaultProps = {};

export default DestroyAction;
