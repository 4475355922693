/**
 * @author TomaszCzura ({}
 */
import * as types from '../actions/action-types';

export function getUsersSuccess(users) {
  return {
    type: types.GET_USERS_SUCCESS,
    users,
  };
}

export function getUsersError(error) {
  return {
    type: types.GET_USERS_ERROR,
    error: error,
  };
}

export function getUserError(error) {
  return {
    type: types.GET_USER_ERROR,
    error,
  };
}

export function getUserSuccess(presentedUser) {
  return {
    type: types.GET_USER_SUCCESS,
    presentedUser,
  };
}

export function changeLanguage(language) {
  return {
    type: types.CHANGE_LANGUAGE,
    language,
  };
}

export function deleteUserSuccess(userId) {
  return {
    type: types.DELETE_USER_SUCCESS,
    userId,
  };
}

export function deleteUserError(error) {
  return {
    type: types.DELETE_USER_ERROR,
    error,
  };
}

export function createUserSuccess(user) {
  return {
    type: types.NEW_USER_SUCCESS,
    user,
  };
}

export function createUserError(error) {
  return {
    type: types.NEW_USER_ERROR,
    error,
  };
}

export function updateUserSuccess(presentedUser) {
  return {
    type: types.UPDATE_USER_SUCCESS,
    presentedUser,
  };
}

export function updateUserError(presentedUser, error) {
  return {
    type: types.UPDATE_USER_ERROR,
    error,
    presentedUser,
  };
}

export function getProfileSuccess(userProfile) {
  return {
    type: types.GET_PROFILE_SUCCESS,
    userProfile,
  };
}

export function getProfileError(error) {
  return {
    type: types.GET_PROFILE_ERROR,
    error,
  };
}
