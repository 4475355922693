import FormComponent from '../utils/FormComponent';
import * as blockUtils from '../../utils/BlockUtils';
import i18next from 'i18next';
import ModalDialog from '../utils/ModalDialog';
import Form from '../utils/Form';
import ErrorBox from '../utils/ErrorBox';
import * as viewUtils from '../../utils/view-utils';
import {getErrorDescription} from '../../utils/view-utils';
import PropTypes from 'prop-types';
import React from 'react';
import TextInput from '../utils/TextInput';
import SelectInput from '../utils/SelectInput';

const TEXT_KEY = 'changelog.attributes.';

class VersionChangeForm extends FormComponent {
  constructor(props) {
    super(props);

    this.sendForm = this.sendForm.bind(this);
  }

  initialState() {
    return {
      id: this.props.objectToEdit ? this.props.objectToEdit.id : -1,
      summary: this.props.objectToEdit ? this.props.objectToEdit.summary : '',
      header: this.props.objectToEdit ? this.props.objectToEdit.header : '',
      changeLogEntryType: this.props.objectToEdit ? this.props.objectToEdit.changeLogEntryType : 'NOT_VALID',
    };
  }

  sendForm() {
    if (this.validateAllFields('version-change-form', TEXT_KEY) === false) {
      return;
    }

    const data = {
      id: this.state.id,
      summary: this.state.summary,
      header: this.state.header,
      changeLogEntryType: this.state.changeLogEntryType,
    };

    this.props.submitForm(data);
  }

  handleError(error) {
    blockUtils.unblockUI();
    window.showAlert(i18next.t('error'), viewUtils.getErrorDescription(error));
  }

  getValuesForEntryType() {
    return [
      {'id': 'NOT_VALID', 'name': ''},
      {'id': 'BUG', 'name': i18next.t('changelog.attributes.bug')},
      {'id': 'FEATURE', 'name': i18next.t('changelog.attributes.feature')},
      {'id': 'OTHER', 'name': i18next.t('changelog.attributes.other')},
    ];
  }

  render() {
    const modalTitle = this.props.versionChange ?
      i18next.t('changelog.edit_version_change') :
      i18next.t('changelog.add_version_change');

    return (
      <ModalDialog
        customClass="version-change-form"
        modalTitle={modalTitle}
        dialogId={this.props.dialogId}
        onDialogHide={blockUtils.unblockUI}
        onDialogShown={this.onFormShow}
        onSubmit={this.sendForm}>

        <Form>
          <ErrorBox errorMessage={getErrorDescription(this.state.error)} />

          <TextInput
            fieldId="header"
            labelText={i18next.t('changelog.attributes.header')}
            isRequired={true}
            error={this.state.headerError}
            validate={() => this.validateField(TEXT_KEY, 'header')}
            onChange={this.onChange}
            value={this.state.header}
            placeholder="This is the gist of what was done"
          />

          <TextInput
            fieldId="summary"
            labelText={i18next.t('changelog.attributes.summary')}
            onChange={this.onChange}
            value={this.state.summary}
            isRequired={true}
            validate={() => this.validateField(TEXT_KEY, 'summary')}
            error={this.state.summaryError}
            placeholder="This is a short summary of changes"
          />

          <SelectInput
            fieldId="changeLogEntryType"
            labelText={i18next.t('changelog.attributes.changeLogEntryType')}
            selected={this.state.changeLogEntryType}
            onChange={this.onChange}
            values={this.getValuesForEntryType()}
            isRequired={true}
          />
        </Form>
      </ModalDialog>
    );
  }
}

VersionChangeForm.propTypes = {
  dialogId: PropTypes.string,
  objectToEdit: PropTypes.object,
  submitForm: PropTypes.func,
};

VersionChangeForm.defaultProps = {};

export default VersionChangeForm;
