import React, {Component} from 'react';
import DataTable from '../utils/DataTable';
import i18next from 'i18next';
import PropTypes from 'prop-types';

class SimplifiedSiteList extends Component {
  render() {
    return (
      <div className="col-md-5">
        <DataTable tableId="simplified_sites_table">
          <thead>
            <tr role="row" className="heading">
              <th>{i18next.t('sites.attrs.name')}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.sites.map((site) => {
              return (
                <tr key={site.id}>
                  <td>{site.name}</td>
                </tr>
              );
            })}
          </tbody>
        </DataTable>
      </div>
    );
  }
}

SimplifiedSiteList.propTypes = {
  sites: PropTypes.array,
};
SimplifiedSiteList.defaultProps = {};

export default SimplifiedSiteList;
