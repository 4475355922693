/**
 * @author Tomasz Czura (9/2/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Languages from '../../i18n/Languages';
import * as ErrorReport from '../../utils/ErrorReport';

export class CookieConsent extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    $('.mt-cookie-consent-bar').cookieBar({
      closeButton: '.mt-cookie-consent-btn',
    });
  }

  render() {
    Languages.initialize(this.props.userProfile);
    ErrorReport.initializeErrorReporting();
    return (
      <div className="mt-cookie-consent-bar mt-cookie-consent-bar-light">
        <div className="mt-cookie-consent-bar-holder">
          <div
            className="mt-cookie-consent-bar-content">
            This website uses cookies to ensure you get the best experience on our website.
            <a
              href="http://www.allaboutcookies.org/cookies/"
              target="_blank"
              rel="noreferrer"
            >
              What is a cookie?
            </a>
            <a href="#">
              Our Cookie Policy
            </a>
          </div>
          <div className="mt-cookie-consent-bar-action">
            <a href="#" className="mt-cookie-consent-btn btn btn-circle red">Understand</a>
          </div>
        </div>
      </div>
    );
  }
}

CookieConsent.propTypes = {
  userProfile: PropTypes.object,
};
CookieConsent.defaultProps = {};

const mapStateToProps = function(store) {
  return {
    userProfile: store.usersState.theLanguage,
    userLanguage: store.usersState.userProfile ? store.usersState.userProfile.language : 'en',
  };
};

export default connect(mapStateToProps)(CookieConsent);
