/**
 * @author TomaszCzura ({}
 */
import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import {ApiErrors} from './errors';
import i18next from 'i18next';

export function toYYYYMMDDHM(milliseconds) {
  const date = new Date(milliseconds);

  const momentDate = moment(date);
  if (momentDate.isValid()) {
    return momentDate.format('YYYY-MM-DD H:mm');
  } else {
    return '';
  }
}

export function toYYYYMMDDHHSS(milliseconds) {
  const date = new Date(milliseconds);

  const momentDate = moment(date);
  if (momentDate.isValid()) {
    return momentDate.format('YYYYMMDDHHmm');
  } else {
    return '';
  }
}

export function toYYYYMMDDHMS(milliseconds) {
  const date = new Date(milliseconds);

  const momentDate = moment(date);
  if (momentDate.isValid()) {
    return momentDate.format('YYYY-MM-DD H:mm:ss');
  } else {
    return '';
  }
}

export function showModal(modalComponent, dialogId, props) {
  const dialogRef = React.createRef();
  const wrapper = document.body.appendChild(document.createElement('div'));
  const dialog = React.createElement(modalComponent, {...props, ref: dialogRef});
  ReactDOM.render(dialog, wrapper);
  const $dialog = $(`#${dialogId}`);
  $dialog.modal('show');
  $dialog.on(('hide.bs.modal'), function() {
    ReactDOM.unmountComponentAtNode(wrapper);
    setTimeout(wrapper.remove(), 100);
  });
  return dialogRef.current;
}

export function getErrorDescription(error) {
  if (error) {
    if (error.response) {
      if (error.response.status === 401) {
        return i18next.t('login.wrong_credentials');
      } else if (error.response.status === 403) {
        return i18next.t('login.wrong_credentials');
      } else if (error.response.data) {
        if (error.response.data.errorCode) {
          return getErrorMessage(error.response.data.errorCode, error.response.data.errorMessage);
        }
        if (error.response.data.errorMessage) {
          return error.response.data.errorMessage;
        }
        // maybe data was not parsed yet
        try {
          const data = JSON.parse(error.response.data);
          if (data.errorCode) {
            return getErrorMessage(data.errorCode, data.errorMessage);
          } else {
            return `${error.message}: ${error.response.data} (${error.response.statusText})`;
          }
        } catch (x) {
          return `${error.message}: ${error.response.data} (${error.response.statusText})`;
        }
      } else {
        return `${error.message}: ${error.response.data} (${error.response.statusText})`;
      }
    } else {
      return i18next.t('api_errors.service_unavailable');
    }
  }
  return '';
}

function getErrorMessage(errorCode, errorMessage) {
  switch (errorCode) {
    case ApiErrors.WRONG_CREDENTIALS: return i18next.t('login.wrong_credentials');
    case ApiErrors.BOOKING_IN_PAST: return i18next.t('api_errors.booking_in_past');
    case ApiErrors.RESERVATION_EXIST: return i18next.t('api_errors.reservation_exist');
    case ApiErrors.USER_EXIST: return i18next.t('api_errors.user_exist');
    case ApiErrors.DUPLICATE_USERNAME: return i18next.t('api_errors.user_already_exist');
    case ApiErrors.DUPLICATE_RESERVATION_ID: return i18next.t('api_errors.duplicate_reservation_id');
    case ApiErrors.INVALID_DATA: return errorMessage ? errorMessage : i18next.t('api_errors.invalid_data');
    case ApiErrors.INVALID_SITE_ID: return i18next.t('api_errors.invalid_site_id');
    case ApiErrors.UNKNOWN: return i18next.t('api_errors.unknown');
    case ApiErrors.ACCESS_DENIED: return i18next.t('api_errors.access_denied');
    case ApiErrors.RESERVATION_NOT_FOUND: return i18next.t('api_errors.reservation_not_found');
    case ApiErrors.RESOURCE_NOT_FOUND: return i18next.t('api_errors.resource_not_found');
    case ApiErrors.USER_NOT_VALIDATED: return i18next.t('api_errors.user_not_validated');
    case ApiErrors.ILLEGAL_USER_OPERATION: return i18next.t('api_errors.illegal_user_operation');
    case ApiErrors.RESERVATION_ALREADY_CHECKED_IN: return i18next.t('api_errors.reservation_already_checked_in');
    case ApiErrors.MISSING_MATCHING_DATA: return i18next.t('api_errors.missing_matching_data');
    case ApiErrors.OVERLAPPING_RESERVATION: return i18next.t('api_errors.overlapping_reservation');
    case ApiErrors.AGENT_NOT_CONNECTED: return i18next.t('api_errors.agent_not_connected');
    case ApiErrors.INVALID_EMAIL: return i18next.t('api_errors.invalid_email');
    case ApiErrors.DUPLICATE_EMAIL: return i18next.t('api_errors.duplicate_email');
    default: return errorMessage ? errorMessage : i18next.t('api_errors.unknown');
  }
}

export function blinkElement($element) {
  const BLINK_TIME = 300;
  $element.stop(true);
  $element.fadeOut(BLINK_TIME).fadeIn(BLINK_TIME)
      .fadeOut(BLINK_TIME).fadeIn(BLINK_TIME)
      .fadeOut(BLINK_TIME).fadeIn(BLINK_TIME);
}
