/**
 * @author TomaszCzura ({}
 */

import axios from 'axios';
import store from '../store';
import * as actions from '../actions/checkins-actions';
import * as endpoints from './endpoints';
import {toLocal, toNetwork} from '../model/checkin';
import * as keyModel from './../model/key';
import * as logModel from './../model/transaction-log';

export function createBooking(booking) {
  const data = toNetwork(booking);

  return axios.put(endpoints.RESERVATIONS, data, endpoints.headers())
      .then((response) => {
        const booking = toLocal(response.data);
        store.dispatch(actions.createCheckinSuccess(booking));
        return response;
      })
      .catch(function(error) {
        store.dispatch(actions.createCheckinError(error));
      });
}

export function getKeysForBooking(bookingId) {
  return axios.get(endpoints.KEYS, endpoints.headers({reservation: bookingId}))
      .then((response) => {
        const keys = response.data.map((key) => keyModel.toLocal(key));
        store.dispatch(actions.getCheckinKeysSuccess(bookingId, keys));
      })
      .catch(() => {
      });
}

export function getActiveBookings(siteId, page, size, sortingName, sortingType, searchPhrase, onFinish) {
  return getBookings(siteId, page, size, true, sortingName, sortingType, searchPhrase, onFinish);
}

export function getHistoricalBookings(siteId, page, size, sortingName, sortingType, searchPhrase, onFinish) {
  return getBookings(siteId, page, size, false, sortingName, sortingType, searchPhrase, onFinish);
}

export function getBookings(siteId, page, size, onlyActive, sortingName, sortingType, searchPhrase, onFinish) {
  const data = {
    page: page,
    size: size,
    query: searchPhrase,
    sort: `${sortingName},${sortingType.toLowerCase()}`,
  };
  if (siteId !== -1) {
    data.siteId = siteId;
  }

  return axios.get(`${endpoints.RESERVATIONS}?onlyActive=${onlyActive}`, endpoints.headers(data))
      .then((response) => {
        const bookings = response.data.content.map((booking) => toLocal(booking));
        const totalPages = response.data.totalPages;
        const totalElements = response.data.totalElements;
        store.dispatch(actions.getCheckinsSuccess(bookings, totalPages, totalElements));
        onFinish();
      })
      .catch(function(error) {
        endpoints.handleUnauthorizedError(error, () => {
          store.dispatch(actions.getCheckinsError(error));
          onFinish();
        });
      });
}

export function getBooking(bookingId, callback = null, forceUpdate = false) {
  const booking = store.getState().checkinsState.checkins.filter((booking) => booking.id === bookingId)[0];
  if (booking && !forceUpdate) {
    store.dispatch(actions.getCheckinSuccess(booking));
    if (callback) callback(booking);
  } else {
    return axios.get(`${endpoints.RESERVATIONS}/${bookingId}`, endpoints.headers())
        .then((response) => {
          const booking = toLocal(response.data);
          store.dispatch(actions.getCheckinSuccess(booking));
          if (callback) callback(booking);
          return response;
        })
        .catch(function(error) {
          store.dispatch(actions.getCheckinError(error));
        });
  }
}

export function unMatchMobileUser(bookingId, mobileUserEmails) {
  const data = {
    mobileUserEmails: [mobileUserEmails],
  };

  return axios.post(endpoints.UNMATCH_MOBILE_USERS(bookingId), data, endpoints.headers())
      .then((response) => {
        store.dispatch(actions.userMatchedSuccess(bookingId, response.data));
      })
      .catch((error) => {
        store.dispatch(actions.userMatchedError(error));
      });
}

export function updateBooking(booking) {
  const data = toNetwork(booking);

  return axios.put(`${endpoints.RESERVATIONS}`, data, endpoints.headers())
      .then((response) => {
        const updatedBooking = toLocal(response.data);
        store.dispatch(actions.updateCheckinSuccess(updatedBooking));
        return response;
      })
      .catch(function(error) {
        store.dispatch(actions.updateCheckinError(booking, error));
      });
}

export function destroyBooking(bookingId) {
  actions.clearCheckinErrors();
  return axios.delete(`${endpoints.RESERVATIONS}/${bookingId}`, endpoints.headers())
      .then((response) => {
        store.dispatch(actions.deleteCheckinSuccess(bookingId));
        return response;
      })
      .catch((error) => {
        store.dispatch(actions.deleteCheckinError(error));
      });
}

export function generateKey(reservationId, onSuccess, onError) {
  return axios.post(endpoints.RESERVATION_KEY_GENERATE(reservationId), null, endpoints.headers())
      .then(onSuccess)
      .catch(() => {
        onError();
      });
}

export function getLogs(reservationId, page, size, sortingName, sortingType, searchPhrase) {
  const data = {
    page: page,
    size: size,
    query: searchPhrase,
    sort: `${sortingName},${sortingType.toLowerCase()}`,
  };

  return axios.get(endpoints.RESERVATION_LOGS(reservationId), endpoints.headers(data))
      .then((response) => {
        const logs = response.data.content.map((log) => logModel.toLocal(log));
        const totalPages = response.data.totalPages;
        const totalElements = response.data.totalElements;
        store.dispatch(actions.getLogsSuccess(reservationId, logs, totalPages, totalElements));
      })
      .catch((error) => {
        store.dispatch(actions.getLogsError(error));
      });
}
