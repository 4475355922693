import axios from 'axios';
import * as endpoints from './endpoints';
import * as model from '../model/version-change';

export function getCurrentVersion() {
  return axios.get(`${endpoints.CHANGE_LOG}/version`, endpoints.headers())
      .then((response) => {
        return response.data;
      });
}

export function getChangesForVersion(versionNumber) {
  return axios.get(`${endpoints.CHANGE_LOG}/${versionNumber}/list`, endpoints.headers())
      .then((response) => {
        return model.toLocal(response.data);
      }).catch((error) => {
        endpoints.handleUnauthorizedError(error, () => {});
      });
}

export function createVersion(changelog) {
  return axios.post(`${endpoints.CHANGE_LOG}`, changelog, endpoints.headers());
}

export function updateVersion(changelog) {
  return axios.patch(`${endpoints.CHANGE_LOG}`, changelog, endpoints.headers());
}

export function deleteVersion(versionNumber) {
  return axios.delete(`${endpoints.CHANGE_LOG}/${versionNumber}`, endpoints.headers());
}

export function createVersionEntry(versionNumber, versionChange) {
  return axios.post(`${endpoints.CHANGE_LOG}/${versionNumber}/entry/save`, versionChange, endpoints.headers());
}

export function updateVersionEntry(versionNumber, versionChange) {
  return axios.patch(`${endpoints.CHANGE_LOG}/${versionNumber}/${versionChange.id}/update`, versionChange, endpoints.headers());
}

export function deleteVersionEntry(versionNumber, versionChange) {
  return axios.delete(`${endpoints.CHANGE_LOG}/${versionNumber}/${versionChange.id}/delete`, endpoints.headers());
}
