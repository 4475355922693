/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

class TableAction extends Component {
  render() {
    let condition = true;
    if (this.props.condition === false) {
      condition = false;
    }

    return (
      <li>
        {condition ?
          (<a onClick={this.props.action}>
            <i className={this.props.icon}/>
            {this.props.text}
          </a>) :
          null
        }
      </li>
    );
  }
}

TableAction.propTypes = {
  condition: PropTypes.bool,
  action: PropTypes.func,
  text: PropTypes.string,
  icon: PropTypes.string,
};
TableAction.defaultProps = {};

export default TableAction;
