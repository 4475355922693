/**
 * @author TomaszCzura ({}
 */
import * as auth from './../utils/auth';
import {browserHistory} from 'react-router';

const ENDPOINT = TrustCenterAddress;
export const KEYS = `${ENDPOINT}/api/keys`;
export const LOGIN = `${ENDPOINT}/api/console/login`;
export const RENEW_TOKEN = `${ENDPOINT}/api/console/recreate-token`;
export const USERS = `${ENDPOINT}/api/console/users`;
export const CHANGE_LOG = `${ENDPOINT}/api/console/changelog`;
export const PASSWORD_FORGET_INIT = `${ENDPOINT}/api/console/users/password-forgotten`;
export const CHANGE_PASSWORD = `${ENDPOINT}/api/console/users/change-password`;
export const RESERVATIONS = `${ENDPOINT}/api/console/reservations`;
export const SITES = `${ENDPOINT}/api/console/sites`;
export const DASHBOARD = `${ENDPOINT}/api/console/dashboard`;
export const MOBILE_USERS = `${ENDPOINT}/api/console/mobile-users`;
export const TRANSACTION_LOGS = `${ENDPOINT}/api/console/transactions`;
export const DEVELOPERS = `${ENDPOINT}/api/console/developers`;
export const FILES = `${ENDPOINT}/api/console/files`;
export const BILLING = `${ENDPOINT}/api/console/billing`;
export const ANALYTICS = `${ENDPOINT}/api/console/analytics`;

export function PUT_NEW_PASSWORD(uuid) {
  return `${CHANGE_PASSWORD}?uuid=${uuid}`;
}

export function GET_BILLING(siteId, year, month) {
  return `${BILLING}/site/${siteId}/year/${year}/month/${month}`;
}

export function UNMATCH_MOBILE_USERS(bookingId) {
  return `${RESERVATIONS}/${bookingId}/unmatch`;
}

export function MOBILE_USER_LOGS(mobileUserId) {
  return `${MOBILE_USERS}/${mobileUserId}/logs`;
}

export function RESERVATION_KEY_GENERATE(reservationId) {
  return `${RESERVATIONS}/${reservationId}/keys`;
}

export function RESERVATION_LOGS(reservationId) {
  return `${RESERVATIONS}/${reservationId}/logs`;
}

export function RESET_DEV_PASSWORD(developerId) {
  return `${DEVELOPERS}/${developerId}/reset-password`;
}

export function GET_SITE_AGENT_LOGS(siteId) {
  return `${SITES}/${siteId}/logs`;
}

export function GET_SITE_AGENT_LOG(siteId, logId) {
  return `${SITES}/${siteId}/logs/${logId}`;
}

export function SITE_ROOMS(siteId) {
  return `${SITES}/${siteId}/rooms`;
}

export function SITE_SPECIALAREAS(siteId) {
  return `${SITES}/${siteId}/specialareas`;
}

export function headers(params = {}) {
  return {
    headers: {
      Authorization: `Bearer ${auth.getToken()}`,
    },
    params: params,
  };
}

export function handleUnauthorizedError(error, onAuthorized) {
  if (error.response && error.response.status === 401) {
    auth.logout(() => {
      browserHistory.push('/login');
    });
  } else {
    if (onAuthorized) onAuthorized();
  }
}
