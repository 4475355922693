/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

class TextInput extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  onChange(e) {
    if (this.props.validate && this.props.validate(e.target.value)) {
      this.props.onChange(e);
    } else {
      this.props.onChange(e);
    }
  }

  handleBlur(e) {
    if (this.props.validate) {
      this.props.validate(e.target.value);
    }
  }

  render() {
    return (
      <div className={`form-group form-md-line-input ${this.props.error ? 'has-error' : ''}`}>
        <label className="col-md-3 control-label" htmlFor={this.props.fieldId}>
          {this.props.labelText}
          {this.props.isRequired ? (<span className="required">*</span>) : null}
        </label>
        <div className="col-md-9">
          <input
            type={this.props.isSecret ? 'password' : 'text'}
            className="form-control"
            readOnly={this.props.isReadonly}
            name={this.props.fieldId}
            placeholder={this.props.placeholder}
            value={this.props.value}
            onChange={this.onChange}
            onBlur={this.handleBlur}/>
          <div className="form-control-focus"> </div>
          {this.props.helpText ? (<span className="help-block">{this.props.helpText.trim()}</span>) : null}
        </div>
        <div className="col-md-9 col-md-offset-3">
          {this.props.error ? (<span className="error-block">{this.props.error}</span>) : null}
        </div>

      </div>
    );
  }
}

TextInput.propTypes = {
  fieldId: PropTypes.string,
  placeholder: PropTypes.string,
  labelText: PropTypes.string,
  helpText: PropTypes.string,
  value: PropTypes.any,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  isReadonly: PropTypes.bool,
  isSecret: PropTypes.bool,
  error: PropTypes.string,
  validate: PropTypes.func,
};

TextInput.defaultProps = {};

export default TextInput;
