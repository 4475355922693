import FormComponent from '../utils/FormComponent';
import * as blockUtils from '../../utils/BlockUtils';
import i18next from 'i18next';
import ModalDialog from '../utils/ModalDialog';
import Form from '../utils/Form';
import ErrorBox from '../utils/ErrorBox';
import * as viewUtils from '../../utils/view-utils';
import {getErrorDescription} from '../../utils/view-utils';
import TextInput from '../utils/TextInput';
import PropTypes from 'prop-types';
import React from 'react';
import DatePickerInput from '../utils/DatePickerInput';
import * as api from '../../api/changelog-api';
import store from '../../store';
import * as actions from '../../actions/dahsboard-actions';

const TEXT_KEY = 'changelog.attributes.';

class ChangeLogForm extends FormComponent {
  constructor(props) {
    super(props);

    this.sendForm = this.sendForm.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
  }

  initialState() {
    return {
      id: this.props.objectToEdit ? this.props.objectToEdit.id : -1,
      versionNumber: this.props.objectToEdit ? this.props.objectToEdit.versionNumber : '',
      summary: this.props.objectToEdit ? this.props.objectToEdit.summary : '',
      releaseDate: this.props.objectToEdit ? this.props.objectToEdit.releaseDate : '',
      cannyLink: this.props.objectToEdit ? this.props.objectToEdit.cannyLink : '',
    };
  }

  sendForm() {
    if (this.validateAllFields('change-log-form', TEXT_KEY) === false) {
      return;
    }

    if (this.state.id !== -1) {
      api.updateVersion(this.state)
          .then((response) => {
            store.dispatch(actions.updateChangeLog(response.data));
            $(`#${this.props.dialogId}`).modal('hide');
          }).catch((error) => this.handleError(error));
    } else {
      api.createVersion(this.state)
          .then((response) => {
            store.dispatch(actions.createChangeLog(response.data));
            $(`#${this.props.dialogId}`).modal('hide');
          }).catch((error) => this.handleError(error));
    }
    blockUtils.blockUI();
  }

  onDateChange(newDate) {
    this.setState({
      releaseDate: newDate,
    });
  }

  handleError(error) {
    blockUtils.unblockUI();
    window.showAlert(i18next.t('error'), viewUtils.getErrorDescription(error));
  }

  render() {
    const modalTitle = this.props.objectToEdit ?
      i18next.t('changelog.edit_change_log', {version: this.props.objectToEdit.versionNumber}) :
      i18next.t('changelog.add_change_log');

    return (
      <ModalDialog
        customClass="change-log-form"
        modalTitle={modalTitle}
        dialogId={this.props.dialogId}
        onDialogHide={blockUtils.unblockUI}
        onDialogShown={this.onFormShow}
        onSubmit={this.sendForm}
      >

        <Form>
          <ErrorBox errorMessage={getErrorDescription(this.state.error)} />

          <TextInput
            fieldId="versionNumber"
            labelText={i18next.t('changelog.attributes.versionNumber')}
            isRequired={true}
            error={this.state.versionNumberError}
            validate={() => this.validateField(TEXT_KEY, 'versionNumber')}
            onChange={this.onChange}
            value={this.state.versionNumber}
            placeholder="v1.2.3"
          />

          <TextInput
            fieldId="summary"
            labelText={i18next.t('changelog.attributes.summary')}
            onChange={this.onChange}
            value={this.state.summary}
            isRequired={true}
            validate={() => this.validateField(TEXT_KEY, 'summary')}
            error={this.state.summaryError}
            placeholder="This is a short summary of changes"
          />

          <DatePickerInput
            checkDateId="releaseDate"
            labelText={i18next.t('changelog.attributes.releaseDate')}
            initialDate={this.state.releaseDate}
            onChange={this.onDateChange}
            isRequired={true}
            error={this.state.releaseDateError}
          />

          <TextInput
            fieldId="cannyLink"
            labelText={i18next.t('changelog.attributes.cannyLink')}
            onChange={this.onChange}
            isRequired={true}
            validate={() => this.validateField(TEXT_KEY, 'cannyLink')}
            value={this.state.cannyLink}
            error={this.state.cannyLinkError}
            placeholder="https://sphinx-electronics.canny.io/changelog/some-changes"
          />

        </Form>
      </ModalDialog>
    );
  }
}

ChangeLogForm.propTypes = {
  dialogId: PropTypes.string,
  objectToEdit: PropTypes.object,
};

ChangeLogForm.defaultProps = {};

export default ChangeLogForm;
