/**
 * @author Tomasz Czura (12/13/16)
 */
import React, {Component} from 'react';
import WhitePortlet from '../utils/WhitePortlet';
import i18next from 'i18next';
import {AppVersion} from '../App';
import StaticInfo from './../utils/StaticInfo';
import * as api from '../../api/changelog-api';
import PropTypes from 'prop-types';

class InfoContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backendVersion: '1.0.0',
    };
  }
  componentDidMount() {
    this.getBackendVersion().then((result) => this.setState({
      backendVersion: result,
    })).catch((err) => this.handleError(err));
  }

  handleError(error) {
    this.setState({
      error: error,
    });
  }

  getBackendVersion() {
    return api.getCurrentVersion();
  }

  render() {
    return (
      <WhitePortlet caption={i18next.t('info.name')} icon="icon-info">

        <StaticInfo title={i18next.t('info.backend_version')} value={this.state.backendVersion}/>
        <StaticInfo title={i18next.t('info.frontend_version')} value={AppVersion}/>
        <StaticInfo title={i18next.t('info.trust_center_address')} value={TrustCenterAddress}/>
        <StaticInfo title={i18next.t('info.socket_address')} value={`${SocketAddress}/api/ws/onsite`}/>

      </WhitePortlet>
    );
  }
}

InfoContainer.propTypes = {
  backendVersion: PropTypes.string,
};
InfoContainer.defaultProps = {};

export default InfoContainer;
