/**
 * @author Tomasz Czura (9/7/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import i18next from 'i18next';

class ShowAction extends Component {
  render() {
    const title = this.props.title || i18next.t('links.show');

    return (
      <li><Link to={this.props.path}><i className={this.props.icon}/>{title}</Link></li>
    );
  }
}

ShowAction.propTypes = {
  path: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
};
ShowAction.defaultProps = {
  icon: 'icon-info',
};

export default ShowAction;
