import React, {Component} from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';

class BatteryStatus extends Component {
  getStatusText() {
    if (this.props.status == null) {
      return '';
    }
    const textKey = 'terminals.battery.' + this.props.status;
    return i18next.t(textKey, {percentage: this.props.batteryPercentage});
  }

  getCssClass() {
    switch (this.props.status) {
      case 0:
      case 4:
        return 'text-connected';
      case 1:
      case 2:
      case 5:
        return 'text-warning';
      default:
        return 'text-disconnected';
    }
  }

  render() {
    return (
      <span className={this.getCssClass()}>
        {this.getStatusText()}
      </span>
    );
  }
}

BatteryStatus.propTypes = {
  status: PropTypes.string,
  batteryPercentage: PropTypes.string,
};

export default BatteryStatus;
