/**
 * @author Tomasz Czura (10/13/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

class PopoverSpan extends Component {
  componentDidMount() {
    $('.popovers').popover();

    $('body').on('click', function(e) {
      // did not click a popover toggle or popover
      if (!$(e.target).parent().hasClass('popovers') &&
          $(e.target).parents('.popover').length == 0 &&
          $('.popover').length > 0) {
        $('.popover').popover('hide');
      }
    });
  }

  render() {
    return (
      <span
        className={`${this.props.viewClass} popovers popover_btn`}
        data-container="body"
        data-trigger={this.props.trigger}
        data-placement={this.props.placement}
        data-html="true"
        data-content={this.props.popoverContent}
        data-original-title={this.props.popoverTitle}
      >
        {this.props.title}
      </span>
    );
  }
}

PopoverSpan.propTypes = {
  viewClass: PropTypes.string,
  title: PropTypes.any,
  popoverTitle: PropTypes.string,
  popoverContent: PropTypes.any,
  placement: PropTypes.string,
  trigger: PropTypes.string,
};
PopoverSpan.defaultProps = {
  placement: 'right',
  trigger: 'hover',
};

export default PopoverSpan;
