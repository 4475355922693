/**
 * @author Tomasz Czura (11/18/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DataTable from './../utils/DataTable';
import i18next from 'i18next';
import SortableDate from './../utils/SortableDate';
import TableActions from './../utils/TableActions';
import ShowAction from './../utils/actions/ShowAction';
import DownloadAction from './../utils/actions/DownloadAction';
import {onRowClick} from '../redux_data_table/ReduxDataTable';

class SiteAgentLogs extends Component {
  render() {
    return (
      <div className="table-container">
        <DataTable tableId="logs_table" defaultSort={[0, 'desc']} notSortable={[1]}>
          <thead>
            <tr>
              <th>{i18next.t('site_agent_logs.attrs.created_at')}</th>
              <th>{i18next.t('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.logs.map((log) => {
              return (
                <tr onClick={() => onRowClick(log.id)} key={log.id}>
                  <td><SortableDate dateMillis={log.createdAt}/></td>
                  <td>
                    <TableActions identifier={log.id}>
                      <ShowAction path={`/sites/${this.props.siteId}/logs/${log.id}`}/>
                      <DownloadAction name={log.createdAt} url=""/>
                    </TableActions>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </DataTable>
      </div>
    );
  }
}

SiteAgentLogs.propTypes = {
  logs: PropTypes.array,
  siteId: PropTypes.number,
};
SiteAgentLogs.defaultProps = {};

export default SiteAgentLogs;
