/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TextInfo from '../utils/TextInfo';
import BooleanInfo from '../utils/BooleanInfo';
import Form from '../utils/Form';
import i18next from 'i18next';

class SiteDetails extends Component {
  render() {
    return (
      <Form>
        <TextInfo
          labelText={i18next.t('sites.attrs.name')}
          value={this.props.presentedSite.name}
        />
        <TextInfo
          labelText={i18next.t('sites.attrs.external_id')}
          value={this.props.presentedSite.externalId}
        />
        <TextInfo
          labelText={i18next.t('sites.attrs.project_code')}
          value={this.props.presentedSite.projectCode}
        />
        <TextInfo
          labelText={i18next.t('sites.attrs.contact_email')}
          value={this.props.presentedSite.contactEmail}
        />
        <TextInfo
          labelText={i18next.t('sites.attrs.phone')}
          value={this.props.presentedSite.usercontactPhone}
        />
        <TextInfo
          labelText={i18next.t('sites.attrs.logo_url')}
          value={this.props.presentedSite.logoImageUrl}
        />
        <TextInfo
          labelText={i18next.t('sites.attrs.address')}
          value={this.props.presentedSite.usercontactAddress}
        />
        <TextInfo
          labelText={i18next.t('sites.attrs.latitude')}
          value={this.props.presentedSite.usercontactLatitude}
        />
        <TextInfo
          labelText={i18next.t('sites.attrs.longitude')}
          value={this.props.presentedSite.usercontactLongitude}
        />
        <BooleanInfo
          labelText={i18next.t('sites.attrs.disconnection_notifications')}
          value={this.props.presentedSite.disconnectionNotifications}
        />
        <TextInfo
          labelText={i18next.t('sites.attrs.token')}
          value={this.props.presentedSite.token}
        />
        <TextInfo
          labelText={i18next.t('sites.attrs.gatewayId')}
          value={this.props.presentedSite.gatewayId}
        />
      </Form>
    );
  }
}

SiteDetails.propTypes = {
  presentedSite: PropTypes.object,
};

SiteDetails.defaultProps = {};

export default SiteDetails;
