/**
 * @author Tomasz Czura (11/10/16)
 */

import * as types from '../actions/action-types';

export function getBillingSuccess(year, month, billing) {
  return {
    type: types.GET_BILLING_SUCCESS,
    year, month, billing,
  };
}

export function getBillingError(error) {
  return {
    type: types.GET_BILLING_ERROR,
    error,
  };
}
