/**
 * @author TomaszCzura ({}
 */
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Form from '../utils/Form';
import TextInfo from '../utils/TextInfo';
import SimplifiedSiteList from '../sites/SimplifiedSiteList';

class UserDetails extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Form>
        <TextInfo labelText={i18next.t('users.attrs.login')}
          value={this.props.presentedUser.username}/>
        <TextInfo labelText={i18next.t('users.attrs.role')}
          value={i18next.t([`users.roles.${this.props.presentedUser.role}`])}/>
        <TextInfo labelText={i18next.t('users.attrs.email')}
          value={this.props.presentedUser.email}/>
        <TextInfo labelText={i18next.t('users.attrs.language')}
          value={this.props.userProfile === 'de' ? <div>German 🇩🇪</div> : <div>English 🇺🇸</div>}/>
        <div className="form-group form-md-line-input">
          <label className="col-md-2 control-label">{i18next.t('developers.attrs.sites')} </label>
          <SimplifiedSiteList sites={this.props.sites}/>
        </div>
      </Form>
    );
  }
}

UserDetails.propTypes = {
  sites: PropTypes.array,
  presentedUser: PropTypes.object,
  userProfile: PropTypes.object,
};
UserDetails.defaultProps = {};

const mapStateToProps = function(store) {
  return {
    sites: store.sitesState.sites,
    userProfile: store.usersState.theLanguage,
  };
};

export default connect(mapStateToProps)(UserDetails);
