/**
 * @author TomaszCzura ({}
 */
import {createStore} from 'redux';

import rootReducer from './reducers/index';

const store = createStore(rootReducer, window.devToolsExtension && window.devToolsExtension());
export default store;

export function isSiteSelected() {
  return selectedSiteId() !== -1;
}

export function selectedSiteId() {
  const sites = store.getState().sitesState.sites;
  if (sites && sites.length === 1) {
    return sites[0].id;
  } else {
    return store.getState().sitesState.currentSiteId;
  }
}

export function doesUserHaveAccessToSite(siteId) {
  const sites = store.getState().sitesState.sites;
  if (sites) {
    return sites.some((item) => siteId === item.id);
  }

  return false;
}

export function selectedSiteOrFirstId() {
  if (isSiteSelected()) {
    return selectedSiteId();
  } else {
    const sites = store.getState().sitesState.sites;
    if (sites.length > 0) {
      return sites[0].id;
    } else {
      return -1;
    }
  }
}

export function nameOfSite(siteId) {
  const site = store.getState().sitesState.sites.filter((site) => site.id === siteId)[0];
  return site ? site.name : '';
}

export function defaultSpecialAreasOfSite(siteId) {
  const site = store.getState().sitesState.sites.filter((site) => site.id === siteId)[0];
  return site ? site.defaultSpecialAreas : '';
}

export function defaultCheckinTimeOfSite(siteId) {
  const site = store.getState().sitesState.sites.filter((site) => site.id === siteId)[0];
  return site ? site.defaultCheckinTime || '15:00' : '15:00';
}

export function defaultCheckoutTimeOfSite(siteId) {
  const site = store.getState().sitesState.sites.filter((site) => site.id === siteId)[0];
  return site ? site.defaultCheckoutTime || '12:00' : '12:00';
}
