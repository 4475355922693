/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as dashboardApi from '../../api/dashboard-api';
import {isSiteSelected, selectedSiteId} from '../../store';
import BatteryAlerts from './BatteryAlerts';
import Feeds from './Feeds';
import ChangeLogs from './ChangeLogs';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.downloadStats = this.downloadStats.bind(this);

    this.state = {
      siteId: selectedSiteId(),
    };
  }

  componentDidMount() {
    this.downloadStats();
  }

  downloadStats() {
    if (isSiteSelected()) {
      dashboardApi.getDashboardStats(selectedSiteId()).then(() => {}).catch((err) => this.handleError(err));
    } else {
      dashboardApi.getDashboardStats().then(() => {}).catch((err) => this.handleError(err));
    }
  }

  handleError(error) {
    this.setState({
      error: error,
    });
  }

  render() {
    return (
      <div>
        <div className="row">
          <ChangeLogs changeLogs={this.props.changeLogs}/>
        </div>
        <div className="row">
          <BatteryAlerts alerts={this.props.batteryAlerts}/>
          <Feeds feeds={this.props.feeds}/>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  batteryAlerts: PropTypes.array,
  feeds: PropTypes.array,
  changeLogs: PropTypes.array,
};

Dashboard.defaultProps = {};

const mapStateToProps = function(store) {
  return {
    batteryAlerts: store.dashboardState.batteryAlerts,
    feeds: store.dashboardState.feeds,
    changeLogs: store.dashboardState.changeLogs,
  };
};

export default connect(mapStateToProps)(Dashboard);
