/**
 * @author Tomasz Czura (2/16/17)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ModalDialog from './ModalDialog';
import i18next from 'i18next';
import * as auth from './../../utils/auth';
import * as authApi from './../../api/auth-api';
import * as blockUtils from './../../utils/BlockUtils';
import moment from 'moment';

class SessionEndDialog extends Component {
  constructor(props) {
    super(props);

    this.onSessionRenew = this.onSessionRenew.bind(this);
    this.updateSecondsLeft = this.updateSecondsLeft.bind(this);
    this.onLogoutClick = this.onLogoutClick.bind(this);

    const millisToSessionEnd = moment(auth.getSessionEnd()).diff();

    this.state = {
      secondsLeft: Math.ceil(millisToSessionEnd/1000),
    };
  }

  componentDidMount() {
    this.counterInterval = setInterval(this.updateSecondsLeft, 1000);
  }

  onSessionRenew() {
    window.clearInterval(this.counterInterval);
    $(`#${this.props.dialogId}`).modal('hide');
    blockUtils.blockUI();
    authApi.renewToken((response) => {
      blockUtils.unblockUI();
      auth.renewToken(response);
      if (this.props.onRenew) this.props.onRenew();
    });
  }

  updateSecondsLeft() {
    if (this.state.secondsLeft > 1) {
      this.setState({secondsLeft: this.state.secondsLeft - 1});
    } else {
      $(`#${this.props.dialogId}`).modal('hide');
      this.onLogoutClick();
    }
  }

  onLogoutClick() {
    window.clearInterval(this.counterInterval);
    auth.logout();
  }

  componentWillUnmount() {
    window.clearInterval(this.counterInterval);
  }

  render() {
    return (
      <ModalDialog modalTitle={i18next.t('confirmation.warning')} dialogId={this.props.dialogId} showClose={false}
        saveText={i18next.t('renew_session')} onSubmit={this.onSessionRenew}
        closeText={i18next.t('logout')}
        onCloseClick={this.onLogoutClick}>
        {i18next.t('session_expires', {seconds: this.state.secondsLeft})}
      </ModalDialog>
    );
  }
}

SessionEndDialog.propTypes = {
  dialogId: PropTypes.string,
  onRenew: PropTypes.func,
};
SessionEndDialog.defaultProps = {};

export default SessionEndDialog;
