import React, {Component} from 'react';
import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';

class TabPanel extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        role="tabpanel"
        hidden={this.props.value !== this.props.index}
        id={`simple-tabpanel-${this.props.index}`}
        aria-labelledby={`simple-tab-${this.props.index}`}
      >
        {this.props.value === this.props.index && (
          <Box sx={{p: 3}}>
            <Typography>{this.props.children}</Typography>
          </Box>
        )}
      </div>
    );
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number,
};

export default TabPanel;
