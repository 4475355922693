/**
 * @author Tomasz Czura (9/1/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import * as blockUtils from './../../utils/BlockUtils';
import * as viewUtils from './../../utils/view-utils';
import * as devApi from './../../api/developers-api';
import * as devModel from './../../model/developer';
import DeveloperForm from './DeveloperForm';
import DataTable from './../utils/DataTable';
import i18next from 'i18next';
import BooleanLabel from './../utils/BooleanLabel';
import TableActions from './../utils/TableActions';
import TableAction from './../utils/actions/TableAction';
import DestroyAction from './../utils/actions/DestroyAction';
import ShowAction from './../utils/actions/ShowAction';
import EditAction from './../utils/actions/EditAction';
import PopoverBtn from './../utils/PopoverBtn';
import SitesTableList from './SitesTableList';
import CreateNewButton from '../CreateNewButton';
import {onRowClick} from '../redux_data_table/ReduxDataTable';
import * as rights from '../../model/rights';

class DevelopersList extends Component {
  constructor(props) {
    super(props);
    this.formDialogId = 'developerFormDialog';
    this.developerTableId = 'developers_table';
    this.destroyDeveloper = this.destroyDeveloper.bind(this);
    this.setDeveloperActive = this.setDeveloperActive.bind(this);
    this.showForm = this.showForm.bind(this);
  }

  componentDidUpdate() {
    this.handleError();
  }

  handleError() {
    const error = this.props.createDeveloperError || this.props.updateDeveloperError;
    if (error) {
      if (this.modalDialog) {
        this.modalDialog.handleError(error);
      }
      blockUtils.unblockUI();
    } else {
      $(`#${this.formDialogId}`).modal('hide');
    }
  }

  destroyDeveloper(developerId) {
    blockUtils.blockUI(`#${this.developerTableId}`);
    devApi.destroyDeveloper(developerId);
  }

  setDeveloperActive(developer, active) {
    const devCopy = devModel.toForm(developer);
    devCopy.active = active;
    devApi.updateDeveloper(devCopy);
    blockUtils.blockUI(`#${this.developerTableId}`);
  }

  showForm(developer) {
    const props = {
      dialogId: this.formDialogId,
      error: this.props.createDeveloperError,
      presentedDeveloper: developer,
    };
    this.modalDialog = viewUtils.showModal(DeveloperForm, this.formDialogId, props);
  }

  render() {
    return (
      <div className="table-container">
        {rights.currentUserHasRight(rights.DEVELOPER_ADD_PRIVILEGE) ?
          (<CreateNewButton callBack={() => this.showForm()} />) :
          null
        }
        <DataTable tableId={this.developerTableId} notSortable={[3]}>
          <thead>
            <tr>
              <th>ID</th>
              <th>{i18next.t('developers.attrs.email')}</th>
              <th>{i18next.t('developers.attrs.active')}</th>
              <th>{i18next.t('developers.attrs.sites')}</th>
              <th>{i18next.t('developers.attrs.own_email_verification')}</th>
              <th>{i18next.t('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.developers.map((dev) => {
              return (
                <tr onClick={() => onRowClick(dev.id)} key={dev.id}>
                  <td>{dev.id}</td>
                  <td>{dev.email}</td>
                  <td><BooleanLabel value={dev.active} /></td>
                  <td>
                    {(dev.sites && dev.sites.length) > 0 ?
                      (<PopoverBtn viewClass='btn btn-xs green'
                        popoverContent={ReactDOMServer.renderToStaticMarkup(<SitesTableList sites={dev.sites} />)}
                        title={`${i18next.t('sites.name', {count: 2})}: ${dev.sites.length}`} />) :
                      <button className="btn btn-xs red popover_btn">
                        {`${i18next.t('sites.name', {count: 2})}: 0`}
                      </button>
                    }
                  </td>
                  <td><BooleanLabel value={dev.hasOwnEmailVerificationService} /></td>
                  <td>
                    <TableActions identifier={dev.id}>
                      <ShowAction path={`developers/${dev.id}`} />
                      <EditAction
                        onEdit={() => this.showForm(dev)}
                        condition={rights.currentUserHasRight(rights.DEVELOPER_EDIT_PRIVILEGE)}
                      />
                      <TableAction
                        action={() => this.setDeveloperActive(dev, false)}
                        condition={dev.active && rights.currentUserHasRight(rights.DEVELOPER_EDIT_PRIVILEGE)}
                        icon="icon-user-unfollow" text={i18next.t('developers.actions.deactivate')}
                      />
                      <TableAction
                        action={() => this.setDeveloperActive(dev, true)}
                        condition={!dev.active && rights.currentUserHasRight(rights.DEVELOPER_EDIT_PRIVILEGE)}
                        icon="icon-user-follow" text={i18next.t('developers.actions.activate')}
                      />
                      <DestroyAction
                        destroyElement={() => this.destroyDeveloper(dev.id)}
                        condition={rights.currentUserHasRight(rights.DEVELOPER_DELETE_PRIVILEGE)}
                      />
                    </TableActions>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </DataTable>
      </div>
    );
  }
}

DevelopersList.propTypes = {
  developers: PropTypes.array,
  createDeveloperError: PropTypes.object,
  updateDeveloperError: PropTypes.object,
};
DevelopersList.defaultProps = {};

export default DevelopersList;
