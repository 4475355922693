/**
 * @author TomaszCzura ({}
 */
import React from 'react';
import PropTypes from 'prop-types';
import FormComponent from './../utils/FormComponent';
import * as checkin from './../../model/checkin';
import i18next from 'i18next';
import Form from './../utils/Form';
import TextInput from './../utils/TextInput';
import Multiselect from './../utils/Multiselect';
import * as blockUtils from './../../utils/BlockUtils';
import ModalDialog from './../utils/ModalDialog';
import store from './../../store';
import DateRange from './../utils/DateRange';
import SelectInput from './../utils/SelectInput';
import * as checkinsApi from '../../api/bookings-api';
import {isValidEmail} from '../../utils/validate-utils';
import ErrorBox from './../utils/ErrorBox';
import {getErrorDescription} from '../../utils/view-utils';
import moment from 'moment';
import TagsInput from './../utils/TagsInput';
import * as sitesApi from './../../api/sites-api';
import {defaultCheckinTimeOfSite, defaultCheckoutTimeOfSite, defaultSpecialAreasOfSite} from '../../store';

class BookingForm extends FormComponent {
  constructor(props) {
    super(props);

    // You must have bound your custom functions in the constructor function before using them as props for components.
    this.onDataRangeChange = this.onDataRangeChange.bind(this);
    this.onCheckedInChanged = this.onCheckedInChanged.bind(this);
    this.sendForm = this.sendForm.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.onEmailsChanged = this.onEmailsChanged.bind(this);
    this.handleClickOnButton = this.handleClickOnButton.bind(this);
    this.onSiteChanged = this.onSiteChanged.bind(this);
    this.onSelectedSpecialAreasChanged = this.onSelectedSpecialAreasChanged.bind(this);
    this.onSpecialAreaListReceived = this.onSpecialAreaListReceived.bind(this);
  }

  initialState() {
    // State is the internal state of this component.
    // Fill it with the props = values passed to this component.
    // (Some passed props may actually be functions, returning the most current value instead of static values.)
    const isEditMode = this.props.presentedCheckin;
    const state = isEditMode ?
      checkin.toForm(this.props.presentedCheckin) :
      checkin.defaultFormModel();

    state['checkinDateError'] = '';
    state['checkoutDateError'] = '';
    state['reservationIdError'] = '';
    state['roomError'] = '';
    state['emailError'] = false;
    state['emptyEmail'] = false;
    state['dateRangeError'] = '';
    state['allSpecialAreaNames_siteid'] = '';
    state['allSpecialAreaNames'] = state['allSpecialAreaNames'] ?
      state['allSpecialAreaNames'] :
      [];
    state['selectedSpecialAreaNames'] = state['selectedSpecialAreaNames'] ?
      state['selectedSpecialAreaNames'] :
      [];
    state['isEditMode'] = isEditMode;
    state['firstNameError'] = false;
    state['lastNameError'] = false;
    state['roomNumberError'] = false;
    state['stayDateError'] = false;
    state['buttonClicked'] = true;
    state['bookingState'] = this.props.bookingState;

    this.handleSiteChanged(state.siteId, state);
    return state;
  }

  onSpecialAreaListReceived(list) {
    const state = {};
    // we use this because render() has already been called and
    // the list of options shall have the siteID as key to be re-rendered
    state['allSpecialAreaNames_siteid'] = this.state.siteId;

    state['allSpecialAreaNames'] = list ?
      list.map((entry) => {
        return {id: entry.areaNumber, name: entry.areaName};
      }) : [];
    const specialAreaDictionary = Object.fromEntries(
        list.map((entry) => [entry.areaName, entry.areaNumber]),
    );

    state.specialAreas = this.state.specialAreas
        .split(',')
        .map((area) => {
          return specialAreaDictionary[area] ? specialAreaDictionary[area] : area;
        })
        .join(',');

    this.setState(state);
  }

  handleSiteChanged(siteId, state) {
    sitesApi.getSiteSpecialareas(siteId, this.onSpecialAreaListReceived);
    state['siteId'] = siteId;

    if (!state['isEditMode']) {
      const defaultSpecialAreas = defaultSpecialAreasOfSite(siteId);
      const defaultCheckinTime = defaultCheckinTimeOfSite(siteId);
      const defaultCheckoutTime = defaultCheckoutTimeOfSite(siteId);

      (state['checkinDate'] =
        moment(new Date()).hours(12).minutes(0).format('YYYY-MM-DD ') +
        defaultCheckinTime),
      (state['checkoutDate'] =
          moment(new Date())
              .hours(12)
              .minutes(0)
              .add(2, 'days')
              .format('YYYY-MM-DD ') + defaultCheckoutTime),
      (state['specialAreas'] = defaultSpecialAreas ?
          defaultSpecialAreas :
          '');
    }
  }

  onSiteChanged(siteId) {
    // this method must not be called from the constructor.
    // " Can only update a mounted or mounting component.
    // This usually means you called setState() on an unmounted component."
    const state = {};
    this.handleSiteChanged(siteId, state);
    this.setState(state);
  }

  onSelectedSpecialAreasChanged(selectedAreas) {
    const state = {};
    state['specialAreas'] = selectedAreas ? selectedAreas.join() : '';
    this.setState(state);
  }

  onDataRangeChange(startDate, endDate) {
    const isInFuture = moment(endDate, 'YYYY-MM-DD H:mm').isAfter();
    const state = {};
    state['checkinDate'] = startDate;
    state['checkoutDate'] = endDate;
    state['dateRangeError'] = '';
    if (!isInFuture) {
      state['dateRangeError'] = i18next.t('errors.data_in_past');
    }
    this.setState(state);
  }

  onCheckedInChanged(e) {
    const state = {};
    const checked = e.target.checked;
    state[e.target.name] = checked;
    if (!checked) {
      state['room'] = '';
      state['bed'] = '';
    }

    this.setState(state);
  }

  sendForm() {
    const isFormValid = this.validateForm();
    if (isFormValid) {
      if (
        this.props.presentedCheckin &&
        checkin.hasKeys(this.props.presentedCheckin)
      ) {
        if (!this.state.checkedIn && this.props.presentedCheckin.checkedIn) {
          confirm(
              () => this.sendRequest(),
              i18next.t('checkins.warnings.checked_in_changed'),
          );
        } else if (
          this.state.room !== this.props.presentedCheckin.room ||
          this.state.bed !== this.props.presentedCheckin.bed
        ) {
          confirm(
              () => this.sendRequest(),
              i18next.t('checkins.warnings.room_change'),
          );
        } else if (
          this.state.checkinDate !==
          moment(new Date(this.props.presentedCheckin.checkinDate)).format(
              'YYYY-MM-DD H:mm',
          ) ||
          this.state.checkoutDate !==
          moment(new Date(this.props.presentedCheckin.checkoutDate)).format(
              'YYYY-MM-DD H:mm',
          )
        ) {
          confirm(
              () => this.sendRequest(),
              i18next.t('checkins.warnings.stay_date_change'),
          );
        } else {
          this.sendRequest();
        }
      } else {
        this.sendRequest();
      }
    }
  }

  sendRequest() {
    if (this.state.id) {
      checkinsApi.updateBooking(this.state);
    } else {
      checkinsApi.createBooking(this.state);
    }
    blockUtils.blockUI();
  }

  validateForm() {
    const validEmail = this.validateEmail();
    const isInFuture = moment(
        this.state.checkoutDate,
        'YYYY-MM-DD H:mm',
    ).isAfter();
    if (!isInFuture) {
      this.setState({
        dateRangeError: i18next.t('errors.data_in_past'),
      });
    }
    if (typeof this.state.allSpecialAreaNames !== 'undefined') {
      // we have a list of all special area names and can display a list to select from.
      // TODO: convert from this.state.selectedSpecialAreaNames to this.state.specialAreas
    }
    const firstName = this.state.firstName;
    if (firstName.length < 1) {
      this.setState({firstNameError: true});
    } else {
      this.setState({firstNameError: false});
    }

    const lastName = this.state.lastName;
    if (lastName.length < 1) {
      this.setState({lastNameError: true});
    } else {
      this.setState({lastNameError: false});
    }

    const firstNameError = this.state.firstNameError;
    const lastNameError = this.state.lastNameError;
    const roomNumberError = this.state.roomNumberError;

    return validEmail && isInFuture && !firstNameError && !lastNameError && !roomNumberError;
  }

  validateEmail(emails = this.state.emails) {
    const temp = [];
    emails.map((email) => {
      if (isValidEmail(email)) {
        temp.push('true');
      } else {
        temp.push('false');
      }
    });

    if (temp.includes('false')) {
      this.setState({emailError: true});
    } else if (temp.includes('true')) {
      this.setState({emailError: false});
    }

    if (temp.length === 0) {
      this.setState({emptyEmail: true});
    } else {
      this.setState({emptyEmail: false});
    }

    return !!(temp.length > 0 && temp.every((item) => item === 'true'));
  }

  handleError(error) {
    this.setState({
      error: error,
    });
  }

  onEmailsChanged(e) {
    this.setState({
      emails: e,
    });
  }

  handleClickOnButton() {
    this.setState({buttonClicked: !this.state.buttonClicked});
  }

  render() {
    const modalTitle = this.state.id ?
      i18next.t('checkins.edit', {id: this.state.id}) :
      i18next.t('checkins.create_new');
    const hasAreas =
      typeof this.state.allSpecialAreaNames == 'undefined' ||
      this.state.allSpecialAreaNames.length > 0;
    const bookingState = this.state.bookingState === 'EXPIRED';
    return (
      <ModalDialog
        modalTitle={modalTitle}
        dialogId={this.props.dialogId}
        onDialogHide={blockUtils.unblockUI}
        onDialogShown={this.onFormShow}
        onSubmit={this.sendForm}
        saveDisabled={bookingState}
      >
        <div className="button__main__container">
          <div className="button__container">
            <button onClick={this.handleClickOnButton}
              className={this.state.buttonClicked ? 'button__border' : ''}
              id="toggle__view__btn">{i18next.t('checkins.attrs.simple_form_button')}
            </button>
            <button onClick={this.handleClickOnButton}
              className={!this.state.buttonClicked ? 'button__border' : ''}
              id="toggle__view__btn">{i18next.t('checkins.attrs.detailed_form_button')}
            </button>
          </div>
        </div>
        <Form>

          <ErrorBox errorMessage={getErrorDescription(this.state.error)} />

          <TextInput
            fieldId="firstName"
            labelText={i18next.t('checkins.attrs.first_name')}
            onChange={this.onChange}
            value={this.state.firstName}
            isRequired={true}
            isReadonly={bookingState}
          />

          {this.state.firstNameError && (
            <span className="error">{i18next.t('checkins.errors.firstName_error')}</span>
          )}

          <TextInput
            fieldId="lastName"
            labelText={i18next.t('checkins.attrs.last_name')}
            onChange={this.onChange}
            value={this.state.lastName}
            isRequired={true}
            isReadonly={bookingState}
          />

          {this.state.lastNameError && (
            <span className="error">{i18next.t('checkins.errors.lastName_error')}</span>
          )}

          <TextInput
            fieldId="room"
            labelText={i18next.t('checkins.attrs.room_number')}
            error={this.state.roomError}
            onChange={this.onChange}
            value={this.state.room}
            isReadonly={bookingState}
          />
          {this.state.roomNumberError && (
            <span className="error">{i18next.t('checkins.errors.roomNumber_error')}</span>
          )}

          {!this.state.buttonClicked && <TextInput
            fieldId="bed"
            labelText={i18next.t('checkins.attrs.bed_number')}
            key={'nolist-' + this.state.siteId}
            onChange={this.onChange}
            value={this.state.bed}
            isReadonly={bookingState}
          />}

          {hasAreas ? (

            <Multiselect
              fieldId="specialAreas"
              key={this.state.allSpecialAreaNames_siteid}
              labelText={i18next.t('checkins.attrs.special_areas')}
              selectableHeader={i18next.t('developers.attrs.available_areas')}
              selectionHeader={i18next.t('developers.attrs.access_to')}
              selected={this.state.specialAreas.split(',')}
              values={this.state.allSpecialAreaNames}
              onChange={this.onSelectedSpecialAreasChanged}
              isReadonly={bookingState}
            />
          ) : (!this.state.buttonClicked ?
            <TextInput
              fieldId="specialAreas"
              labelText={i18next.t('checkins.attrs.special_areas')}
              onChange={this.onChange}
              value={this.state.specialAreas}
              helpText="1,2,3,4"
              isReadonly={bookingState}
            /> : null
          )}

          <DateRange
            checkDateId="checkDateId"
            isRequired={true}
            error={this.state.dateRangeError}
            onChange={this.onDataRangeChange}
            startDateValue={this.state.checkinDate}
            endDateValue={this.state.checkoutDate}
            labelText={i18next.t('checkins.attrs.stay_date')}
            isReadonly={bookingState}
          />

          {!this.state.buttonClicked && <SelectInput
            fieldId="siteId"
            labelText={i18next.t('checkins.attrs.site_name')}
            isReadonly={!!this.state.id}
            onChange={(event) => {
              this.onSiteChanged(event.target.value);
              this.onChange(event);
            }}
            values={store.getState().sitesState.sites}
            selected={this.state.siteId}
          />}

          {!this.state.buttonClicked && <TextInput
            fieldId="reservationId"
            labelText={i18next.t('checkins.attrs.reservation_id')}
            error={this.state.reservationIdError}
            onChange={this.onChange}
            value={this.state.reservationId}
            isReadonly={bookingState}
          />}

          <TagsInput
            fieldId="emails"
            labelText={i18next.t('checkins.attrs.emails')}
            onChange={this.onEmailsChanged}
            values={this.state.emails}
            isRequired={true}
            isReadonly={bookingState}
            placeholder={i18next.t('checkins.attrs.emails_placeholder')}
          />
          {this.state.emailError && (
            <span className="error">{i18next.t('checkins.errors.invalidEmail_error')}</span>
          )}

          {this.state.emptyEmail && (
            <span className="error">{i18next.t('checkins.errors.emptyEmail_error')}</span>
          )}
        </Form>
      </ModalDialog>
    );
  }
}

BookingForm.propTypes = {
  specialareas: PropTypes.array,
};
BookingForm.defaultProps = {};

export default BookingForm;
