/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import WhitePortlet from '../utils/WhitePortlet';
import BookingsList from './BookingsList';
import i18next from 'i18next';
import * as checkinsApi from '../../api/bookings-api';
import {selectedSiteId} from '../../store';
import ErrorBox from './../utils/ErrorBox';
import RadioAction from './../utils/RadioAction';
import {DataTypes} from '../../model/utils';
import * as blockUtils from './../../utils/BlockUtils';
import {blinkElement, getErrorDescription} from '../../utils/view-utils';
import BookingSocket from './../../socket/BookingSocket';

class BookingsListContainer extends Component {
  constructor(props) {
    super(props);

    this.downloadBookings = this.downloadBookings.bind(this);
    this.onDataTypeChange = this.onDataTypeChange.bind(this);
    this.onBookingsDownload = this.onBookingsDownload.bind(this);
    this.onBookingRefreshed = this.onBookingRefreshed.bind(this);
    this.onKeyRefreshed = this.onKeyRefreshed.bind(this);

    this.tableId = 'checkins_table';
    this.dataType = DataTypes.ACTIVE;

    this.pageNumber = 0;
    this.elementsCount = 10;
    this.sortingName = '';
    this.sortingType = '';
    this.searchPhrase = '';
  }

  downloadBookings(pageNumber = this.pageNumber, elementsCount = this.elementsCount,
      sortingName = this.sortingName, sortingType = this.sortingType,
      searchPhrase = this.searchPhrase, siteId = this.props.siteId) {
    blockUtils.blockUI(`#${this.tableId}`);
    switch (this.dataType) {
      case DataTypes.ACTIVE:
        checkinsApi.getActiveBookings(siteId, pageNumber, elementsCount, sortingName,
            sortingType, searchPhrase, this.onBookingsDownload);
        break;
      case DataTypes.HISTORICAL:
        checkinsApi.getHistoricalBookings(siteId, pageNumber, elementsCount, sortingName,
            sortingType, searchPhrase, this.onBookingsDownload);
        break;
    }

    this.pageNumber = pageNumber;
    this.elementsCount = elementsCount;
    this.sortingName = sortingName;
    this.sortingType = sortingType;
    this.searchPhrase = searchPhrase;
  }

  onBookingsDownload() {
    blockUtils.unblockUI(`#${this.tableId}`);
  }

  componentDidMount() {
    BookingSocket.getInstance().connect(this.onBookingRefreshed, this.onKeyRefreshed);
    this.downloadBookings(this.pageNumber, this.elementsCount, 'checkinDate', 'desc');
  }

  onDataTypeChange(dataType) {
    this.dataType = dataType;
    this.downloadBookings(this.pageNumber, this.elementsCount, 'checkinDate', 'desc');
  }

  componentDidUpdate() {
    blockUtils.unblockUI(`#${this.tableId}`);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.forceUpdate || JSON.stringify(nextProps) !== JSON.stringify(this.props);
  }

  onBookingRefreshed(booking) {
    blinkElement($(`#booking_${booking.id}`));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.siteId !== this.props.siteId) {
      this.downloadBookings(this.pageNumber, this.elementsCount, this.sortingName,
          this.sortingType, this.searchPhrase, nextProps.siteId);
    }
  }

  onKeyRefreshed(keyId) {
    const $keyRow = $(`#booking_key_${keyId}`);
    const $parentRow = $keyRow.closest('tr.parent');
    if ($parentRow.length > 0) {// not collapsed
      blinkElement($('tr.child').find(`#booking_key_${keyId}`));
    } else {// row collapsed - blink parent row
      blinkElement($keyRow.closest('tr.collapsible'));
    }
  }

  render() {
    const actions =
            <div className="btn-group btn-group-devided" data-toggle="buttons">
              <RadioAction title={i18next.t('active_data')} groupName="data_type" radioId={DataTypes.ACTIVE}
                active={this.dataType === DataTypes.ACTIVE} onChange={this.onDataTypeChange}/>
              <RadioAction title={i18next.t('historical_data')} groupName="data_type" radioId={DataTypes.HISTORICAL}
                active={this.dataType === DataTypes.HISTORICAL} onChange={this.onDataTypeChange}/>
            </div>;
    return (
      <WhitePortlet
        caption={i18next.t('checkins.name', {count: 2})}
        icon="icon-calendar"
        actions={actions}
        actionsType={'active-switch'}
      >
        <ErrorBox errorMessage={getErrorDescription(this.props.getCheckinsError)}/>
        <ErrorBox errorMessage={getErrorDescription(this.props.destroyCheckinError)}/>
        <ErrorBox errorMessage={getErrorDescription(this.props.updateCheckinError)}/>

        <BookingsList
          checkins={this.props.checkins}
          createCheckinError={this.props.createCheckinError}
          tableId={this.tableId}
          getData={this.downloadBookings}
          defaultSort={[2, 'desc']}
          totalPages={this.props.totalPages}
          totalElements={this.props.totalElements}
        />
      </WhitePortlet>
    );
  }
}

BookingsListContainer.propTypes = {
  checkins: PropTypes.array,
  error: PropTypes.any,
  getCheckinsError: PropTypes.object,
  destroyCheckinError: PropTypes.object,
  createCheckinError: PropTypes.object,
  updateCheckinError: PropTypes.object,
  totalElements: PropTypes.number,
  totalPages: PropTypes.number,
  forceUpdate: PropTypes.bool,
  siteId: PropTypes.number,
};

BookingsListContainer.defaultProps = {};

const mapStateToProps = function(store) {
  return {
    checkins: store.checkinsState.checkins,
    getCheckinError: store.checkinsState.getCheckinError,
    getCheckinsError: store.checkinsState.getCheckinsError,
    createCheckinError: store.checkinsState.createCheckinError,
    updateCheckinError: store.checkinsState.updateCheckinError,
    destroyCheckinError: store.checkinsState.destroyCheckinError,
    totalPages: store.checkinsState.totalPages,
    totalElements: store.checkinsState.totalElements,
    forceUpdate: store.checkinsState.forceUpdate,
    siteId: selectedSiteId(),
  };
};

export default connect(mapStateToProps)(BookingsListContainer);
