import axios from 'axios';
import * as endpoints from './endpoints';

export function getOptionsForKpiGraphs(siteId) {
  return axios.get(`${endpoints.ANALYTICS}/${siteId}/kpi/options`, endpoints.headers());
}

export function getSiteKpis(siteId, chartQueryTime) {
  const params = {chartQueryTime: chartQueryTime};
  return axios.get(`${endpoints.ANALYTICS}/${siteId}/kpi`, endpoints.headers(params));
}

export function getTerminalKpis(siteId, terminalId, chartQueryTime) {
  const params = {chartQueryTime: chartQueryTime};
  return axios.get(`${endpoints.ANALYTICS}/${siteId}/kpi/${terminalId}`, endpoints.headers(params));
}

export function getSiteStatus(siteId, data) {
  return axios.get(`${endpoints.ANALYTICS}/${siteId}`, endpoints.headers(data));
}

export function unlockTerminal(siteId, terminalId, action) {
  return axios.get(`${endpoints.ANALYTICS}/${siteId}/management/${terminalId}/${action}`, endpoints.headers());
}

export function updateAnalyticsOptions(siteId, data) {
  return axios.post(`${endpoints.ANALYTICS}/${siteId}/kpi/options/update`, data, endpoints.headers());
}
