/**
 * @author TomaszCzura ({}
 */
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import ProfileMenu from './../profile/ProfileMenu';

class TopMenu extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="page-top">
        <div className="top-menu">
          <ul className="nav navbar-nav pull-right">
            <ProfileMenu userProfile={this.props.userProfile} />
          </ul>
        </div>
      </div>
    );
  }
}

TopMenu.propTypes = {
  userProfile: PropTypes.object,
};
TopMenu.defaultProps = {};


const mapStateToProps = function(store) {
  return {
    userProfile: store.usersState.userProfile,
  };
};

export default connect(mapStateToProps)(TopMenu);
