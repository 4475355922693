/**
 * @author Tomasz Czura ({}
 */

export function reservationToLocal(reservation) {
  return {
    id: reservation.id,
    siteName: reservation.site.name,
    reservationId: reservation.reservationId,
    checkinDate: reservation.checkinDate,
    checkoutDate: reservation.checkoutDate,
  };
}

export function hasReservations(mobileUser) {
  if (mobileUser.reservations) {
    return mobileUser.reservations.length > 0;
  }
  return false;
}

export function toLocal(mobileUser) {
  return {
    id: mobileUser.id,
    email: mobileUser.email,
    emailVerified: mobileUser.emailVerified,
    registrationDate: mobileUser.registrationDate,
    developerName: mobileUser.developerEmail,
    firstName: mobileUser.firstName,
    lastName: mobileUser.lastName,
    reservations: mobileUser.reservations.map((reservation) => reservationToLocal(reservation)) || [],
    mobileDevices: mobileUser.mobileDevices ? mobileUser.mobileDevices.length : '0',
  };
}

// Shorten a single UUID.
export function toShortUUID(uuid) {
  if (uuid) {
    const parts = uuid.split('-');
    let shortenedUUID = '';
    for (let i = 0; i<parts.length; i++) {
      shortenedUUID += parts[i].charAt(0);
    }
    return shortenedUUID.toUpperCase();
  } else {
    return '';
  }
}
