/**
 * @author Tomasz Czura (8/26/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

class PopoverBtn extends Component {
  componentDidMount() {
    $('.popovers').popover();
  }

  render() {
    return (
      <button className={`${this.props.viewClass} popovers popover_btn`} data-container="body" data-trigger="hover"
        data-placement="bottom"
        data-html="true"
        data-content={this.props.popoverContent}
        data-original-title={this.props.popoverTitle}>{this.props.title}</button>
    );
  }
}

PopoverBtn.propTypes = {
  viewClass: PropTypes.string,
  title: PropTypes.string,
  popoverTitle: PropTypes.string,
  popoverContent: PropTypes.string,
};
PopoverBtn.defaultProps = {};

export default PopoverBtn;
