/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CreateNewButton from '../CreateNewButton';

class WhitePortlet extends Component {
  render() {
    const iconClass = `${this.props.icon}`;

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="portlet light portlet-fit portlet-datatable bordered">
            <div className="portlet-title">
              <div className="caption">
                <i className={iconClass}/>
                <span className="caption-subject sbold uppercase">{this.props.caption}</span>
              </div>
              {this.props.addButtonCallback ?
                <div className={'active-switch'}>
                  <CreateNewButton
                    callBack={this.props.addButtonCallback}
                  />
                </div> :
                null
              }
              <div className={this.props.actionsType === 'active-switch' ? 'active-switch' : 'actions'}>
                {this.props.actions}
              </div>
            </div>
            <div className="portlet-body">
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

WhitePortlet.propTypes = {
  children: PropTypes.node,
  caption: PropTypes.string,
  icon: PropTypes.string,
  actions: PropTypes.any,
  actionsType: PropTypes.string,
  addButtonCallback: PropTypes.func,
};

WhitePortlet.defaultProps = {};

export default WhitePortlet;
