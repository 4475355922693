/**
 * @author Tomasz Czura (9/6/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

class RowsCountSelect extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    if (this.props.onElementsCount) {
      this.props.onElementsCount(e.target.value);
    }
  }

  render() {
    return (
      <div className="col-md-2 col-sm-2 form-group" id={`${this.props.tableId}_length`}>
        <select name="checkins_table_length" aria-controls={this.props.tableId} onChange={this.onChange}
          className="form-control" value={this.props.elementsCount}>
          <option value="10">10 {i18next.t('redux_table.records')}</option>
          <option value="25">25 {i18next.t('redux_table.records')}</option>
          <option value="50">50 {i18next.t('redux_table.records')}</option>
          <option value="100">100 {i18next.t('redux_table.records')}</option>
        </select>
      </div>
    );
  }
}

RowsCountSelect.propTypes = {
  tableId: PropTypes.string,
  onElementsCount: PropTypes.func,
  elementsCount: PropTypes.any,
};
RowsCountSelect.defaultProps = {};

export default RowsCountSelect;
