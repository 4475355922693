/**
 * @author Tomasz Czura (11/11/16)
 */

export function getBatteryStatusInfo(status) {
  switch (parseInt(status)) {
    case 0x00: return 'BATTERY_OK';
    case 0x01: return 'BATTERY_LOW';
    case 0x02: return 'BATTERY_VERY_LOW';
    case 0x03: return 'BATTERY_REMOVED';
    case 0x04: return 'BATTERY_NEW';
    case 0xFF: return 'BATTERY_UNKNOWN';
  }
}
