/**
 * @author TomaszCzura ({}
 */

export const SiteTypes = [
  {id: 'TEST', name: 'Test'},
  {id: 'DEMO', name: 'Demo'},
  {id: 'PRODUCTION', name: 'Production'},
];

export function toNetwork(site) {
  return {
    id: site.id,
    name: site.name,
    contactEmail: site.contactEmail || null,
    language: site.language || 'en',
    siteType: site.siteType,
    usercontactPhone: site.usercontactPhone || null,
    logoImageUrl: site.logoImageUrl || null,
    usercontactAddress: site.usercontactAddress || null,
    usercontactLatitude: site.usercontactLatitude || null,
    usercontactLongitude: site.usercontactLongitude || null,
    defaultSpecialAreas: site.defaultSpecialAreas || null,
    defaultCheckinTime: site.defaultCheckinTime || null,
    defaultCheckoutTime: site.defaultCheckoutTime || null,
    disconnectionNotifications: site.disconnectionNotifications,
    token: site.token,
    disabled: site.disabled,
    externalId: site.externalId,
    projectCode: site.projectCode,
    hmsConnected: site.hmsConnected,
    siteAgentVersion: site.siteAgentVersion,
    appTechnology: site.appTechnology,
    gatewayId: site.gatewayId,
  };
}

export function toForm(site) {
  return {
    id: site.id,
    name: site.name,
    nameError: '',
    contactEmail: site.contactEmail || '',
    language: site.language || 'en',
    siteType: site.site ? site.site.siteType : site.siteType,
    usercontactPhone: site.usercontactPhone || null,
    logoImageUrl: site.logoImageUrl || null,
    usercontactAddress: site.usercontactAddress || null,
    usercontactLatitude: site.usercontactLatitude || null,
    usercontactLongitude: site.usercontactLongitude || null,
    defaultSpecialAreas: site.defaultSpecialAreas || '',
    defaultCheckinTime: site.defaultCheckinTime || '15:00',
    defaultCheckoutTime: site.defaultCheckoutTime || '12:00',
    disconnectionNotifications: site.disconnectionNotifications || false,
    token: site.token || '',
    disabled: '',
    externalId: site.externalId,
    projectCode: site.projectCode,
    connected: site.connected,
    hmsConnected: site.hmsConnected,
    siteAgentVersion: site.siteAgentVersion || '',
    appTechnology: site.appTechnology || 'SPK',
    gatewayId: site.gatewayId || '',
  };
}

export function defaultSite() {
  return {
    name: '',
    nameError: '',
    contactEmail: '',
    language: 'en',
    siteType: 'TEST',
    usercontactPhone: '',
    logoImageUrl: '',
    usercontactAddress: '',
    usercontactLatitude: '',
    usercontactLongitude: '',
    defaultSpecialAreas: '',
    defaultCheckinTime: '15:00',
    defaultCheckoutTime: '12:00',
    disconnectionNotifications: false,
    token: '',
    disabled: '',
    externalId: '',
    projectCode: '',
    siteAgentVersion: '',
    appTechnology: 'SPK',
    gatewayId: '',
  };
}
