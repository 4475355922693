/**
 * @author TomaszCzura ({}
 */
import {Component} from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

class FormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.onChange = this.onChange.bind(this);
    this.onCheckboxChanged = this.onCheckboxChanged.bind(this);
    this.onFormShow = this.onFormShow.bind(this);
    this.validateRequiredField = this.validateRequiredField.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  onFormShow() {
    this.setState(this.initialState());
    this.forceUpdate();
  }

  validateAllFields(formId, textKey) {
    const keyIdentifier = '.' + formId + ' :input';
    const inputs = $(keyIdentifier);
    for (let i = 0; i < inputs.length; i++) {
      const $input = $(inputs[i]);
      const fieldName = $input.attr('name');
      if (!fieldName) {
        continue;
      }

      if ($input.attr('type') === 'checkbox') {
        continue;
      }

      const valueIsValid = this.validateField(textKey, fieldName);
      if (valueIsValid === false) {
        return false;
      }
    }

    return true;
  }

  validateField(textKey, fieldName) {
    const value = this.state[fieldName];
    const fieldText = i18next.t(textKey + fieldName);
    return this.validateRequiredField(value, fieldText, fieldName + 'Error');
  }

  onChange(e) {
    const state = {};
    state[e.target.name] = e.target.value;
    this.setState(state);
  }

  onCheckboxChanged(e) {
    const state = {};
    state[e.target.name] = e.target.checked;
    this.setState(state);
  }

  handleError(error) {
    this.setState({
      error: error,
    });
  }

  validateRequiredField(fieldValue, fieldName, errorName) {
    const state = {};
    if (fieldValue && fieldValue != '-1') {
      state[errorName] = '';
      this.setState(state);
      return true;
    } else {
      state[errorName] = i18next.t('errors.is_required', {field: fieldName});
      this.setState(state);
      return false;
    }
  }
}

FormComponent.propTypes = {
  error: PropTypes.any,
};
FormComponent.defaultProps = {};

export default FormComponent;
