/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import {connect} from 'react-redux';
import * as sitesApi from './../../api/sites-api';
import store from '../../store';
import {changeCurrentSite} from '../../actions/sites-actions';
import Select from 'react-select';

class HotelSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };

    this.onHotelChange = this.onHotelChange.bind(this);
  }

  componentDidMount() {
    sitesApi.getSites();
  }

  componentDidUpdate() {
    if (this.state.isLoading) {
      this.setState({isLoading: false});
    }
  }

  onHotelChange(e) {
    store.dispatch(changeCurrentSite(e.value));
  }

  render() {
    const sites = this.props.userProfile.simpleSites && this.props.userProfile.simpleSites.length ?
      this.props.userProfile.simpleSites : [];

    const options = [];
    options.push({value: '-1', label: `${i18next.t('sites.all')}`});
    sites.map((site) => options.push({value: site.id, label: site.name}));
    return (
      <div style={{width: '600px'}}>
        {this.state.isLoading ?
        (
          <img src="/assets/metronic/global/img/input-spinner.gif"
            className="pull-right"
            id="top_hotel_selector_loading"/>
        ) : null}
        {sites.length > 1 ?
        (
          <Select
            id="top_hotel_selector"
            onChange={this.onHotelChange}
            disabled={this.state.isLoading}
            placeholder={<div>{i18next.t('search_for_site')}</div>}
            options={options}
          />
        ) : null}

      </div>

    );
  }
}

HotelSelector.propTypes = {
  userProfile: PropTypes.object,
};
HotelSelector.defaultProps = {};

const mapStateToProps = function(store) {
  return {
    userProfile: store.usersState.userProfile || {},
  };
};

export default connect(mapStateToProps)(HotelSelector);
