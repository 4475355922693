/**
 * @author Tomasz Czura (8/18/16)
 */

export function isValidEmail(email) {
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
}

export function isValidSpecialArea(input) {
  const regex = /^[0-9]+$/;
  return regex.test(input);
}

export function isValidTime(input) {
  const regex = /^$|^(([01][0-9])|(2[0-3])):[0-5][0-9]$/;
  return regex.test(input);
}
