/**
 * @author TomaszCzura ({}
 */
import * as types from './action-types';

export function getTransactionLogsSuccess(logs, totalPages, totalElements) {
  return {
    type: types.GET_TRANSACTION_LOGS_SUCCESS,
    logs, totalPages, totalElements,
  };
}

export function getTransactionLogsError(error) {
  return {
    type: types.GET_TRANSACTION_LOGS_ERROR,
    error,
  };
}
