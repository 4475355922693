/**
 * @author TomaszCzura ({}
 */
import * as types from '../actions/action-types';
import {isSiteSelected, selectedSiteId} from '../store';
import {DashboardMessageTypes} from '../socket/DashboardSocket';

const initialState = {
  batteryAlerts: [],
  feeds: [],
  changeLogs: [],
};

const dashboardReducer = function(state = initialState, action) {
  let newFeed = {};
  switch (action.type) {
    case types.GET_DASHBOARD_STATS:
      return Object.assign({}, state, {
        batteryAlerts: action.stats.batteryAlerts,
        feeds: action.stats.feeds,
        changeLogs: action.stats.changeLogs,
      });
    case types.NEW_MOBILE_USER:
      newFeed = {
        type: DashboardMessageTypes.NEW_MOBILE_USER,
        date: new Date().getTime(),
      };
      return Object.assign({}, state, {
        allMobileUsers: state.allMobileUsers + 1,
        feeds: [newFeed, ...state.feeds],
      });
    case types.ADD_CHANGE_LOG:
      return Object.assign({}, state, {
        changeLogs: [action.changelog, ...state.changeLogs],
      });
    case types.EDIT_CHANGE_LOG:
      const updatedChangelog = action.changelog;
      const newChangeLogs = state.changeLogs.map((changelog) => changelog.id === updatedChangelog.id ? updatedChangelog : changelog);
      return Object.assign({}, state, {
        changeLogs: newChangeLogs,
      });
    case types.DELETE_CHANGE_LOG:
      const updatedArray = state.changeLogs.filter(function(obj) {
        return obj.versionNumber !== action.versionNumber;
      });
      return Object.assign({}, state, {
        changeLogs: updatedArray,
      });
    case types.KEY_ISSUED:
      if (isSiteSelected() && selectedSiteId() === action.message.siteId || !isSiteSelected()) {
        newFeed.message = action.message;
        newFeed.type = DashboardMessageTypes.KEY_ISSUED;
        newFeed.date = new Date().getTime();

        return Object.assign({}, state, {
          feeds: [newFeed, ...state.feeds],
        });
      }
      break;
    case types.DOOR_OPENING:
      if (isSiteSelected() && selectedSiteId() === action.data.siteId || !isSiteSelected()) {
        const newAlerts = updateBatteryAlerts(state, action.data);

        newFeed = action.data;
        newFeed.type = DashboardMessageTypes.DOOR_OPENING;
        newFeed.date = new Date().getTime();

        return Object.assign({}, state, {
          batteryAlerts: newAlerts,
          feeds: [action.data, ...state.feeds],
        });
      }
      break;
    case types.SITE_AGENT_CONNECTION_CHANGED:
      if (isSiteSelected() && selectedSiteId() === action.data.siteId || !isSiteSelected()) {
        newFeed = action.data;
        newFeed.type = DashboardMessageTypes.SITE_CONNECTION_CHANGE;
        newFeed.date = new Date().getTime();
        return Object.assign({}, state, {
          feeds: [newFeed, ...state.feeds],
        });
      }
      break;
  }
  return state;
};

function updateBatteryAlerts(state, data) {
  let newAlerts = [];
  if (!data.success || !data.showBatteryAlert) return [...state.batteryAlerts];
  const batteryAlert = state.batteryAlerts.filter((alert) => alert.siteId === data.siteId && alert.room === data.room);
  if (batteryAlert.length > 0) {
    batteryAlert[0].batteryHealth = data.batteryHealth;
    batteryAlert[0].batteryLevel = data.batteryLevel;
    batteryAlert[0].date = new Date().getTime();
    newAlerts = [...state.batteryAlerts];
  } else {
    const newAlert = {
      siteId: data.siteId,
      room: data.room,
      siteName: data.siteName,
      batteryLevel: data.batteryLevel,
      batteryHealth: data.batteryHealth,
      date: new Date().getTime(),
    };
    newAlerts = [newAlert, ...state.batteryAlerts];
  }
  return newAlerts;
}

export default dashboardReducer;
