/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';

class Logo extends Component {
  render() {
    return (
      <div className="page-logo">
        <a href='/'>
          <img src="/assets/images/hafele_icon.png" className="logo-default"/>
        </a>

        <div className="menu-toggler sidebar-toggler"></div>
      </div>
    );
  }
}

Logo.propTypes = {};
Logo.defaultProps = {};

export default Logo;
