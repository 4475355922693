import FormComponent from '../utils/FormComponent';
import * as api from '../../api/analytics-api';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import ModalDialog from '../utils/ModalDialog';
import * as blockUtils from '../../utils/BlockUtils';
import Form from '../utils/Form';
import * as viewUtils from '../../utils/view-utils';
import TextInput from '../utils/TextInput';
import React from 'react';

const TEXT_KEY = 'terminals.options.';

class AnalyticsOptionsForm extends FormComponent {
  constructor(props) {
    super(props);

    this.sendForm = this.sendForm.bind(this);

    this.state = {
      minimumUptime: '',
      minimumWebUnlockSuccessRate: '',
      maximumWebUnlockLatency: '',
      minimumBatteryPercentage: '',
      minimumBatteryDaysRemaining: '',
      maximumBatteryFluctuation: '',
      minimumFirmwareVersionString: '',
      maximumTimeDeviation: '',
    };
  }

  initialState() {
    blockUtils.blockUI();
    api.getOptionsForKpiGraphs(this.props.siteId).then((response) => {
      const options = response.data;
      this.setState({
        minimumUptime: options.minimumUptime,
        minimumWebUnlockSuccessRate: options.minimumWebUnlockSuccessRate,
        maximumWebUnlockLatency: options.maximumWebUnlockLatency,
        minimumBatteryPercentage: options.minimumBatteryPercentage,
        minimumBatteryDaysRemaining: options.minimumBatteryDaysRemaining,
        maximumBatteryFluctuation: options.maximumBatteryFluctuation,
        minimumFirmwareVersionString: options.minimumFirmwareVersionString,
        maximumTimeDeviation: options.maximumTimeDeviation,
      });
      blockUtils.unblockUI();
    }).catch((error) => {
      blockUtils.unblockUI();
      window.showAlert(i18next.t('error'), viewUtils.getErrorDescription(error));
    });
  }

  sendForm() {
    if (this.validateAllFields('analytics-options-form', TEXT_KEY) === false) {
      return;
    }

    blockUtils.blockUI();
    api.updateAnalyticsOptions(this.props.siteId, this.state).then((response) => {
      blockUtils.unblockUI();
      $(`#${this.props.dialogId}`).modal('hide');
      return response;
    }).catch((error) => {
      blockUtils.unblockUI();
      window.showAlert(i18next.t('error'), viewUtils.getErrorDescription(error));
    });
  }

  render() {
    return (
      <ModalDialog
        customClass="analytics-options-form"
        modalTitle={i18next.t('terminals.edit_analytics_options')}
        dialogId={this.props.dialogId}
        onDialogHide={blockUtils.unblockUI}
        onDialogShown={this.onFormShow}
        onSubmit={this.sendForm}>

        <Form>
          <TextInput
            fieldId="minimumUptime"
            labelText={i18next.t('terminals.options.minimumUptime')}
            onChange={this.onChange}
            value={this.state.minimumUptime}
            isRequired={true}
            validate={() => this.validateField(TEXT_KEY, 'minimumUptime')}
            error={this.state.minimumUptimeError}
          />

          <TextInput
            fieldId="minimumWebUnlockSuccessRate"
            labelText={i18next.t('terminals.options.minimumWebUnlockSuccessRate')}
            onChange={this.onChange}
            value={this.state.minimumWebUnlockSuccessRate}
            isRequired={true}
            validate={() => this.validateField(TEXT_KEY, 'minimumWebUnlockSuccessRate')}
            error={this.state.minimumWebUnlockSuccessRateError}
          />

          <TextInput
            fieldId="maximumWebUnlockLatency"
            labelText={i18next.t('terminals.options.maximumWebUnlockLatency')}
            onChange={this.onChange}
            value={this.state.maximumWebUnlockLatency}
            isRequired={true}
            validate={() => this.validateField(TEXT_KEY, 'maximumWebUnlockLatency')}
            error={this.state.maximumWebUnlockLatencyError}
          />

          <TextInput
            fieldId="minimumBatteryPercentage"
            labelText={i18next.t('terminals.options.minimumBatteryPercentage')}
            onChange={this.onChange}
            value={this.state.minimumBatteryPercentage}
            isRequired={true}
            validate={() => this.validateField(TEXT_KEY, 'minimumBatteryPercentage')}
            error={this.state.minimumBatteryPercentageError}
          />

          <TextInput
            fieldId="minimumBatteryDaysRemaining"
            labelText={i18next.t('terminals.options.minimumBatteryDaysRemaining')}
            onChange={this.onChange}
            value={this.state.minimumBatteryDaysRemaining}
            isRequired={true}
            validate={() => this.validateField(TEXT_KEY, 'minimumBatteryDaysRemaining')}
            error={this.state.minimumBatteryDaysRemainingError}
          />

          <TextInput
            fieldId="maximumBatteryFluctuation"
            labelText={i18next.t('terminals.options.maximumBatteryFluctuation')}
            onChange={this.onChange}
            value={this.state.maximumBatteryFluctuation}
            isRequired={true}
            validate={() => this.validateField(TEXT_KEY, 'maximumBatteryFluctuation')}
            error={this.state.maximumBatteryFluctuationError}
          />

          <TextInput
            fieldId="minimumFirmwareVersionString"
            labelText={i18next.t('terminals.options.minimumFirmwareVersionString')}
            onChange={this.onChange}
            value={this.state.minimumFirmwareVersionString}
            isRequired={true}
            validate={() => this.validateField(TEXT_KEY, 'minimumFirmwareVersionString')}
            error={this.state.minimumFirmwareVersionStringError}
          />

          <TextInput
            fieldId="maximumTimeDeviation"
            labelText={i18next.t('terminals.options.maximumTimeDeviation')}
            onChange={this.onChange}
            value={this.state.maximumTimeDeviation}
            isRequired={true}
            validate={() => this.validateField(TEXT_KEY, 'maximumTimeDeviation')}
            error={this.state.maximumTimeDeviationError}
          />

        </Form>
      </ModalDialog>
    );
  }
}

AnalyticsOptionsForm.propTypes = {
  siteId: PropTypes.number,
  dialogId: PropTypes.string,
};

export default AnalyticsOptionsForm;
