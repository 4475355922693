/**
 * @author TomaszCzura ({}
 */
import React from 'react';
import PropTypes from 'prop-types';
import Logo from './Logo';
import Actions from './Actions';
import TopMenu from './TopMenu';

class Header extends React.Component {
  render() {
    return (
      <header className="page-header navbar navbar-fixed-top">
        <div className="page-header-inner">
          <Logo/>
          <a href="#"
            className="menu-toggler responsive-toggler"
            data-toggle="collapse"
            data-target=".navbar-collapse"
          />
          <Actions/>
          <TopMenu profile={this.props.userProfile}/>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  userProfile: PropTypes.object,
};

export default Header;
