/**
 * @author Tomasz Czura (11/18/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import WhitePortlet from '../utils/WhitePortlet';
import i18next from 'i18next';
import {connect} from 'react-redux';
import ErrorBox from './../utils/ErrorBox';
import * as viewUtils from './../../utils/view-utils';
import * as blockUtils from './../../utils/BlockUtils';
import * as siteAgentLogsApi from './../../api/site-agent-api';
import LogDetails from './LogDetails';

class LogDetailsContainer extends Component {
  componentDidMount() {
    siteAgentLogsApi.getLog(this.props.params.siteId, this.props.params.logId);
    blockUtils.blockUI();
  }

  UNSAFE_componentWillUpdate() {
    blockUtils.unblockUI();
  }

  render() {
    const caption = this.props.log ?
      i18next.t('site_agent_logs.details') +
      viewUtils.toYYYYMMDDHMS(this.props.log.createdAt) :
      i18next.t('site_agent_logs.details_error');

    return (
      <WhitePortlet caption={caption} icon="icon-list">
        <ErrorBox errorMessage={viewUtils.getErrorDescription(this.props.getLogError)}/>
        <LogDetails log={this.props.log}/>
      </WhitePortlet>
    );
  }
}

LogDetailsContainer.propTypes = {
  log: PropTypes.object,
  getLogError: PropTypes.object,
  params: PropTypes.any,
};
LogDetailsContainer.defaultProps = {};

const mapStateToProps = function(store) {
  return {
    log: store.siteAgentLogsState.presentedLog,
    getLogError: store.siteAgentLogsState.getLogError,
  };
};

export default connect(mapStateToProps)(LogDetailsContainer);
