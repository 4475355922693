/**
 * @author Tomasz Czura (8/18/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ErrorBox extends Component {
  render() {
    return (
      <div>
        {this.props.errorMessage ? (
                    <div className="alert alert-danger">{this.props.errorMessage}</div>
                ) : null}
      </div>
    );
  }
}

ErrorBox.propTypes = {
  errorMessage: PropTypes.string,
};
ErrorBox.defaultProps = {};

export default ErrorBox;
