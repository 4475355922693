/**
 * @author TomaszCzura ({}
 */
import React, {
  Component,
} from 'react';

class Footer extends Component {
  render() {
    if (typeof ExtraTextInFooter !== 'undefined') {
      return (
        <footer className="page-footer">
          <div className="page-footer-inner">
            2016 - {new Date().getFullYear()} &copy; Häfele<br/>
            <b>{eval('ExtraTextInFooter')}</b>
          </div>
        </footer>
      );
    }
    return (
      <footer className="page-footer">
        <div className="page-footer-inner">
          2016 - {new Date().getFullYear()} &copy; Häfele
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {};
Footer.defaultProps = {};

export default Footer;
