/**
 * @author Tomasz Czura (9/7/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import WhitePortlet from '../utils/WhitePortlet';
import * as devApi from '../../api/developers-api';
import i18next from 'i18next';
import * as blockUtils from './../../utils/BlockUtils';
import DeveloperDetails from './DeveloperDetails';
import ErrorBox from './../utils/ErrorBox';
import * as viewUtils from './../../utils/view-utils';

class DevelopersDetailsContainer extends Component {
  componentDidMount() {
    blockUtils.blockUI();
    devApi.getDeveloper(this.props.params.developerId);
  }

  UNSAFE_componentWillUpdate() {
    blockUtils.unblockUI();
  }

  render() {
    const caption = `${i18next.t('developers.name')} (#${this.props.params.developerId})`;

    return (
      <WhitePortlet caption={caption} icon="icon-screen-desktop">
        <ErrorBox errorMessage={viewUtils.getErrorDescription(this.props.getDeveloperError)}/>

        <DeveloperDetails presentedDeveloper={this.props.presentedDeveloper}/>
      </WhitePortlet>
    );
  }
}

DevelopersDetailsContainer.propTypes = {
  params: PropTypes.any,
  getDeveloperError: PropTypes.object,
  presentedDeveloper: PropTypes.object,
};

DevelopersDetailsContainer.defaultProps = {};

const mapStateToProps = function(store) {
  return {
    presentedDeveloper: store.developersState.presentedDeveloper,
    getDeveloperError: store.developersState.getDeveloperError,
  };
};

export default connect(mapStateToProps)(DevelopersDetailsContainer);
