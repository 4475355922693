/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

class Status extends Component {
  render() {
    return (
      <span className={`${this.props.online ? 'text-connected' : 'text-disconnected'}`}>
        {this.props.online ?
          i18next.t('sites.attrs.hms_status_connected') :
          i18next.t('sites.attrs.hms_status_disconnected')}
      </span>
    );
  }
}

Status.propTypes = {
  online: PropTypes.bool,
};
Status.defaultProps = {};

export default Status;
