/**
 * @author Tomasz Czura (11/18/16)
 */
import * as types from './action-types';

export function getLogsSuccess(logs) {
  return {
    type: types.GET_SITE_AGENT_LOGS_SUCCESS,
    logs,
  };
}

export function getLogsError(error) {
  return {
    type: types.GET_SITE_AGENT_LOGS_ERROR,
    error,
  };
}

export function getLogSuccess(log) {
  return {
    type: types.GET_SITE_AGENT_LOG_SUCCESS,
    log,
  };
}

export function getLogError(error) {
  return {
    type: types.GET_SITE_AGENT_LOG_ERROR,
    error,
  };
}
