/**
 * @author Tomasz Czura (11/10/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DataTable from './../utils/DataTable';
import i18next from 'i18next';
import * as viewUtils from './../../utils/view-utils';

class BillingList extends Component {
  constructor(props) {
    super(props);

    this.tableId = 'billing_table';
  }

  render() {
    return (
      <div>
        <DataTable tableId={this.tableId}
          defaultSort={[3, 'desc']}
          showLoading={false}
          csvExportBtn={this.props.billing.length > 0}
          csvFilename={this.props.csvFilename}>
          <thead>
            <tr>
              <th>{i18next.t('billing.attrs.res_id')}</th>
              <th>{i18next.t('billing.attrs.room_number')}</th>
              <th>{i18next.t('checkins.attrs.start_date')}</th>
              <th>{i18next.t('checkins.attrs.end_date')}</th>
              <th>{i18next.t('billing.attrs.created_at')}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.billing.map((billing) => {
              return (
                <tr key={billing.id}>
                  <td>{billing.externalReservationId}</td>
                  <td>{billing.roomNumber}</td>
                  <td>{viewUtils.toYYYYMMDDHM(billing.checkIn)}</td>
                  <td>{viewUtils.toYYYYMMDDHM(billing.checkOut)}</td>
                  <td>{viewUtils.toYYYYMMDDHM(billing.createdAt)}</td>
                </tr>
              );
            })}
          </tbody>
        </DataTable>
      </div>
    );
  }
}

BillingList.propTypes = {
  billing: PropTypes.array,
  csvFilename: PropTypes.string,
};
BillingList.defaultProps = {};

export default BillingList;
