/**
 * @author Tomasz Czura (11/23/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import WhitePortlet from './../utils/WhitePortlet';
import i18next from 'i18next';
import FeedRow from './FeedRow';

class Feeds extends Component {
  render() {
    return (
      <div className="col-md-6 col-sm-12 col-xs-12">
        <WhitePortlet caption={i18next.t('dashboard.feeds')} icon="icon-info">
          <div className="scrolled_div tile">
            <ul className="feeds">
              {this.props.feeds.map((feed, index) => {
                return (
                  <FeedRow feed={feed} key={index}/>
                );
              })}
            </ul>
          </div>
        </WhitePortlet>
      </div>
    );
  }
}

Feeds.propTypes = {
  feeds: PropTypes.array,
};
Feeds.defaultProps = {};

export default Feeds;
