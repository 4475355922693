/**
 * @author TomaszCzura ({}
 */
import {Component} from 'react';
import PropTypes from 'prop-types';
import * as rights from './../../model/rights';

class RoleBasedComponent extends Component {
  constructor(props) {
    super(props);
    this.checkRights = this.checkRights.bind(this);
  }

  checkRights(onSuccess, onFailure = null) {
    const componentRights = this.getRights();
    if (!rights.currentUserHasRight(componentRights)) {
      if (onFailure) {
        onFailure();
      } else {
        this.props.router.replace('/');
      }
    } else {
      if (onSuccess) {
        onSuccess();
      }
    }
  }
}

RoleBasedComponent.propTypes = {
  router: PropTypes.any,
};
RoleBasedComponent.defaultProps = {};

export default RoleBasedComponent;
