/**
 * @author TomaszCzura ({}
 */
import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import UsersList from './UsersList';
import WhitePortlet from '../utils/WhitePortlet';
import RoleBasedComponent from '../utils/RoleBasedComponent';
import * as rights from '../../model/rights';
import i18next from 'i18next';
import * as usersApi from './../../api/users-api';
import {connect} from 'react-redux';
import {isSiteSelected, selectedSiteId} from '../../store';
import ErrorBox from './../utils/ErrorBox';
import {getErrorDescription} from '../../utils/view-utils';

class UsersListContainer extends RoleBasedComponent {
  componentDidMount() {
    this.checkRights(() => usersApi.getUsers());
    usersApi.getUserProfile();
  }

  getRights() {
    return rights.USER_READ_PRIVILEGE;
  }

  render() {
    return (
      <WhitePortlet caption={i18next.t('users.name', {count: 2})} icon="icon-users">
        <ErrorBox errorMessage={getErrorDescription(this.props.getUsersError)}/>
        <ErrorBox errorMessage={getErrorDescription(this.props.destroyUserError)}/>

        <UsersList users={this.props.users} createUserError={this.props.createUserError}
          availableRoles={this.props.userProfile.availableRoles}
          sites={this.props.userProfile.sites.filter((site) => site.selectedByUser)}
        />
      </WhitePortlet>
    );
  }
}

UsersListContainer.propTypes = {
  users: PropTypes.array,
  error: PropTypes.any,
};
UsersListContainer.defaultProps = {
  users: [],
  getUsersError: PropTypes.object,
  destroyUserError: PropTypes.object,
  createUserError: PropTypes.object,
};

const mapStateToProps = function(store) {
  let users = store.usersState.users;
  if (isSiteSelected()) {
    const siteId = selectedSiteId();
    users = users.filter((user) => user.siteId === siteId || user.siteId === -1);
  }
  return {
    users: users,
    destroyUserError: store.usersState.destroyUserError,
    createUserError: store.usersState.createUserError,
    getUsersError: store.usersState.getUsersError,
    userProfile: store.usersState.userProfile,
  };
};

export default connect(mapStateToProps)(withRouter(UsersListContainer));
