/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

class ConfirmDialog extends Component {
  constructor(props) {
    super(props);
    this.abort = this.abort.bind(this);
    this.confirm = this.confirm.bind(this);
  }

  abort() {
    $('#confirmation_dialog').modal('hide');
  }

  confirm() {
    $('#confirmation_dialog').modal('hide');
    this.props.onConfirm();
  }

  render() {
    return (
      <div
        className="modal fade"
        id="confirmation_dialog"
        role="basic"
        aria-hidden="false"
        data-keyboard="false"
        data-backdrop="static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              />
              <h4 className="modal-title">
                {i18next.t('confirmation.warning')}
              </h4>
            </div>
            <div className="modal-body">{this.props.message}</div>
            <div className="modal-footer">
              <button type="button" className="btn dark btn-outline" onClick={this.abort}>
                {i18next.t('confirmation.no')}
              </button>

              <button type="button" className="btn red" onClick={this.confirm}>
                {i18next.t('confirmation.yes')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ConfirmDialog.propTypes = {
  onConfirm: PropTypes.func,
  message: PropTypes.string,
};
ConfirmDialog.defaultProps = {};

export default ConfirmDialog;
