/**
 * @author TomaszCzura ({}
 */
import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import * as siteModel from './../../model/site';
import * as blockUtils from './../../utils/BlockUtils';
import FormComponent from './../utils/FormComponent';
import Form from './../utils/Form';
import ModalDialog from './../utils/ModalDialog';
import TextInput from './../utils/TextInput';
import Checkbox from './../utils/Checkbox';
import * as sitesApi from './../../api/sites-api';
import {isValidEmail} from '../../utils/validate-utils';
import ErrorBox from './../utils/ErrorBox';
import {getErrorDescription} from '../../utils/view-utils';
import SelectInput from './../utils/SelectInput';
import * as user from '../../model/user';
import * as site from '../../model/site';

class SiteForm extends FormComponent {
  constructor(props) {
    super(props);
    this.sendForm = this.sendForm.bind(this);
    this.validateName = this.validateName.bind(this);
    this.validateContactEmail = this.validateContactEmail.bind(this);
    this.onDisconnectionNotificationChanged = this.onDisconnectionNotificationChanged.bind(this);
  }

  sendForm() {
    const validName = this.validateName();
    const validEmail = this.validateContactEmail();

    if (validEmail && validName) {
      if (this.state.id) {
        sitesApi.updateSite(this.state);
      } else {
        sitesApi.createSite(this.state);
      }

      blockUtils.blockUI();
    }
  }

  initialState() {
    const state = this.props.presentedSite ? siteModel.toForm(this.props.presentedSite) : siteModel.defaultSite();
    state['nameError'] = '';
    state['emailError'] = '';
    state['error'] = '';
    return state;
  }

  onDisconnectionNotificationChanged(e) {
    const state = {};
    const isEnabled = e.target.checked;
    state[e.target.name] = isEnabled;
    if (!isEnabled && !this.state.contactEmail) {
      state['emailError'] = '';
    }
    this.setState(state);
  }

  validateName(name = this.state.name) {
    return this.validateRequiredField(name, i18next.t('sites.attrs.name'), 'nameError');
  }

  validateContactEmail(email = this.state.contactEmail) {
    if (email) {
      if (isValidEmail(email)) {
        this.setState({emailError: ''});
        return true;
      } else {
        this.setState({
          emailError: i18next.t('errors.invalid_format', {
            field: i18next.t('sites.attrs.contact_email'),
          }),
        });
        return false;
      }
    } else if (this.state.disconnectionNotifications) {
      this.setState({
        emailError: i18next.t('errors.is_required', {
          field: i18next.t('sites.attrs.contact_email'),
        }),
      });
      return false;
    } else {
      this.setState({
        emailError: '',
      });
      return true;
    }
  }

  handleError(errorMessage) {
    this.setState({
      error: errorMessage,
    });
  }

  render() {
    const modalTitle = this.state.id ?
      i18next.t('sites.edit', {
        id: this.state.id,
      }) : i18next.t('sites.create_new');

    const appTechnologyList = [
      {id: 'SPK', name: 'SPK'},
      {id: 'PWA', name: 'PWA'},
    ];

    return (
      <ModalDialog modalTitle={modalTitle}
        dialogId={this.props.dialogId}
        onDialogHide={blockUtils.unblockUI}
        onDialogShown={this.onFormShow}
        onSubmit={this.sendForm}>
        <Form>
          <ErrorBox errorMessage={getErrorDescription(this.state.error)} />

          <TextInput fieldId="name"
            labelText={i18next.t('sites.attrs.name')}
            isRequired={true}
            error={this.state.nameError}
            validate={this.validateName}
            onChange={this.onChange}
            value={this.state.name} />

          <TextInput fieldId="externalId"
            labelText={i18next.t('sites.attrs.external_id')}
            onChange={this.onChange}
            value={this.state.externalId} />

          <TextInput fieldId="projectCode"
            labelText={i18next.t('sites.attrs.project_code')}
            onChange={this.onChange}
            value={this.state.projectCode} />

          <Checkbox fieldId="disconnectionNotifications"
            labelText={i18next.t('sites.attrs.disconnection_notifications')}
            onChange={this.onDisconnectionNotificationChanged}
            value={this.state.disconnectionNotifications} />

          <TextInput fieldId="contactEmail"
            labelText={i18next.t('sites.attrs.contact_email')}
            isRequired={this.state.disconnectionNotifications}
            validate={this.validateContactEmail}
            error={this.state.emailError}
            onChange={this.onChange}
            value={this.state.contactEmail} />

          <SelectInput fieldId="language" labelText={i18next.t('users.attrs.language')} selected={this.state.language}
            onChange={this.onChange} values={user.UserLanguages} />

          <SelectInput fieldId="siteType" labelText={i18next.t('sites.attrs.siteType')} selected={this.state.siteType}
            onChange={this.onChange} values={site.SiteTypes} />

          <TextInput fieldId="usercontactPhone"
            labelText={i18next.t('sites.attrs.phone')}
            isRequired={false}
            onChange={this.onChange}
            value={this.state.usercontactPhone} />

          <TextInput fieldId="logoImageUrl"
            labelText={i18next.t('sites.attrs.logo_url')}
            isRequired={false}
            onChange={this.onChange}
            value={this.state.logoImageUrl} />

          <TextInput fieldId="usercontactAddress"
            labelText={i18next.t('sites.attrs.address')}
            isRequired={false}
            onChange={this.onChange}
            value={this.state.usercontactAddress} />

          <TextInput fieldId="usercontactLatitude"
            labelText={i18next.t('sites.attrs.latitude')}
            placeholder={i18next.t('sites.attrs.latitude_hint')}
            isRequired={false}
            onChange={this.onChange}
            value={this.state.usercontactLatitude} />

          <TextInput fieldId="usercontactLongitude"
            labelText={i18next.t('sites.attrs.longitude')}
            placeholder={i18next.t('sites.attrs.longitude_hint')}
            isRequired={false}
            onChange={this.onChange}
            value={this.state.usercontactLongitude} />

          <TextInput fieldId="token"
            labelText={i18next.t('sites.attrs.token')}
            onChange={this.onChange}
            value={this.state.token} />

          <SelectInput
            fieldId="appTechnology"
            labelText={i18next.t('sites.attrs.app_technology')}
            selected={this.state.appTechnology}
            onChange={this.onChange}
            values={appTechnologyList}
            isRequired={true}
          />

          <TextInput fieldId="gatewayId"
            labelText={i18next.t('sites.attrs.gatewayId')}
            onChange={this.onChange}
            value={this.state.gatewayId} />
        </Form>
      </ModalDialog>
    );
  }
}

SiteForm.propTypes = {
  dialogId: PropTypes.string,
  presentedSite: PropTypes.object,
  error: PropTypes.any,
};

SiteForm.defaultProps = {};

export default SiteForm;
