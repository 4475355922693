/**
 * @author Tomasz Czura (9/7/16)
 */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import WhitePortlet from '../utils/WhitePortlet';
import RoleBasedComponent from '../utils/RoleBasedComponent';
import i18next from 'i18next';
import * as rights from '../../model/rights';
import * as filesApi from './../../api/files-api';
import ErrorBox from './../utils/ErrorBox';
import {getErrorDescription} from '../../utils/view-utils';
import FilesList from './FilesList';

class FilesListContainer extends RoleBasedComponent {
  componentDidMount() {
    this.checkRights(() => filesApi.getFiles());
  }

  getRights() {
    return rights.FILE_READ_PRIVILEGE;
  }

  render() {
    return (
      <WhitePortlet caption={i18next.t('files.name', {count: 2})} icon="icon-docs">
        <ErrorBox errorMessage={getErrorDescription(this.props.getFilesError)}/>
        <ErrorBox errorMessage={getErrorDescription(this.props.destroyFileError)}/>

        <FilesList files={this.props.files}
          updateFileError={this.props.updateFileError}
          createFileError={this.props.createFileError}/>
      </WhitePortlet>
    );
  }
}

FilesListContainer.propTypes = {
  files: PropTypes.array,
  getFilesError: PropTypes.object,
  createFileError: PropTypes.object,
  destroyFileError: PropTypes.object,
  updateFileError: PropTypes.object,
};
FilesListContainer.defaultProps = {};

const mapStateToProps = function(store) {
  return {
    files: store.filesState.files,
    getFilesError: store.filesState.getFilesError,
    createFileError: store.filesState.createFileError,
    destroyFileError: store.filesState.destroyFileError,
    updateFileError: store.filesState.updateFileError,
  };
};

export default connect(mapStateToProps)(FilesListContainer);
