/**
 * @author TomaszCzura ({}
 */
import * as types from './action-types';

export function getMobileUsersSuccess(users, totalPages, totalElements) {
  return {
    type: types.GET_MOBILE_USERS_SUCCESS,
    users, totalPages, totalElements,
  };
}

export function getReservationsSuccess(reservations, totalPages, totalElements) {
  return {
    type: types.GET_RESERVATION_SUCCESS,
    reservations, totalPages, totalElements,
  };
}

export function getMobileUsersError(error) {
  return {
    type: types.GET_MOBILE_USERS_ERROR,
    error,
  };
}

export function getMobileUserLogsSuccess(logs, totalPages, totalElements) {
  return {
    type: types.GET_MOBILE_USER_LOGS_SUCESS,
    logs, totalPages, totalElements,
  };
}

export function getMobileUserLogsError(error) {
  return {
    type: types.GET_MOBILE_USER_LOGS_ERROR,
    error,
  };
}

export function destroyMobileUserSuccess(mobileUserId) {
  return {
    type: types.DELETE_MOBILE_USER_SUCCESS,
    mobileUserId,
  };
}

export function destroyMobileUserError(error) {
  return {
    type: types.DELETE_MOBILE_USER_ERROR,
    error,
  };
}
