/**
 * @author TomaszCzura ({}
 */
import {combineReducers} from 'redux';
import * as types from '../actions/action-types';

// Reducers
import keyReducer from './key-reducer';
import userReducer from './user-reducer';
import siteReducer from './site-reducer';
import checkinReducer from './checkin-reducer';
import dashboardReducer from './dashboard-reducer';
import mobileUsersReducer from './mobile-user-reducer';
import transactionLogReducer from './transaction-log-reducer';
import developerReducer from './developer-reducer';
import fileReducer from './file-reducer';
import billingReducer from './billing-reducer';
import roomReducer from './room-reducer';
import specialareaReducer from './specialarea-reducer';
import siteAgentLogsReducer from './site-agent-log-reducer';
import {reducer as toastrReducer} from 'react-redux-toastr';

const reducers = combineReducers({
  keysState: keyReducer,
  usersState: userReducer,
  sitesState: siteReducer,
  checkinsState: checkinReducer,
  dashboardState: dashboardReducer,
  mobileUsersState: mobileUsersReducer,
  transactionLogsState: transactionLogReducer,
  developersState: developerReducer,
  filesState: fileReducer,
  billingState: billingReducer,
  roomsState: roomReducer,
  specialareasState: specialareaReducer,
  siteAgentLogsState: siteAgentLogsReducer,
  toastr: toastrReducer,
});

const rootReducer = (state, action) => {
  switch (action.type) {
    case types.USER_LOGOUT:
      state = undefined;
      break;
  }

  return reducers(state, action);
};

export default rootReducer;
