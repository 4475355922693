/**
 * @author TomaszCzura ({}
 */
import * as types from '../actions/action-types';
import * as auth from '../utils/auth';

const initialState = {
  users: [],
  presentedUser: {},
  userProfile: {},
  theLanguage: 'en',
  createUserError: null,
  getUsersError: null,
  getUserError: null,
  updateUserError: null,
  destroyUserError: null,
};

const usersReducer = function(state = initialState, action) {
  switch (action.type) {
    case types.NEW_USER_SUCCESS:
    {
      const newUsers = state.users.concat([action.user]);
      return Object.assign({}, state, {
        users: newUsers,
        createUserError: null,
        presentedUser: {},
      });
    }
    case types.NEW_USER_ERROR:
      return Object.assign({}, state, {
        keys: state.users,
        createUserError: action.error,
        presentedUser: {},
      });
    case types.GET_USERS_SUCCESS:
      return Object.assign({}, state, {
        users: action.users,
        getUsersError: null,
        presentedUser: {},
      });
    case types.GET_USERS_ERROR:
      return Object.assign({}, state, {
        users: [],
        getUsersError: action.error,
        presentedUser: {},
      });
    case types.GET_USER_SUCCESS:
      auth.setCurrentUser(action.presentedUser);
      return Object.assign({}, state, {
        presentedUser: action.presentedUser,
        getUserError: null,
      });
    case types.GET_USER_ERROR:
      return Object.assign({}, state, {
        presentedUser: {},
        getUserError: action.error,
      });
    case types.CHANGE_LANGUAGE:
      return Object.assign({}, state, {
        theLanguage: action.language,
      });
    case types.UPDATE_USER_SUCCESS:
    {
      const updatedUser = action.presentedUser;
      const newUsers = state.users.map((user) => user.id === action.presentedUser.id ? action.presentedUser : user);
      if (state.userProfile && state.userProfile.id === updatedUser.id) {
        return Object.assign({}, state, {
          users: newUsers,
          presentedUser: action.presentedUser,
          updateUserError: null,
          userProfile: action.presentedUser,
        });
      } else {
        return Object.assign({}, state, {
          users: newUsers,
          presentedUser: action.presentedUser,
          updateUserError: null,
        });
      }
    }
    case types.UPDATE_USER_ERROR:
      return Object.assign({}, state, {
        updateUserError: action.error,
      });
    case types.DELETE_USER_SUCCESS:
    {
      const newUsers = state.users.filter((user) => user.id !== action.userId);
      return Object.assign({}, state, {
        users: newUsers,
        destroyUserError: null,
        presentedUser: {},
      });
    }
    case types.DELETE_USER_ERROR:
      return Object.assign({}, state, {
        destroyUserError: action.error,
        presentedUser: {},
      });
    case types.GET_PROFILE_SUCCESS:
      auth.setCurrentUser(action.userProfile);
      return Object.assign({}, state, {
        userProfile: action.userProfile,
        error: null,
      });
    case types.GET_PROFILE_ERROR:
      return Object.assign({}, state, {
        userProfile: null,
        getUserError: action.error,
      });
    case types.CLEAR_USER_ERRORS:
      return Object.assign({}, state, {
        createUserError: null,
        getUsersError: null,
        getUserError: null,
        updateUserError: null,
        destroyUserError: null,
      });
  }

  return state;
};

export default usersReducer;
