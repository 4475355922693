/**
 * @author Tomasz Czura (9/7/16)
 */
import React from 'react';
import PropTypes from 'prop-types';
import Form from './../utils/Form';
import FormComponent from './../utils/FormComponent';
import ErrorBox from './../utils/ErrorBox';
import ModalDialog from './../utils/ModalDialog';
import TextInput from './../utils/TextInput';
import * as blockUtils from './../../utils/BlockUtils';
import * as fileModel from './../../model/file';
import * as filesApi from './../../api/files-api';
import {getErrorDescription} from './../../utils/view-utils';
import i18next from 'i18next';

class FileForm extends FormComponent {
  constructor(props) {
    super(props);

    this.sendForm = this.sendForm.bind(this);
    this.validateVersion = this.validateVersion.bind(this);
    this.validateName = this.validateName.bind(this);
    this.validateUrl = this.validateUrl.bind(this);
  }

  initialState() {
    return this.props.presentedFile ? fileModel.toForm(this.props.presentedFile) : fileModel.defaultLocal();
  }

  validateVersion(value = this.state.version) {
    return this.validateRequiredField(value, i18next.t('files.attrs.version'), 'versionError');
  }

  validateName(value = this.state.name) {
    return this.validateRequiredField(value, i18next.t('files.attrs.name'), 'nameError');
  }

  validateUrl(value = this.state.url) {
    return this.validateRequiredField(value, i18next.t('files.attrs.file_url'), 'urlError');
  }

  sendForm() {
    const validVersion = this.validateVersion();
    const validName = this.validateName();
    const validUrl = this.validateUrl();

    if (validName && validVersion && validUrl) {
      if (this.state.id) {
        filesApi.updateFile(this.state);
      } else {
        filesApi.createFile(this.state);
      }
      blockUtils.blockUI();
    }
  }

  render() {
    const modalTitle = this.state.id ? i18next.t('files.edit', {id: this.state.id}) : i18next.t('files.create_new');

    return (
      <ModalDialog modalTitle={modalTitle} dialogId={this.props.dialogId} onDialogHide={blockUtils.unblockUI}
        onDialogShown={this.onFormShow} onSubmit={this.sendForm}>
        <Form fileForm={true}>
          <ErrorBox errorMessage={getErrorDescription(this.state.error)}/>

          <TextInput fieldId="name" labelText={i18next.t('files.attrs.name')}
            isRequired={true} validate={this.validateName} error={this.state.nameError}
            onChange={this.onChange} value={this.state.name}/>

          <TextInput fieldId="version" labelText={i18next.t('files.attrs.version')}
            isRequired={true} validate={this.validateVersion} error={this.state.versionError}
            onChange={this.onChange} value={this.state.version}/>

          <TextInput fieldId="url" labelText={i18next.t('files.attrs.file_url')}
            isRequired={true} validate={this.validateUrl} error={this.state.urlError}
            onChange={this.onChange} value={this.state.url}/>

        </Form>

      </ModalDialog>
    );
  }
}

FileForm.propTypes = {
  presentedFile: PropTypes.object,
};
FileForm.defaultProps = {};

export default FileForm;
