/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import DataTable from './../utils/DataTable';
import i18next from 'i18next';
import * as usersApi from './../../api/users-api';
import * as blockUtils from './../../utils/BlockUtils';
import * as viewUtils from './../../utils/view-utils';
import EditAction from './../utils/actions/EditAction';
import TableActions from './../utils/TableActions';
import PopoverBtn from './../utils/PopoverBtn';
import SitesTableList from '../developers/SitesTableList';
import CreateNewButton from '../CreateNewButton';
import {onRowClick} from '../redux_data_table/ReduxDataTable';
import * as rights from '../../model/rights';
import RegisterNewUserForm from './RegisterNewUserForm';
import EditAnotherUserForm from './EditAnotherUserForm';
import TableAction from '../utils/actions/TableAction';
import BooleanLabel from '../utils/BooleanLabel';
import * as userModel from '../../model/user';

class UsersList extends Component {
  constructor(props) {
    super(props);
    this.userFormDialogId = 'userFormDialog';
    this.showUserForm = this.showUserForm.bind(this);
    this.changeUserState = this.changeUserState.bind(this);
  }

  changeUserState(user) {
    confirm(
        () => {
          blockUtils.blockUI('#users_table');
          const userCopy = userModel.toForm(user, this.props.availableRoles, this.props.sites);
          userCopy.enabled = !userCopy.enabled;
          usersApi.disableOrEnableUser(userCopy).then(() => user.enabled = !user.enabled).catch((err) => this.handleError(err));
        },
      user.enabled ?
        i18next.t('users.actions.sure_disable') :
        i18next.t('users.actions.sure_enable'),
    );
  }

  componentDidUpdate() {
    this.handleError();
  }

  handleError() {
    if (this.props.createUserError) {
      if (this.modalDialog) {
        this.modalDialog.handleError(this.props.createUserError);
      }
      blockUtils.unblockUI();
    } else {
      $(`#${this.userFormDialogId}`).modal('hide');
    }
  }

  showUserForm(user) {
    const props = {
      dialogId: this.userFormDialogId,
      error: this.props.createUserError,
      presentedUser: user,
      availableRoles: this.props.availableRoles,
      sites: this.props.sites,
    };
    this.modalDialog = viewUtils.showModal(user ? EditAnotherUserForm : RegisterNewUserForm, this.userFormDialogId, props);
  }

  render() {
    return (
      <div className="table-container">
        {rights.currentUserHasRight(rights.USER_ADD_PRIVILEGE) ?
          (<CreateNewButton callBack={() => this.showUserForm()} />) :
          null
        }
        <DataTable tableId="users_table" notSortable={[5]}>
          <thead>
            <tr role="row" className="heading">
              <th>ID</th>
              <th>{i18next.t('users.attrs.login')}</th>
              <th>{i18next.t('users.attrs.role')}</th>
              <th>{i18next.t('sites.attrs.enabled')}</th>
              <th>{i18next.t('users.attrs.site_name')}</th>
              <th>{i18next.t('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.users.map((user) => {
              return (
                <tr onClick={() => onRowClick(user.id)} key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.username ? user.username : user.email}</td>
                  <td>{i18next.t([`users.roles.${user.role}`])}</td>
                  <td><BooleanLabel value={user.enabled}/></td>
                  <td>
                    { (user.sites && user.sites.length) > 0 ?
                      (<PopoverBtn viewClass='btn btn-xs green'
                        popoverContent={ReactDOMServer.renderToStaticMarkup(<SitesTableList sites={user.sites}/>)}
                        title={`${i18next.t('sites.name', {count: 2})}: ${user.sites.length}`}/>) :
                      <button className="btn btn-xs red popover_btn">
                        {`${i18next.t('sites.name', {count: 2})}: 0`}
                      </button>}
                  </td>
                  <td>
                    <TableActions identifier={user.id}>
                      <EditAction
                        onEdit={() => this.showUserForm(user)}
                        condition={user.enabled && rights.currentUserHasRight(rights.USER_EDIT_PRIVILEGE)}
                      />
                      <TableAction
                        text={i18next.t('sites.actions.enable')}
                        action={() => this.changeUserState(user)}
                        condition={user.enabled !== true}
                        icon="icon-check"
                      />
                      <TableAction
                        text={i18next.t('sites.actions.disable')}
                        action={() => this.changeUserState(user)}
                        condition={user.enabled === true}
                        icon="icon-ban"
                      />
                    </TableActions>
                  </td>
                </tr>);
            })}
          </tbody>
        </DataTable>
      </div>
    );
  }
}

UsersList.propTypes = {
  users: PropTypes.array,
  createUserError: PropTypes.any,
  availableRoles: PropTypes.array,
  sites: PropTypes.array,
};
UsersList.defaultProps = {};

export default UsersList;
