/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import BooleanLabel from './BooleanLabel';

class BooleanInfo extends Component {
  render() {
    return (
      <div className="form-group form-md-line-input">
        <label className="col-md-2 control-label" >{this.props.labelText} </label>
        <div className="col-md-5">
          <div className="form-control form-control-static">
            <BooleanLabel value={this.props.value}/>
          </div>
        </div>
      </div>
    );
  }
}

BooleanInfo.propTypes = {
  value: PropTypes.bool,
  labelText: PropTypes.string,
};
BooleanInfo.defaultProps = {};

export default BooleanInfo;
