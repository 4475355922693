/**
 * @author TomaszCzura ({}
 */
import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import * as siteModel from './../../model/site';
import * as blockUtils from './../../utils/BlockUtils';
import FormComponent from './../utils/FormComponent';
import Form from './../utils/Form';
import ModalDialog from './../utils/ModalDialog';
import TextInput from './../utils/TextInput';
import * as sitesApi from './../../api/sites-api';
import {isValidTime} from './../../utils/validate-utils';
import ErrorBox from './../utils/ErrorBox';
import {getErrorDescription} from './../../utils/view-utils';

class SiteDefaultsForm extends FormComponent {
  constructor(props) {
    super(props);

    this.sendForm = this.sendForm.bind(this);
    this.validateCheckinTime = this.validateCheckinTime.bind(this);
    this.validateCheckoutTime = this.validateCheckoutTime.bind(this);
    this.onDisconnectionNotificationChanged = this.onDisconnectionNotificationChanged.bind(this);
  }

  sendForm() {
    const validateCheckin = this.validateCheckinTime();
    const validateCheckout = this.validateCheckoutTime();

    if (validateCheckin && validateCheckout) {
      if (this.state.id) {
        sitesApi.updateSite(this.state);
      } else {
        sitesApi.createSite(this.state);
      }

      blockUtils.blockUI();
    }
  }

  initialState() {
    const state = this.props.presentedSite ? siteModel.toForm(this.props.presentedSite) : siteModel.defaultSite();
    state['nameError'] = '';
    state['checkinError'] = '';
    state['checkoutError'] = '';
    state['error'] = '';
    return state;
  }

  onDisconnectionNotificationChanged(e) {
    const state = {};
    const isEnabled = e.target.checked;
    state[e.target.name] = isEnabled;
    if (!isEnabled && !this.state.contactEmail) {
      state['checkinError'] = '';
      state['checkoutError'] = '';
    }
    this.setState(state);
  }

  validateCheckinTime(input = this.state.defaultCheckinTime) {
    if (input) {
      if (isValidTime(input)) {
        this.setState({checkinError: ''});
        return true;
      } else {
        this.setState({
          checkinError: i18next.t('errors.invalid_format',
              {
                field: i18next.t('sites.attrs.default_checkin_time'),
              }),
        });
        return false;
      }
    } else {
      this.setState({
        checkinError: '',
      });
      return true;
    }
  }

  validateCheckoutTime(input = this.state.defaultCheckoutTime) {
    if (input) {
      if (isValidTime(input)) {
        this.setState({checkoutError: ''});
        return true;
      } else {
        this.setState({
          checkoutError: i18next.t('errors.invalid_format',
              {
                field: i18next.t('sites.attrs.default_checkout_time'),
              }),
        });
        return false;
      }
    } else {
      this.setState({
        checkoutError: '',
      });
      return true;
    }
  }

  handleError(errorMessage) {
    this.setState({
      error: errorMessage,
    });
  }

  render() {
    const modalTitle = this.state.id ?
      i18next.t('sites.editdefaults', {name: this.state.name}) :
      i18next.t('sites.create_new');

    return (
      <ModalDialog modalTitle={modalTitle}
        dialogId={this.props.dialogId}
        onDialogHide={blockUtils.unblockUI}
        onDialogShown={this.onFormShow}
        onSubmit={this.sendForm}>
        <Form>
          <ErrorBox errorMessage={getErrorDescription(this.state.error)}/>

          <TextInput fieldId="defaultSpecialAreas"
            labelText={i18next.t('sites.attrs.default_special_areas')}
            isRequired={false}
            onChange={this.onChange}
            value={this.state.defaultSpecialAreas} helpText="1,2,3,4"/>

          <TextInput fieldId="defaultCheckinTime"
            labelText={i18next.t('sites.attrs.default_checkin_time')}
            isRequired={false}
            error={this.state.checkinError}
            onChange={this.onChange}
            validate={this.validateCheckinTime}
            value={this.state.defaultCheckinTime}
            helpText="HH:MM"/>

          <TextInput fieldId="defaultCheckoutTime"
            labelText={i18next.t('sites.attrs.default_checkout_time')}
            isRequired={false}
            error={this.state.checkoutError}
            onChange={this.onChange}
            validate={this.validateCheckoutTime}
            value={this.state.defaultCheckoutTime}
            helpText="HH:MM"/>

        </Form>
      </ModalDialog>
    );
  }
}

SiteDefaultsForm.propTypes = {
  dialogId: PropTypes.string,
  presentedSite: PropTypes.object,
  error: PropTypes.any,
};

SiteDefaultsForm.defaultProps = {};

export default SiteDefaultsForm;
