/**
 * @author TomaszCzura ({}
 */
import moment from 'moment';
import i18next from 'i18next';
import {
  selectedSiteOrFirstId,
  nameOfSite,
  defaultSpecialAreasOfSite,
  defaultCheckinTimeOfSite,
  defaultCheckoutTimeOfSite,
} from '../store';

export function toLocal(booking) {
  let siteId = booking.siteId;
  if (!siteId && booking.site) {
    siteId = booking.site.id;
  }
  let siteName = booking.site ? booking.site.name : '';
  if (!siteName && siteId !== -1) {
    siteName = nameOfSite(siteId);
  }

  return {
    id: booking.id,
    firstName: booking.firstName,
    lastName: booking.lastName,
    checkinDate: booking.startDate,
    checkoutDate: booking.endDate,
    siteId: siteId,
    siteName: siteName,
    room: booking.room,
    bed: booking.bed,
    reservationId: booking.externalReservationId,
    keys: booking.keys,
    emails: booking.emails || [],
    specialAreas: booking.specialAreas,
  };
}

export function toNetwork(booking) {
  return {
    id: booking.id,
    guestFirstName: booking.firstName,
    guestLastName: booking.lastName,
    startDate: moment(booking.checkinDate, 'YYYY-MM-DD H:mm').format('YYYY-MM-DDTHH:mm:00.000Z'),
    endDate: moment(booking.checkoutDate, 'YYYY-MM-DD H:mm').format('YYYY-MM-DDTHH:mm:00.000Z'),
    siteId: booking.siteId,
    roomNumber: booking.room || null,
    bedNumber: booking.bed,
    reservationId: booking.reservationId || null,
    keys: booking.keys || null,
    emails: booking.emails || [],
    specialAreas: booking.specialAreas,
  };
}

export function toForm(booking) {
  const siteId = selectedSiteOrFirstId();
  const siteName = nameOfSite(siteId);

  return {
    id: booking.id,
    firstName: booking.firstName || '',
    lastName: booking.lastName || '',
    checkinDate: moment(new Date(booking.checkinDate)).format('YYYY-MM-DD H:mm'),
    checkoutDate: moment(new Date(booking.checkoutDate)).format('YYYY-MM-DD H:mm'),
    siteId: booking.siteId || siteId,
    siteName: booking.siteName || siteName,
    room: booking.room || '',
    bed: booking.bed || '',
    reservationId: booking.reservationId || '',
    keys: booking.keys || [],
    emails: booking.emails || [],
    specialAreas: booking.specialAreas || '',
  };
}

export function defaultFormModel() {
  const siteId = selectedSiteOrFirstId();
  const siteName = nameOfSite(siteId);
  const defaultSpecialAreas = defaultSpecialAreasOfSite(siteId);
  const defaultCheckinTime = defaultCheckinTimeOfSite(siteId);
  const defaultCheckoutTime = defaultCheckoutTimeOfSite(siteId);

  return {
    id: '',
    firstName: '',
    lastName: '',
    checkinDate: moment(new Date()).hours(12).minutes(0).format('YYYY-MM-DD ') + defaultCheckinTime,
    checkoutDate: moment(new Date()).hours(12).minutes(0).add(2, 'days').format('YYYY-MM-DD ') + defaultCheckoutTime,
    siteId: siteId,
    siteName: siteName,
    room: '',
    bed: '',
    reservationId: '',
    keys: [],
    emails: [],
    specialAreas: defaultSpecialAreas,
  };
}

export function isValid(booking) {
  const checkoutDate = moment(booking.checkoutDate);
  return checkoutDate.isAfter();
}

export function isMatched(booking) {
  return booking.keys && booking.keys.length > 0;
}

export function canGenerateKey(booking) {
  return isValid(booking) && isMatched(booking) && isCheckedIn(booking);
}

export function roomBedDescription(booking) {
  let desc = booking.room || '';
  if (booking.bed) {
    desc = `${desc}, ${i18next.t('checkins.attrs.bed')}: ${booking.bed}`;
  }
  return desc;
}

export function hasKeys(booking) {
  if (booking.keys) {
    return booking.keys.length > 0;
  }
  return false;
}

export function isCheckedIn(booking) {
  return !!booking.room;
}

export function getState(booking) {
  const guests = !booking.emails ? '0': `${booking.emails.length}`;
  return `${i18next.t('checkins.attrs.status')} : ` + guests;
}
