/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

class AlertDialog extends Component {
  constructor(props) {
    super(props);
    this.confirm = this.confirm.bind(this);
  }

  confirm() {
    $('#alert_dialog').modal('hide');
    if (this.props.onOkClick) {
      this.props.onOkClick();
    }
  }

  render() {
    return (
      <div className="modal fade"
        id="alert_dialog"
        role="basic"
        aria-hidden="false"
        data-keyboard="false"
        data-backdrop="static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              />
              <h4 className="modal-title">{ this.props.title}</h4>
            </div>
            <div className="modal-body"
              dangerouslySetInnerHTML={{__html: this.props.text}}
            />
            <div className="modal-footer">
              <button
                type="button"
                className="btn blue"
                onClick={this.confirm}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AlertDialog.propTypes = {
  onOkClick: PropTypes.func,
  text: PropTypes.string,
  title: PropTypes.string,
};
AlertDialog.defaultProps = {};

export default AlertDialog;
