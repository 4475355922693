/**
 * @author Marcus Wolschon (2019-12-02 mnarcus.wolschon@sphinx-electronics.de)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import WhitePortlet from '../utils/WhitePortlet';
import SpecialareasList from './SpecialareasList';
import i18next from 'i18next';
import {connect} from 'react-redux';
import * as sitesApi from './../../api/sites-api';
import ErrorBox from './../utils/ErrorBox';
import {getErrorDescription} from './../../utils/view-utils';
import {selectedSiteId} from './../../store';

class SpecialareasListContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      siteId: selectedSiteId(),
    };
  }

  componentDidMount() {
    const siteId = this.state.siteId;
    if (siteId > 0) {
      sitesApi.getSiteSpecialareas(siteId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.siteId != this.state.siteId) {
      this.setState({
        siteId: nextProps.siteId,
      });
      if (nextProps.siteId > 0) {
        sitesApi.getSiteSpecialareas(nextProps.siteId);
      }
    }
  }

  render() {
    return (
      <WhitePortlet caption={i18next.t('specialareas.name', {count: 2})} icon="icon-grid">
        <ErrorBox errorMessage={getErrorDescription(this.props.getSpecialareasError)}/>
        <ErrorBox errorMessage={getErrorDescription(this.props.updateSpecialAreaError)}/>
        {this.state.siteId > 0 ?
          <SpecialareasList
            specialareas={this.props.specialareas}
            siteId={this.props.siteId}
            createSpecialareaError={this.props.createSpecialareaError}
          /> :
          <ErrorBox errorMessage={i18next.t('specialareas.errors.select_site')}/>
        }

      </WhitePortlet>
    );
  }
}

SpecialareasListContainer.propTypes = {
  params: PropTypes.any,
  site: PropTypes.object,
  siteId: PropTypes.number,
  specialareas: PropTypes.array,
  getSpecialareasError: PropTypes.object,
  createSpecialareaError: PropTypes.object,
  updateSpecialAreaError: PropTypes.object,
};
SpecialareasListContainer.defaultProps = {};

const mapStateToProps = function(store) {
  const currentSiteId = store.sitesState.currentSiteId == -1 ? selectedSiteId() : store.sitesState.currentSiteId;

  return {
    specialareas: store.specialareasState.specialareas,
    getSpecialareasError: store.specialareasState.getSpecialareasError,
    createSpecialareaError: store.specialareasState.createSpecialareaError,
    updateSpecialAreaError: store.specialareasState.updateSpecialAreaError,
    siteId: currentSiteId,
  };
};

export default connect(mapStateToProps)(SpecialareasListContainer);
