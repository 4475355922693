/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

class UserTypeLabel extends Component {
  render() {
    let text;
    let style;
    switch (this.props.value) {
      case 'VERIFIED_USER':
        text = i18next.t('users.user_types.verified');
        style = {backgroundColor: '#830086'};
        break;
      case 'UNVERIFIED_USER':
        text = i18next.t('users.user_types.unverified');
        style = {backgroundColor: '#002670'};
        break;
      default:
        style = {backgroundColor: '#0088A6'};
        text = i18next.t('users.user_types.other');
    }

    return (
      <span style={style} className="label label-sm">
        {text}
      </span>
    );
  }
}

UserTypeLabel.propTypes = {
  value: PropTypes.string,
};
UserTypeLabel.defaultProps = {};

export default UserTypeLabel;
