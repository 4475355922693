/**
 * @author TomaszCzura ({}
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import ReduxDataTable from './../redux_data_table/ReduxDataTable';
import SortableDate from './../utils/SortableDate';
import {getLockCodeText} from '../../model/transaction-log';
import BooleanLabel from '../utils/BooleanLabel';

class TransactionLogsList extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="table-container">
        <ReduxDataTable {...this.props} notSortable={[0, 8]}>
          <thead>
            <tr role="row" className="heading">
              <th>{i18next.t('keys.attrs.site_name')}</th>
              <th data-sort="type">{i18next.t('transaction_logs.attrs.type')}</th>
              <th data-sort="source">{i18next.t('transaction_logs.attrs.source')}</th>
              <th data-sort="reservationId">{i18next.t('checkins.attrs.reservation_id')}</th>
              <th data-sort="room">{i18next.t('checkins.attrs.room')}</th>
              <th data-sort="mobileUserEmail">{i18next.t('users.attrs.email')}</th>
              <th data-sort="verified">{i18next.t('users.user_types.verified')}</th>
              <th data-sort="osVersion">{i18next.t('transaction_logs.attrs.os_version')}</th>
              <th>{i18next.t('transaction_logs.attrs.terminal_error_code')}</th>
              <th data-sort="terminalBatteryLevel">{i18next.t('transaction_logs.attrs.terminal_battery_level')}</th>
              <th data-sort="createdAt">{i18next.t('transaction_logs.attrs.event_date')}</th>
            </tr>
          </thead>
          <tbody>
            {
              this.props.logs.map((log) => {
                return (
                  <tr key={log.id} className="odd">
                    <td>{log.siteName}</td>
                    <td>{log.type.toUpperCase()}</td>
                    <td>{log.source.toUpperCase()}</td>
                    <td>{log.reservationId}</td>
                    <td>{log.room}</td>
                    <td>{log.mobileUserEmail}</td>
                    <td>{log.verified === null ? null : <BooleanLabel value={log.verified} />} </td>
                    <td>{log.osVersion}</td>
                    <td>{log.terminalErrorCodeAsMessage === '' ? '' : getLockCodeText(log.terminalErrorCodeAsMessage)}</td>
                    <td>{log.terminalBatteryLevel}</td>
                    <td><SortableDate dateMillis={log.reportedAt}/></td>
                  </tr>
                );
              })
            }
          </tbody>
        </ReduxDataTable>
      </div>
    );
  }
}

TransactionLogsList.propTypes = {
  logs: PropTypes.array,
  totalElements: PropTypes.number,
  totalPages: PropTypes.number,
  onPageChange: PropTypes.func,
  onElementsCountChange: PropTypes.func,
  elementsCount: PropTypes.number,
  tableId: PropTypes.string,
};
TransactionLogsList.defaultProps = {};

export default TransactionLogsList;
