/**
 * @author Marcus Wolschon marcus.wolschon@sphinx-electronics.de
 */

// TrustCenter Java class: com.mitrako.trustcenter.model.SpecialAreaEditData
export function toNetwork(specialarea) {
  return {
    areaName: specialarea.areaName,
    areaNumber: specialarea.areaNumber,
  };
}

// TrustCenter Java class: com.mitrako.trustcenter.model.SpecialArea
export function toForm(specialarea) {
  return {
    id: specialarea.id,
    siteId: specialarea.siteId,
    areaName: specialarea.areaName,
    areaNumber: specialarea.areaNumber,
  };
}

export function defaultSpecialArea() {
  return {
    areaName: '',
    areaNumber: '',
  };
}
