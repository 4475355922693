/**
 * @author Tomasz Czura (11/10/16)
 */
import * as types from '../actions/action-types';

const initialState = {
  billing: [],
  getBillingError: null,
};

const billingReducer = function(state = initialState, action) {
  switch (action.type) {
    case types.GET_BILLING_SUCCESS:
      return Object.assign({}, state, {billing: action.billing, getBillingError: null});
    case types.GET_BILLING_ERROR:
      return Object.assign({}, state, {billing: [], getBillingError: action.error});
    case types.CURRENT_SITE_CHANGE:
      return Object.assign({}, state, {billing: [], getBillingError: null});
  }
  return state;
};

export default billingReducer;
