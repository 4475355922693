/**
 * @author Tomasz Czura (11/7/16)
 */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import TransactionLogsList from './../transaction_logs/TransactionLogsList';
import WhitePortlet from '../utils/WhitePortlet';
import RoleBasedComponent from '../utils/RoleBasedComponent';
import * as rights from '../../model/rights';
import i18next from 'i18next';
import * as bookingsApi from './../../api/bookings-api';
import ErrorBox from './../utils/ErrorBox';
import {getErrorDescription} from '../../utils/view-utils';
import * as blockUtils from './../../utils/BlockUtils';

class BookingLogsContainer extends RoleBasedComponent {
  constructor(props) {
    super(props);
    this.tableId = 'booking_logs_table';
    this.downloadBookingLogs = this.downloadBookingLogs.bind(this);

    this.pageNumber = 0;
    this.elementsCount = 10;
    this.sortingName = 'createdAt';
    this.sortingType = 'desc';
    this.searchPhrase = '';
  }

  downloadBookingLogs(pageNumber = this.pageNumber, elementsCount = this.elementsCount, sortingName = this.sortingName,
      sortingType = this.sortingType, searchPhrase = this.searchPhrase) {
    bookingsApi.getLogs(this.props.params.bookingId, pageNumber, elementsCount, sortingName, sortingType, searchPhrase);
    blockUtils.blockUI(`#${this.tableId}`);

    this.pageNumber = pageNumber;
    this.elementsCount = elementsCount;
    this.sortingName = sortingName;
    this.sortingType = sortingType;
    this.searchPhrase = searchPhrase;
  }

  componentDidMount() {
    this.checkRights(() => this.downloadBookingLogs());
  }

  componentDidUpdate() {
    blockUtils.unblockUI(`#${this.tableId}`);
  }

  getRights() {
    return rights.SITE_READ_PRIVILEGE;
  }

  render() {
    return (
      <WhitePortlet caption={i18next.t('transaction_logs.name', {count: 2})} icon="icon-list">
        <ErrorBox errorMessage={getErrorDescription(this.props.getLogsError)}/>

        <TransactionLogsList logs={this.props.logs} error={this.props.error} getData={this.downloadBookingLogs}
          tableId={this.tableId} defaultSort={[2, 'desc']}
          totalPages={this.props.totalPages} totalElements={this.props.totalElements} />
      </WhitePortlet>
    );
  }
}

BookingLogsContainer.propTypes = {
  logs: PropTypes.array,
  error: PropTypes.any,
  getLogsError: PropTypes.object,
  totalElements: PropTypes.number,
  totalPages: PropTypes.number,
};
BookingLogsContainer.defaultProps = {};

const mapStateToProps = function(store) {
  return {
    logs: store.checkinsState.logs,
    getLogsError: store.checkinsState.getLogsError,
    totalPages: store.checkinsState.logsTotalPages,
    totalElements: store.checkinsState.logsTotalElements,
  };
};

export default connect(mapStateToProps)(BookingLogsContainer);
