/**
 * @author Tomasz Czura (9/7/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TableAction from './TableAction';
import i18next from 'i18next';

class EditDefaultsAction extends Component {
  render() {
    return (
      <TableAction text={i18next.t('links.editdefaults')} action={() => this.props.onEdit()}
        condition={this.props.condition} icon="icon-calendar"/>
    );
  }
}

EditDefaultsAction.propTypes = {
  onEdit: PropTypes.func,
  condition: PropTypes.bool,
};
EditDefaultsAction.defaultProps = {};

export default EditDefaultsAction;
