/**
 * @author Tomasz Czura (9/7/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {isMobile} from 'react-device-detect';

class TableActions extends Component {
  render() {
    return (
      <div className={isMobile ? 'min-height: 30px;' : 'actions'}>
        <div className="btn-group" id={this.props.identifier}>
          <button type="button"
            className="btn gray btn-sm dropdown-toggle"
            data-toggle="dropdown"
            data-hover="dropdown"
            data-close-others="true"
            aria-expanded="false"
          >
            {/* <span className="hidden-sm hidden-xs">{i18next.t('actions')}</span>*/}
            <i className="fa fa-cog"/>
            <i className="fa fa-angle-down"/>
          </button>
          <ul className="dropdown-menu in-table dropup" role="menu" style={{left: 'auto', right: 0, marginRight: '5px'}}>
            {this.props.children}
          </ul>
        </div>
      </div>
    );
  }
}

TableActions.propTypes = {
  children: PropTypes.node,
  identifier: PropTypes.any,
};
TableActions.defaultProps = {};

export default TableActions;
