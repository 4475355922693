/**
 * @author Tomasz Czura (11/18/16)
 */
import * as types from './../actions/action-types';

const initialState = {
  logs: [],
  getLogsError: null,
  getLogError: null,
  presentedLog: {},
};

const siteAgentLogsReducer = function(state = initialState, action) {
  switch (action.type) {
    case types.GET_SITE_AGENT_LOGS_SUCCESS:
      return Object.assign({}, state, {logs: action.logs, getLogsError: null});
    case types.GET_SITE_AGENT_LOGS_ERROR:
      return Object.assign({}, state, {logs: [], getLogsError: action.error});
    case types.GET_SITE_AGENT_LOG_SUCCESS:
      return Object.assign({}, state, {presentedLog: action.log, getLogError: null});
    case types.GET_SITE_AGENT_LOG_ERROR:
      return Object.assign({}, state, {presentedLog: {}, getLogError: action.error});
  }
  return state;
};

export default siteAgentLogsReducer;
