/**
 * @author Tomasz Czura (9/7/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import InnerPortlet from './../utils/InnerPortlet';
import i18next from 'i18next';
import TextInfo from '../utils/TextInfo';
import CopyToClipboard from 'react-copy-to-clipboard';

class TokenPortlet extends Component {
  constructor(props) {
    super(props);

    this.onRevoke = this.onRevoke.bind(this);
    this.onRegenerate = this.onRegenerate.bind(this);
  }

  onRegenerate() {
    confirm(() => this.props.onRegenerate(this.props.developer), i18next.t('developers.sure_to_regenerate'));
  }

  onRevoke() {
    confirm(() => this.props.onRevoke(this.props.developer), i18next.t('developers.sure_to_revoke'));
  }

  render() {
    const actions =
            <div className="actions">
              <button type="button" className="btn btn-warning btn-circle" onClick={this.onRegenerate}
                style={{marginRight: 5}}>{i18next.t('developers.actions.regenerate')}
              </button>
              <button type="button"
                className="btn btn-danger btn-circle"
                onClick={this.onRevoke}
                disabled={!this.props.token || !this.props.token.secret}
              >
                {i18next.t('developers.actions.revoke')}
              </button>
            </div>;

    return (
      <div className="col-md-12">
        {this.props.token ?
                    <InnerPortlet title={this.props.title} actions={actions}>
                      <TextInfo labelText={i18next.t('developers.attrs.token_id')} value={this.props.token.id}/>
                      <TextInfo labelText={i18next.t('developers.attrs.token_secret')}
                        value={this.props.token.secret}/>
                      <TextInfo labelText={i18next.t('developers.attrs.encoded_token')}
                        value={this.props.encoded} textCols="6">

                        {this.props.encoded ?
                            <CopyToClipboard text={this.props.encoded}>
                              <button type="button"
                                className="btn btn-xs copy_btn">{i18next.t('links.copy')}</button>
                            </CopyToClipboard> :
                            null
                        }
                      </TextInfo>
                    </InnerPortlet> :
                    null
        }
      </div>
    );
  }
}

TokenPortlet.propTypes = {
  title: PropTypes.string,
  encoded: PropTypes.string,
  token: PropTypes.object,
  onRevoke: PropTypes.func,
  onRegenerate: PropTypes.func,
  developer: PropTypes.object,
};
TokenPortlet.defaultProps = {};

export default TokenPortlet;
