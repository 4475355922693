/**
 * @author TomaszCzura ({}
 */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import TransactionLogsList from './TransactionLogsList';
import WhitePortlet from '../utils/WhitePortlet';
import RoleBasedComponent from '../utils/RoleBasedComponent';
import {selectedSiteId} from '../../store';
import * as rights from '../../model/rights';
import i18next from 'i18next';
import * as logsApi from './../../api/transaction-logs-api';
import ErrorBox from './../utils/ErrorBox';
import {getErrorDescription} from '../../utils/view-utils';
import * as blockUtils from './../../utils/BlockUtils';

class TransactionLogsListContainer extends RoleBasedComponent {
  constructor(props) {
    super(props);

    this.tableId = 'transaction_logs_table';

    this.downloadTransactionLogs = this.downloadTransactionLogs.bind(this);

    this.pageNumber = 0;
    this.elementsCount = 10;
    this.sortingName = '';
    this.sortingType = '';
    this.searchPhrase = '';
  }

  downloadTransactionLogs(pageNumber = this.pageNumber,
      elementsCount = this.elementsCount,
      sortingName = this.sortingName,
      sortingType = this.sortingType,
      searchPhrase = this.searchPhrase,
      siteId = this.props.siteId) {
    logsApi.getTransactionLogs(pageNumber, elementsCount, sortingName, sortingType, searchPhrase, siteId);
    blockUtils.blockUI(`#${this.tableId}`);

    this.pageNumber = pageNumber;
    this.elementsCount = elementsCount;
    this.sortingName = sortingName;
    this.sortingType = sortingType;
    this.searchPhrase = searchPhrase;
  }

  componentDidMount() {
    this.checkRights(() => this.downloadTransactionLogs(this.pageNumber, this.elementsCount, 'createdAt', 'desc'));
  }

  componentDidUpdate() {
    blockUtils.unblockUI(`#${this.tableId}`);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.siteId !== this.props.siteId) {
      this.downloadTransactionLogs(this.pageNumber,
          this.elementsCount,
          this.sortingName,
          this.sortingType,
          this.searchPhrase,
          nextProps.siteId);
    }
  }

  getRights() {
    return rights.LOG_READ_PRIVILEGE;
  }

  render() {
    return (
      <WhitePortlet caption={i18next.t('transaction_logs.name', {count: 2})} icon="icon-list">
        <ErrorBox errorMessage={getErrorDescription(this.props.getLogsError)}/>

        <TransactionLogsList logs={this.props.logs} error={this.props.error} getData={this.downloadTransactionLogs}
          tableId={this.tableId} defaultSort={[2, 'desc']}
          totalPages={this.props.totalPages} totalElements={this.props.totalElements}/>
      </WhitePortlet>
    );
  }
}

TransactionLogsListContainer.propTypes = {
  logs: PropTypes.array,
  error: PropTypes.any,
  getLogsError: PropTypes.object,
  totalElements: PropTypes.number,
  totalPages: PropTypes.number,
};
TransactionLogsListContainer.defaultProps = {};

const mapStateToProps = function(store) {
  return {
    logs: store.transactionLogsState.transactionLogs,
    getLogsError: store.transactionLogsState.getLogsError,
    totalPages: store.transactionLogsState.totalPages,
    totalElements: store.transactionLogsState.totalElements,
    siteId: selectedSiteId(),
  };
};

export default connect(mapStateToProps)(TransactionLogsListContainer);
