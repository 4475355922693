/**
 * @author Tomasz Czura (12/13/16)
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

class StaticInfo extends Component {
  render() {
    return (
      <div className="row static-info">
        <div className={`col-md-${this.props.titleColumns} name`}> {this.props.title}: </div>
        <div className={`col-md-${this.props.valueColumns} value`}> {this.props.value}
        </div>
      </div>
    );
  }
}

StaticInfo.propTypes = {
  titleColumns: PropTypes.string,
  valueColumns: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.any,
};
StaticInfo.defaultProps = {
  titleColumns: '2',
  valueColumns: '10',
};

export default StaticInfo;
